import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Typography, Box, Button } from "@mui/material";
import { advertisementLinks, advertisementDetails } from "../constants/advertisement";

const AdvertisementDetails = () => {
  const navigate = useNavigate(); // Для переходу назад
  const { id } = useParams(); // Отримуємо ID з URL
  const card = advertisementLinks.find((card) => card.id === parseInt(id)); // Знаходимо відповідну картку
  const details = advertisementDetails[id]?.details; // Отримуємо деталі для картки

  if (!card) {
    return (
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "50px",
        }}
      >
        <Typography variant="h4" color="error">
          Card not found
        </Typography>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: "50px",
      }}
    >
      <Box>

        {/* Перевіряємо, чи є деталі, і рендеримо */}
        {details && details.map((paragraph, index) => (
          <Typography
            key={index}
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: paragraph.bold ? "bold" : "normal",
              textAlign: paragraph.center ? "center" : "justify",
              marginBottom: "15px",
              whiteSpace: "pre-line"

            }}
          >
            {paragraph.text}
          </Typography>
        ))}
        <Button
          variant="contained"
          onClick={() => navigate(-1)} // Повертаємося назад
          sx={{
            marginTop: "20px",
            backgroundColor: "#cab397", // Встановлюємо кастомний колір фону
            color: "#fff", // Колір тексту
            "&:hover": {
              backgroundColor: "#b09a86", // Колір при наведенні
            },
          }}
        >
          Назад
        </Button>
      </Box>
    </Container>
  );
};

export default AdvertisementDetails;
