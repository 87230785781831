import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import links from "../../constants/links";
import { Stack, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const MobileMenu = ({ active, handleClose }) => {
  const [submenu, setSubmenu] = useState(false);

  const mobileSubMenu = Object.values(links.subHeaderMenu).map((link) => (
    <NavLink
      key={link.id}
      to={`${link.link}`}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      onClick={handleClose}
    >
      <li className="mobile__submenu__link">{link.name}</li>
    </NavLink>
  ));

  const navLinks = links.headerLinks.map((link) => {
    if (link.id === 2) {
      return (
        <li
          key={link.id}
          className="mobile__link mobile__link-dropdown"
          onClick={() => setSubmenu(!submenu)}
        >
          <span className="mobile__link-dropdown-content">
            {link.name}
            <ArrowDownwardIcon
              className={submenu ? 'mobile__submenu-icon active' : 'mobile__submenu-icon'}
              style={{ width: "0.8em", height: "0.8em" }}
            />
          </span>
          <ul
            className={submenu ? "mobile__submenu active" : "mobile__submenu"}
            style={{ margin: 0 }}
          >
            {mobileSubMenu}
          </ul>
        </li>
      );
    } else {
      return (
        <NavLink
          onClick={handleClose}
          key={link.id}
          to={link.link}
          className={({ isActive }) =>
            isActive ? "mobile__link active" : "mobile__link"
          }
        >
          {link.name}
        </NavLink>
      );
    }
  });

  return (
    <>
      <Stack className={active ? "menu active" : "menu"}>
        {navLinks}
        <Button
          sx={{ position: "absolute", top: 20, right: 20, color: "#000" }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </Button>
      </Stack>
    </>
  );
};

export default MobileMenu;
