import React, {useState} from 'react';
import { Routes, Route, NavLink} from "react-router-dom"; 
import '../App.css';
import Documents from './Documents';
import Principal from './Principal';
import {Stack, Typography, Box, Container, Grid} from '@mui/material';

import Sertificate from './Sertificate';
import Agreement from './Agreement';
import {motion} from 'framer-motion';
import {animations} from '../constants';
import links from '../constants/links';

const About = () => {

  const [title, setTitle] = useState('Свідоцтво')



  return (
    <Container
      component={motion.div}     
      variants={animations.sectionAnimation}
      initial='offscreen'
      animate='onscreen' 
    >
      <Grid container sx={{paddingTop: '90px'}}>
        <Grid item md={4} sx={{display: 'flex', flexDirection: 'column', gap: '30px', position: {md: 'sticky', xs: 'relative'}, top: {md: '30px', xs: 0}, height: '100%'}}>
          <Typography
           sx={{
            fontSize: '32px', 
            fontWeight: 700, 
            textTransform: 'uppercase', 
            color: '#303030'
            }}
          > 
            {title}
          </Typography>
          {/* <Link to='/documents/principal' onClick={() => setTitle('Положення')}>
            Положення
          </Link>
          <Link to='/documents/regulations'>
            Регламент
          </Link>
          <Link to='/documents/sertificate'>
            Свідоцтво
          </Link>
          <Link to='/documents/agreement'>
            Третейська угода
          </Link> */}
          {links.documentsLinks.map((link, id) => (
            <NavLink 
              key={id} 
              to={link.link} 
              onClick={() => setTitle(link.name)}
              className={({isActive}) => (isActive ? 'page__link active' : 'page__link')}
              >
                {link.name}
            </NavLink>
          ))}
        </Grid>
        <Grid item md={8}>
          <Routes>
             
              <Route path="principal" element={ <Principal/> } />
              
              <Route path="sertificate" element={ <Sertificate /> } />
              <Route path='agreement' element={<Agreement />}></Route>
          </Routes>
        </Grid>
      </Grid>
    </Container>
  )
}

export default About
