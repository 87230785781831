import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import  links  from "../../constants/links";
import "./navbar.css";
import {
  AppBar,
  Toolbar,
  Stack,
  Typography,
  Container,
  Box,
} from "@mui/material";
import LogoIcon from "../../assets/icons/Logo.png";
import MobileMenu from "./MobileMenu";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const NavBar = () => {
  // БУРЕГЕР ММЕНЮ
  const [menu, setMenu] = useState(false);
  const handleClose = () => {
    setMenu(!menu);
    console.log("213321");
  };

  // DROPDOWNMENU ДЛЯ ДЕСКТОП ВЕРСІЇ
  const desctopDropMenu = Object.values(links.subHeaderMenu).map((link) => (
    <NavLink
      key={link.id}
      to={`${link.link}`} // додавання "/" на початку шляху
      style={{
        textDecoration: "none",
        color: "inherit",
        padding:'10px 0'
      }}
      
    >
      <li className='submenu__link'>{link.name}</li>
    </NavLink>
  ));
  const navLinks = links.headerLinks.map((link) => {
    if (link.id === 2) {
      return (
        <li className="navbar__link navbar__link-dropdown" style={{display:'flex', alignItems:'center', textTransform:'uppercase'}} >
            {link.name}
            <ArrowDownwardIcon className="navbar__link-dropdown-icon" style={{width: '0.8em', height:'0.8em'}}/>
          <ul className="submenu">{desctopDropMenu}</ul>
        </li>
      );
    } else {
      return (
        <li className="navbar__link">
          <NavLink
            id={link.id}
            to={link.link}
            className={({ isActive }) =>
              isActive
                ? "navbar__link-url active"
                : "navbar__link-url"
            }
          >
            {link.name}
          </NavLink>
        </li>
      );
    }
  });

  return (
    <>
      <MobileMenu active={menu} handleClose={handleClose} />
      <AppBar position="static" sx={{ background: "#fff" }}>
        <Container
          sx={{
            paddingLeft: { xs: "5px", sm: "16px" },
            paddingRight: { xs: "5px", sm: "16px" },
          }}
        >
          <Stack
            direction={{ md: "row", sm: "row", xs: "column" }}
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: "22px 0", alignItems: "center" }}
          >
            <NavLink
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexGrow: "1",
                textDecoration: "none",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  color: "#303030",
                  fontSize: { xs: "15px", sm: "15px", md: "15px" },
                  fontFamily: "PlayfairDisplay",
                  fontWeight: 700,
                  textTransform: "uppercase",
                  textAlign: { xs: "center", sm: "left", md: "left" },
                }}
              >
                Постійно діючий <br />
                <Typography
                  variant="p"
                  color="#303030"
                  fontWeight="700"
                  sx={{ fontSize: { xs: "26px", sm: "27px" } }}
                >
                  Третейський суд
                </Typography>
                <br /> при Асоціації "Правова Ліга"
              </Typography>
            </NavLink>
            <Stack
              direction={{ md: "row", sm: "row", xs: "column" }}
              spacing={{ md: 4, xs: 2 }}
              sx={{
                borderTop: { md: "none", sm: "none", xs: "1px solid #000" },
                marginTop: { sm: 0, xs: "20px" },
                paddingTop: { sm: 0, xs: "20px" },
              }}
            >
              <Stack>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "#000",
                    textAlign: "right",
                    fontFamily: "PlayfairDisplay",
                    fontSize: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  sud.te.ua@gmail.com
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "#000",
                    textAlign: "right",
                    fontFamily: "PlayfairDisplay",
                    fontSize: "20px",
                  }}
                >
                  +38 (0352) 52-25-44
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "#000",
                    textAlign: "right",
                    fontFamily: "PlayfairDisplay",
                    fontSize: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  понеділок-п'ятниця
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: "#000",
                    textAlign: "right",
                    fontFamily: "PlayfairDisplay",
                    fontSize: "20px",
                  }}
                >
                  10:00–18:00
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" className="navbar" spacing={2}>
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                justifyContent: "start",
                alignItems:'center',
                gap:'30px',
                padding: 0,
                margin: 0,
              }}
            >
              {navLinks}
            </ul>
            <Box
              className="hamburger"
              sx={{
                position: "relative",
                width: "30px",
                alignItems: "center",
              }}
              onClick={() => setMenu(!menu)}
            >
              <span
                className={menu ? "hamburger__line active" : "hamburger__line"}
              ></span>
              <span
                className={menu ? "hamburger__line active" : "hamburger__line"}
              ></span>
              <span
                className={menu ? "hamburger__line active" : "hamburger__line"}
              ></span>
            </Box>
          </Stack>
          {/* <Toolbar sx={{paddingLeft: {xs: 0}, paddingRight: {xs: 0}, gap: {xs: '10px'}}}>
                    <NavLink to="/" style={{display: 'flex', alignItems: 'center', gap: '10px', flexGrow: '1', textDecoration: 'none'}}>
                      <img src={LogoIcon}/>
                      <Typography
                       variant='h6' 
                       component='div' 
                       sx={{flexGrow: 1, 
                            color: '#303030',
                            fontSize: {xs: '19px',sm: '24px' , md: '24px'},
                            fontFamily: 'PlayfairDisplay',
                            fontWeight: 700,
                            textTransform: 'uppercase'
                      }}>
                              Третейський суд
                      </Typography>
                    </NavLink>

                  <Stack direction='row' spacing={2} sx={{display: {xs: 'none', md: 'block'}}}>

                      {links.headerLinks.map((link, id) => (
                        <NavLink
                          key={id} 
                          to={link.link} 
                          className={({isActive}) => (isActive ? 'navbar__link active' : 'navbar__link')}
                        >
                          {link.name}
                        </NavLink>
                      ))}
                  </Stack>
                  <Typography
                    variant='h6'
                    component='div' 
                    sx={{
                        display: {xs: 'none', lg: 'block'},
                        flexGrow: 1, 
                        color: '#000', 
                        textAlign: 'right', 
                        fontFamily: 'PlayfairDisplay', 
                        fontSize: '24px'
                        
                  }}>
                    +38 (0352) 52-25-44
                  </Typography>
                  <Box 
                    sx={{
                      display: {xs: 'block' ,md: 'none'}, 
                      position: 'relative', 
                      width: '30px', 
                      alignItems: 'center',
                      zIndex: 101,
                    }}
                    onClick={() => setMenu(!menu)}
                  >
                    <span className={menu ? 'hamburger__line active' : 'hamburger__line'}></span>
                    <span className={menu ? 'hamburger__line active' : 'hamburger__line'}></span>
                    <span className={menu ? 'hamburger__line active' : 'hamburger__line'}></span>
                  </Box>
            </Toolbar> */}
        </Container>
      </AppBar>
    </>
  );
};

export default NavBar;
