import { m } from "framer-motion";

const pageData = {
  order2023: {
    title: 'Регламент',
    content: [
      {
        bold: true,
        right: true,
        content: `ЗАТВЕРДЖЕНО
    рішенням Ради Асоціації «ПРАВОВА ЛІГА»
    від 22 березня 2007 року, протокол № 1`,
      },
      {
        bold: true,
        right: true,
        content: `ЗМІНИ ЗАТВЕРДЖЕНО
    рішенням Ради Асоціації «ПРАВОВА ЛІГА»
    від 09 лютого 2021 року, протокол №1/2021`,
      },
      {
        bold: true,
        center: true,
        content: `РЕГЛАМЕНТ
    Постійно діючого третейського суду
    при Асоціації "ПРАВОВА ЛІГА"
    (нова редакція)`,
      },

      {
        bold: true,
        content: `Глава 1. Загальні положення
    Стаття 1. Статус Третейського суду.`,
      },
      {
        content: `1. Постійно діючий третейський суд при Асоціації "ПРАВОВА ЛІГА" (далі - Третейський суд) є недержавним, незалежним, постійно діючим третейським судом та здійснює свою діяльність у відповідності з чинним законодавством, що регулює діяльність третейських судів в Україні та цим Регламентом Третейського суду.
    2. Третейський суд діє при Асоціації "ПРАВОВА ЛІГА", яка матеріально і організаційно забезпечує його діяльність.
    3. Завданням Третейського суду є захист майнових і немайнових прав та охоронюваних законом інтересів фізичних чи юридичних осіб шляхом всебічного розгляду та вирішення спорів відповідно з чинним законодавством та Регламенту Третейського суду (далі - Регламент). Діяльність Третейського суду та третейських суддів не є підприємницькою.
    4. На розгляд до Третейського суду може бути передано будь-який спір, що виник з цивільних чи господарських правовідносин, крім випадків, передбачених Законом України «Про третейські суди» та іншими законодавчими актами.`,
      },
      {
        bold: true,
        content: `Стаття 2. Компетенція Третейського суду.`,
      },
      {
        content: `1. Третейський суд порушує справи за позовними заявами підприємств, організацій, фізичних осіб-підприємців та фізичних осіб, які звертаються до Третейського суду за захистом своїх прав та охоронюваних законом інтересів.
    2. Третейський суд з дотриманням вимог Закону України "Про третейські суди" самостійно вирішує питання про наявність або відсутність у нього компетенції для розгляду конкретного спору.
    3. Третейський суд розглядає спори за наявністю третейського застереження у договорі, контракті або у вигляді окремої письмової угоди (далі - Третейська угода) між сторонами про передачу на його вирішення конкретного спору, визначених категорій або всіх спорів, що виникли або можуть виникнути між сторонами у зв'язку з будь-якими правовідносинами незалежно від того, мали вони чи ні договірний характер.
    4. Третейська угода укладається у письмовій формі. Третейська угода вважається укладеною у письмовій формі, якщо вона міститься в документі, підписаному сторонами чи укладена шляхом обміну листами, повідомленнями по телетайпу, телеграфу або з використанням інших засобів електронного чи іншого зв'язку, що забезпечує фіксацію такої угоди або шляхом направлення відзиву на позов, в якому одна із сторін підтверджує наявність угоди, а інша сторона проти цього не заперечує. Посилання у договорі, контракті на документ, який містить умову про третейський розгляд спору, є Третейською угодою за умови, що договір укладений у письмовій формі і це посилання є таким, що робить Третейську угоду частиною договору.
    5. Якщо сторони домовились звернутися до Третейського суду, Регламент для них є обов'язковим.
    6. Сторона має право заявити про відсутність у Третейського суду компетенції стосовно переданого на вирішення спору до початку розгляду справи по суті.
    7. Сторона має право заявити про перевищення Третейським судом меж його компетенції, якщо в процесі третейського розгляду справи виникне питання, розгляд якого не передбачено третейською угодою або яке не може бути предметом такого розгляду відповідно до закону та цього Регламенту.
    8. У випадках, передбачених пунктами 6 і 7 цієї статті, Третейський суд повинен відкласти розгляд справи по суті до вирішення ним питання щодо наявності у нього компетенції.
    9. Питання щодо компетенції Третейського суду по конкретній справі вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, складом Третейського суду чи третейським суддею, який розглядає спір одноособово. При цьому Третейська угода, що є частиною договору, тлумачиться як угода, яка не залежить від інших умов договору. Недійсність окремих положень договору, контракту, що містить третейське застереження, не тягне за собою недійсність такого третейського застереження.
    10. З питань наявності чи відсутності компетенції Третейський суд постановляє мотивовану ухвалу.`,
      },
      {
        bold: true,
        content: `Стаття 3. Місцезнаходження Третейського суду.`,
      },
      {
        content: `5. Місцезнаходженням Третейського суду є місцезнаходження Асоціації "ПРАВОВА ЛІГА" (ідентифікаційний код 34826691): 46001, майдан Волі 4, м. Тернопіль, Україна.
    6. Перед зверненням до Третейського суду Сторони зобов’язуються перевірити місцезнаходження Асоціації "ПРАВОВА ЛІГА" згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань.
    7. Зміна місцезнаходження Третейського суду не впливає на чинність третейської угоди.
            `,
      },
      {
        bold: true,
        content: "Стаття 4. Місце третейського розгляду.",
      },
      {
        content: `1. Місцем третейського розгляду є місцезнаходження Третейського суду.
    2. Сторони можуть домовитися про проведення третейського розгляду поза місцем знаходження Третейського суду. У такому разі всі додаткові витрати, пов'язані з проведенням розгляду, покладаються на сторони.`,
      },
      {
        bold: true,
        content: `Стаття 5. Законодавство, яке застосовується Третейським судом при вирішенні спорів.`,
      },
      {
        content: `1. Третейські суди вирішують спори на підставі Конституції та законів України, інших нормативно-правових актів та міжнародних договорів України.
    2. Третейський суд у випадках, передбачених законом або міжнародним договором України, застосовує норми права інших держав.
    3. У разі відсутності законодавства, що регулює певні спірні відносини, Третейський суд застосовує законодавство, яке регулює подібні відносини, а за відсутності такого Третейський суд застосовує аналогію права чи керується торговими та іншими звичаями, якщо останні за своїм характером та змістом властиві таким спірним відносинам.`,
      },
      {
        bold: true,
        content: `Стаття 6. Мова третейського розгляду.`,
      },
      {
        content: `1. Розгляд справ у Третейському суді провадиться українською мовою.
    2. Процесуальні документи Третейського суду приймаються українською мовою.
    3. Сторона, яка надає документи чи письмові докази мовою іншою, ніж мова третейського розгляду, повинна забезпечити їх переклад на мову третейського розгляду.
    4. Якщо сторона не володіє мовою, якою провадиться розгляд, Третейський суд за проханням сторони і за її рахунок забезпечується послугами перекладача.`,
      },
      {
        bold: true,
        content: `Стаття 7. Гласність.`,
      },
      {
        content: `1. Третейський розгляд здійснюється відкрито.
    2. У разі коли проти відкритого третейського розгляду висунуто хоча б однією стороною заперечення з мотивів додержання та збереження комерційної або банківської таємниці чи забезпечення конфіденційності інформації, справа розглядається у закритому засіданні.`,
      },
      {
        bold: true,
        content: `Стаття 8. Конфіденційність.`,
      },
      {
        content: `1. Відомості, пов’язані з третейським розглядом справи, є конфіденційними. Третейський суд, суддя Третейського суду, працівники апарату Третейського суду не вправі розголошувати відомості та інформацію, що стали йому (їм) відомі під час третейського розгляду, без згоди сторін або їх правонаступників.
    2. Забороняється вимагати від третейського судді надання документів, відомостей та інформації, якими він володіє у зв'язку з третейським розглядом справи, крім випадків, передбачених законами України.
    3. У будь-якому випадку, при публікаціях не допускається посилання на найменування сторін, їх банківські реквізити та аналогічні відомості.
    4. Надання відомостей щодо спорів, переданих на розгляд до Третейського суду, допускається лише на письмовий запит компетентних органів у випадках та з підстав, передбачених законами України.
    5. Не буде вважатись розголошенням відомостей в разі, якщо сторони в ході розгляду справи не подавали письмових заяв чи клопотань про збереження конфіденційності.`,
      },
      {
        bold: true,
        content: `Стаття 9. Принципи діяльності Третейського суду.`,
      },
      {
        content: `1. Третейський суд діє на принципах, визначених Законом України «Про третейські суди».`,
      },
      {
        bold: true,
        content: `Глава 2. Учасники третейського розгляду
    Стаття 10. Склад осіб, які приймають участь у третейському розгляді.`,
      },
      {
        content: `1. Особами, що приймають участь у третейському розгляді є сторони, їх представники.
    2. Сторонами третейського розгляду можуть бути юридичні та фізичні особи, які подали до Третейського суду позов на захист своїх прав та інтересів, або до яких пред'явлений позов.
    3. Позивачами є юридичні особи, фізичні особи-підприємці та фізичні особи, які подали позов.
    4. Відповідачами є юридичні особи, фізичні особи-підприємці та фізичні особи, до яких подана позовна вимога.
    5. Треті особи вступають в третейський розгляд тільки зі згоди сторін третейського розгляду. Для залучення до третейського розгляду третьої особи, окрім згоди сторін, необхідна також згода особи, яка залучається. Згода на залучення третьої особи повинна бути викладена у письмовій формі.
    6. Для об'єктивного і вірного третейського розгляду Третейський суд вправі за власною ініціативою залучати третіх осіб за їх згодою без згоди сторін.`,
      },
      {
        bold: true,
        content: `Стаття 11. Інші учасники третейського розгляду.`,
      },
      {
        content: `1. Іншими учасниками третейського розгляду, які сприяють здійсненню третейського розгляду можуть бути: експерти, свідки, перекладачі, секретар третейського розгляду.`,
      },
      {
        bold: true,
        content: `Стаття 12. Загальні засади третейського розгляду.`,
      },
      {
        content: `1. Третейський розгляд здійснюється на засадах змагальності та рівності сторін. До сторін повинно бути рівне ставлення і кожній стороні повинні бути надані рівні можливості для викладення своєї позиції.`,
      },
      {
        bold: true,
        content: `Стаття 13. Права та обов'язки осіб, які приймають участь у справі.`,
      },
      {
        content: `1. Особи, які приймають участь у справі, мають право знайомитись з матеріалами справи, робити з них виписки, знімати копії: заявляти відводи; надавати докази і ознайомлюватися з доказами, наданими іншими особами, що приймають участь у справі, до початку третейського розгляду; брати участь у дослідженні доказів; задавати питання іншим учасникам третейського розгляду, заявляти клопотання, робити заяви, давати пояснення Третейському суду, наводити свої доводи по всім питанням, що виникають при розгляді справи; знайомитися з клопотаннями, заявленими іншими особами, заперечувати проти клопотань, доводів інших осіб, що приймають участь у справі; отримувати копії ухвал та рішень, користуватися іншими процесуальними правами, наданими їм цим Регламентом.
    2. Особи, які приймають участь у справі, повинні добросовісно користуватися всіма наданими їм правами.
    3. Особи, які приймають участь у справі, несуть обов'язки, передбачені цим Регламентом або покладені на них Третейським судом у відповідності з цим Регламентом.`,
      },
      {
        bold: true,
        content: `Стаття 14. Процесуальна правоздатність і процесуальна дієздатність.`,
      },
      {
        content: `1. Здатність мати процесуальні права і нести процесуальні обов'язки (процесуальна правоздатність) визнається в рівній мірі за всіма юридичними і фізичними особами, які мають згідно з законом право на судовий захист в Третейському суді своїх прав і законних інтересів.
    2. Здатність своїми діями здійснювати процесуальні права і виконувати процесуальні обов'язки (процесуальна дієздатність) належить в Третейському суді - юридичним і фізичним особам.
    3. Права і законні інтереси недієздатних фізичних осіб захищають в третейському розгляді їх законні представники.`,
      },
      {
        bold: true,
        content: `Стаття 15. Участь у справі декількох позивачів або відповідачів.`,
      },
      {
        content: `1. Позов може бути поданий до Третейського суду сумісно декількома позивачами або до декількох відповідачів (процесуальна співучасть). Кожен з позивачів або відповідачів виступає в процесі самостійно. Співучасники можуть доручити ведення справи одному або декільком з співучасників.`,
      },
      {
        bold: true,
        content: `Стаття 16. Залучення до участі у справі іншого відповідача.
    Заміна неналежного відповідача.`,
      },
      {
        content: `1. Третейський суд за наявністю достатніх підстав має право до прийняття рішення залучити за клопотанням позивача або за своєю ініціативою до участі у справі іншого відповідача, при наявності підписаної Третейської угоди.
    2. У разі, якщо при підготовці справи до третейського розгляду або під час третейського розгляду буде встановлено, що позов пред'явлений не до тої особи, яка повинна відповідати за позовом, Третейський суд може за згодою позивача, не припиняючи провадження у справі, допустити заміну неналежного відповідача належним відповідачем, при наявності підписаної Третейської угоди.
    3. Про залучення іншого відповідача чи заміну неналежного відповідача розгляд справи проводиться спочатку, про що Третейський суд виносить ухвалу.`,
      },
      {
        bold: true,
        content: `Стаття 17. Процесуальне правонаступництво.`,
      },
      {
        content: `1. У випадках вибуття однієї з сторін третейського розгляду (реорганізація юридичної особи, уступка вимоги, переведення боргу, смерть фізичної особи та інші випадки переміни осіб у зобов'язаннях) Третейський суд проводить заміну цієї сторони її правонаступником, при згоді правонаступника і зазначає про це в ухвалі або в рішенні. Правонаступництво можливе на будь-якій стадії третейського розгляду.
    2. Для правонаступника усі дії, здійснені в третейському процесі до вступу правонаступника в справу, обов'язкові в тій мірі, в якій вони були обов'язкові для особи, яку правонаступник змінив.
    3. Якщо Третейська угода укладена у вигляді третейського застереження, то вона вважається невід’ємною частиною угоди і щодо неї діють такі самі правила правонаступництва, що й до угоди в цілому.
    4. Якщо Третейська угода укладена у вигляді окремої угоди, то у разі заміни кредитора у зобов’язанні останній повинен повідомити нового кредитора про її існування. Зміна Третейської угоди в такому випадку можлива лише за умови явно вираженої згоди іншої (інших) сторони (сторін) угоди.`,
      },
      {
        bold: true,
        content: `Стаття 18. Зміна підстави або предмета позову, зміна розміру позовних вимог, відмова від позову, визнання позову, мирова угода.`,
      },
      {
        content: `1. Позивач має право при розгляді справи в Третейському суді до прийняття рішення змінити підставу або предмет позову, збільшити або зменшити розмір позовних вимог.
    2. Позивач має право при розгляді справи в Третейському суді до прийняття рішення, відмовитись від позову повністю або частково.
    3. Відповідач має право при розгляді справи в Третейському суді визнати позов повністю або частково.
    4. Сторони можуть закінчити справу мировою угодою в порядку, передбаченому главою 11 цього Регламенту.
    5. Третейський суд не приймає відмову позивача від позову, зменшення ним розміру позовних вимог, визнання відповідачем позову, не затверджує мирову угоду сторін, якщо це суперечить закону або порушує права інших осіб.`,
      },
      {
        bold: true,
        content: `Стаття 19. Треті особи, які заявляють самостійні вимоги відносно предмета спору.`,
      },
      {
        content: `1. Треті особи, які заявляють самостійні вимоги відносно предмета спору, можуть за їх згодою та за згодою сторін вступити у справу третейського розгляду до прийняття Третейським судом рішення, при умові існування щодо них Третейської угоди. Вони користуються усіма правами і несуть усі обов'язки позивача.
    2. У разі якщо третя особа, заявляє самостійні вимоги відносно предмета спору, вступила у справу після початку третейського розгляду, розгляд справи в Третейському суді проводиться спочатку.
    3. Про вступ в справу третьої особи, яка заявляє самостійні вимоги відносно предмета спору, виноситься ухвала.`,
      },
      {
        bold: true,
        content: `Стаття 20. Треті особи, які не заявляють самостійних вимог відносно предмета спору.`,
      },
      {
        content: `1. Треті особи, які не заявляють самостійних вимог відносно предмета спору, можуть за згодою сторін вступити в справу третейського розгляду на стороні позивача або відповідача до прийняття Третейським судом рішення, якщо рішення в справі може вплинути на права або обов'язки по відношенню до однієї з сторін. Вони можуть бути залучені до участі у справі третейського розгляду за їх згодою за ініціативою Третейського суду.
    2. Треті особи, які не заявляють самостійних вимог щодо предмета спору, мають процесуальні права і обов'язки сторони, за виключенням права на зміну підстави або предмета позову, збільшення або зменшення розміру позовних вимог, відмови від позову, визнання позову або укладення мирової угоди, пред'явлення зустрічного позову.
    3. Про вступ у справу третьої особи, яка не заявляє самостійних вимог щодо предмета спору, або про залучення в справу цієї особи або про відмову про це Третейським судом виноситься ухвала.`,
      },
      {
        bold: true,
        content: `Глава 3. Представництво у Третейському суді
    Стаття 21 Ведення справ у Третейському суді через представників.`,
      },
      {
        content: `1. Сторона, третя особа, а також особа, якій законом надано право звертатися до Третейського суду в інтересах іншої особи, може вести справи у Третейському суді особисто та (або) через представника.
    2. Особиста участь у справі особи не позбавляє її права мати в цій справі представника.
    3. Юридична особа незалежно від порядку її створення бере участь у справі через свого керівника, члена виконавчого органу, іншу особу, уповноважену діяти від її імені відповідно до закону, статуту, положення, трудового договору (контракту), або через представника.`,
      },
      {
        bold: true,
        content: `Стаття 22. Особи, які можуть бути представниками у Третейському суді.`,
      },
      {
        content: `1. Представником у Третейському суді може бути адвокат або представник.
    2. Одна й та сама особа може бути одночасно представником декількох позивачів або декількох відповідачів або декількох третіх осіб на одній стороні, за умови відсутності конфлікту інтересів між ними.`,
      },
      {
        bold: true,
        content: `Стаття 23. Документи, що посвідчують повноваження представників`,
      },
      {
        content: `1. Повноваження представників сторін та інших учасників справи мають бути підтверджені такими документами:
    1) довіреністю фізичної;
    2) довіреністю юридичної особи.
    2. Довіреність фізичної особи повинна бути посвідчена нотаріально або у визначених законом випадках іншою особою.
    3. Довіреність від імені юридичної особи видається за підписом (електронним цифровим підписом) посадової особи, уповноваженої на це законом, установчими документами.
    4. Повноваження адвоката як представника підтверджуються довіреністю або ордером, виданим відповідно до Закону України "Про адвокатуру та адвокатську діяльність".
    5. Відповідність копії документа, що підтверджує повноваження представника, оригіналу може бути засвідчена підписом третейського судді.
    6. Оригінали документів, зазначених у цій статті, копії з них, засвідчені третейським суддею, або копії з них, засвідчені у визначеному законом порядку, приєднуються до матеріалів справи.
    7. У разі подання представником до Третейського суду заяви, скарги, клопотання він додає довіреність або інший документ, що посвідчує його повноваження, якщо в справі немає підтвердження такого повноваження на момент подання відповідної заяви, скарги, клопотання.
    8. Довіреності або інші документи, що підтверджують повноваження представника і були посвідчені в інших державах, повинні бути легалізовані в установленому законодавством порядку, якщо інше не встановлено міжнародними договорами, згода на обов’язковість яких надана Верховною Радою України.`,
      },
      {
        bold: true,
        content: `Стаття 24. Повноваження представника.`,
      },
      {
        content: `1. Представник, який має повноваження на ведення справи в Третейському суді, здійснює від імені особи, яку він представляє, її процесуальні права та обов’язки.
    2. Обмеження повноважень представника на вчинення певної процесуальної дії мають бути застережені у виданій йому довіреності або ордері.
    3. Підстави і порядок припинення представництва за довіреністю визначаються Цивільним кодексом України, а представництва за ордером - законодавством про адвокатуру.
    4. Про припинення представництва або обмеження повноважень представника за довіреністю або ордером має бути повідомлено Третейський суд шляхом подання письмової заяви.
    5. У разі припинення повноважень представника на здійснення представництва особи у справі представник не може бути у цій самій справі представником іншої сторони, третьої особи на іншій стороні або третьої особи із самостійними вимогами щодо предмета спору.`,
      },
      {
        bold: true,
        content: `Глава 4. Процесуальні строки
    Стаття 25. Встановлення та обчислення процесуальних строків.`,
      },
      {
        content: `1. Строки, в межах яких вчиняються процесуальні дії, встановлюються законом, цим Регламентом, а якщо такі строки законом, цим Регламентом не визначені, - встановлюються Третейським судом.
    2. Третейський суд має встановлювати розумні строки для вчинення процесуальних дій. Строк є розумним, якщо він передбачає час, достатній, з урахуванням обставин справи, для вчинення процесуальної дії, та відповідає завданню третейського судочинства.
    3. Строки, встановлені законом, цим Регламентом або Третейським судом, обчислюються роками, місяцями і днями, а також можуть визначатися вказівкою на подію, яка повинна неминуче настати.
    4. Перебіг строку третейського розгляду починається з наступною дня після відповідної календарної дати або настання події, з якою пов'язано його початок.`,
      },
      {
        bold: true,
        content: `Стаття 26. Закінчення строків третейського розгляду.`,
      },
      {
        content: `1. Строк, обчислюваний роками, закінчується у відповідні місяць і число останнього року строку.
    2. Строк, обчислюваний місяцями, закінчується у відповідне число останнього місяця строку. Якщо закінчення строку, обчислюваного місяцями, припадає на такий місяць, що відповідного числа не має, строк закінчується в останній день цього місяця.
    3. Якщо закінчення строку припадає на вихідний, святковий чи інший неробочий день, останнім днем строку є перший після нього робочий день.
    4. Перебіг строку, закінчення якого пов'язане з подією, яка повинна неминуче настати, закінчується наступного дня після настання події.
    5. Останній день строку триває до 24 години. Строк не вважається пропущеним, якщо до його закінчення заява, інші документи чи матеріали або грошові кошти здано на пошту чи передані іншими відповідними засобами зв'язку.`,
      },
      {
        bold: true,
        content: `Стаття 27. Наслідки пропущення строків третейського розгляду.`,
      },
      {
        content: `1. Право на вчинення процесуальної дії втрачається із закінченням строку, встановленого законом, цим Регламентом або Третейським судом.
    2. Документи, подані після закінчення строків третейського розгляду, залишаються без розгляду, якщо Третейський суд за клопотанням особи, що їх подала, не знайде підстав для поновлення або продовження строку.`,
      },
      {
        bold: true,
        content: `Стаття 28. Зупинення строків третейського розгляду.`,
      },
      {
        content: `1. Зупинення провадження у справі зупиняє перебіг строків третейського розгляду. Зупинення цих строків починається з моменту настання тієї події, внаслідок якої Третейський суд зупинив провадження.`,
      },
      {
        bold: true,
        content: `Стаття 29. Поновлення та продовження строків третейського розгляду.`,
      },
      {
        content: `1. Третейський суд поновлює або продовжує строк, встановлений відповідно законом, цим Регламентом за клопотанням сторони або іншої особи у разі його пропущення з поважних причин.
    2. Строки вчиненим процесуальних дій, передбачені Законом України "Про третейські суди" можуть бути поновлені або продовжені лише за спільною згодою сторін та складу Третейського суду, яким вирішується спір.
    3. Одночасно з клопотанням про поновлення чи продовження строку може бути вчинено ту дію або подано той документ чи доказ, стосовно якого заявлено клопотання.
    4. З питань, зазначених у цій статті, Третейським судом виноситься ухвала.`,
      },
      {
        bold: true,
        content: `Стаття 30. Строк розгляду спору.`,
      },
      {
        content: `1. Розгляд справи Третейським судом починається з винесення відповідної ухвали та направлення її сторонам.
    2. Третейський суд застосовує заходи до розгляду спору протягом розумного строку, але не більше 2 (двох) місяців з дня винесення ухвали про порушення провадження у справі, крім випадків, коли за згодою сторін або за рішенням Третейського суду строки розгляду справи продовжені. Про продовження строку третейського розгляду третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду виносить ухвалу.
    3. Третейський суд на початку розгляду справи з’ясовує у сторін можливість закінчити справу мировою угодою та в подальшому сприяє вирішенню спору шляхом укладання мирової угоди на всіх стадіях третейського розгляду.`,
      },
      {
        bold: true,
        content: `Стаття 31. Інші строки.`,
      },
      {
        content: `1. Всі інші строки, пов'язані з третейським розглядом, визначені у конкретних статтях цього Регламенту.`,
      },
      {
        bold: true,
        content: `Глава 5. Докази
    Стаття 32. Докази.`,
      },
      {
        content: `1. Доказами у третейському розгляді є будь-які фактичні дані, на підставі яких Третейський суд встановлює наявність або відсутність обставин, що обґрунтовують вимоги і заперечення сторін, та інші обставини, що мають значення для правильного вирішення спору.`,
      },
      {
        bold: true,
        content: `Стаття 33. Засоби доказування.`,
      },
      {
        content: `1. Засобами доказування є пояснення сторін, третіх осіб, їхніх представників, викладені в заявах по суті, показання свідків, письмові, речові докази, висновки експертів.`,
      },
      {
        bold: true,
        content: `Стаття 34. Обов'язки доказування і надання доказів та їх зберігання.`,
      },
      {
        content: `1. Кожна сторона третейського розгляду повинна довести ті обставини, на які вона посилається як на підставу своїх вимог і заперечень.
    2. Докази подаються сторонами та іншими учасниками справи.
    3. Третейський суд має право вимагати від сторін, надання доказів, необхідних для повного, всебічного і об'єктивного вирішення спору.
    4. У разі посилання учасника справи на невчинення іншим учасником справи певних дій або відсутність певної події, Третейський суд може зобов’язати такого іншого учасника справи надати відповідні докази вчинення цих дій або наявності певної події. У разі ненадання таких доказів Третейський суд може визнати обставину невчинення відповідних дій або відсутності події встановленою.
    5. Якщо Третейський суд визнає за необхідне одержати документи від підприємств, установ, організацій, які не є учасниками третейського розгляду, він своєю ухвалою уповноважує сторони або одну із сторін одержати відповідні документи та надати їх Третейському суду.
    6. Сторони повинні надавати Третейському суду докази в оригіналах чи належним чином засвідчених копіях.
    7. Всі зібрані письмові докази разом з процесуальними документами сторін та Третейського суду зберігаються у справі в прошитому та пронумерованому вигляді.
    8. Третейський суд не може збирати докази, що стосуються предмета спору, з власної ініціативи, крім витребування доказів Третейським судом у випадку, коли він має сумніви у добросовісному здійсненні учасниками справи їхніх процесуальних прав або виконанні обов’язків щодо доказів.`,
      },
      {
        bold: true,
        content: `Стаття 35. Належність доказів.`,
      },
      {
        content: `1. Належними є докази, на підставі яких можна встановити обставини, які входять в предмет доказування. Третейський суд бере до розгляду тільки ті докази, які стосуються справи, що розглядається та предмета доказування.
    2. Предметом доказування є обставини, які підтверджують заявлені вимоги чи заперечення або мають інше значення для розгляду справи і підлягають встановленню при прийнятті Третейським судом рішення.
    3. Сторони мають право обґрунтовувати належність конкретного доказу для підтвердження їхніх вимог або заперечень.`,
      },
      {
        bold: true,
        content: `Стаття 36. Допустимість доказів.`,
      },
      {
        content: `1. Обставини справи, які за законом повинні бути підтверджені певними засобами доказування, не можуть підтверджуватися ніякими іншими засобами доказування.
    2. Третейський суд не бере до уваги докази, які одержані з порушенням порядку, встановленого законом.`,
      },
      {
        bold: true,
        content: `Стаття 37. Підстави звільнення від доказування.`,
      },
      {
        content: `1. Обставини, визнані Третейським судом загальновідомими, не потребують доказування.
    2. Обставини встановлені рішенням Третейського суду, що набрало законної сили, не доказуються при розгляді інших справ, у яких беруть участь ті самі особи або особа, щодо якої встановлено ці обставини.
    3. Рішення судів в господарській, цивільній або адміністративній справі, що набрали законної сили, обов'язкові для Третейського суду про обставини, встановлені цими рішеннями по відношенню до осіб, які приймають участь у справі.
    4. Обставини, встановлені стосовно певної особи рішенням суду в господарській, цивільній або адміністративній справі, що набрало законної сили, можуть бути у загальному порядку спростовані особою, яка не брала участі у справі, в якій такі обставини були встановлені.
    5. Обвинувальний вирок суду в кримінальному провадженні або постанова суду, якою особу притягнуто до адміністративної відповідальності у справі про адміністративне правопорушення, які набрали законної сили, є обов’язковими для Третейського суду, що розглядає справу про правові наслідки дій чи бездіяльності особи, стосовно якої ухвалений вирок або постанова суду, лише в питанні, чи мали місце ці дії (бездіяльність) та чи вчинені вони цією особою.
    6. Правова оцінка, надана судом певному факту при розгляді іншої справи, не є обов’язковою для Третейського суду.
    7. Обставини, встановлені рішенням міжнародного комерційного арбітражу, виправдувальним вироком суду у кримінальному провадженні, ухвалою про закриття кримінального провадження і звільнення особи від кримінальної відповідальності, підлягають доказуванню в загальному порядку при розгляді справи Третейським судом.`,
      },
      {
        bold: true,
        content: `Стаття 38. Звільнення від доказування обставин, визнаних сторонами.`,
      },
      {
        content: `1. Третейський суд повинен сприяти досягненню сторонами згоди в оцінці обставин в цілому або в їх окремих частинах, проявляти в цих цілях необхідну ініціативу, використовувати свої процесуальні повноваження і авторитет Третейського суду.
    2. Обставини, які визнаються учасниками справи, не підлягають доказуванню, якщо Третейський суд не має обґрунтованих підстав вважати їх недостовірними або визнаними у зв’язку з примусом. Обставини, які визнаються учасниками справи, можуть бути зазначені в заявах по суті справи, письмових поясненнях учасників справи, їх представників.
    3. Відмова від визнання обставин приймається Третейським судом, якщо сторона, яка відмовляється, доведе, що вона визнала ці обставини внаслідок помилки, що має істотне значення, обману, насильства, погрози чи тяжкої обставини, або що обставини визнано у результаті зловмисної домовленості її представника з іншою стороною. Про прийняття відмови сторони від визнання обставин Третейський суд виносить ухвалу. У разі прийняття Третейським судом відмови сторони від визнання обставин вони доводяться в загальному порядку.`,
      },
      {
        bold: true,
        content: `Стаття 39 Оцінка доказів.`,
      },
      {
        content: `1. Третейський суд оцінює докази за своїм внутрішнім переконанням, що ґрунтується на всебічному, повному, об'єктивному і безпосередньому дослідженні наявних у справі доказів.
    2. Кожний доказ підлягає оцінці Третейським судом поряд з іншими доказами.
    3. Жодні докази не мають для Третейського суду заздалегідь встановленої сили. Третейський суд оцінює належність, допустимість, достовірність кожного доказу окремо, а також вірогідність і взаємний зв’язок доказів у їх сукупності.`,
      },
      {
        bold: true,
        content: `Стаття 40. Письмові докази.`,
      },
      {
        content: `1. Письмовими доказами є будь-які документи, акти, довідки, листування службового або особистого характеру або витяги з них, виконані у формі цифрового, графічного запису або іншим шляхом, що дозволяє встановити достовірність документа.
    2. Документи, отримані через факсимільний, електронний або інший зв'язок, а також документ, підписані електронним цифровим підписом або іншим аналогом власноручного підпису, допускаються в якості письмових доказів у випадках і в порядку, встановлених законом, іншим нормативно - правовим актом або договором.
    3. До письмових документів, що надаються Третейському суду, виконаних повністю або в частині іноземною мовою, повинні бути додані їх належним чином завірені переклади на українську мову.
    4. Документ отриманий в іноземній державі, визнається в Третейському суді письмовим доказом, якщо він легалізований у встановленому порядку.
    5. Іноземні офіційні документи визнаються у Третейському суді письмовими доказами без їх легалізації у випадках, передбачених міжнародними договорами України.
    6. Оригінали документів сторін, що є у справі, за заявами осіб, що їх представляють, можуть бути повернуті їм після прийняття рішення, якщо ці документи не підлягають передачі іншій особі. Одночасно з заявами зазначені особи надають належним чином завірені копії документів або заявляють клопотання про засвідчення Третейським судом вірності копій, що залишаються у справі.
    7. Якщо Третейський суд дійде висновку, що повернення оригіналів документів не нанесе шкоди правильному розгляду справи, ці документи можуть бути повернуті в процесі провадження у справі до винесення рішення.`,
      },
      {
        bold: true,
        content: `Стаття 41. Речові докази.`,
      },
      {
        content: `1. Речовими доказами є предмети матеріального світу, що містять інформацію про обставини, які мають значення для справи.
    2. Речовими доказами є також магнітні, електронні та інші носії інформації, що містять аудіовізуальну інформацію про обставини, що мають значення для справи.`,
      },
      {
        bold: true,
        content: `Стаття 42. Зберігання речових доказів.`,
      },
      {
        content: `1. Речові докази зберігаються за місцем їх знаходження. Вони повинні бути детально описані, опечатані, а у разі необхідності зняті на фото - чи відеоплівку.
    2. Речові докази можуть зберігатися за місцезнаходженням Третейського суду, якщо Третейський суд визнає це за необхідне.
    3. Витрати на зберігання речових доказів розподіляються між сторонами у відповідності з правилами, встановленими цим Регламентом.
    4. Третейський суд і зберігач вживають заходів по зберіганню речових доказів у незмінному стані.`,
      },
      {
        bold: true,
        content: `Стаття 43. Огляд і дослідження письмових, речових доказів за місцем їх знаходження.`,
      },
      {
        content: `1. Третейський суд може провести огляд і дослідження письмових, речових за їх місцезнаходженням, у разі неможливості їх доставки в Третейський суд. Про проведення огляду і дослідженню письмових, речових доказів за їх місцезнаходженням виноситься ухвала.
    2. Про дату, час і місце огляду доказів за їх місцезнаходженням повідомляються учасники справи. Неявка цих осіб не є перешкодою для проведення огляду.
    3. Про огляд доказів за їх місцезнаходженням складається протокол, що підписується всіма особами, які беруть участь в огляді. До протоколу додаються разом з описом усі складені або звірені під час огляду на місці плани, креслення, копії документів, а також зроблені під час огляду фотознімки, електронні копії доказів, відеозаписи тощо.
    4. Якщо огляд проводиться за відсутності хоча б однієї із сторін, а також в інших випадках, коли Третейський суд визнає це за необхідне, здійснюється відеофіксація огляду технічними засобами.
    5. Особи, які беруть участь в огляді доказів за їх місцезнаходженням, мають право звертати увагу Третейського суду на ту чи іншу обставину, яка, на їх погляд, має значення для повноцінного проведення огляду, встановлення обставин, які мають значення для розгляду справи, робити свої зауваження щодо протоколу огляду.`,
      },
      {
        bold: true,
        content: `Стаття 44. Розпорядження речовими доказами, що знаходяться у Третейському суді.`,
      },
      {
        content: `1. Речові докази, що знаходяться у Третейському суді, після їх огляду і дослідження Третейським судом повертаються особам, від яких вони були отримані, якщо вони не підлягають передачі іншим особам.
    2. Речі, які згідно закону не можуть знаходитись у користуванні окремих осіб, передаються відповідним організаціям.
    3. З питань розпорядження речовими доказами Третейський суд виносить ухвалу.`,
      },
      {
        bold: true,
        content: `Стаття 45. Пояснення осіб, які приймають участь у справі.`,
      },
      {
        content: `1. Особа, яка приймає участь у справі, надає Третейському суду свої пояснення про відомі їй обставини, які мають значення для справи, в письмовій або усній формі. На вимогою Третейського суду особа, яка приймає участь у справі, має викласти свої пояснення у письмовій формі.
    2. Пояснення, викладені у письмовій формі, додаються до матеріалів справи.
    3. Після оголошення пояснень, викладених у письмовій формі, особа, яка надала це пояснення, має право дати відносно нього необхідні пояснення, а також зобов'язана відповісти на питання інших осіб, які приймають участь у справі, Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 46. Призначення експертизи.`,
      },
      {
        content: `1. З метою правильного вирішення спору Третейський суд має право зобов'язати сторони чи одну із сторін замовити проведення експертизи для роз'яснення питань, що потребують спеціальних знань, про що Третейський суд виносить відповідну ухвалу.
    2. У разі ухилення позивача від замовлення проведення експертизи, призначеної Третейським судом, і при неможливості розглянути справу через дослідження інших доказів у справі, позовна заява залишається без розгляду, про що виноситься ухвала.
    3. У разі ухилення відповідача від замовлення проведення експертизи, призначеної Третейським судом, Третейський суд може зобов'язати замовити проведення експертизи позивача, або розглянути справу без проведення експертизи дослідивши інші докази у справі.
    4. Перелік і зміст питань, з яких повинна бути проведена експертиза, визначається в ухвалі Третейського суду. Учасники справи мають право запропонувати Третейському суду питання, роз’яснення яких, на їхню думку, потребує висновку експерта. У разі відхилення або зміни питань, запропонованих учасниками справи, Третейський суд зобов’язаний мотивувати таке відхилення або зміну.
    5. Питання, які ставляться експерту, і його висновок з них не можуть виходити за межі спеціальних знань експерта.
    6. В ухвалі про зобов'язання замовлення проведення експертизи зазначаються підстави для призначення експертизи; прізвище, ім'я і по-батькові експерта або найменування експертної установи, в якій повинна бути проведена експертиза: питання, поставлені перед експертом: матеріали і документи, надані в розпорядження експерта; строк, на протязі якого повинна бути проведена експертиза і повинно бути надано висновок Третейському суду.
    7. Висновок експерта оголошується у засіданні Третейського суду і досліджується поряд з іншими доказами у справі. За клопотанням особи, яка приймає участь у справі, або за ініціативою Третейського суду експерт може бути викликаний у засідання Третейського суду. Експерт після оголошення його висновку має право дати по ньому необхідні пояснення, а також відповісти на додаткові питання осіб, які приймають участь у справі, і Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 47. Показання свідків.`,
      },
      {
        content: `1. За клопотанням особи, яка приймає участь у справі, Третейський суд може викликати свідка для надання пояснень по справі. Особа, яка заявляє клопотання про виклик свідка, зобов'язана зазначити, які обставини, що мають значення для справи, може підтвердити свідок, і повідомити суду його прізвище, ім'я, по-батькові і місце проживання.
    2. Третейський суд за власною ініціативою може викликати у якості свідка особу, яка приймала участь у складанні документа, що досліджується Третейським судом як письмовий доказ, або у створенні або зміні предмета, що досліджується Третейським судом як речовий доказ.
    3. За пропозицією Третейського суду свідок може викласти показання, що надані усно, у письмовій формі. Показання свідка, що викладені у письмовій формі, які приєднуються до матеріалів справи.
    4. Не є доказами відомості, які повідомлені свідком, якщо він не може зазначити джерело повідомлених ним відомостей.`,
      },
      {
        bold: true,
        content: `Глава 6. Витрати, пов'язані з вирішенням спору Третейським судом
    Стаття 48. Склад витрат, пов'язаних з вирішенням спору Третейським судом.`,
      },
      {
        content: `1. До складу витрат, пов'язаних з вирішенням спору Третейським судом, належать:
    1) гонорари третейських суддів;
    2) третейський збір, розмір, строки та порядок сплати якого сторонами визначаються цим Регламентом;
    3) витрати, понесені третейськими суддями у зв'язку з участю в третейському розгляді, в тому числі витрати, понесені третейськими суддями на оплату проїзду до місця вирішення спору;
    4) витрати, пов'язані з оплатою послуг експертів, перекладачів, якщо такі були запрошені чи призначені для участі в третейському розгляді;
    5) витрати, пов'язані з оглядом і дослідженням речових та письмових доказів у їх місцезнаходженні;
    6) витрати, понесені свідками;
    7) витрати, пов'язані з оплатою стороною, на користь якої було прийнято рішення Третейського суду, послуг представника, пов'язаних із наданням правової допомоги;
    8) витрати, пов'язані з організаційним забезпеченням третейського розгляду;
    9) витрати на листування Третейського суду;
    10) витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок;
    11) гонорар секретаря Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 49. Третейський збір.`,
      },
      {
        content: `1. При поданні позовної заяви позивач зобов'язаний сплатити третейський збір.
    2. До сплати третейського збору третейський розгляд за позовом не здійснюється, крім випадку надання відстрочення сплати третейського збору.
    3. Якщо третейський збір не сплачується на протязі місячного строку з моменту подання позовної заяви позовна заява повертається без розгляду, про що головою Третейського суду, його заступником, у відсутність голови Третейського суду, виноситься ухвала, яка не підлягає оскарженню.
    4. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений, сплачений третейський збір повертається позивачу, крім випадків, якщо це було зумовлено відводом усіх суддів, укладанням сторонами мирової угоди, відмовою позивача від позову та іншими обставинами, передбаченими Законом України "Про третейські суди".
    5. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений з підстав, зазначених у пункті 4 статті 84 цього Регламенту, то сторони відшкодовують Третейському суду всі понесені ним витрати, про що зазначається в ухвалі Третейського суду.
    6. Голова Третейського суду, його заступник, у відсутність голови Третейського суду, може надати відстрочення, а також зменшити або збільшити розмір третейського збору, про що вказується у розпорядженні, яке не підлягає оскарженню.`,
      },
      {
        bold: true,
        content: `Стаття 50. Витрати, які здійснюються Третейським судом з наступним стягненням з сторін спору.`,
      },
      {
        content: `1. Розподіл витрат, пов'язаних з організаційним забезпеченням третейського розгляду, витрати на листування Третейського суду, витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок, витрати, пов’язані з оплатою гонорару секретаря Третейського суду, здійснюються Третейським судом з подальшим стягненням зі сторони спору, у порядку, визначеному ст.52 цього Регламенту.`,
      },
      {
        bold: true,
        content: `Стаття 51. Покриття витрат, які не включаються до складу третейського збору.`,
      },
      {
        content: `1. Третейський суд може покласти на сторони або на одну з них обов'язок внести аванс на покриття витрат, які не включаються до складу третейського збору.
    2. Аванс на покриття витрат, які не включаються до складу третейського збору може бути, витребуваний Третейським судом від сторони, яка заявила про необхідність здійснення дії, що приводить або може привести до цих витрат.
    3. У разі участі у третейському розгляді третейського судді, який має місцеперебування поза місцем проведення засідання Третейського суду, позивач повинен внести аванс на оплату витрат його участі у третейському розгляді (по проїзду, проживанню тощо).
    4. Якщо на прохання однієї з сторін при розгляді справи здійснюється переклад пояснень сторін, їх заяв тощо, а також питань, вказівок, ухвал, рішень Третейського суду, то витрати по перекладу оплачуються зазначеною стороною.`,
      },
      {
        bold: true,
        content: `Стаття 52. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом.`,
      },
      {
        content: `1. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом здійснюється Третейським судом у такому порядку:
    - при задоволенні позову витрати покладаються на відповідача; при відмові в позові - на позивача;
    - при частковому задоволенні позову - на обидві сторони пропорційно розміру задоволених вимог.
    2. Порядок розподілу витрат, пов'язаних з вирішенням спору Третейським судом, зазначається у рішенні або ухвалі Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 53. Розміри третейського збору.`,
      },
      {
        content: `1. Третейський збір справляється у відсотковому співвідношенні до ціни позову та у фіксованому розмірі у відповідному розмірі прожиткового мінімуму для працездатних осіб, встановленого законом на період, в якому відповідна позовна заява подається до Третейського суду.
    2. Розмір третейського збору за подання до Третейського суду:
    - позовної заяви майнового характеру - 1 відсоток ціни позову, але не менше 1 розміру прожиткового мінімуму для працездатних осіб.
    - позовної заяви немайнового характеру – 1 розмір прожиткового мінімуму для працездатних осіб.
    3. За позовні заяви, що містять одночасно вимоги майнового і не майнового характеру, сплачується одночасно третейський збір, встановлений для позовних заяв майнового характеру і для позовних заяв немайнового характеру.
    4. При зменшенні ціни позову сплачений третейський збір не повертається.`,
      },
      {
        bold: true,
        content: `Стаття 54. Порядок сплати третейських зборів та інших витрат.`,
      },
      {
        content: `1. Суми третейських зборів та інших витрат, що підлягають сплаті Третейському суду, вважаються сплаченими у день їх зарахування на поточний рахунок Асоціації "ПРАВОВА ЛІГА".
    2. При сплаті третейського збору у платіжному дорученні у графі "Призначення платежу" повинно бути зазначено: "Третейський збір за розгляд спору між "....." (найменування позивача та відповідача), а також сума позову. Платіжне доручення з відміткою банку про перерахування третейського збору залучаться до матеріалів справи.`,
      },
      {
        bold: true,
        content: `Глава 7. Гонорари третейських суддів та порядок їх виплати
    Стаття 55. Гонорари третейських суддів, порядок їх виплати.`,
      },
      {
        content: `1. Третейському судді, який приймає участь третейському розгляді, після закінчення розгляду конкретної справи сплачується гонорар у розмірі 30 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.
    2. Якщо справа розглядається складом Третейського суду у кількості трьох третейських суддів, цим третейським суддям після закінчення розгляду конкретної справи сплачуються гонорари у таких розмірах: головуючому складу Третейського суду 12 відсотків, двом іншим третейським суддям складу Третейського суду по 9 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.
    3. Гонорари третейським суддям сплачуються Асоціацією "ПРАВОВА ЛІГА" на поточні рахунки третейських суддів у банківських установах. Реквізити банківських рахунків повідомляються кожним третейським суддею Третейському суду у письмовій формі. Для виплати гонорару Асоціація "ПРАВОВА ЛІГА" може вимагати від третейського судді надання додаткових документів (копію довідки про ідентифікаційний номер, копію паспорту, тощо).
    4. За заявою третейського судді, викладеної письмово, розгляд справи може здійснюватись безоплатно.`,
      },
      {
        bold: true,
        content: `Глава 8. Формування третейського суду
    Стаття 56. Склад третейського суду.`,
      },
      {
        content: `1. Третейським суддею, який розглядає справу одноособово, третейськими суддями у разі розгляду справи у складі трьох третейських суддів, можуть бути призначені особи із списку третейських суддів Третейського суду за їх згодою, які прямо чи опосередковано не заінтересовані в результаті вирішення спору, а також мають необхідний досвід, ділові та моральні якості, необхідні для вирішення спору.`,
      },
      {
        bold: true,
        content: `Стаття 57. Формування складу третейського суду.`,
      },
      {
        content: `1. Якщо сторони не домовились про те, що справа розглядається третейським судом у складі трьох третейських суддів, справа підлягає розгляду третейським суддею одноособово.
    2. Третейський суддя, який розглядає справу одноособово, призначається за його згодою головою Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.
    3. При розгляді справи у складі трьох третейських суддів головуючого складу третейського суду та третейських суддів призначає за їх згодою голова Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.
    4. Після формування складу третейського суду, голова Третейського суду, його заступником, у відсутність голови Третейського суду, передає справу головуючому складу третейського суду або третейському судді, який розглядає справу одноособово.
    5. Призначення третейського судді або складу третейських суддів, а також передача справи головуючому складу третейського суду або третейському судді, який розглядає справу одноособово, оформляється розпорядженням голови Третейського суду, його заступника, у відсутність голови Третейського суду, яке є остаточним і не підлягає оскарженню.`,
      },
      {
        bold: true,
        content: `Стаття 58. Повідомлення сторін про порушення справи.`,
      },
      {
        content: `1. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду постановляє ухвалу про порушення справи, про що повідомляються сторони, і пропонує відповідачу у строк не більше ніж 5 днів з дня отримання ухвали про порушення справи надати Третейському суду свій відзив на позов, що підкріплений відповідними доказами.
    2. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду може продовжити строк для надання відповідачем відзиву на позов при надходженні відповідного клопотання. За результатами розгляду відповідного клопотання виноситься ухвала, яка не підлягає оскарженню.`,
      },
      {
        bold: true,
        content: `Стаття 59. Припинення повноважень третейського судді, складу третейського суду, заміна третейського судді.`,
      },
      {
        content: `1. Повноваження третейського судді припиняються:
    1) за погодженням сторін;
    2) у разі відводу чи самовідводу третейського судді відповідно до цього Регламенту;
    3) у разі набрання законної сили обвинувальним вироком суду щодо нього;
    4) у разі набрання законної сили судовим рішенням про визнання його обмежено дієздатним чи недієздатним;
    5) у разі смерті, визнання його безвісно відсутнім або оголошення померлим рішенням суду, що набрало законної сили.
    2. Повноваження складу третейського суду припиняються після ухвалення ним рішення у справі.`,
      },
      {
        bold: true,
        content: `Стаття 60. Поновлення повноважень третейського суду.`,
      },
      {
        content: `1. Повноваження Третейського суду поновлюються у разі виникнення потреби в:
    1) прийнятті додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні, – за заявою сторони;
    2) роз’ясненні резолютивної частини рішення – за заявою сторони;
    3) виправленні у рішенні описок, арифметичних помилок або будь-яких інших неточностей – з власної ініціативи Третейського суду або за заявою сторони.
    2. Після виконання зазначених у цій статті процесуальних дій повноваження Третейського суду припиняються.`,
      },
      {
        bold: true,
        content: `Стаття 61. Підстави відводу чи самовідводу третейського судді.`,
      },
      {
        content: `1. Третейський суддя не може брати участі у розгляді справи, а після його призначення підлягає відводу чи самовідводу:
    - якщо він особисто чи опосередковано заінтересований у результаті розгляду справи;
    - якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках;
    - на його прохання або за спільним рішенням сторін;
    - у разі встановлення стороною обставин, які дають їй підстави вважати упередженим або необ'єктивним ставлення третейського судді до справи, про яке сторона дізналася після його призначення;
    - у разі тривалого, більш як один місяць від дня призначення, невиконання ним обов'язків третейського судді у конкретній справі;
    - у разі виявлення невідповідності третейського судді вимогам, встановленим статтею 18 Закону України "Про третейські суди";
    - якщо третейський суддя бере участь у вирішенні спору, який прямо чи опосередковано пов'язаний з виконанням ним службових повноважень, наданих державою.
    2. Жодна особа не може бути третейським суддею у справі, в якій вона раніше брала участь як третейський суддя, але була відведена чи заявила самовідвід, як сторона, представник сторони або в будь-якій іншій якості.`,
      },
      {
        bold: true,
        content: `Стаття 62. Порядок відводу чи самовідводу третейського судді.`,
      },
      {
        content: `1. У разі звернення голови Третейського суду, його заступника, у відсутність голови Третейського суду, до особи зі списку третейських суддів Третейського суду за отриманням згоди на призначення її третейським суддею у конкретній справі, ця особа повинна повідомити про наявність обставин, які є підставами для відводу чи самовідводу відповідно до статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту.
    2. Третейський суддя повинен без зволікання повідомити сторони про підстави його відводу чи самовідводу, зазначені у статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту, що виникли після початку третейського розгляду, та заявити самовідвід.
    3. Письмова мотивована заява сторони про відвід третейського судді, з доказом надіслання її іншій стороні, має бути подана стороною до Третейського суду протягом п’яти календарних днів після того, як цій стороні стали відомі обставини, які є підставою для відводу третейського судді відповідно до Закону "Про третейські суди" та цього Регламенту.
    4. У разі пропуску встановленого строку, питання про прийняття заяви про відвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, залежно від причин пропуску строку. У випадку відмови у прийнятті заяви про відвід третейського судді голова Третейського суду, його заступник, у відсутність голови Третейського суду, виносить ухвалу, яка є остаточною і не підлягає оскарженню.
    5. Якщо відвід заявляється повторно з підстав, розглянутих раніше, така заява залишається без розгляду.
    6. Третейський суд зобов’язаний надати іншій стороні можливість прокоментувати заяву про відвід третейського судді.
    7. У разі коли інша сторона не погоджується з відводом третейського судді, вона має право протягом п’яти календарних днів з дня отримання копії заяви про відвід третейського судді, подати до Третейського суду свої мотивовані заперечення. У цьому випадку питання про відвід вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, а у разі розгляду справи у кількості трьох третейських суддів головою Третейського суду, його заступником, у відсутність голови Третейського суду, спільно з іншими третейськими суддями, призначеними у справі, рішення якого (яких) є обов'язковим для сторін. За результатами розгляду заяви про відвід третейського судді виноситься ухвала. Питання про відвід, яке заявлене голові Третейського суду, який здійснює розгляд справи як третейський суддя, вирішується заступником голови Третейського суду у порядку, визначеному цим Регламентом.
    8. Питання про відвід вирішується без повідомлення учасників справи. За ініціативою голови Третейського суду, його заступника, у відсутність голови Третейського суду, питання про відвід може вирішуватися у засіданні третейського суду з повідомленням учасників справи. Неявка учасників справи у засідання третейського суду, в якому вирішується питання про відвід, не перешкоджає розгляду питання про відвід.
    9. У разі задоволення заяви про відвід третейського судді, який розглядає справу одноособово, справа розглядається в Третейському суді іншим третейським суддею, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.
    10. У разі задоволення заяви про відвід комусь із третейських суддів або всьому складу суду, у разі колегіального вирішення спору, справа розглядається в Третейському суді тим самим кількісним складом колегії третейських суддів без участі відведеного третейського судді або іншим складом третейських суддів, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.
    11. Питання про самовідвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, на підставі мотивованої заяви третейського судді.
    12. Під час розгляду заяви про відвід Третейський суд, включаючи третейського суддю, якому заявлено відвід, продовжує третейський розгляд. Третейський суд не може ухвалити рішення доти, поки голова Третейського суду, його заступник, у відсутність голови Третейського суду, не постановить ухвалу за підсумками розгляду згаданої заяви.
    13. Самовідвід третейського судді або згода сторони на припинення його повноважень не повинна розцінюватися як визнання залежності та упередженості третейського судді.`,
      },
      {
        bold: true,
        content: `Стаття 63. Підстави та порядок відводу чи самовідводу експерта, перекладача.`,
      },
      {
        content: `1. Якщо експерт, перекладач особисто чи опосередковано заінтересовані у результаті розгляду справи, або якщо вони є родичами однієї із сторін або інших осіб, які беруть участь у справі, або перебувають з цими особами чи сторонами в особливих стосунках вони підлягають відводу чи самовідводу.
    2. Питання про відвід експерта, перекладача вирішується складом третейським суддею, який розглядає справу одноособово, а у разі колегіального вирішення спору - складом третейських суддів.`,
      },
      {
        bold: true,
        content: `Глава 9. Пред'явлення позову
    Стаття 64. Пред'явлення позову.`,
      },
      {
        content: `1. Провадження у справі порушується поданням позовної заяви.
    2. Позовна заява подається у Третейський суд у письмовій формі і підписується керівником юридичної особи або іншою особою, яка має право діяти без довіреності від імені позивача, або фізичною особою-підприємцем, або фізичною особою, або уповноваженим довіреністю представником позивача.
    3. Датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата отримання поштового відправлення представником Третейського суду, при відправленні позовної заяви через електронну приймальню – дата, зазначена в електронному повідомленні про отримання.
    При подачі позовної заяви через електронну приймальню, її оригінал має бути поданий позивачем в першому засіданні третейського суду.
    4. У разі якщо сторона подає позовну заяву у зв'язку з правовідношенням, яке вже є предметом третейського розгляду між тими ж сторонами у відповідності з цим Регламентом, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за заявою будь-якої з сторін може об'єднати вимоги, що містяться у різних позовних заявах в одне провадження.`,
      },
      {
        bold: true,
        content: `Стаття 65. Зміст позовної заяви.`,
      },
      {
        content: `1. Позовна заява подається у письмовій формі.
    2. У позовній заяві, що подається до Третейського суду, повинні зазначатися:
    - назва Третейського суду;
    - дата подання позовної заяви;
    - повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові - для фізичних осіб) сторін та інших учасників справи, їх місцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб); поштовий індекс; ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), а також реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України (якщо такі відомості відомі позивачу), вказівку на статус фізичної особи - підприємця (для фізичних осіб - підприємців); відомі номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти;
    - повне найменування і місцезнаходження представника позивача, якщо він є юридичною особою або прізвище, ім'я, по батькові, дата народження, місце реєстрації представника, який є фізичною особою або фізичною особою-підприємцем, у випадках, коли позов подається представником; номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти представника;
    - ціна позову, якщо позов підлягає грошовій оцінці;
    - зміст позовних вимог: спосіб (способи) захисту прав або інтересів, передбачений законом чи договором, або інший спосіб (способи) захисту прав та інтересів, який не суперечить закону і який позивач просить Третейський суд визначити у рішенні; якщо позов подано до кількох відповідачів - зміст позовних вимог щодо кожного з них;
    - виклад обставин, якими позивач обґрунтовує свої вимоги; зазначення доказів, що підтверджують вказані обставини; правові підстави позову;
    - посилання на наявність Третейської угоди між сторонами та докази її укладення;
    - перелік письмових матеріалів, які додаються до позовної заяви;
    - підпис позивача або його представника з посиланням на документ, що засвідчує повноваження представника.
    3. У позовній заяві можуть бути вказані й інші відомості, необхідні для правильного вирішення спору.`,
      },
      {
        bold: true,
        content: `Стаття 66. Документи, що додаються до позовної заяви.`,
      },
      {
        content: `1. До позовної заяви додаються документи, які підтверджують:
    1) наявність Третейської угоди;
    2) відправлення іншим учасникам справи копії позовної заяви і доданих до неї документів;
    3) обґрунтованість позовних вимог;
    4) сплату третейського збору у встановленому цим Регламентом порядку і розмірі.
    2. До позовної заяви, підписаної представником позивача, додається довіреність чи інший документ, що підтверджує повноваження представника позивача.`,
      },
      {
        bold: true,
        content: `Стаття 67. Ціна позову.`,
      },
      {
        content: `1. Ціна позву визначається:
    1) у позовах про стягнення грошових коштів - сумою, яка стягується;
    2) у позовах про визнання права власності на майно або його витребування – дійсною вартістю майна;
    3) у позовах про розірвання договору найму (оренди) - сукупністю платежів за користування майном протягом строку, що залишається до кінця дії договору;
    4) у позовах, які складаються з кількох самостійних вимог, - загальною сумою всіх вимог.
    2. Якщо позивач не визначив або неправильно визначив ціну позову, або визначена позивачем ціна позову вочевидь не відповідає дійсній вартості спірного майна або на момент пред’явлення позову встановити точну його ціну неможливо, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за власною ініціативою визначає ціну позову на підставі наявних відомостей та виносить відповідну ухвалу з зазначенім строків для сплати третейського збору.`,
      },
      {
        bold: true,
        content: `Стаття 68. Прийняття позовної заяви і порушення провадження у справі.`,
      },
      {
        content: `1. Питання про прийняття позовної заяви до провадження Третейського суду вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, у триденний строк з дня отримання позовної заяви Третейським судом.
    2. При прийнятті позову Третейський суд вирішує питання про наявність і дійсність угоди про передачу спору на розгляд Третейського суду.
    3. У разі, якщо Третейський суд дійде висновку про відсутність або недійсність зазначеної угоди, він відмовляє у розгляді справи. Про відмову у розгляді справи виноситься мотивована ухвала, яка надсилається сторонам. При цьому заявнику разом з ухвалою повертаються позовні матеріали.
    4. Третейський суд приймає до провадження позовну заяву, подану з додержанням вимог цього Регламенту до його форми і змісту.
    5. Про прийняття позовної заяви Третейський суд постановляє ухвалу, якою порушується провадження у справі.
    6. В ухвалі зазначається на підготовку справи до третейського розгляду, дії, які належить виконати особам, які приймають участь у справі, і строки їх виконання.
    7. Ухвала про порушення провадження у справі направляються особам, які приймають участь у справі, не пізніше трьох днів після дня її винесення.`,
      },
      {
        bold: true,
        content: `Стаття 69. Усунення недоліків позовної заяви.`,
      },
      {
        content: `1. Встановивши, що позовна заява подана без додержання вимог цього Регламенту, голова Третейського суду, його заступник, у відсутність голови Третейського суду, пропонує позивачу усунути виявлені недоліки.
    2. Якщо ці недоліки будуть усунуті, то датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата штемпеля поштового відомства адресата.`,
      },
      {
        bold: true,
        content: `Стаття 70. Відзив на позовну заяву.`,
      },
      {
        content: `1. Відповідач повинен надати Третейському суду письмовий відзив на позовну заяву. Відзив на позовну заяву направляється Третейському суду по пошті, шляхом вручення або через електронну приймальню не менше ніж за три дні до першого засідання Третейського суду.
    2. У випадку подачі відзиву на позовну заяву через електронну приймальню, його оригінал подається відповідачем в першому засіданні.
    3. У відзиві відповідач викладає заперечення проти позову.
    4. Відзив підписується відповідачем або його представником.
    5. Відзив повинен містити:
    1) найменування (ім’я) позивача і номер справи;
    2) повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові) (для фізичних осіб) відповідача, його місцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб), поштовий індекс, ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України, номери засобів зв’язку, офіційну електронну адресу та адресу електронної пошти, за наявності;
    3) у разі повного або часткового визнання позовних вимог - вимоги, які визнаються відповідачем;
    4) обставини, які визнаються відповідачем, а також правову оцінку обставин, наданих позивачем, з якою відповідач погоджується;
    5) заперечення (за наявності) щодо наведених позивачем обставин та правових підстав позову, з якими відповідач не погоджується, із посиланням на відповідні докази та норми права;
    6) перелік документів та інших доказів, що додаються до відзиву, та зазначення документів і доказів, які не можуть бути подані разом із відзивом, із зазначенням причин їх неподання.
    6. Копія відзиву та доданих до нього документів іншим учасникам справи повинна бути надіслана (надана) одночасно з надісланням (наданням) відзиву до Третейського суду.
    7. До відзиву додаються:
    1) докази, які підтверджують обставини, на яких ґрунтуються заперечення відповідача, якщо такі докази не надані позивачем;
    2) документи, що підтверджують надіслання (надання) відзиву і доданих до нього доказів іншим учасникам справи.
    8. До відзиву, підписаного представником відповідача, додається довіреність чи інший документ, що підтверджує повноваження представника відповідача.
    9. У разі ненадання відповідачем відзиву у встановлений Третейським судом строк без поважних причин, Третейський суд вирішує справу за наявними матеріалами.`,
      },
      {
        bold: true,
        content: `Стаття 71. Зустрічний позов.`,
      },
      {
        content: `1. Відповідач вправі подати зустрічний позов для розгляду Третейським судом, якщо такий позов є підвідомчим Третейському суду та може бути предметом третейського розгляду відповідно до Третейської угоди.
    2. Зустрічний позов може бути подано на будь-якій стадії третейського розгляду до прийняття рішення по справі.
    3. Третейський суд приймає зустрічний позов до спільного розгляду з первісним позовом, якщо обидва позови взаємно пов'язані та їх спільний розгляд є доцільним, зокрема, коли вони випливають з одних і тих самих правовідносин або коли задоволення зустрічного позову може виключити повністю або частково задоволення первісного позову.
    4. Зустрічна позовна заява має відповідати вимогам статті 35 Закону України "Про третейські суди" та статтям 65, 66 цього Регламенту.
    5. Зустрічна позовна заява, подана з порушенням вимог цієї статті, ухвалою Третейського суду повертається заявнику. Копія зустрічної позовної заяви долучається до матеріалів справи.
    6. Сторона зобов'язана надати відзив на пред'явлений до неї зустрічний позов у порядку та строки, визначені Третейським судом.`,
      },
      {
        bold: true,
        content: `Стаття 72. Забезпечення позову.`,
      },
      {
        content: `1. Якщо сторони не домовилися про інше, Третейський суд може за заявою будь-якої сторони розпорядитися про вжиття стороною таких забезпечувальних заходів щодо предмета спору, які він вважає необхідними, з урахуванням положень цивільного та господарського процесуального законодавства. Третейський суд може витребувати від будь-якої сторони надати належне забезпечення позову у зв'язку з такими заходами.`,
      },
      {
        bold: true,
        content: `Глава 10. Підготовка до третейського розгляду
    Стаття 73. Підготовка розгляду справи.`,
      },
      {
        content: `1. Склад Третейського суду перевіряє стан підготовки справи до розгляду і, якщо визнає за необхідне, вживає додаткові заходи по підготовці справи.
    2. Якщо склад Третейського суду приймає додаткові заходи по підготовці справи, він повинен встановити строки, на протязі яких ці додаткові заходи повинні бути виконані.
    3. Головуючий складу Третейського суду, або третейський суддя, який розглядає справу одноособово, може давати відповідальному секретарю Третейського суду окремі доручення у зв'язку з підготовкою та проведенням розгляду справи. Він також доручає йому виклик сторін на засідання.
    4. З метою правильного і своєчасного вирішення спору третейський суддя вчиняє в необхідних випадках такі дії по підготовці справи до розгляду:
    - вирішує питання про залучення до участі у справі іншого відповідача та про виключення чи заміну неналежного відповідача;
    - викликає представників сторін для уточнення обставин справи і з’ясовує, які матеріали може бути подано додатково;
    - зобов’язує сторони, інших осіб, що приймають участь у справі, виконати певні дії (звірити розрахунки, надати документи, відомості, висновки, необхідні для вирішення спору, тощо);
    - вирішує питання про призначення експертизи;
    - вирішує питання про визнання явки представників сторін у засідання Третейського суду обов’язковою;
    - вирішує питання про виклик посадових та інших осіб для дачі пояснень по суті справи;
    - вирішує питання про вжиття заходів до забезпечення позову;
    - вчиняє інші дії, спрямовані на забезпечення правильного і своєчасного розгляду справи.`,
      },
      {
        bold: true,
        content: `Стаття 74. Подання та надсилання документів щодо третейського розгляду.`,
      },
      {
        content: `1. Усі документи, що подаються однією із сторін до Третейського суду, повинні бути надіслані або передані іншій стороні з дотриманням правил, передбачених цією статтею. Сторонам також повинні бути надіслані або передані інші документи і докази, на яких може ґрунтуватися рішення третейського суду.
    2. Своєчасне надсилання або передача сторонам усіх документів у справі забезпечуються третейським суддею.
    3. Третейський суд повідомляє сторони та інших осіб, які беруть участь у третейському розгляді, про день, час та місце проведення засідання Третейського суду.
    4. Листування здійснюється за офіційним місцезнаходженням юридичної особи або фізичної особи – підприємця згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань або за місцем проживання фізичної особи.
    5. Сторони зобов’язані невідкладно повідомити Третейський суд про зміну своєї адреси у спосіб, що передбачає фіксацію надходження такого повідомлення.
    У разі відсутності заяви про зміну місця проживання або місцезнаходження документи надсилаються сторонам справи на останню відому Третейському суду адресу і вважається доставленою, навіть якщо сторона процесу за цією адресою більше не проживає або не знаходиться.
    Позовна заява, відзив на позов, інші заяви, клопотання та заперечення з процесуальних питань, судове повідомлення, рішення, ухвала Третейського суду, надсилаються сторонам рекомендованим листом з повідомленням про вручення або вручаються їх представникам особисто під розписку.
    6. Документи можуть надсилатися будь-яким поштовим оператором за умови дотримання вимог цієї статті.
    7. Днем вручення документів, пов’язаних з розглядом справи, є:
    - день вручення документів під розписку;
    - день отримання повідомлення про доставлення судової повістки на електронну адресу особи;
    - день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, повідомленою цією особою Третейському суду;
    - день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, що зареєстровані у встановленому законом порядку, якщо ця особа не повідомила Третейському суду іншої адреси.
    Якщо документи надіслано на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження, відправлення вважається врученим у робочий день, в який його було надіслано, а якщо його було надіслано пізніше 17 години, відправлення вважається врученим у робочий день, наступний за днем її відправлення, незалежно від надходження до суду повідомлення про її доставлення.`,
      },
      {
        bold: true,
        content: `Глава 11. Сприяння сторонам у досягненні ними мирової угоди
    Стаття 75. Примирення сторін.`,
      },
      {
        content: `1. Третейський суд застосовує заходи для примирення сторін, сприяє їм у вирішенні спору шляхом укладення мирової угоди на всіх стадіях процесу.
    2. Третейський суд на початку розгляду справи повинен з'ясувати у сторін можливість закінчити справу мировою угодою.`,
      },
      {
        bold: true,
        content: `Стаття 76. Укладення мирової угоди.`,
      },
      {
        content: `1. Сторони мають право закінчити справу укладенням мирової угоди, як до початку третейського розгляду, так і на будь-якій його стадії, до прийняття рішення.
    2. Мирова угода не повинна порушувати права і законні інтереси інших осіб і суперечити закону.
    3. Мирова угода затверджується Третейським судом.`,
      },
      {
        bold: true,
        content: `Стаття 77. Вирішення питання щодо затвердження мирової угоди.`,
      },
      {
        content: `1. За клопотанням сторін Третейський суд приймає рішення про затвердження мирової угоди. Мирова угоди може стосуватися лише прав і обов'язків сторін щодо предмета спору.
    2. Зміст мирової угоди викладається безпосередньо в рішенні Третейського суду. До рішень третейського суду, прийнятих відповідно до цієї статті, застосовуються правила розділу VI Закону України "Про третейські суди".
    3. Третейський суд не затверджує мирову угоду, якщо вона суперечить закону або порушує права і законні інтереси інших осіб.`,
      },
      {
        bold: true,
        content: `Глава 12. Зупинення провадження у справі
    Стаття 78. Обов'язок Третейського суду зупинити провадження у справі.`,
      },
      {
        content: `1. Третейський суд зобов'язаний зупинити провадження у справі у випадках:
    1) неможливості розгляду цієї справи до вирішення пов'язаної з нею іншої справи, що розглядається судом або відповідного питання компетентними органами;
    2) злиття, приєднання, поділу, перетворення юридичної особи, яка була стороною у справі або у зв'язку зі смертю або оголошення фізичної особи померлою, яка була стороною у справі, якщо спірні правовідносини допускають правонаступництво;
    3) призначення Третейським судом експертизи;
    4) надсилання Третейським судом матеріалів до слідчих органів.
    2. Третейський суд зупиняє провадження у справі також в інших випадках, передбачених цим Регламентом.
    3. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`,
      },
      {
        bold: true,
        content: `Стаття 79. Право Третейського суду зупинити провадження у справі.`,
      },
      {
        content: `1. Третейський суд може за заявою особи, яка бере участь у справі, а також з власної ініціативи зупинити провадження у справі у випадках:
    1) перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
    2) захворювання сторони, підтвердженого медичною довідкою, що виключає можливість явки до суду протягом тривалого часу;
    3) перебування сторони у тривалому службовому відрядженні;
    4) в інших випадках, коли Третейський суд вважатиме за необхідне зупинити провадження у справі, у зв'язку з неможливістю її розгляду.
    2. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`,
      },
      {
        bold: true,
        content: `Стаття 80. Строки, на які зупиняється провадження у справі.`,
      },
      {
        content: `1. Провадження у справі зупиняється у випадках, встановлених:
    1) пунктом 1 частини першої статті 78 цього Регламенту - до набрання законної сили судового рішення, від якого залежить вирішення справи або рішення відповідного компетентного органу;
    2) пунктом 2 частини першої статті 78 цього Регламенту - до залучення до участі у справі правонаступника чи представника;
    3) пунктом 3 частини першої статті 78 цього Регламенту - на час проведення експертизи;
    4) пунктом 4 частини першої статті 78 цього Регламенту - на час проведення відповідної перевірки;
    5) пунктом 1 частини першої статті 79 цього Регламенту - до припинення перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
    6) пунктом 2 частини першої статті 79 цього Регламенту - на час хвороби сторони;
    7) пунктами 3-4 частини першої статті 79 цього Регламенту - до усунення обставин, що зумовили зупинення провадження у справі.`,
      },
      {
        bold: true,
        content: `Стаття 81. Відновлення провадження у справі.`,
      },
      {
        content: `1. Провадження у справі відновлюється ухвалою Третейського суду за заявою особи, яка бере участь у справі, або з ініціативи суду після усунення обставин, що викликали його зупинення.
    2. З дня відновлення провадження у справі перебіг процесуальних строків продовжується.
    3. Після відновлення провадження Третейський суд викликає сторони та інших осіб, які беруть участь у справі, і продовжує третейський розгляд.`,
      },
      {
        bold: true,
        content: `Глава 13. Залишення позовної заяви без розгляду
    Стаття 82. Підстави для залишення позовної заяви без розгляду.`,
      },
      {
        content: `1. Третейський суд залишає позовну заяву без розгляду, якщо після її прийняття до розгляду встановить, що:
    1) позов подано особою, яка не має процесуальної дієздатності;
    2) позовну заяву не підписано або підписано особою, яка не має права підписувати її, або особою, посадове становище якої не вказано;
    3) позивач без поважних причин не подав витребувані Третейським судом докази, необхідні для вирішення спору, або позивач (його представник) не з’явився у засідання Третейського суду або не повідомив про причини неявки, крім випадку, якщо від нього надійшла заява про розгляд справи за його відсутності і його нез’явлення не перешкоджає вирішенню спору;
    4) спір між тими самими сторонами, про той самий предмет і з тих самих підстав розглядається в іншому суді;
    5) позивач ухиляється від замовлення проведення експертизи, призначеної Третейським судом, і якщо неможливо розглянути справу за допомогою дослідження інших доказів;
    6) позовна заява не відповідає вимогам цього Регламенту, або не було сплачено третейський збір, і позивач не усунув цих недоліків у встановлений Третейським судом строк;
    7) позивач подав заяву про залишення позову без розгляду.`,
      },
      {
        bold: true,
        content: `Стаття 83. Порядок і наслідки залишення позовної заяви без розгляду.`,
      },
      {
        content: `1. У разі залишення позовної заяви без розгляду виноситься ухвала, в якій зазначаються підстави для залишення позовної заяви без розгляду. Копії ухвали направляються особам, які приймають участь у справі.
    2. Залишення позовної заяви без розгляду не позбавляє позивача права знову звернутись у Третейський суд з позовною заявою у загальному порядку після усунення обставин, що послугували підставою для залишення заяви без розгляду.`,
      },
      {
        bold: true,
        content: `Глава 14. Припинення третейського розгляду
    Стаття 84. Підстави припинення третейського розгляду.`,
      },
      {
        content: `1. Підставами для припинення третейського розгляду є такі випадки:
    1) спір не підлягає вирішенню в третейських судах України;
    2) відсутній предмет спору;
    3) є рішення компетентного суду між тими ж сторонами, з того ж предмета і з тих самих підстав;
    4) позивач відмовився від позову і відмову прийнято Третейським судом;
    5) сторони уклали угоду про припинення третейського розгляду і вона затверджена Третейським судом;
    6) підприємство, установу чи організацію, які є стороною третейського розгляду, ліквідовано;
    7) Третейський суд є некомпетентним щодо переданого на його розгляд спору;
    8) у разі смерті фізичної особи (фізичної особи-підприємця), яка була стороною у справі, якщо спірні правовідносини не допускають правонаступництва.`,
      },
      {
        bold: true,
        content: `Стаття 85. Порядок і наслідки припинення третейського розгляду.`,
      },
      {
        content: `1. Про припинення третейського розгляду Третейський суд постановляє ухвалу. В ухвалі Третейський суд зазначає підстави для припинення третейського розгляду, а також вирішує питання про розподіл між сторонами витрат. Копії ухвали направляються особам, які приймають участь у справі.
    2. У разі припинення третейського розгляду повторне звернення до Третейського суду з приводу спору між тими самими сторонами, про той самий предмет і з тих самих підстав не допускається. Наявність ухвали про припинення третейського розгляду у зв'язку з прийняттям відмови позивача від позову не позбавляє відповідача в цій справі права на звернення до Третейського суду за вирішенням цього спору.`,
      },
      {
        bold: true,
        content: `Глава 15. Третейський розгляд
    Стаття 86. Засідання Третейського суду.`,
      },
      {
        content: `1. Третейський розгляд здійснюється у засіданні Третейського суду за участю сторін або їх представників. Сторона має право заявити Третейському суду клопотання щодо розгляду спору без її участі.
    2. Третейський суд вправі визнати явку сторін у засідання обов'язковою.
    3. Третейський суддя, а при колегіальному вирішенні спору – головуючий у засіданні Третейського суду:
    1) відкриває засідання Третейського суду і оголошує, яка справа підлягає розгляду;
    2) перевіряє явку в засідання осіб, які приймають участь у справі, їх представників та інших учасників третейського розгляду, встановлює їх особи і перевіряє їх повноваження; встановлює, чи повідомлені належним чином особи, які не з'явились у засідання, і які є відомості про причини їх неявки;
    3) з'ясовує питання щодо можливості розгляду справи;
    4) оголошує склад Третейського суду, повідомляє, хто веде протокол засідання третейського розгляду, хто приймає участь в якості експерта, перекладача, і роз'яснює особам, які приймають участь у справі, їх право заявляти відводи;
    5) роз'яснює особам, які приймають участь у справі, та іншим учасникам третейського процесу їх процесуальні права і обов'язки;
    6) видаляє з місця третейського розгляду свідків до початку їх опитування;
    7) визначає з врахуванням думки осіб, які приймають участь у справі, послідовність проведення процесуальних дій;
    8) з'ясовує, чи підтримує позивач позов, чи визнає позов відповідач, чи не бажають сторони закінчить справу мировою угодою;
    9) керує засіданням Третейського суду, забезпечує умови для всебічного і повного дослідження доказів і обставин справи, забезпечує розгляд заяв і клопотань осіб, які приймають участь у справі.`,
      },
      {
        bold: true,
        content: `Стаття 87. Протокол засідання Третейського суду.`,
      },
      {
        content: `1. Протокол засідання Третейського суду ведеться лише в разі наявності угоди між сторонами про ведення протоколу.
    2. Для ведення протоколу за згодою третейських суддів (третейського судді) сторонами може бути призначений секретар третейського розгляду. За його відсутності третейські судді можуть обрати секретаря із складу (крім головуючого) третейського суду, яким вирішується спір.
    3. У протоколі засідання третейського суду зазначаються:
    1) дата та місце розгляду справи,
    2) прізвища третейських суддів і секретаря;
    3) суть спору;
    4) найменування сторін, їхніх представників, свідків, експертів, інших осіб, які беруть участь у розгляді справи;
    5) заяви та клопотання сторін;
    6) вказівки та вимоги до сторін по справі, висунуті Третейським судом;
    7) розклад засідань третейського суду;
    8) зміст пояснень сторін та інших осіб, які беруть участь у справі;
    9) подані під час третейського розгляду докази, відомості про їх огляд;
    10) узгодження сторонами питання про необхідність зберігання та умови зберігання рішень Третейського суду;
    11) витрати, понесені сторонами.
    4. Протокол підписується секретарем та всіма третейськими суддями, які розглядали справу.`,
      },
      {
        bold: true,
        content: `Стаття 88. Підстави та порядок відводу секретаря третейського розгляду, який веде протокол.`,
      },
      {
        content: `1. Якщо секретар особисто чи опосередковано заінтересований у результаті розгляду справи, або якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках він підлягає відводу чи самовідводу.
    2. Питання про відвід секретаря вирішується складом Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 89. Наслідки неподання сторонами документів, інших письмових матеріалів або неявки сторін.`,
      },
      {
        content: `1. Ненадання витребуваних Третейським судом доказів, інших письмових документів чи матеріалів, неявка у засідання Третейського суду сторін або їхніх представників, які належним чином були повідомлені про розгляд справи і проведення засідання Третейського суду, не є перешкодою для третейського розгляду і прийняті рішення, якщо причина ненадання документів або неявки сторін у засідання визнана Третейським судом неповажною.`,
      },
      {
        bold: true,
        content: `Стаття 90. Наслідки неявки в засідання експертів, свідків, перекладачів.`,
      },
      {
        content: `1. При неявці в засідання Третейського суду експертів, свідків, перекладачів, належним чином повідомлених про час і місце засідань Третейський суд виносить ухвалу про відкладення третейського розгляду, якщо сторони не заявили клопотання про розгляд справи за відсутністю зазначених осіб.`,
      },
      {
        bold: true,
        content: `Стаття 91. Відкладення засідання Третейського суду.`,
      },
      {
        content: `1. Третейський суд відкладає третейський розгляд у випадках, передбачених цим Регламентом, а також у разі неявки в засідання Третейського суду особи, яка приймає участь у справі, якщо у відношенні цієї особи у суду відсутні відомості про повідомлення її про час і місце третейського розгляду.
    2. Третейський суд може відкласти третейський розгляд за клопотанням сторін.
    3. У разі якщо особа, яка приймає участь у справі і повідомлена належним чином про час і місце третейського розгляду, заявила клопотання про відкладення третейського розгляду з обґрунтуванням причини неявки у засідання, Третейський суд може відкласти третейський розгляд, якщо визнає причини неявки поважними.
    4. Третейський суд може відкласти третейський розгляд за клопотанням особи, яка приймає участь у справі, у зв'язку з неявкою у засідання його представника з поважної причини.
    5. Третейський суд може відкласти третейський розгляд, якщо визнає, що він не може відбутися в даному засіданні, у тому числі внаслідок неявки будь-кого з осіб, які приймають участь у справі, інших учасників третейського процесу, а також при задоволенні клопотання сторони про відкладення третейського розгляду у зв'язку з необхідністю надання нею додаткових доказів, при здійсненні інших процесуальних дій.
    6. При відкладенні третейського розгляду Третейський суд вправі допитати свідків, які з'явилися, якщо в засіданні присутні сторони або їх представники. Показання цих свідків оголошуються у новому засіданні Третейського суду.
    7. Третейський розгляд може бути відкладено на строк, необхідний для усунення обставин, що послугували підставою для відкладення, в межах строку розгляду справи, встановленого цим Регламентом.
    8. Про відкладення третейського розгляду Третейський суд виносить ухвалу.
    9. Про час і місце нового засідання Третейський суд повідомляє осіб, які приймають участь у справі, та інших учасників третейського розгляду. Особи, які з'явились в засідання, повідомляються про час і місце нового засідання безпосередньо в засіданні Третейського суду під розписку.
    10. Третейський розгляд в новому засіданні Третейського суду поновлюється з того моменту, з якого воно було відкладено. Повторний розгляд доказів, досліджених до відкладення третейського розгляду, не провадиться.`,
      },
      {
        bold: true,
        content: `Стаття 92. Розгляд Третейським судом заяв і клопотань осіб, які приймають участь у справі.`,
      },
      {
        content: `1. Заяви і клопотання осіб, які приймають участь у справі, розглядаються Третейським судом після того, як буде заслухана думка решти присутніх у засіданні Третейського суду осіб, які беруть участь у справі.
    2. За результатами розгляду заяв і клопотань Третейський суд виносить ухвали.`,
      },
      {
        bold: true,
        content: `Стаття 93. Дослідження доказів.`,
      },
      {
        content: `1. При розгляді справи Третейський суд повинен безпосередньо дослідити докази у справі: ознайомитися з письмовими доказами, оглянути речові докази, заслухати пояснення осіб, які приймають участь у справі, показання свідків, висновки експертизи, а також оголосити такі пояснення, показання, висновки, надані у письмовій формі.
    2. Особа, яка приймає участь у справі, вправі дати Третейському суду пояснення про представлені нею докази, і докази, витребувані Третейським судом за її клопотанням, а також задати питання викликаним у засідання Третейського суду експертам і свідкам. При цьому першим задає питання особа, за клопотанням якої були викликані експерти і свідки.`,
      },
      {
        bold: true,
        content: `Стаття 94. Перерва у засіданні Третейського суду.`,
      },
      {
        content: `1. Третейський суд за клопотанням особи, яка приймає участь у справі, або за власною ініціативою може оголосити перерву у засіданні Третейського суду.
    2. Перерва у засіданні третейського суду може бути об'явлена на строк, що не перевищує п'яти днів.
    3. Щодо перерви і час продовження засідання, зазначається в ухвалі Третейського суду.
    4. Після закінчення перерви засідання Третейського суду продовжується. Повторний розгляд досліджених до перерви доказів не провадиться, у тому числі при заміні представників осіб, які приймають участь у справі.
    5. Особи, які приймають участь у справі і присутні в засіданні Третейського суду до оголошення перерви, вважаються належним чином оповіщеними про час і місце засідання Третейського суду, і їх нез'явлення у засідання після закінчення перерви не є перешкодою для його продовження.`,
      },
      {
        bold: true,
        content: `Стаття 95. Проведення усного слухання.`,
      },
      {
        content: `1. Для викладення сторонами своїх позицій на підставі доказів, що надаються та для проведення усних промов сторін здійснюється усне слухання справи.
    2. Слухання проводиться у закритому засіданні. З дозволу складу Третейського суду, або третейського судді, який розглядає справу одноособово, і зі згоди сторін під час слухання можуть бути присутні особи, які не приймають участі у третейському розгляді.`,
      },
      {
        bold: true,
        content: `Стаття 96. Розгляд на підставі письмових матеріалів.`,
      },
      {
        content: `1. Сторони можуть домовитись про розгляд спору на підставі тільки письмових матеріалів наданих сторонами, без проведення усного слухання.
    2. Якщо надані матеріали виявляться недостатніми для розгляду спору, склад Третейського суду, або суддя, який розглядає справу одноособово, може призначити усне слухання.`,
      },
      {
        bold: true,
        content: `Стаття 97. Промови сторін.`,
      },
      {
        content: `1. Після з'ясування всіх обставин справи та перевірки їх доказами головуючий у засіданні Третейського суду з'ясовує у осіб, які приймають участь у справі, чи не бажають вони доповнити матеріали справи. За відсутністю таких заяв головуючий у засіданні оголошує дослідження доказів закінченими, і Третейський суд надає право особам, які приймають участь у справі висловити свою думку про те, як повинна бути вирішена справа, дати оцінку доказам, зробити висновки про встановлення чи не встановлення фактів, що мають значення для правильного розгляду справи, висловитись відносно правової кваліфікації спірних правовідносин з посиланням на закон, який, за їх міркуванням, регулює ці правовідносини, але лише з посиланням на обставини і докази, які були досліджені у засіданні Третейського суду.
    2. Черговість виступу сторін встановлюється суддею Третейського суду, а у разі розгляду справи колегіально - складом колегії суддів.`,
      },
      {
        bold: true,
        content: `Стаття 98. Відновлення дослідження доказів.`,
      },
      {
        content: `1. У разі якщо Третейський суд під час або після промов сторін визнає за необхідне з'ясувати додаткові обставини або дослідити нові докази, Третейський суд поновлює дослідження доказів, про що виноситься ухвала.
    2. Після закінчення додаткового дослідження доказів, промови сторін проводяться у загальному порядку, встановленому цим Регламентом.`,
      },
      {
        bold: true,
        content: `Стаття 99. Закінчення розгляду справи по суті.`,
      },
      {
        content: `1. Після того як Третейський суд переконається, що сторони мали достатньо можливостей для викладення своєї позиції та для подання доказів, Третейський суд оголошує про завершення третейського розгляду у частині дослідження обставин справи і переходить до прийняття рішення.`,
      },
      {
        bold: true,
        content: `Глава 16. Рішення Третейського суду
    Стаття 100. Прийняття рішення Третейським судом.`,
      },
      {
        content: `1. Рішення Третейського суду приймається після дослідження усіх обставин справи третейським суддею, що одноособово розглядав справу, або більшістю голосів третейських суддів, які входять до складу Третейського суду. Рішення приймається у закритій нараді Третейського суду.
    2. Рішення оголошується у засіданні Третейського суду.
    3. Третейський суддя, який не згодний із прийнятим рішенням (частково або в цілому), може викласти у письмовій формі свою окрему думку.
    4. Третейський суд вправі оголосити лише резолютивну частину рішення. У цьому випадку, якщо сторони не погодили строк направлення їм рішення, мотивоване рішення має бути направлене сторонам у строк, який не перевищує п'яти днів з дня оголошення резолютивної частини рішення.
    5. Кожній стороні направляється по одному примірнику рішення.
    6. У разі відмови сторони одержати рішення Третейського суду або її неявки без поважних причин у засідання Третейського суду, де воно оголошується, рішення вважається таким, що оголошене сторонам, про що на рішенні робиться відповідна відмітка, а копія такого рішення надсилається такій стороні.
    7. Датою прийняття рішення є дата оголошення рішення у засіданні Третейського суду або оголошення його резолютивної частини.`,
      },
      {
        bold: true,
        content: `Стаття 101. Вимоги до рішення Третейського суду.`,
      },
      {
        content: `1. Рішення Третейського суду викладається у письмовій формі і підписується третейським суддею, який одноособово розглядав справу, або повним складом третейського суду, що розглядав справу, в тому числі і третейським суддею, який має окрему думку. Окрема думка третейського судді викладається письмово та додається до рішення Третейського суду.
    2. Рішення Третейського суду скріплюється підписом Президента Асоціації "ПРАВОВА ЛІГА" та круглою печаткою Асоціації "ПРАВОВА ЛІГА".
    3. У рішенні Третейського суду повинні бути зазначені:
    1) назва Третейського суду;
    2) дата прийняття рішення;
    3) склад третейського суду і порядок його формування:
    4) місце третейського розгляду;
    5) сторони, їх представники та інші учасники третейського розгляду, що брали участь у розгляді справи третейським судом;
    6) висновок про компетенцію Третейського суду, обсяг його повноважень за Третейською угодою;
    7) стислий виклад позовної заяви, відзиву на позовну заяву, заяв, пояснень, клопотань сторін та їхніх представників, інших учасників третейського розгляду;
    8) встановлені обставини справи, підстави виникнення спору, докази, на підставі яких прийнято рішення, зміст мирової угоди, якщо вона укладена сторонами, мотиви, з яких Третейський суд відхилив доводи, докази та заявлені під час третейського розгляду клопотання сторін;
    9) висновок про задоволення позову або про відмову в позові повністю або частково по кожній із заявлених вимог;
    10) норми законодавства, якими керувався Третейський суд при прийнятті рішення.
    4. Висновки третейського суду, що містяться в рішенні по справі, не можуть залежати від настання або ненастання будь-яких обставин.
    5. У разі задоволення позовних вимог у резолютивній частині рішення зазначаються:
    1) сторона, на користь якої вирішено спір;
    2) сторона, з якої за рішенням Третейського суду має бути здійснено стягнення грошових сум та/або яка зобов'язана виконати певні дії або утриматися від виконання певних дій;
    3) розмір грошової суми, яка підлягає стягненню, та/або дії, які підлягають виконанню або від виконання яких сторона має утриматися за рішенням Третейського суду;
    4) строк сплати коштів та/або строк і спосіб виконання таких дій;
    5) порядок розподілу між сторонами витрат, пов'язаних з вирішенням спору третейським судом;
    6) інші обставини, які Третейський суд вважає за необхідне зазначити.`,
      },
      {
        bold: true,
        content: `Стаття 102. Додаткове рішення.`,
      },
      {
        content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, може протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про прийняття додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні.
    2. Заяву про прийняття додаткового рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.
    3. За результатами розгляду заяви приймається додаткове рішення, яке є складовою частиною рішення Третейського суду, або виноситься мотивована ухвала про відмову у задоволенні заяви про прийняття додаткового рішення.`,
      },
      {
        bold: true,
        content: `Стаття 103. Роз'яснення рішення.`,
      },
      {
        content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, має право протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про роз'яснення резолютивної частини рішення.
    2. Заяву про роз'яснення резолютивної частини рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.
    3. За результатами розгляду заяви виноситься ухвала про роз'яснення рішення, яка є складовою частиною рішення, або мотивована ухвала про відмову у роз'ясненні рішення.
    4. Здійснюючи роз'яснення резолютивної частини рішення, Третейський суд не має права змінювати зміст рішення.`,
      },
      {
        bold: true,
        content: `Стаття 104. Виправлення рішення.`,
      },
      {
        content: `1. Третейський суд у тому ж складі, з власної ініціативи або за заявою сторони третейського розгляду, може виправити у рішенні описки, арифметичні помилки або будь-які інші неточності, про що виноситься ухвала, яка є складовою частиною рішення.`,
      },
      {
        bold: true,
        content: `Стаття 105. Обов'язковість рішення Третейського суду.`,
      },
      {
        content: `1. Сторони, які передали спір на вирішення Третейського суду, зобов'язані добровільно виконати рішення Третейського суду, без будь-яких зволікань чи застережень.
    2. Сторони та Третейський суд вживають усіх необхідних заходів з метою забезпечення виконання рішення Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 106. Оскарження рішення Третейського суду.`,
      },
      {
        content: `1. Рішення Третейського суду є остаточним і оскарженню не підлягає, крім випадків, передбачених Законом України "Про третейські суди".
    2. Рішення Третейського суду може бути оскаржене стороною у випадках, передбачених Законом України "Про третейські суди", до компетентного суду відповідно до встановлених законом підвідомчості та підсудності справ.
    3. Рішення Третейського суду може бути оскаржене та скасоване лише з таких підстав:
    1) справа, по якій прийнято рішення Третейського суду, не підвідомча Третейському суду відповідно до закону;
    2) рішення Третейського суду прийнято у спорі, не передбаченому третейською угодою, або цим рішенням вирішені питання, які виходять за межі Третейської угоди. Якщо рішенням Третейського суду вирішені питання, які виходять за межі Третейської угоди, то скасовано може бути лише ту частину рішення, що стосується питань, які виходять за межі Третейської угоди;
    3) Третейську угоду визнано недійсною компетентним судом;
    4) склад Третейського суду, яким прийнято рішення, не відповідав вимогам статей 16, 19 Закону України "Про третейські суди".
    4. Заяву про скасування рішення Третейського суду може бути подано до компетентного суду протягом трьох місяців з дня прийняття рішення Третейським судом.
    5. Скасування компетентним судом рішення Третейського суду не позбавляє сторони права повторно звернутися до Третейського суду, крім випадків, передбачених цією статтею.
    6. У разі якщо рішення Третейського суду скасовано повністю або частково внаслідок визнання компетентним судом недійсною Третейської угоди або через те, що рішення прийнято у спорі, який не передбачений Третейською угодою, чи цим рішенням вирішені питання, що виходять за межі Третейської угоди, відповідний спір не підлягає подальшому розгляду в третейських судах.`,
      },
      {
        bold: true,
        content: `Глава 17. Ухвали Третейського суду
    Стаття 107. Винесення Третейським судом ухвал.`,
      },
      {
        content: `1. З питань, що виникають у процесі розгляду справи і не стосуються суті спору, Третейський суд постановляє ухвали.`,
      },
      {
        bold: true,
        content: `Стаття 108. Зміст ухвали`,
      },
      {
        content: `1. В ухвалі повинні бути зазначені:
    1) дата і місце винесення ухвали;
    2) найменування Третейського суду, склад суду;
    3) найменування і номер справи;
    4) найменування осіб, які приймають участь у справі;
    5) питання, з якого виноситься ухвала;
    6) мотиви, з яких Третейський суд дійшов до висновків, прийняв або відхилив доводи осіб, які приймають участь у справі, з посиланням на закони та інші нормативно-правові акти;
    7) висновок за результатами розгляду Третейським судом питання.
    Ухвала, підписується третейським суддею або складом Третейського суду, який виніс цю ухвалу.`,
      },
      {
        bold: true,
        content: `Стаття 109. Направлення ухвали.`,
      },
      {
        content: `1. Копії ухвали, направляються особам, які приймають участь у справі, та іншими особам, яких вона стосується, рекомендованим листом з повідомленням про вручення або вручаються їм під розписку.
    2. Копії ухвали направляються в п'ятиденний строк з дня винесення ухвали, якщо інший строк не встановлений цим Регламентом.`,
      },
      {
        bold: true,
        content: `Стаття 110. Виконання ухвали.`,
      },
      {
        content: `1. Ухвала, винесена Третейським судом, виконується негайно, якщо інше не встановлено цим Регламентом або Третейським судом.`,
      },
      {
        bold: true,
        content: `Глава 18. Інше
    Стаття 111. Зберігання матеріалів справи, розглянутої Третейським судом.`,
      },
      {
        content: `1. Справа, розглянута Третейським судом, зберігається у Третейському суді протягом 10 років з дня прийняття рішення Третейського суду.`,
      },
      {
        bold: true,
        content: `Стаття 112. Публікація рішень Третейського суду.`,
      },
      {
        content: `1. Опублікування винесених Третейським судом рішень дозволяється з дозволу голови Третейського суду, його заступника, у відсутність голови Третейського суду.
    2. При опублікуванні таких рішень необхідно забезпечити нерозголошення комерційної таємниці, слідкувати за тим, щоб не зазначались найменування контрагентів у правочині, найменування цінних паперів, цін, якщо інше не дозволено сторонами справи. голова Третейського суду, його заступник, у відсутність голови Третейського суду, може не допустити до опублікування й інші відомості.`,
      },
    ]
  },
  order2024: {
    title: 'Регламент 2024',
    content: [
      {
        right: true,
        content: `ЗАТВЕРДЖЕНО
    Рішення Ради Асоціації «ПРАВОВА ЛІГА»
    від 22 березня 2007 року, протокол № 1`,
      },
      {
        right: true,
        content: `із змінами
    ЗАТВЕРДЖЕНО
    Рішення Ради Асоціації «ПРАВОВА ЛІГА»
    від 09 лютого 2021 року, протокол №1/2021`,
      },
      {
        right: true,
        content: `ЗАТВЕРДЖЕНО
    Рішення Ради Асоціації «ПРАВОВА ЛІГА»
    від 30 вересня 2024 року, протокол №1/2024
    `,
      },
      {
        center: true,
        content: `РЕГЛАМЕНТ
    Постійно діючого третейського суду
    при Асоціації "ПРАВОВА ЛІГА"
    (нова редакція)
    `,
      },
      {
        center: true,
        bold: true,
        content: `Глава 1. Загальні положення`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 1. Статус Третейського суду`,
      },
      {
        content: `1. Постійно діючий третейський суд при Асоціації "ПРАВОВА ЛІГА" (далі - Третейський суд) є недержавним, незалежним, постійно діючим третейським судом та здійснює свою діяльність у відповідності з чинним законодавством, що регулює діяльність третейських судів в Україні та цим Регламентом Третейського суду.
    
        2. Третейський суд діє при Асоціації "ПРАВОВА ЛІГА", яка матеріально і організаційно забезпечує його діяльність.
    
        3. Завданням Третейського суду є захист майнових і немайнових прав та охоронюваних законом інтересів фізичних чи юридичних осіб шляхом всебічного розгляду та вирішення спорів відповідно з чинним законодавством та Регламенту Третейського суду (далі - Регламент). Діяльність Третейського суду та третейських суддів не є підприємницькою.
        
        4. На розгляд до Третейського суду може бути передано будь-який спір, що виник з цивільних чи господарських правовідносин, крім випадків, передбачених Законом України «Про третейські суди» та іншими законодавчими актами.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 2. Компетенція Третейського суду.`,
      },
      {
        content: `1. Третейський суд порушує справи за позовними заявами підприємств, організацій, фізичних осіб-підприємців та фізичних осіб, які звертаються до Третейського суду за захистом своїх прав та охоронюваних законом інтересів.
    
    2. Третейський суд з дотриманням вимог Закону України "Про третейські суди" самостійно вирішує питання про наявність або відсутність у нього компетенції для розгляду конкретного спору.
    
    3. Третейський суд розглядає спори за наявністю третейського застереження у договорі, контракті або у вигляді окремої письмової угоди (далі - Третейська угода) між сторонами про передачу на його вирішення конкретного спору, визначених категорій або всіх спорів, що виникли або можуть виникнути між сторонами у зв'язку з будь-якими правовідносинами незалежно від того, мали вони чи ні договірний характер.
    
    4. Третейська угода укладається у письмовій формі. Третейська угода вважається укладеною у письмовій формі, якщо вона міститься в документі, підписаному сторонами чи укладена шляхом обміну листами, повідомленнями по телетайпу, телеграфу або з використанням інших засобів електронного чи іншого зв'язку, що забезпечує фіксацію такої угоди або шляхом направлення відзиву на позов, в якому одна із сторін підтверджує наявність угоди, а інша сторона проти цього не заперечує. Посилання у договорі, контракті на документ, який містить умову про третейський
    розгляд спору, є Третейською угодою за умови, що договір укладений у письмовій формі і це посилання є таким, що робить Третейську угоду частиною договору.
    
    5. Якщо сторони домовились звернутися до Третейського суду, Регламент для них є обов'язковим.
    
    6. Сторона має право заявити про відсутність у Третейського суду компетенції стосовно переданого на вирішення спору до початку розгляду справи по суті.
    
    7. Сторона має право заявити про перевищення Третейським судом меж його компетенції, якщо в процесі третейського розгляду справи виникне питання, розгляд якого не передбачено третейською угодою або яке не може бути предметом такого розгляду відповідно до закону та цього Регламенту.
    
    8. У випадках, передбачених пунктами 6 і 7 цієї статті, Третейський суд повинен відкласти розгляд справи по суті до вирішення ним питання щодо наявності у нього компетенції.
    
    9. Питання щодо компетенції Третейського суду по конкретній справі вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, складом Третейського суду чи третейським суддею, який розглядає спір одноособово. При цьому Третейська угода, що є частиною договору, тлумачиться як угода, яка не залежить від інших умов договору. Недійсність окремих положень договору, контракту, що містить третейське застереження, не тягне за собою недійсність такого третейського застереження.
    
    10. З питань наявності чи відсутності компетенції Третейський суд постановляє мотивовану ухвалу.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 3. Місцезнаходження Третейського суду.`,
      },
      {
        content: `5. Місцезнаходженням Третейського суду є місцезнаходження Асоціації "ПРАВОВА ЛІГА" (ідентифікаційний код 34826691): 46001, майдан Волі, 4, м. Тернопіль, Україна.
    
    6. Перед зверненням до Третейського суду Сторони зобов’язуються перевірити місцезнаходження Асоціації "ПРАВОВА ЛІГА" згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань.
    
    7. Зміна місцезнаходження Третейського суду не впливає на чинність третейської угоди.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 4. Місце третейського розгляду.`,
      },
      {
        content: `1. Місцем третейського розгляду є місцезнаходження Третейського суду.
    
    2. Сторони можуть домовитися про проведення третейського розгляду поза місцем знаходження Третейського суду. У такому разі всі додаткові витрати, пов'язані з проведенням розгляду, покладаються на сторони.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 5. Законодавство, яке застосовується Третейським судом при вирішенні спорів.`,
      },
      {
        content: `1. Третейські суди вирішують спори на підставі Конституції та законів України, інших нормативно-правових актів та міжнародних договорів України.
    
    2. Третейський суд у випадках, передбачених законом або міжнародним договором України, застосовує норми права інших держав.
    
    3. У разі відсутності законодавства, що регулює певні спірні відносини, Третейський суд застосовує законодавство, яке регулює подібні відносини, а за відсутності такого Третейський суд застосовує аналогію права чи керується торговими та іншими звичаями, якщо останні за своїм характером та змістом властиві таким спірним відносинам.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 6. Мова третейського розгляду.`,
      },
      {
        content: `1. Розгляд справ у Третейському суді провадиться українською мовою.
    
    2. Процесуальні документи Третейського суду приймаються українською мовою.
    
    3. Сторона, яка надає документи чи письмові докази мовою іншою, ніж мова третейського розгляду, повинна забезпечити їх переклад на мову третейського розгляду.
    
    4. Якщо сторона не володіє мовою, якою провадиться розгляд, Третейський суд за проханням сторони і за її рахунок забезпечується послугами перекладача.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 7. Гласність.`,
      },
      {
        content: `1. Третейський розгляд здійснюється відкрито.
    
    2. У разі коли проти відкритого третейського розгляду висунуто хоча б однією стороною заперечення з мотивів додержання та збереження комерційної або банківської таємниці чи забезпечення конфіденційності інформації, справа розглядається у закритому засіданні.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 8. Конфіденційність.`,
      },
      {
        content: `1. Відомості, пов’язані з третейським розглядом справи, є конфіденційними. Третейський суд, суддя Третейського суду, працівники апарату Третейського суду не вправі розголошувати відомості та інформацію, що стали йому (їм) відомі під час третейського розгляду, без згоди сторін або їх правонаступників.
    
    2. Забороняється вимагати від третейського судді надання документів, відомостей та інформації, якими він володіє у зв'язку з третейським розглядом справи, крім випадків, передбачених законами України.
    
    3. У будь-якому випадку, при публікаціях не допускається посилання на найменування сторін, їх банківські реквізити та аналогічні відомості.
    
    4. Надання відомостей щодо спорів, переданих на розгляд до Третейського суду, допускається лише на письмовий запит компетентних органів у випадках та з підстав, передбачених законами України.
    
    5. Не буде вважатись розголошенням відомостей в разі, якщо сторони в ході розгляду справи не подавали письмових заяв чи клопотань про збереження конфіденційності.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 9. Принципи діяльності Третейського суду.`,
      },
      {
        content: `1. Третейський суд діє на принципах, визначених Законом України «Про третейські суди».`,
      },
      {
        center: true,
        bold: true,
        content: `Глава 2. Учасники третейського розгляду`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 10. Склад осіб, які приймають участь у третейському розгляді.`,
      },
      {
        content: `1. Особами, що приймають участь у третейському розгляді є сторони, їх представники.
    
    2. Сторонами третейського розгляду можуть бути юридичні та фізичні особи, які подали до Третейського суду позов на захист своїх прав та інтересів, або до яких пред'явлений позов.
    
    3. Позивачами є юридичні особи, фізичні особи-підприємці та фізичні особи, які подали позов.
    
    4. Відповідачами є юридичні особи, фізичні особи-підприємці та фізичні особи, до яких подана позовна вимога.
    
    5. Треті особи вступають в третейський розгляд тільки зі згоди сторін третейського розгляду. Для залучення до третейського розгляду третьої особи, окрім згоди сторін, необхідна також згода особи, яка залучається. Згода на залучення третьої особи повинна бути викладена у письмовій формі.
    
    6. Для об'єктивного і вірного третейського розгляду Третейський суд вправі за власною ініціативою залучати третіх осіб за їх згодою без згоди сторін.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 11. Інші учасники третейського розгляду.`,
      },
      {
        content: `1. Іншими учасниками третейського розгляду, які сприяють здійсненню третейського розгляду можуть бути: експерти, свідки, перекладачі, секретар третейського розгляду.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 12. Загальні засади третейського розгляду.`,
      },
      {
        content: `1. Третейський розгляд здійснюється на засадах змагальності та рівності сторін. До сторін повинно бути рівне ставлення і кожній стороні повинні бути надані рівні можливості для викладення своєї позиції.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 13. Права та обов'язки осіб, які приймають участь у справі.`,
      },
      {
        content: `1. Особи, які приймають участь у справі, мають право знайомитись з матеріалами справи, робити з них виписки, знімати копії: заявляти відводи; надавати докази і ознайомлюватися з доказами, наданими іншими особами, що приймають участь у справі, до початку третейського розгляду; брати участь у дослідженні доказів; задавати питання іншим учасникам третейського розгляду, заявляти клопотання, робити заяви, давати пояснення Третейському суду, наводити свої доводи по всім питанням, що виникають при розгляді справи; знайомитися з клопотаннями, заявленими іншими особами, заперечувати проти клопотань, доводів інших осіб, що приймають участь у справі; отримувати копії ухвал та рішень, користуватися іншими процесуальними правами, наданими їм цим Регламентом.
    
    2. Особи, які приймають участь у справі, повинні добросовісно користуватися всіма наданими їм правами.
    
    3. Особи, які приймають участь у справі, несуть обов'язки, передбачені цим Регламентом або покладені на них Третейським судом у відповідності з цим Регламентом.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 14. Процесуальна правоздатність і процесуальна дієздатність.`,
      },
      {
        content: `1. Здатність мати процесуальні права і нести процесуальні обов'язки (процесуальна правоздатність) визнається в рівній мірі за всіма юридичними і фізичними особами, які мають згідно з законом право на судовий захист в Третейському суді своїх прав і законних інтересів.
    
    2. Здатність своїми діями здійснювати процесуальні права і виконувати процесуальні обов'язки (процесуальна дієздатність) належить в Третейському суді - юридичним і фізичним особам.
    
    3. Права і законні інтереси недієздатних фізичних осіб захищають в третейському розгляді їх законні представники.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 15. Участь у справі декількох позивачів або відповідачів.`,
      },
      {
        content: `1. Позов може бути поданий до Третейського суду сумісно декількома позивачами або до декількох відповідачів (процесуальна співучасть). Кожен з позивачів або відповідачів виступає в процесі самостійно. Співучасники можуть доручити ведення справи одному або декільком з співучасників.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 16. Залучення до участі у справі іншого відповідача.
    Заміна неналежного відповідача.`,
      },
      {
        content: `1. Третейський суд за наявністю достатніх підстав має право до прийняття рішення залучити за клопотанням позивача або за своєю ініціативою до участі у справі іншого відповідача, при наявності підписаної Третейської угоди.
    
    2. У разі, якщо при підготовці справи до третейського розгляду або під час третейського розгляду буде встановлено, що позов пред'явлений не до тої особи, яка повинна відповідати за позовом, Третейський суд може за згодою позивача, не припиняючи провадження у справі, допустити заміну неналежного відповідача належним відповідачем, при наявності підписаної Третейської угоди.
    
    3. Про залучення іншого відповідача чи заміну неналежного відповідача розгляд справи проводиться спочатку, про що Третейський суд виносить ухвалу.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 17. Процесуальне правонаступництво.`,
      },
      {
        content: `1. У випадках вибуття однієї з сторін третейського розгляду (реорганізація юридичної особи, уступка вимоги, переведення боргу, смерть фізичної особи та інші випадки переміни осіб у зобов'язаннях) Третейський суд проводить заміну цієї сторони її правонаступником, при згоді правонаступника і зазначає про це в ухвалі або в рішенні. Правонаступництво можливе на будь-якій стадії третейського розгляду.
    
    2. Для правонаступника усі дії, здійснені в третейському процесі до вступу правонаступника в справу, обов'язкові в тій мірі, в якій вони були обов'язкові для особи, яку правонаступник змінив.
    
    3. Якщо Третейська угода укладена у вигляді третейського застереження, то вона вважається невід’ємною частиною угоди і щодо неї діють такі самі правила правонаступництва, що й до угоди в цілому.
    
    4. Якщо Третейська угода укладена у вигляді окремої угоди, то у разі заміни кредитора у зобов’язанні останній повинен повідомити нового кредитора про її існування. Зміна Третейської угоди в такому випадку можлива лише за умови явно вираженої згоди іншої (інших) сторони (сторін) угоди.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 18. Зміна підстави або предмета позову, зміна розміру позовних вимог, відмова від позову, визнання позову, мирова угода.`,
      },
      {
        content: `1. Позивач має право при розгляді справи в Третейському суді до прийняття рішення змінити підставу або предмет позову, збільшити або зменшити розмір позовних вимог.
    
    2. Позивач має право при розгляді справи в Третейському суді до прийняття рішення, відмовитись від позову повністю або частково.
    
    3. Відповідач має право при розгляді справи в Третейському суді визнати позов повністю або частково.
    
    4. Сторони можуть закінчити справу мировою угодою в порядку, передбаченому главою 11 цього Регламенту.
    
    5. Третейський суд не приймає відмову позивача від позову, зменшення ним розміру позовних вимог, визнання відповідачем позову, не затверджує мирову угоду сторін, якщо це суперечить закону або порушує права інших осіб.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 19. Треті особи, які заявляють самостійні вимоги відносно предмета спору.`,
      },
      {
        content: `1. Треті особи, які заявляють самостійні вимоги відносно предмета спору, можуть за їх згодою та за згодою сторін вступити у справу третейського розгляду до прийняття Третейським судом рішення, при умові існування щодо них Третейської угоди. Вони користуються усіма правами і несуть усі обов'язки позивача.
    
    2. У разі якщо третя особа, заявляє самостійні вимоги відносно предмета спору, вступила у справу після початку третейського розгляду, розгляд справи в Третейському суді проводиться спочатку.
    
    3. Про вступ в справу третьої особи, яка заявляє самостійні вимоги відносно предмета спору, виноситься ухвала.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 20. Треті особи, які не заявляють самостійних вимог відносно предмета спору.`,
      },
      {
        content: `1. Треті особи, які не заявляють самостійних вимог відносно предмета спору, можуть за згодою сторін вступити в справу третейського розгляду на стороні позивача або відповідача до прийняття Третейським судом рішення, якщо рішення в справі може вплинути на права або обов'язки по відношенню до однієї з сторін. Вони можуть бути залучені до участі у справі третейського розгляду за їх згодою за ініціативою Третейського суду.
    
    2. Треті особи, які не заявляють самостійних вимог щодо предмета спору, мають процесуальні права і обов'язки сторони, за виключенням права на зміну підстави або предмета позову, збільшення або зменшення розміру позовних вимог, відмови від позову, визнання позову або укладення мирової угоди, пред'явлення зустрічного позову.
    
    3. Про вступ у справу третьої особи, яка не заявляє самостійних вимог щодо предмета спору, або про залучення в справу цієї особи або про відмову про це Третейським судом виноситься ухвала.`,
      },
      {
        center: true,
        bold: true,
        content: `Глава 3. Представництво у Третейському суді`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 21 Ведення справ у Третейському суді через представників.`,
      },
      {
        content: `1. Сторона, третя особа, а також особа, якій законом надано право звертатися до Третейського суду в інтересах іншої особи, може вести справи у Третейському суді особисто та (або) через представника.
    
    2. Особиста участь у справі особи не позбавляє її права мати в цій справі представника.
    
    3. Юридична особа незалежно від порядку її створення бере участь у справі через свого керівника, члена виконавчого органу, іншу особу, уповноважену діяти від її імені відповідно до закону, статуту, положення, трудового договору (контракту), або через представника.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 22. Особи, які можуть бути представниками у Третейському суді.`,
      },
      {
        content: `1. Представником у Третейському суді може бути адвокат або представник.
    
    2. Одна й та сама особа може бути одночасно представником декількох позивачів або декількох відповідачів або декількох третіх осіб на одній стороні, за умови відсутності конфлікту інтересів між ними.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 23. Документи, що посвідчують повноваження представників`,
      },
      {
        content: `1. Повноваження представників сторін та інших учасників справи мають бути підтверджені такими документами:
    
    1) довіреністю фізичної;
    2) довіреністю юридичної особи.
    
    2. Довіреність фізичної особи повинна бути посвідчена нотаріально або у визначених законом випадках іншою особою.
    
    3. Довіреність від імені юридичної особи видається за підписом (електронним цифровим підписом) посадової особи, уповноваженої на це законом, установчими документами.
    
    4. Повноваження адвоката як представника підтверджуються довіреністю або ордером, виданим відповідно до Закону України "Про адвокатуру та адвокатську діяльність".
    
    5. Відповідність копії документа, що підтверджує повноваження представника, оригіналу може бути засвідчена підписом третейського судді.
    
    6. Оригінали документів, зазначених у цій статті, копії з них, засвідчені третейським суддею, або копії з них, засвідчені у визначеному законом порядку, приєднуються до матеріалів справи.
    
    7. У разі подання представником до Третейського суду заяви, скарги, клопотання він додає довіреність або інший документ, що посвідчує його повноваження, якщо в справі немає підтвердження такого повноваження на момент подання відповідної заяви, скарги, клопотання.
    
    8. Довіреності або інші документи, що підтверджують повноваження представника і були посвідчені в інших державах, повинні бути легалізовані в установленому законодавством порядку, якщо інше не встановлено міжнародними договорами, згода на обов’язковість яких надана Верховною Радою України.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 24. Повноваження представника.`,
      },
      {
        content: `1. Представник, який має повноваження на ведення справи в Третейському суді, здійснює від імені особи, яку він представляє, її процесуальні права та обов’язки.
    
    2. Обмеження повноважень представника на вчинення певної процесуальної дії мають бути застережені у виданій йому довіреності або ордері.
    
    3. Підстави і порядок припинення представництва за довіреністю визначаються Цивільним кодексом України, а представництва за ордером - законодавством про адвокатуру.
    
    4. Про припинення представництва або обмеження повноважень представника за довіреністю або ордером має бути повідомлено Третейський суд шляхом подання письмової заяви.
    
    5. У разі припинення повноважень представника на здійснення представництва особи у справі представник не може бути у цій самій справі представником іншої сторони, третьої особи на іншій стороні або третьої особи із самостійними вимогами щодо предмета спору.`,
      },
      {
        center: true,
        bold: true,
        content: `Глава 4. Процесуальні строки`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 25. Встановлення та обчислення процесуальних строків.`,
      },
      {
        content: `1. Строки, в межах яких вчиняються процесуальні дії, встановлюються законом, цим Регламентом, а якщо такі строки законом, цим Регламентом не визначені, - встановлюються Третейським судом.
    
    2. Третейський суд має встановлювати розумні строки для вчинення процесуальних дій. Строк є розумним, якщо він передбачає час, достатній, з урахуванням обставин справи, для вчинення процесуальної дії, та відповідає завданню третейського судочинства.
    
    3. Строки, встановлені законом, цим Регламентом або Третейським судом, обчислюються роками, місяцями і днями, а також можуть визначатися вказівкою на подію, яка повинна неминуче настати.
    
    4. Перебіг строку третейського розгляду починається з наступною дня після відповідної календарної дати або настання події, з якою пов'язано його початок.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 26. Закінчення строків третейського розгляду.`,
      },
      {
        content: `1. Строк, обчислюваний роками, закінчується у відповідні місяць і число останнього року строку.
    
    2. Строк, обчислюваний місяцями, закінчується у відповідне число останнього місяця строку. Якщо закінчення строку, обчислюваного місяцями, припадає на такий місяць, що відповідного числа не має, строк закінчується в останній день цього місяця.
    
    3. Якщо закінчення строку припадає на вихідний, святковий чи інший неробочий день, останнім днем строку є перший після нього робочий день.
    
    4. Перебіг строку, закінчення якого пов'язане з подією, яка повинна неминуче настати, закінчується наступного дня після настання події.
    
    5. Останній день строку триває до 24 години. Строк не вважається пропущеним, якщо до його закінчення заява, інші документи чи матеріали або грошові кошти здано на пошту чи передані іншими відповідними засобами зв'язку.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 27. Наслідки пропущення строків третейського розгляду.`,
      },
      {
        content: `1. Право на вчинення процесуальної дії втрачається із закінченням строку, встановленого законом, цим Регламентом або Третейським судом.
    
    2. Документи, подані після закінчення строків третейського розгляду, залишаються без розгляду, якщо Третейський суд за клопотанням особи, що їх подала, не знайде підстав для поновлення або продовження строку.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 28. Зупинення строків третейського розгляду.`,
      },
      {
        content: `1. Зупинення провадження у справі зупиняє перебіг строків третейського розгляду. Зупинення цих строків починається з моменту настання тієї події, внаслідок якої Третейський суд зупинив провадження.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 29. Поновлення та продовження строків третейського розгляду.`,
      },
      {
        content: `1. Третейський суд поновлює або продовжує строк, встановлений відповідно законом, цим Регламентом за клопотанням сторони або іншої особи у разі його пропущення з поважних причин.
    
    2. Строки вчиненим процесуальних дій, передбачені Законом України "Про третейські суди" можуть бути поновлені або продовжені лише за спільною згодою сторін та складу Третейського суду, яким вирішується спір.
    
    3. Одночасно з клопотанням про поновлення чи продовження строку може бути вчинено ту дію або подано той документ чи доказ, стосовно якого заявлено клопотання.
    
    4. З питань, зазначених у цій статті, Третейським судом виноситься ухвала.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 30. Строк розгляду спору.`,
      },
      {
        content: `1. Розгляд справи Третейським судом починається з винесення відповідної ухвали
    та направлення її сторонам.
    
    2. Третейський суд застосовує заходи до розгляду спору протягом розумного строку, але не більше 2 (двох) місяців з дня винесення ухвали про порушення провадження у справі, крім випадків, коли за згодою сторін або за рішенням Третейського суду строки розгляду справи продовжені. Про продовження строку третейського розгляду третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду виносить ухвалу.
    
    3. Третейський суд на початку розгляду справи з’ясовує у сторін можливість закінчити справу мировою угодою та в подальшому сприяє вирішенню спору шляхом укладання мирової угоди на всіх стадіях третейського розгляду.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 31. Інші строки.`,
      },
      {
        content: `1. Всі інші строки, пов'язані з третейським розглядом, визначені у конкретних статтях цього Регламенту.`,
      },
      {
        center: true,
        bold: true,
        content: `Глава 5. Докази`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 32. Докази.`,
      },
      {
        content: `1. Доказами у третейському розгляді є будь-які фактичні дані, на підставі яких Третейський суд встановлює наявність або відсутність обставин, що обґрунтовують вимоги і заперечення сторін, та інші обставини, що мають значення для правильного вирішення спору.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 33. Засоби доказування.`,
      },
      {
        content: `1. Засобами доказування є пояснення сторін, третіх осіб, їхніх представників, викладені в заявах по суті, показання свідків, письмові, речові докази, висновки експертів.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 34. Обов'язки доказування і надання доказів та їх зберігання.`,
      },
      {
        content: `1. Кожна сторона третейського розгляду повинна довести ті обставини, на які вона посилається як на підставу своїх вимог і заперечень.
    
    2. Докази подаються сторонами та іншими учасниками справи.
    
    3. Третейський суд має право вимагати від сторін, надання доказів, необхідних для повного, всебічного і об'єктивного вирішення спору.
    
    4. У разі посилання учасника справи на невчинення іншим учасником справи певних дій або відсутність певної події, Третейський суд може зобов’язати такого іншого учасника справи надати відповідні докази вчинення цих дій або наявності певної події. У разі ненадання таких доказів Третейський суд може визнати обставину невчинення відповідних дій або відсутності події встановленою.
    
    5. Якщо Третейський суд визнає за необхідне одержати документи від підприємств, установ, організацій, які не є учасниками третейського розгляду, він своєю ухвалою уповноважує сторони або одну із сторін одержати відповідні документи та надати їх Третейському суду.
    
    6. Сторони повинні надавати Третейському суду докази в оригіналах чи належним чином засвідчених копіях.
    
    7. Всі зібрані письмові докази разом з процесуальними документами сторін та Третейського суду зберігаються у справі в прошитому та пронумерованому вигляді.
    
    8. Третейський суд не може збирати докази, що стосуються предмета спору, з власної ініціативи, крім витребування доказів Третейським судом у випадку, коли він має сумніви у добросовісному здійсненні учасниками справи їхніх процесуальних прав або виконанні обов’язків щодо доказів.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 35. Належність доказів.`,
      },
      {
        content: `1. Належними є докази, на підставі яких можна встановити обставини, які входять в предмет доказування. Третейський суд бере до розгляду тільки ті докази, які стосуються справи, що розглядається та предмета доказування.
    
    2. Предметом доказування є обставини, які підтверджують заявлені вимоги чи заперечення або мають інше значення для розгляду справи і підлягають встановленню при прийнятті Третейським судом рішення.
    
    3. Сторони мають право обґрунтовувати належність конкретного доказу для підтвердження їхніх вимог або заперечень.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 36. Допустимість доказів.`,
      },
      {
        content: `1. Обставини справи, які за законом повинні бути підтверджені певними засобами доказування, не можуть підтверджуватися ніякими іншими засобами доказування.
    
    2. Третейський суд не бере до уваги докази, які одержані з порушенням порядку, встановленого законом.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 37. Підстави звільнення від доказування.`,
      },
      {
        content: `1. Обставини, визнані Третейським судом загальновідомими, не потребують доказування.
    
    2. Обставини встановлені рішенням Третейського суду, що набрало законної сили, не доказуються при розгляді інших справ, у яких беруть участь ті самі особи або особа, щодо якої встановлено ці обставини.
    
    3. Рішення судів в господарській, цивільній або адміністративній справі, що набрали законної сили, обов'язкові для Третейського суду про обставини, встановлені цими рішеннями по відношенню до осіб, які приймають участь у справі.
    
    4. Обставини, встановлені стосовно певної особи рішенням суду в господарській, цивільній або адміністративній справі, що набрало законної сили, можуть бути у загальному порядку спростовані особою, яка не брала участі у справі, в якій такі обставини були встановлені.
    
    5. Обвинувальний вирок суду в кримінальному провадженні або постанова суду, якою особу притягнуто до адміністративної відповідальності у справі про адміністративне правопорушення, які набрали законної сили, є обов’язковими для Третейського суду, що розглядає справу про правові наслідки дій чи бездіяльності особи, стосовно якої ухвалений вирок або постанова суду, лише в питанні, чи мали місце ці дії (бездіяльність) та чи вчинені вони цією особою.
    
    6. Правова оцінка, надана судом певному факту при розгляді іншої справи, не є обов’язковою для Третейського суду.
    
    7. Обставини, встановлені рішенням міжнародного комерційного арбітражу, виправдувальним вироком суду у кримінальному провадженні, ухвалою про закриття кримінального провадження і звільнення особи від кримінальної відповідальності, підлягають доказуванню в загальному порядку при розгляді справи Третейським судом.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 38. Звільнення від доказування обставин, визнаних сторонами.`,
      },
      {
        content: `1. Третейський суд повинен сприяти досягненню сторонами згоди в оцінці обставин в цілому або в їх окремих частинах, проявляти в цих цілях необхідну ініціативу, використовувати свої процесуальні повноваження і авторитет Третейського суду.
    
    2. Обставини, які визнаються учасниками справи, не підлягають доказуванню, якщо Третейський суд не має обґрунтованих підстав вважати їх недостовірними або визнаними у зв’язку з примусом. Обставини, які визнаються учасниками справи, можуть бути зазначені в заявах по суті справи, письмових поясненнях учасників справи, їх представників.
    
    3. Відмова від визнання обставин приймається Третейським судом, якщо сторона, яка відмовляється, доведе, що вона визнала ці обставини внаслідок помилки, що має істотне значення, обману, насильства, погрози чи тяжкої обставини, або що обставини визнано у результаті зловмисної домовленості її представника з іншою стороною. Про прийняття відмови сторони від визнання обставин Третейський суд виносить ухвалу. У разі прийняття Третейським судом відмови сторони від визнання обставин вони доводяться в загальному порядку.
    `,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 39 Оцінка доказів.`,
      },
      {
        content: `1. Третейський суд оцінює докази за своїм внутрішнім переконанням, що ґрунтується на всебічному, повному, об'єктивному і безпосередньому дослідженні наявних у справі доказів.
    
    
    2. Кожний доказ підлягає оцінці Третейським судом поряд з іншими доказами.
    
    3. Жодні докази не мають для Третейського суду заздалегідь встановленої сили. Третейський суд оцінює належність, допустимість, достовірність кожного доказу окремо, а також вірогідність і взаємний зв’язок доказів у їх сукупності.
    
    
    Стаття 40. Письмові докази.
    
    1. Письмовими доказами є будь-які документи, акти, довідки, листування службового або особистого характеру або витяги з них, виконані у формі цифрового, графічного запису або іншим шляхом, що дозволяє встановити достовірність документа.
    
    2. Документи, отримані через факсимільний, електронний або інший зв'язок, а також документ, підписані електронним цифровим підписом або іншим аналогом власноручного підпису, допускаються в якості письмових доказів у випадках і в порядку, встановлених законом, іншим нормативно - правовим актом або договором.
    
    3. До письмових документів, що надаються Третейському суду, виконаних повністю або в частині іноземною мовою, повинні бути додані їх належним чином завірені переклади на українську мову.
    
    4. Документ отриманий в іноземній державі, визнається в Третейському суді письмовим доказом, якщо він легалізований у встановленому порядку.
    
    5. Іноземні офіційні документи визнаються у Третейському суді письмовими доказами без їх легалізації у випадках, передбачених міжнародними договорами України.
    
    6. Оригінали документів сторін, що є у справі, за заявами осіб, що їх представляють, можуть бути повернуті їм після прийняття рішення, якщо ці документи не підлягають передачі іншій особі. Одночасно з заявами зазначені особи надають належним чином завірені копії документів або заявляють клопотання про засвідчення Третейським судом вірності копій, що залишаються у справі.
    
    7. Якщо Третейський суд дійде висновку, що повернення оригіналів документів не нанесе шкоди правильному розгляду справи, ці документи можуть бути повернуті в процесі провадження у справі до винесення рішення.`,
      },
      {
        center: true,
        italic: true,
        content: `Стаття 41. Речові докази.`
      },
      {
        content: `1. Речовими доказами є предмети матеріального світу, що містять інформацію про обставини, які мають значення для справи.
    
    2. Речовими доказами є також магнітні, електронні та інші носії інформації, що містять аудіовізуальну інформацію про обставини, що мають значення для справи.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 42. Зберігання речових доказів.`
      },
      {
        content: `1. Речові докази зберігаються за місцем їх знаходження. Вони повинні бути детально описані, опечатані, а у разі необхідності зняті на фото - чи відеоплівку.
    
    2. Речові докази можуть зберігатися за місцезнаходженням Третейського суду, якщо Третейський суд визнає це за необхідне.
    
    3. Витрати на зберігання речових доказів розподіляються між сторонами у відповідності з правилами, встановленими цим Регламентом.
    
    4. Третейський суд і зберігач вживають заходів по зберіганню речових доказів у незмінному стані.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 43. Огляд і дослідження письмових, речових доказів за місцем їх знаходження.`
      },
      {
        content: `1. Третейський суд може провести огляд і дослідження письмових, речових за їх місцезнаходженням, у разі неможливості їх доставки в Третейський суд. Про проведення огляду і дослідженню письмових, речових доказів за їх місцезнаходженням виноситься ухвала.
    
    2. Про дату, час і місце огляду доказів за їх місцезнаходженням повідомляються учасники справи. Неявка цих осіб не є перешкодою для проведення огляду.
    
    3. Про огляд доказів за їх місцезнаходженням складається протокол, що підписується всіма особами, які беруть участь в огляді. До протоколу додаються разом з описом усі складені або звірені під час огляду на місці плани, креслення, копії документів, а також зроблені під час огляду фотознімки, електронні копії доказів, відеозаписи тощо.
    
    4. Якщо огляд проводиться за відсутності хоча б однієї із сторін, а також в інших випадках, коли Третейський суд визнає це за необхідне, здійснюється відеофіксація огляду технічними засобами.
    
    5. Особи, які беруть участь в огляді доказів за їх місцезнаходженням, мають право звертати увагу Третейського суду на ту чи іншу обставину, яка, на їх погляд, має значення для повноцінного проведення огляду, встановлення обставин, які мають значення для розгляду справи, робити свої зауваження щодо протоколу огляду.
    
    Стаття 44. Розпорядження речовими доказами, що знаходяться у Третейському суді.
    
    1. Речові докази, що знаходяться у Третейському суді, після їх огляду і дослідження Третейським судом повертаються особам, від яких вони були отримані, якщо вони не підлягають передачі іншим особам.
    
    2. Речі, які згідно закону не можуть знаходитись у користуванні окремих осіб, передаються відповідним організаціям.
    
    3. З питань розпорядження речовими доказами Третейський суд виносить ухвалу.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 45. Пояснення осіб, які приймають участь у справі.`
      },
      {
        content: `1. Особа, яка приймає участь у справі, надає Третейському суду свої пояснення про відомі їй обставини, які мають значення для справи, в письмовій або усній формі. На вимогою Третейського суду особа, яка приймає участь у справі, має викласти свої пояснення у письмовій формі.
    
    2. Пояснення, викладені у письмовій формі, додаються до матеріалів справи.
    
    3. Після оголошення пояснень, викладених у письмовій формі, особа, яка надала це пояснення, має право дати відносно нього необхідні пояснення, а також зобов'язана відповісти на питання інших осіб, які приймають участь у справі, Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 46. Призначення експертизи.`
      },
      {
        content: `1. З метою правильного вирішення спору Третейський суд має право зобов'язати сторони чи одну із сторін замовити проведення експертизи для роз'яснення питань, що потребують спеціальних знань, про що Третейський суд виносить відповідну ухвалу.
    
    2. У разі ухилення позивача від замовлення проведення експертизи, призначеної Третейським судом, і при неможливості розглянути справу через дослідження інших доказів у справі, позовна заява залишається без розгляду, про що виноситься ухвала.
    
    3. У разі ухилення відповідача від замовлення проведення експертизи, призначеної Третейським судом, Третейський суд може зобов'язати замовити проведення експертизи позивача, або розглянути справу без проведення експертизи дослідивши інші докази у справі.
    
    4. Перелік і зміст питань, з яких повинна бути проведена експертиза, визначається в ухвалі Третейського суду. Учасники справи мають право запропонувати Третейському суду питання, роз’яснення яких, на їхню думку, потребує висновку експерта. У разі відхилення або зміни питань, запропонованих учасниками справи, Третейський суд зобов’язаний мотивувати таке відхилення або зміну.
    
    5. Питання, які ставляться експерту, і його висновок з них не можуть виходити за межі спеціальних знань експерта.
    
    6. В ухвалі про зобов'язання замовлення проведення експертизи зазначаються підстави для призначення експертизи; прізвище, ім'я і по-батькові експерта або найменування експертної установи, в якій повинна бути проведена експертиза: питання, поставлені перед експертом: матеріали і документи, надані в розпорядження експерта; строк, на протязі якого повинна бути проведена експертиза і повинно бути надано висновок Третейському суду.
    
    7. Висновок експерта оголошується у засіданні Третейського суду і досліджується поряд з іншими доказами у справі. За клопотанням особи, яка приймає участь у справі, або за ініціативою Третейського суду експерт може бути викликаний у засідання Третейського суду. Експерт після оголошення його висновку має право дати по ньому необхідні пояснення, а також відповісти на додаткові питання осіб, які приймають участь у справі, і Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 47. Показання свідків.`
      },
      {
        content: `1. За клопотанням особи, яка приймає участь у справі, Третейський суд може викликати свідка для надання пояснень по справі. Особа, яка заявляє клопотання про виклик свідка, зобов'язана зазначити, які обставини, що мають значення для справи, може підтвердити свідок, і повідомити суду його прізвище, ім'я, по-батькові і місце проживання.
    
    2. Третейський суд за власною ініціативою може викликати у якості свідка особу, яка приймала участь у складанні документа, що досліджується Третейським судом як письмовий доказ, або у створенні або зміні предмета, що досліджується Третейським судом як речовий доказ.
    
    3. За пропозицією Третейського суду свідок може викласти показання, що надані усно, у письмовій формі. Показання свідка, що викладені у письмовій формі, які приєднуються до матеріалів справи.
    
    4. Не є доказами відомості, які повідомлені свідком, якщо він не може зазначити джерело повідомлених ним відомостей.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 6. Витрати, пов'язані з вирішенням спору Третейським судом`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 48. Склад витрат, пов'язаних з вирішенням спору Третейським судом.`
      },
      {
        content: `1. До складу витрат, пов'язаних з вирішенням спору Третейським судом, належать:
    
    1) гонорари третейських суддів;
    2) третейський збір, розмір, строки та порядок сплати якого сторонами визначаються цим Регламентом;
    3) витрати, понесені третейськими суддями у зв'язку з участю в третейському розгляді, в тому числі витрати, понесені третейськими суддями на оплату проїзду до місця вирішення спору;
    4) витрати, пов'язані з оплатою послуг експертів, перекладачів, якщо такі були запрошені чи призначені для участі в третейському розгляді;
    5) витрати, пов'язані з оглядом і дослідженням речових та письмових доказів у їх місцезнаходженні;
    6) витрати, понесені свідками;
    7) витрати, пов'язані з оплатою стороною, на користь якої було прийнято рішення Третейського суду, послуг представника, пов'язаних із наданням правової допомоги;
    8) витрати, пов'язані з організаційним забезпеченням третейського розгляду;
    9) витрати на листування Третейського суду;
    10) витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок;
    11) гонорар секретаря Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 49. Третейський збір.`
      },
      {
        content: `1. При поданні позовної заяви позивач зобов'язаний сплатити третейський збір.
    
    2. До сплати третейського збору третейський розгляд за позовом не здійснюється, крім випадку надання відстрочення сплати третейського збору.
    
    3. Якщо третейський збір не сплачується на протязі місячного строку з моменту подання позовної заяви позовна заява повертається без розгляду, про що головою Третейського суду, його заступником, у відсутність голови Третейського суду, виноситься ухвала, яка не підлягає оскарженню.
    
    4. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений, сплачений третейський збір повертається позивачу, крім випадків, якщо це було зумовлено відводом усіх суддів, укладанням сторонами мирової угоди, відмовою позивача від позову та іншими обставинами, передбаченими Законом України
    "Про третейські суди".
    
    5. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений з підстав, зазначених у пункті 4 статті 84 цього Регламенту, то сторони відшкодовують Третейському суду всі понесені ним витрати, про що зазначається в ухвалі Третейського суду.
    
    6. Голова Третейського суду, його заступник, у відсутність голови Третейського суду, може надати відстрочення, а також зменшити або збільшити розмір третейського збору, про що вказується у розпорядженні, яке не підлягає оскарженню.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 50. Витрати, які здійснюються Третейським судом з наступним стягненням з сторін спору.`
      },
      {
        content: `1. Розподіл витрат, пов'язаних з організаційним забезпеченням третейського розгляду, витрати на листування Третейського суду, витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок, витрати, пов’язані з оплатою гонорару секретаря Третейського суду, здійснюються Третейським судом з подальшим стягненням зі сторони спору, у порядку, визначеному ст.52 цього Регламенту.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 51. Покриття витрат, які не включаються до складу третейського збору.`
      },
      {
        content: `1. Третейський суд може покласти на сторони або на одну з них обов'язок внести аванс на покриття витрат, які не включаються до складу третейського збору.
    
    2. Аванс на покриття витрат, які не включаються до складу третейського збору може бути, витребуваний Третейським судом від сторони, яка заявила про необхідність здійснення дії, що приводить або може привести до цих витрат.
    
    3. У разі участі у третейському розгляді третейського судді, який має місцеперебування поза місцем проведення засідання Третейського суду, позивач повинен внести аванс на оплату витрат його участі у третейському розгляді (по проїзду, проживанню тощо).
    
    4. Якщо на прохання однієї з сторін при розгляді справи здійснюється переклад пояснень сторін, їх заяв тощо, а також питань, вказівок, ухвал, рішень Третейського суду, то витрати по перекладу оплачуються зазначеною стороною.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 52. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом.`
      },
      {
        content: `1. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом здійснюється Третейським судом у такому порядку:
    
    -при задоволенні позову витрати покладаються на відповідача; при відмові в позові - на позивача;
    -при частковому задоволенні позову - на обидві сторони пропорційно розміру задоволених вимог.
    
    2. Порядок розподілу витрат, пов'язаних з вирішенням спору Третейським судом, зазначається у рішенні або ухвалі Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 53. Розміри третейського збору.`
      },
      {
        content: `1. Третейський збір справляється у відсотковому співвідношенні до ціни позову та у фіксованому розмірі у відповідному розмірі прожиткового мінімуму для працездатних осіб, встановленого законом на період, в якому відповідна позовна заява подається до Третейського суду.
    
    2. Розмір третейського збору за подання до Третейського суду:
    -позовної заяви майнового характеру - 1 відсоток ціни позову, але не менше 1 розміру прожиткового мінімуму для працездатних осіб.
    -позовної заяви немайнового характеру – 1 розмір прожиткового мінімуму для працездатних осіб.
    
    3. За позовні заяви, що містять одночасно вимоги майнового і не майнового характеру, сплачується одночасно третейський збір, встановлений для позовних заяв майнового характеру і для позовних заяв немайнового характеру.
    
    4. При зменшенні ціни позову сплачений третейський збір не повертається.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 54. Порядок сплати третейських зборів та інших витрат.`
      },
      {
        content: `1. Суми третейських зборів та інших витрат, що підлягають сплаті Третейському суду, вважаються сплаченими у день їх зарахування на поточний рахунок Асоціації "ПРАВОВА ЛІГА".
    
    2. При сплаті третейського збору у платіжному дорученні у графі "Призначення платежу" повинно бути зазначено: "Третейський збір за розгляд спору між "....." (найменування позивача та відповідача), а також сума позову. Платіжне доручення з відміткою банку про перерахування третейського збору долучаються до матеріалів справи.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 7. Гонорари третейських суддів та порядок їх виплати`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 55. Гонорари третейських суддів, порядок їх виплати.`
      },
      {
        content: `1. Третейському судді, який приймає участь третейському розгляді, після закінчення розгляду конкретної справи сплачується гонорар у розмірі 30 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.
    
    2. Якщо справа розглядається складом Третейського суду у кількості трьох третейських суддів, цим третейським суддям після закінчення розгляду конкретної справи сплачуються гонорари у таких розмірах: головуючому складу Третейського суду 12 відсотків, двом іншим третейським суддям складу Третейського суду по 9 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.
    
    3. Гонорари третейським суддям сплачуються Асоціацією "ПРАВОВА ЛІГА" на поточні рахунки третейських суддів у банківських установах. Реквізити банківських рахунків повідомляються кожним третейським суддею Третейському суду у письмовій формі. Для виплати гонорару Асоціація "ПРАВОВА ЛІГА" може вимагати від третейського судді надання додаткових документів (копію довідки про ідентифікаційний номер, копію паспорту, тощо).
    
    4. За заявою третейського судді, викладеної письмово, розгляд справи може здійснюватися безоплатно.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 8. Формування третейського суду`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 56. Склад третейського суду.`
      },
      {
        content: `1. Третейським суддею, який розглядає справу одноособово, третейськими суддями у разі розгляду справи у складі трьох третейських суддів, можуть бути призначені особи із списку третейських суддів Третейського суду за їх згодою, які прямо чи опосередковано не заінтересовані в результаті вирішення спору, а також мають необхідний досвід, ділові та моральні якості, необхідні для вирішення спору.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 57. Формування складу третейського суду.`
      },
      {
        content: `1. Якщо сторони не домовились про те, що справа розглядається третейським судом у складі трьох третейських суддів, справа підлягає розгляду третейським суддею одноособово.
    
    2. Третейський суддя, який розглядає справу одноособово, призначається за його згодою головою Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.
    
    3. При розгляді справи у складі трьох третейських суддів головуючого складу третейського суду та третейських суддів призначає за їх згодою голова Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.
    
    4. Після формування складу третейського суду, голова Третейського суду, його заступником, у відсутність голови Третейського суду, передає справу головуючому складу третейського суду або третейському судді, який розглядає справу одноособово.
    
    5. Призначення третейського судді або складу третейських суддів, а також передача справи головуючому складу третейського суду або третейському судді, який
    розглядає справу одноособово, оформляється розпорядженням голови Третейського суду, його заступника, у відсутність голови Третейського суду, яке є остаточним і не підлягає оскарженню.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 58. Повідомлення сторін про порушення справи.`
      },
      {
        content: `1. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду постановляє ухвалу про порушення справи, про що повідомляються сторони, і пропонує відповідачу у строк не більше ніж 5 днів з дня отримання ухвали про порушення справи надати Третейському суду свій відзив на позов, що підкріплений відповідними доказами.
    
    2. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду може продовжити строк для надання відповідачем відзиву на позов при надходженні відповідного клопотання. За результатами розгляду відповідного клопотання виноситься ухвала, яка не підлягає оскарженню.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 59. Припинення повноважень третейського судді, складу третейського суду, заміна третейського судді.`
      },
      {
        content: `1. Повноваження третейського судді припиняються:
    1) за погодженням сторін;
    2) у разі відводу чи самовідводу третейського судді відповідно до цього Регламенту;
    3) у разі набрання законної сили обвинувальним вироком суду щодо нього;
    4) у разі набрання законної сили судовим рішенням про визнання його обмежено дієздатним чи недієздатним;
    5) у разі смерті, визнання його безвісно відсутнім або оголошення померлим рішенням суду, що набрало законної сили.
    
    2. Повноваження складу третейського суду припиняються після ухвалення ним рішення у справі.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 60. Поновлення повноважень третейського суду.`
      },
      {
        content: `1. Повноваження Третейського суду поновлюються у разі виникнення потреби в:
    1) прийнятті додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні, – за заявою сторони;
    2) роз’ясненні резолютивної частини рішення – за заявою сторони;
    3) виправленні у рішенні описок, арифметичних помилок або будь-яких інших неточностей – з власної ініціативи Третейського суду або за заявою сторони.
    
    2. Після виконання зазначених у цій статті процесуальних дій повноваження Третейського суду припиняються.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 61. Підстави відводу чи самовідводу третейського судді.`
      },
      {
        content: `1. Третейський суддя не може брати участі у розгляді справи, а після його призначення підлягає відводу чи самовідводу:
    -якщо він особисто чи опосередковано заінтересований у результаті розгляду справи;
    -якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках;
    на його прохання або за спільним рішенням сторін;
    -у разі встановлення стороною обставин, які дають їй підстави вважати упередженим або необ'єктивним ставлення третейського судді до справи, про яке сторона дізналася після його призначення;
    -у разі тривалого, більш як один місяць від дня призначення, невиконання ним обов'язків третейського судді у конкретній справі;
    -у разі виявлення невідповідності третейського судді вимогам, встановленим статтею 18 Закону України "Про третейські суди";
    -якщо третейський суддя бере участь у вирішенні спору, який прямо чи опосередковано пов'язаний з виконанням ним службових повноважень, наданихдержавою.
    2. Жодна особа не може бути третейським суддею у справі, в якій вона раніше брала участь як третейський суддя, але була відведена чи заявила самовідвід, як сторона, представник сторони або в будь-якій іншій якості.
    
    3. Незгода сторони з процесуальними рішеннями третейського судді, рішення або окрема думка третейського судді в інших справах, висловлена публічно думка третейського судді щодо того чи іншого юридичного питання не може бути підставою для відводу.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 62. Порядок відводу чи самовідводу третейського судді.`
      },
      {
        content: `1. У разі звернення голови Третейського суду, його заступника, у відсутність голови Третейського суду, до особи зі списку третейських суддів Третейського суду за отриманням згоди на призначення її третейським суддею у конкретній справі, ця особа повинна повідомити про наявність обставин, які є підставами для відводу чи самовідводу відповідно до статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту.
    
    2. Третейський суддя повинен без зволікання повідомити сторони про підстави його відводу чи самовідводу, зазначені у статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту, що виникли після початку третейського розгляду, та заявити самовідвід.
    
    3. Письмова мотивована заява сторони про відвід третейського судді, з доказом надіслання її іншій стороні, має бути подана стороною до Третейського суду протягом п’яти календарних днів після того, як цій стороні стали відомі обставини, які є підставою для відводу третейського судді відповідно до Закону "Про третейські суди" та цього Регламенту.
    
    4. У разі пропуску встановленого строку, питання про прийняття заяви про відвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, залежно від причин пропуску строку. У випадку відмови у прийнятті заяви про відвід третейського судді голова Третейського суду, його заступник, у відсутність голови Третейського суду, виносить ухвалу.
    
    5. Якщо відвід заявляється повторно з підстав, розглянутих раніше, третейський суд, який розглядає справу, залишає таку заяву без розгляду, про що виносить ухвалу.
    
    6. Третейський суд зобов’язаний надати іншій стороні можливість прокоментувати заяву про відвід третейського судді.
    
    7. У разі коли інша сторона не погоджується з відводом третейського судді, вона має право протягом п’яти календарних днів з дня отримання копії заяви про відвід третейського судді, подати до Третейського суду свої мотивовані заперечення. У цьому випадку питання про відвід вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, а у разі розгляду справи у кількості трьох третейських суддів головою Третейського суду, його заступником, у відсутність голови Третейського суду, спільно з іншими третейськими суддями, призначеними у справі, рішення якого (яких) є обов'язковим для сторін. За результатами розгляду заяви про відвід третейського судді виноситься ухвала. Питання про відвід, яке заявлене голові Третейського суду, який здійснює розгляд справи як третейський суддя, вирішується заступником голови Третейського суду у порядку, визначеному цим Регламентом.
    
    8. Питання про відвід вирішується без повідомлення учасників справи. За ініціативою голови Третейського суду, його заступника, у відсутність голови Третейського суду, питання про відвід може вирішуватися у засіданні третейського суду з повідомленням учасників справи. Неявка учасників справи у засідання третейського суду, в якому вирішується питання про відвід, не перешкоджає розгляду питання про відвід.
    
    9. У разі задоволення заяви про відвід третейського судді, який розглядає справу одноособово, справа розглядається в Третейському суді іншим третейським суддею, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.
    
    10. У разі задоволення заяви про відвід комусь із третейських суддів або всьому
    складу суду, у разі колегіального вирішення спору, справа розглядається в Третейському суді тим самим кількісним складом колегії третейських суддів без участі відведеного третейського судді або іншим складом третейських суддів, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.
    
    11. Питання про самовідвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, на підставі мотивованої заяви третейського судді.
    
    12. Під час розгляду заяви про відвід Третейський суд, включаючи третейського суддю, якому заявлено відвід, продовжує третейський розгляд. Третейський суд не може ухвалити рішення доти, поки голова Третейського суду, його заступник, у відсутність голови Третейського суду, не постановить ухвалу за підсумками розгляду згаданої заяви.
    
    13. Самовідвід третейського судді або згода сторони на припинення його повноважень не повинна розцінюватися як визнання залежності та упередженості третейського судді.
    
    14. Особі, яка розглядає заяву про відвід, не може бути заявлений відвід.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 63. Підстави та порядок відводу чи самовідводу експерта, перекладача.`
      },
      {
        content: `1. Якщо експерт, перекладач особисто чи опосередковано заінтересовані у результаті розгляду справи, або якщо вони є родичами однієї із сторін або інших осіб, які беруть участь у справі, або перебувають з цими особами чи сторонами в особливих стосунках вони підлягають відводу чи самовідводу.
    
    2. Питання про відвід експерта, перекладача вирішується складом третейським суддею, який розглядає справу одноособово, а у разі колегіального вирішення спору - складом третейських суддів.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 9. Пред'явлення позову`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 64. Пред'явлення позову.`
      },
      {
        content: `1. Провадження у справі порушується поданням позовної заяви.
    
    2. Позовна заява подається у Третейський суд у письмовій формі і підписується керівником юридичної особи або іншою особою, яка має право діяти без довіреності від імені позивача, або фізичною особою-підприємцем, або фізичною особою, або уповноваженим довіреністю представником позивача.
    
    3. Датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата отримання поштового відправлення представником Третейського суду, при відправленні позовної заяви через електронну приймальню – дата, зазначена в електронному повідомленні про отримання. При подачі позовної заяви через електронну приймальню, її оригінал має бути поданий позивачем в першому засіданні третейського суду.
    
    4. У разі якщо сторона подає позовну заяву у зв'язку з правовідносинами, які вже є предметом третейського розгляду між тими ж сторонами у відповідності з цим Регламентом, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за заявою будь-якої з сторін може об'єднати вимоги, що містяться у різних позовних заявах в одне провадження. Про об’єднання справ в одне провадження, про відмову в об’єднанні справ в одне провадження виноситься ухвала.
    
    5. Справи, що перебувають у провадженні Третейського суду, в разі об’єднання їх в одне провадження передаються на розгляд третейському судді, який раніше за інших суддів відкрив провадження у справі.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 65. Зміст позовної заяви.`
      },
      {
        content: `1. Позовна заява подається у письмовій формі.
    
    2. У позовній заяві, що подається до Третейського суду, повинні зазначатися:
    
    -назва Третейського суду;
    -дата подання позовної заяви;
    -повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові - для фізичних осіб) сторін та інших учасників справи, їхмісцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб); поштовий індекс; ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), а також реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України (якщо такі відомості відомі позивачу), вказівку на статус фізичної особи - підприємця (для фізичних осіб - підприємців); відомі номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти;
    -повне найменування і місцезнаходження представника позивача, якщо він є юридичною особою або прізвище, ім'я, по батькові, дата народження, місце реєстрації представника, який є фізичною особою або фізичною особою-підприємцем, у випадках, коли позов подається представником; номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти представника;
    -ціна позову, якщо позов підлягає грошовій оцінці;
    -зміст позовних вимог: спосіб (способи) захисту прав або інтересів, передбачений законом чи договором, або інший спосіб (способи) захисту прав та інтересів, який не суперечить закону і який позивач просить Третейський суд визначити у рішенні; якщо позов подано до кількох відповідачів - зміст позовних вимог щодо кожного з них;
    -виклад обставин, якими позивач обґрунтовує свої вимоги; зазначення доказів, що підтверджують вказані обставини; правові підстави позову;
    -посилання на наявність Третейської угоди між сторонами та докази її укладення;
    перелік письмових матеріалів, які додаються до позовної заяви;
    -підпис позивача або його представника з посиланням на документ, що засвідчує повноваження представника.
    
    3. У позовній заяві можуть бути вказані й інші відомості, необхідні для правильного вирішення спору.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 66. Документи, що додаються до позовної заяви.`
      },
      {
        content: `1. До позовної заяви додаються документи, які підтверджують:
    1) наявність Третейської угоди;
    2) відправлення іншим учасникам справи копії позовної заяви і доданих до неї документів;
    3) обґрунтованість позовних вимог;
    4)сплату третейського збору у встановленому цим Регламентом порядку і розмірі.
    
    2. До позовної заяви, підписаної представником позивача, додається довіреність чи інший документ, що підтверджує повноваження представника позивача.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 67. Ціна позову.`
      },
      {
        content: `1. Ціна позву визначається:
    1) у позовах про стягнення грошових коштів - сумою, яка стягується;
    2) у позовах про визнання права власності на майно або його витребування – дійсною вартістю майна;
    3) у позовах про розірвання договору найму (оренди) - сукупністю платежів за користування майном протягом строку, що залишається до кінця дії договору;
    4)у позовах, які складаються з кількох самостійних вимог, - загальною сумою всіх вимог.
    
    2. Якщо позивач не визначив або неправильно визначив ціну позову, або визначена позивачем ціна позову вочевидь не відповідає дійсній вартості спірного майна або на момент пред’явлення позову встановити точну його ціну неможливо, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за власною ініціативою визначає ціну позову на підставі наявних відомостей та виносить
    відповідну ухвалу з зазначенім строків для сплати третейського збору.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 68. Прийняття позовної заяви і порушення провадження у справі.`
      },
      {
        content: `1. Питання про прийняття позовної заяви до провадження Третейського суду вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, у триденний строк з дня отримання позовної заяви Третейським судом.
    
    2. При прийнятті позову Третейський суд вирішує питання про наявність і дійсність угоди про передачу спору на розгляд Третейського суду.
    
    3. У разі, якщо Третейський суд дійде висновку про відсутність або недійсність зазначеної угоди, він відмовляє у розгляді справи. Про відмову у розгляді справи виноситься мотивована ухвала, яка надсилається сторонам. При цьому заявнику разом з ухвалою повертаються позовні матеріали.
    
    4. Третейський суд приймає до провадження позовну заяву, подану з додержанням вимог цього Регламенту до його форми і змісту.
    
    5. Про прийняття позовної заяви Третейський суд постановляє ухвалу, якою порушується провадження у справі.
    
    6. В ухвалі зазначається на підготовку справи до третейського розгляду, дії, які належить виконати особам, які приймають участь у справі, і строки їх виконання.
    
    7. Ухвала про порушення провадження у справі направляються особам, які приймають участь у справі, не пізніше трьох днів після дня її винесення.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 69. Усунення недоліків позовної заяви.`
      },
      {
        content: `1. Встановивши, що позовна заява подана без додержання вимог цього Регламенту, голова Третейського суду, його заступник, у відсутність голови Третейського суду, пропонує позивачу усунути виявлені недоліки.
    
    2. Якщо ці недоліки будуть усунуті, то датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата штемпеля поштового відомства адресата.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 70. Відзив на позовну заяву.`
      },
      {
        content: `1. Відповідач повинен надати Третейському суду письмовий відзив на позовну заяву. Відзив на позовну заяву направляється Третейському суду по пошті, шляхом вручення або через електронну приймальню не менше ніж за три дні до першого засідання Третейського суду.
    
    2. У випадку подачі відзиву на позовну заяву через електронну приймальню, його оригінал подається відповідачем в першому засіданні.
    
    3. У відзиві відповідач викладає заперечення проти позову.
    
    4. Відзив підписується відповідачем або його представником.
    
    5. Відзив повинен містити:
    1) найменування (ім’я) позивача і номер справи;
    2) повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові) (для фізичних осіб) відповідача, його місцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб), поштовий індекс, ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України, номери засобів зв’язку, офіційну електронну адресу та адресу електронної пошти, за наявності;
    3) у разі повного або часткового визнання позовних вимог - вимоги, які визнаються відповідачем;
    4) обставини, які визнаються відповідачем, а також правову оцінку обставин, наданих позивачем, з якою відповідач погоджується;
    5) заперечення (за наявності) щодо наведених позивачем обставин та правових підстав позову, з якими відповідач не погоджується, із посиланням на відповідні докази та норми права;
    6) перелік документів та інших доказів, що додаються до відзиву, та зазначення документів і доказів, які не можуть бути подані разом із відзивом, із зазначенням причин їх неподання.
    
    6. Копія відзиву та доданих до нього документів іншим учасникам справи повинна бути надіслана (надана) одночасно з надісланням (наданням) відзиву до Третейського суду.
    
    7. До відзиву додаються:
    1) докази, які підтверджують обставини, на яких ґрунтуються заперечення відповідача, якщо такі докази не надані позивачем;
    2) документи, що підтверджують надіслання (надання) відзиву і доданих до нього доказів іншим учасникам справи.
    
    8. До відзиву, підписаного представником відповідача, додається довіреність чи інший документ, що підтверджує повноваження представника відповідача.
    
    9. У разі ненадання відповідачем відзиву у встановлений Третейським судом строк без поважних причин, Третейський суд вирішує справу за наявними матеріалами.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 71. Зустрічний позов.`
      },
      {
        content: `1. Відповідач вправі подати зустрічний позов для розгляду Третейським судом, якщо такий позов є підвідомчим Третейському суду та може бути предметом третейського розгляду відповідно до Третейської угоди.
    
    2. Зустрічний позов може бути подано на будь-якій стадії третейського розгляду до прийняття рішення по справі.
    
    3. Третейський суд приймає зустрічний позов до спільного розгляду з первісним позовом, якщо обидва позови взаємно пов'язані та їх спільний розгляд є доцільним, зокрема, коли вони випливають з одних і тих самих правовідносин або коли задоволення зустрічного позову може виключити повністю або частково задоволення первісного позову.
    
    4. Зустрічна позовна заява має відповідати вимогам статті 35 Закону України "Про третейські суди" та статтям 65, 66 цього Регламенту.
    
    5. Зустрічна позовна заява, подана з порушенням вимог цієї статті, ухвалою Третейського суду повертається заявнику. Копія зустрічної позовної заяви долучається до матеріалів справи.
    
    6. Сторона зобов'язана надати відзив на пред'явлений до неї зустрічний позов у порядку та строки, визначені Третейським судом.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 72. Забезпечення позову.`
      },
      {
        content: `1. Якщо сторони не домовилися про інше, Третейський суд може за заявою будь-якої сторони розпорядитися про вжиття стороною таких забезпечувальних заходів щодо предмета спору, які він вважає необхідними, з урахуванням положень цивільного та господарського процесуального законодавства. Третейський суд може витребувати від будь-якої сторони надати належне забезпечення позову у зв'язку з такими заходами.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 10. Підготовка до третейського розгляду`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 73. Підготовка розгляду справи.`
      },
      {
        content: `1. Склад Третейського суду перевіряє стан підготовки справи до розгляду і, якщо визнає за необхідне, вживає додаткові заходи по підготовці справи.
    
    2. Якщо склад Третейського суду приймає додаткові заходи по підготовці справи, він повинен встановити строки, на протязі яких ці додаткові заходи повинні бути виконані.
    
    3. Головуючий складу Третейського суду, або третейський суддя, який розглядає справу одноособово, може давати відповідальному секретарю Третейського суду окремі доручення у зв'язку з підготовкою та проведенням розгляду справи. Він також доручає йому виклик сторін на засідання.
    
    4. З метою правильного і своєчасного вирішення спору третейський суддя вчиняє в необхідних випадках такі дії по підготовці справи до розгляду:
    -вирішує питання про залучення до участі у справі іншого відповідача та провиключення чи заміну неналежного відповідача;
    -викликає представників сторін для уточнення обставин справи і з’ясовує, які матеріали може бути подано додатково;
    -зобов’язує сторони, інших осіб, що приймають участь у справі, виконати певні дії (звірити розрахунки, надати документи, відомості, висновки, необхідні для вирішення спору, тощо);
    -вирішує питання про призначення експертизи;
    -вирішує питання про визнання явки представників сторін у засідання Третейського суду обов’язковою;
    -вирішує питання про виклик посадових та інших осіб для дачі пояснень по суті справи;
    -вирішує питання про вжиття заходів до забезпечення позову;
    -вчиняє інші дії, спрямовані на забезпечення правильного і своєчасного розгляду справи.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 74. Подання та надсилання документів щодо третейського розгляду.`
      },
      {
        content: `1. Усі документи, що подаються однією із сторін до Третейського суду, повинні бути надіслані або передані іншій стороні з дотриманням правил, передбачених цією статтею. Сторонам також повинні бути надіслані або передані інші документи і докази, на яких може ґрунтуватися рішення третейського суду.
    
    2. Своєчасне надсилання або передача сторонам усіх документів у справі забезпечуються третейським суддею.
    
    3. Третейський суд повідомляє сторони та інших осіб, які беруть участь у третейському розгляді, про день, час та місце проведення засідання Третейського суду.
    
    4. Листування здійснюється за офіційним місцезнаходженням юридичної особи або фізичної особи-підприємця згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань або за місцем проживання фізичної особи чи на електронну адресу, яка вказана у документах, надісланих Третейському суду або на документах, в яких міститься третейське застереження.
    
    5. Якщо остання відома адреса місця проживання (перебування), місцезнаходження чи місця роботи учасників справи знаходиться на тимчасово окупованій території, Третейський суд викликає або повідомляє учасників справи, які не мають електронної адреси (електронного кабінету) через оголошення на веб-сайті Третейського суду (https://www.sud.te.ua/). На даній сторінці розміщуються оголошення про дату, час і місце судового засідання/розгляду справи, а також про ухвалення відповідного судового рішення. Указана інформація для учасників справи:
    1) остання відома адреса місця проживання (перебування), місцезнаходження чи місця роботи яких знаходиться на тимчасово окупованій території;
    2) остання відома адреса місця проживання (перебування), місцезнаходження чи місце роботи яких знаходиться в районі проведення антитерористичної операції;
    3) зареєстроване місце проживання (перебування), місцезнаходження чи місце роботи яких невідоме.
    Третейський суд викликає або повідомляє таких учасників справи про дату, час і місце першого судового засідання у справі через оголошення на веб-сайті Третейського суду, яке повинно бути розміщене не пізніше ніж за двадцять днів до дати відповідного судового засідання
    Третейський суд викликає або повідомляє таких учасників справи про дату, час і місце інших судових засідань чи про вчинення відповідної процесуальної дії через оголошення на веб-сайті Третейського суду, яке повинно бути розміщене не пізніше ніж за десять днів до дати відповідного судового засідання або вчинення відповідної процесуальної дії.
    З опублікуванням такого оголошення відповідач вважається повідомленим про дату, час і місце розгляду справи.
    
    6. Учасники справи, остання відома адреса місця проживання (перебування) чи місцезнаходження яких знаходиться на тимчасово окупованій території і які не мають електронної адреси (електронного кабінету), повідомляються про ухвалення
    відповідного судового рішення шляхом розміщення інформації на веб-сайті Третейського суду або шляхом розміщення тексту відповідного судового рішення на веб-сайті Третейського суду, з урахуванням вимог, визначених цим Регламентом.
    З моменту розміщення такої інформації вважається, що особа отримала судове рішення.
    
    7. Передбачений частинами 5 та 6 цієї статті порядок виклику у Третейський суд та повідомлення про судове рішення може застосовуватися стосовно інших учасників судового процесу, адреса місця проживання (перебування), місцезнаходження чи місця роботи яких знаходиться на тимчасово окупованій території, якщо від цього залежить реалізація ними своїх процесуальних прав і обов’язків.
    
    8. Сторони зобов’язані невідкладно повідомити Третейський суд про зміну своєї адреси (у тому числі електронної адреси) у спосіб, що передбачає фіксацію надходження такого повідомлення.
    У разі відсутності заяви про зміну місця проживання або місцезнаходження документи надсилаються сторонам справи на останню відому Третейському суду адресу і вважається доставленою, навіть якщо сторона процесу за цією адресою більше не проживає або не знаходиться.
    Позовна заява, відзив на позов, інші заяви, клопотання та заперечення з процесуальних питань, судове повідомлення, рішення, ухвала Третейського суду, надсилаються сторонам рекомендованим листом з повідомленням про вручення чи вручаються їх представникам особисто під розписку або на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження, з дотриманням правил, визначених цим Регламентом.
    
    9. Документи можуть надсилатися будь-яким поштовим оператором, за умови дотримання вимог цієї статті.
    
    10. Днем вручення документів, пов’язаних з розглядом справи, є:
    - день вручення документів під розписку;
    - день отримання повідомлення про доставлення судової повістки на електронну адресу особи;
    - день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, повідомленою цією особою Третейському суду;
    - день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, що зареєстровані у встановленому законом порядку, якщо ця особа не повідомила Третейському суду іншої адреси.
    Якщо документи надіслано на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження, відправлення вважається врученим у робочий день, в який його було надіслано, а якщо його було надіслано пізніше 17 години, відправлення вважається врученим у робочий день, наступний за днем її відправлення, незалежно від надходження до суду повідомлення про її доставлення.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 11. Сприяння сторонам у досягненні ними мирової угоди`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 75. Примирення сторін.`
      },
      {
        content: `1. Третейський суд застосовує заходи для примирення сторін, сприяє їм у вирішенні спору шляхом укладення мирової угоди на всіх стадіях процесу.
    
    2. Третейський суд на початку розгляду справи повинен з'ясувати у сторін можливість закінчити справу мировою угодою.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 76. Укладення мирової угоди.`
      },
      {
        content: `1. Сторони мають право закінчити справу укладенням мирової угоди, як до початку третейського розгляду, так і на будь-якій його стадії, до прийняття рішення.
    
    2. Мирова угода не повинна порушувати права і законні інтереси інших осіб і суперечити закону.
    
    3. Мирова угода затверджується Третейським судом.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 77. Вирішення питання щодо затвердження мирової угоди.`
      },
      {
        content: `1. За клопотанням сторін Третейський суд приймає рішення про затвердження мирової угоди. Мирова угоди може стосуватися лише прав і обов'язків сторін щодо предмета спору.
    
    2. Зміст мирової угоди викладається безпосередньо в рішенні Третейського суду. До рішень третейського суду, прийнятих відповідно до цієї статті, застосовуються правила розділу VI Закону України "Про третейські суди".
    
    3. Третейський суд не затверджує мирову угоду, якщо вона суперечить закону або порушує права і законні інтереси інших осіб.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 12. Зупинення провадження у справі`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 78. Обов'язок Третейського суду зупинити провадження у справі.`
      },
      {
        content: `1. Третейський суд зобов'язаний зупинити провадження у справі у випадках:
    1)неможливості розгляду цієї справи до вирішення пов'язаної з нею іншої справи, що розглядається судом або відповідного питання компетентними органами;
    2)злиття, приєднання, поділу, перетворення юридичної особи, яка була стороною у справі або у зв'язку зі смертю або оголошення фізичної особи померлою, яка була стороною у справі, якщо спірні правовідносини допускають правонаступництво;
    3)призначення Третейським судом експертизи;
    4)надсилання Третейським судом матеріалів до слідчих органів.
    
    2. Третейський суд зупиняє провадження у справі також в інших випадках, передбачених цим Регламентом.
    
    3. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 79. Право Третейського суду зупинити провадження у справі.`
      },
      {
        content: `1. Третейський суд може за заявою особи, яка бере участь у справі, а також з власної ініціативи зупинити провадження у справі у випадках:
    1) перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
    2) захворювання сторони, підтвердженого медичною довідкою, що виключає можливість явки до суду протягом тривалого часу;
    3) перебування сторони у тривалому службовому відрядженні;
    4) в інших випадках, коли Третейський суд вважатиме за необхідне зупинити провадження у справі, у зв'язку з неможливістю її розгляду.
    
    2. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 80. Строки, на які зупиняється провадження у справі.`
      },
      {
        content: `1. Провадження у справі зупиняється у випадках, встановлених:
    -пунктом 1 частини першої статті 78 цього Регламенту - до набрання законної сили судового рішення, від якого залежить вирішення справи або рішення відповідного компетентного органу;
    -пунктом 2 частини першої статті 78 цього Регламенту - до залучення до участі у справі правонаступника чи представника;
    -пунктом 3 частини першої статті 78 цього Регламенту - на час проведення експертизи;
    -пунктом 4 частини першої статті 78 цього Регламенту - на час проведення відповідної перевірки;
    -пунктом 1 частини першої статті 79 цього Регламенту - до припинення перебування сторони на строковій військовій службі або альтернативній(невійськовій) службі не за місцем проживання;
    -пунктом 2 частини першої статті 79 цього Регламенту - на час хвороби сторони;
    -пунктами 3-4 частини першої статті 79 цього Регламенту - до усунення обставин, що зумовили зупинення провадження у справі.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 81. Відновлення провадження у справі.`
      },
      {
        content: `1. Провадження у справі відновлюється ухвалою Третейського суду за заявою особи, яка бере участь у справі, або з ініціативи суду після усунення обставин, що викликали його зупинення.
    
    2. З дня відновлення провадження у справі перебіг процесуальних строків продовжується.
    
    3. Після відновлення провадження Третейський суд викликає сторони та інших осіб, які беруть участь у справі, і продовжує третейський розгляд.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 13. Залишення позовної заяви без розгляду`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 82. Підстави для залишення позовної заяви без розгляду.`
      },
      {
        content: `1. Третейський суд залишає позовну заяву без розгляду, якщо після її прийняття до розгляду встановить, що:
    1) позов подано особою, яка не має процесуальної дієздатності;
    2) позовну заяву не підписано або підписано особою, яка не має права підписувати її, або особою, посадове становище якої не вказано;
    3) позивач без поважних причин не подав витребувані Третейським судом докази, необхідні для вирішення спору, або позивач (його представник) не з’явився у засідання Третейського суду або не повідомив про причини неявки, крім випадку, якщо від нього надійшла заява про розгляд справи за його відсутності і його нез’явлення не перешкоджає вирішенню спору;
    4) спір між тими самими сторонами, про той самий предмет і з тих самих підстав розглядається в іншому суді;
    5) позивач ухиляється від замовлення проведення експертизи, призначеної Третейським судом, і якщо неможливо розглянути справу за допомогою дослідження інших доказів;
    6) позовна заява не відповідає вимогам цього Регламенту, або не було сплачено третейський збір, і позивач не усунув цих недоліків у встановлений Третейським судом строк;
    7) позивач подав заяву про залишення позову без розгляду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 83. Порядок і наслідки залишення позовної заяви без розгляду.`
      },
      {
        content: `1. У разі залишення позовної заяви без розгляду виноситься ухвала, в якій зазначаються підстави для залишення позовної заяви без розгляду. Копії ухвали направляються особам, які приймають участь у справі.
    
    2. Залишення позовної заяви без розгляду не позбавляє позивача права знову звернутись у Третейський суд з позовною заявою у загальному порядку після усунення обставин, що послугували підставою для залишення заяви без розгляду.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 14. Припинення третейського розгляду`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 84. Підстави припинення третейського розгляду.`
      },
      {
        content: `1. Підставами для припинення третейського розгляду є такі випадки:
    1) спір не підлягає вирішенню в третейських судах України;
    2) відсутній предмет спору;
    3) є рішення компетентного суду між тими ж сторонами, з того ж предмета і з тих самих підстав;
    4) позивач відмовився від позову і відмову прийнято Третейським судом;
    5) сторони уклали угоду про припинення третейського розгляду і вона затверджена Третейським судом;
    6) підприємство, установу чи організацію, які є стороною третейського розгляду, ліквідовано;
    Третейський суд є некомпетентним щодо переданого на його розгляд спору;
    7) у разі смерті фізичної особи (фізичної особи-підприємця), яка була стороною у справі, якщо спірні правовідносини не допускають правонаступництва.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 85. Порядок і наслідки припинення третейського розгляду.`
      },
      {
        content: `1. Про припинення третейського розгляду Третейський суд постановляє ухвалу. В ухвалі Третейський суд зазначає підстави для припинення третейського розгляду, а також вирішує питання про розподіл між сторонами витрат. Копії ухвали направляються особам, які приймають участь у справі.
    
    2. У разі припинення третейського розгляду повторне звернення до Третейського суду з приводу спору між тими самими сторонами, про той самий предмет і з тих самих підстав не допускається. Наявність ухвали про припинення третейського розгляду у зв'язку з прийняттям відмови позивача від позову не позбавляє відповідача в цій справі права на звернення до Третейського суду за вирішенням цього спору.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 15. Третейський розгляд`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 86. Засідання Третейського суду.`
      },
      {
        content: `1. Третейський розгляд здійснюється у засіданні Третейського суду за участю сторін або їх представників. Сторона має право заявити Третейському суду клопотання щодо розгляду спору без її участі.
    
    2. Третейський суд вправі визнати явку сторін у засідання обов'язковою.
    
    3. Третейський суддя, а при колегіальному вирішенні спору – головуючий у засіданні Третейського суду:
    1) відкриває засідання Третейського суду і оголошує, яка справа підлягає розгляду;
    2) перевіряє явку в засідання осіб, які приймають участь у справі, їх представників та інших учасників третейського розгляду, встановлює їх особи і перевіряє їх повноваження;
    3) встановлює, чи повідомлені належним чином особи, які не з'явились у засідання, і які є відомості про причини їх неявки;
    4) з'ясовує питання щодо можливості розгляду справи;
    5) оголошує склад Третейського суду, повідомляє, хто веде протокол засідання третейського розгляду, хто приймає участь в якості експерта, перекладача, і роз'яснює особам, які приймають участь у справі, їх право заявляти відводи;
    6) роз'яснює особам, які приймають участь у справі, та іншим учасникам третейського процесу їх процесуальні права і обов'язки;
    7) видаляє з місця третейського розгляду свідків до початку їх опитування;
    8) визначає з врахуванням думки осіб, які приймають участь у справі, послідовність проведення процесуальних дій;
    9) з'ясовує, чи підтримує позивач позов, чи визнає позов відповідач, чи не бажають сторони закінчить справу мировою угодою;
    10) керує засіданням Третейського суду, забезпечує умови для всебічного і повного дослідження доказів і обставин справи, забезпечує розгляд заяв і клопотань осіб, які приймають участь у справі.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 87. Протокол засідання Третейського суду.`
      },
      {
        content: `1. Протокол засідання Третейського суду ведеться лише в разі наявності угоди між сторонами про ведення протоколу.
    
    2. Для ведення протоколу за згодою третейських суддів (третейського судді) сторонами може бути призначений секретар третейського розгляду. За його відсутності третейські судді можуть обрати секретаря із складу (крім головуючого) третейського суду, яким вирішується спір.
    
    3. У протоколі засідання третейського суду зазначаються:
    1) дата та місце розгляду справи,
    2) прізвища третейських суддів і секретаря;
    3) суть спору;
    4) найменування сторін, їхніх представників, свідків, експертів, інших осіб, якіберуть участь у розгляді справи;
    5) заяви та клопотання сторін;
    6) вказівки та вимоги до сторін по справі, висунуті Третейським судом;
    7) розклад засідань третейського суду;
    8) зміст пояснень сторін та інших осіб, які беруть участь у справі;
    9) подані під час третейського розгляду докази, відомості про їх огляд;
    10) узгодження сторонами питання про необхідність зберігання та умови зберігання рішень Третейського суду;
    11) витрати, понесені сторонами.
    
    4. Протокол підписується секретарем та всіма третейськими суддями, які розглядали справу.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 88. Підстави та порядок відводу секретаря третейського розгляду, який веде протокол.`
      },
      {
        content: `1. Якщо секретар особисто чи опосередковано заінтересований у результаті розгляду справи, або якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках він підлягає відводу чи самовідводу.
    
    2. Питання про відвід секретаря вирішується складом Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 89. Наслідки неподання сторонами документів, інших письмових матеріалів або неявки сторін.`
      },
      {
        content: `1. Ненадання витребуваних Третейським судом доказів, інших письмових документів чи матеріалів, неявка у засідання Третейського суду сторін або їхніх представників, які належним чином були повідомлені про розгляд справи і проведення засідання Третейського суду, не є перешкодою для третейського розгляду і прийняті рішення, якщо причина ненадання документів або неявки сторін у засідання визнана Третейським судом неповажною.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 90. Наслідки неявки в засідання експертів, свідків, перекладачів.`
      },
      {
        content: `1. При неявці в засідання Третейського суду експертів, свідків, перекладачів, належним чином повідомлених про час і місце засідань Третейський суд виносить ухвалу про відкладення третейського розгляду, якщо сторони не заявили клопотання про розгляд справи за відсутністю зазначених осіб.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 91. Відкладення засідання Третейського суду.`
      },
      {
        content: `1. Третейський суд відкладає третейський розгляд у випадках, передбачених цим Регламентом, а також у разі неявки в засідання Третейського суду особи, яка приймає участь у справі, якщо у відношенні цієї особи у суду відсутні відомості про повідомлення її про час і місце третейського розгляду.
    
    2. Третейський суд може відкласти третейський розгляд за клопотанням сторін.
    
    3. У разі якщо особа, яка приймає участь у справі і повідомлена належним чином про час і місце третейського розгляду, заявила клопотання про відкладення третейського розгляду з обґрунтуванням причини неявки у засідання, Третейський суд може відкласти третейський розгляд, якщо визнає причини неявки поважними.
    
    4. Третейський суд може відкласти третейський розгляд за клопотанням особи, яка приймає участь у справі, у зв'язку з неявкою у засідання його представника з поважної причини.
    
    5. Третейський суд може відкласти третейський розгляд, якщо визнає, що він не може відбутися в даному засіданні, у тому числі внаслідок неявки будь-кого з осіб, які приймають участь у справі, інших учасників третейського процесу, а також при задоволенні клопотання сторони про відкладення третейського розгляду у зв'язку з необхідністю надання нею додаткових доказів, при здійсненні інших процесуальних дій.
    
    6. При відкладенні третейського розгляду Третейський суд вправі допитати свідків, які з'явилися, якщо в засіданні присутні сторони або їх представники.
    Показання цих свідків оголошуються у новому засіданні Третейського суду.
    
    7. Третейський розгляд може бути відкладено на строк, необхідний для усунення обставин, що послугували підставою для відкладення, в межах строку розгляду справи, встановленого цим Регламентом.
    
    8. Про відкладення третейського розгляду Третейський суд виносить ухвалу.
    
    9. Про час і місце нового засідання Третейський суд повідомляє осіб, які приймають участь у справі, та інших учасників третейського розгляду. Особи, які з'явились в засідання, повідомляються про час і місце нового засідання безпосередньо в засіданні Третейського суду під розписку.
    
    10. Третейський розгляд в новому засіданні Третейського суду поновлюється з того моменту, з якого воно було відкладено. Повторний розгляд доказів, досліджених до відкладення третейського розгляду, не провадиться.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 92. Розгляд Третейським судом заяв і клопотань осіб, які приймають участь у справі.`
      },
      {
        content: `1. Заяви і клопотання осіб, які приймають участь у справі, розглядаються Третейським судом після того, як буде заслухана думка решти присутніх у засіданні Третейського суду осіб, які беруть участь у справі.
    
    2. За результатами розгляду заяв і клопотань Третейський суд виносить ухвали.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 93. Дослідження доказів.`
      },
      {
        content: `1. При розгляді справи Третейський суд повинен безпосередньо дослідити докази у справі: ознайомитися з письмовими доказами, оглянути речові докази, заслухати пояснення осіб, які приймають участь у справі, показання свідків, висновки експертизи, а також оголосити такі пояснення, показання, висновки, надані у письмовій формі.
    
    2. Особа, яка приймає участь у справі, вправі дати Третейському суду пояснення про представлені нею докази, і докази, витребувані Третейським судом за її клопотанням, а також задати питання викликаним у засідання Третейського суду експертам і свідкам. При цьому першим задає питання особа, за клопотанням якої були викликані експерти і свідки.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 94. Перерва у засіданні Третейського суду.`
      },
      {
        content: `1. Третейський суд за клопотанням особи, яка приймає участь у справі, або за власною ініціативою може оголосити перерву у засіданні Третейського суду.
    
    2. Перерва у засіданні третейського суду може бути об'явлена на строк, що не перевищує п'яти днів.
    
    3. Щодо перерви і час продовження засідання, зазначається в ухвалі Третейського суду.
    
    4. Після закінчення перерви засідання Третейського суду продовжується. Повторний розгляд досліджених до перерви доказів не провадиться, у тому числі при заміні представників осіб, які приймають участь у справі.
    
    5. Особи, які приймають участь у справі і присутні в засіданні Третейського суду до оголошення перерви, вважаються належним чином оповіщеними про час і місце засідання Третейського суду, і їх нез'явлення у засідання після закінчення перерви не є перешкодою для його продовження.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 95. Проведення усного слухання.`
      },
      {
        content: `1. Для викладення сторонами своїх позицій на підставі доказів, що надаються та для проведення усних промов сторін здійснюється усне слухання справи.
    
    2. Слухання проводиться у закритому засіданні. З дозволу складу Третейського суду, або третейського судді, який розглядає справу одноособово, і зі згоди сторін під час слухання можуть бути присутні особи, які не приймають участі у третейському розгляді.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 96. Розгляд на підставі письмових матеріалів.`
      },
      {
        content: `1. Сторони можуть домовитись про розгляд спору на підставі тільки письмових матеріалів наданих сторонами, без проведення усного слухання.
    
    
    2. Якщо надані матеріали виявляться недостатніми для розгляду спору, склад Третейського суду, або суддя, який розглядає справу одноособово, може призначити усне слухання.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 97. Промови сторін.`
      },
      {
        content: `1. Після з'ясування всіх обставин справи та перевірки їх доказами головуючий у засіданні Третейського суду з'ясовує у осіб, які приймають участь у справі, чи не бажають вони доповнити матеріали справи. За відсутністю таких заяв головуючий у засіданні оголошує дослідження доказів закінченими, і Третейський суд надає право особам, які приймають участь у справі висловити свою думку про те, як повинна бути вирішена справа, дати оцінку доказам, зробити висновки про встановлення чи не встановлення фактів, що мають значення для правильного розгляду справи, висловитись відносно правової кваліфікації спірних правовідносин з посиланням на закон, який, за їх міркуванням, регулює ці правовідносини, але лише з посиланням на обставини і докази, які були досліджені у засіданні Третейського суду.
    
    2. Черговість виступу сторін встановлюється суддею Третейського суду, а у разі розгляду справи колегіально - складом колегії суддів.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 98. Відновлення дослідження доказів.`
      },
      {
        content: `1. У разі якщо Третейський суд під час або після промов сторін визнає за необхідне з'ясувати додаткові обставини або дослідити нові докази, Третейський суд поновлює дослідження доказів, про що виноситься ухвала.
    
    2. Після закінчення додаткового дослідження доказів, промови сторін проводяться у загальному порядку, встановленому цим Регламентом.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 99. Закінчення розгляду справи по суті.`
      },
      { content: `1. Після того як Третейський суд переконається, що сторони мали достатньо можливостей для викладення своєї позиції та для подання доказів, Третейський суд оголошує про завершення третейського розгляду у частині дослідження обставин справи і переходить до прийняття рішення.` },
      {
        center: true,
        bold: true,
        content: `Глава 16. Рішення Третейського суду`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 100. Прийняття рішення Третейським судом.`
      },
      {
        content: `1. Рішення Третейського суду приймається після дослідження усіх обставин справи третейським суддею, що одноособово розглядав справу, або більшістю голосів третейських суддів, які входять до складу Третейського суду. Рішення приймається у закритій нараді Третейського суду.
    
    2. Рішення оголошується у засіданні Третейського суду.
    
    3. Третейський суддя, який не згодний із прийнятим рішенням (частково або в цілому), може викласти у письмовій формі свою окрему думку.
    
    4. Третейський суд вправі оголосити лише резолютивну частину рішення. У цьому випадку, якщо сторони не погодили строк направлення їм рішення, мотивоване рішення має бути направлене сторонам у строк, який не перевищує п'яти днів з дня оголошення резолютивної частини рішення.
    
    5. Кожній стороні направляється по одному примірнику рішення.
    
    6. У разі відмови сторони одержати рішення Третейського суду або її неявки без поважних причин у засідання Третейського суду, де воно оголошується, рішення вважається таким, що оголошене сторонам, про що на рішенні робиться відповідна відмітка, а копія такого рішення надсилається такій стороні.
    
    7. Датою прийняття рішення є дата оголошення рішення у засіданні Третейського суду або оголошення його резолютивної частини.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 101. Вимоги до рішення Третейського суду.`
      },
      {
        content: `1. Рішення Третейського суду викладається у письмовій формі і підписується третейським суддею, який одноособово розглядав справу, або повним складом
    третейського суду, що розглядав справу, в тому числі і третейським суддею, який має окрему думку. Окрема думка третейського судді викладається письмово та додається до рішення Третейського суду.
    
    
    2. Рішення Третейського суду скріплюється підписом Президента Асоціації "ПРАВОВА ЛІГА" та круглою печаткою Асоціації "ПРАВОВА ЛІГА".
    
    3. У рішенні Третейського суду повинні бути зазначені:
    -назва Третейського суду;
    -дата прийняття рішення;
    -склад третейського суду і порядок його формування:
    -місце третейського розгляду;
    -сторони, їх представники та інші учасники третейського розгляду, що брали участь у розгляді -справи третейським судом;
    -висновок про компетенцію Третейського суду, обсяг його повноважень за Третейською угодою;
    -стислий виклад позовної заяви, відзиву на позовну заяву, заяв, пояснень, клопотань сторін та їхніх представників, інших учасників третейського розгляду;
    -встановлені обставини справи, підстави виникнення спору, докази, на підставі яких прийнято рішення, зміст мирової угоди, якщо вона укладена сторонами, мотиви, з яких Третейський суд відхилив доводи, докази та заявлені під час третейського розгляду клопотання сторін;
    -висновок про задоволення позову або про відмову в позові повністю або частково по кожній із заявлених вимог;
    -норми законодавства, якими керувався Третейський суд при прийнятті рішення.
    
    4. Висновки третейського суду, що містяться в рішенні по справі, не можуть залежати від настання або ненастання будь-яких обставин.
    
    5. У разі задоволення позовних вимог у резолютивній частині рішення зазначаються:
    1) сторона, на користь якої вирішено спір;
    2) сторона, з якої за рішенням Третейського суду має бути здійснено стягнення грошових сум та/або яка зобов'язана виконати певні дії або утриматися від виконання певних дій;
    3) розмір грошової суми, яка підлягає стягненню, та/або дії, які підлягають виконанню або від виконання яких сторона має утриматися за рішенням Третейського суду;
    4) строк сплати коштів та/або строк і спосіб виконання таких дій;
    5) порядок розподілу між сторонами витрат, пов'язаних з вирішенням спору третейським судом;
    інші обставини, які Третейський суд вважає за необхідне зазначити.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 102. Додаткове рішення.`
      },
      {
        content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, може протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про прийняття додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні.
    
    2. Заяву про прийняття додаткового рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.
    
    3. За результатами розгляду заяви приймається додаткове рішення, яке є складовою частиною рішення Третейського суду, або виноситься мотивована ухвала про відмову у задоволенні заяви про прийняття додаткового рішення.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 103. Роз'яснення рішення.`
      },
      {
        content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, має право протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про роз'яснення резолютивної частини рішення.
    
    2. Заяву про роз'яснення резолютивної частини рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.
    
    3. За результатами розгляду заяви виноситься ухвала про роз'яснення рішення, яка є складовою частиною рішення, або мотивована ухвала про відмову у роз'ясненні рішення.
    
    4. Здійснюючи роз'яснення резолютивної частини рішення, Третейський суд не має права змінювати зміст рішення.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 104. Виправлення рішення.`
      },
      {
        content: `1. Третейський суд у тому ж складі, з власної ініціативи або за заявою сторони третейського розгляду, може виправити у рішенні описки, арифметичні помилки або будь-які інші неточності, про що виноситься ухвала, яка є складовою частиною рішення.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 105. Обов'язковість рішення Третейського суду.`
      },
      {
        content: `1. Сторони, які передали спір на вирішення Третейського суду, зобов'язані добровільно виконати рішення Третейського суду, без будь-яких зволікань чи застережень.
    
    2. Сторони та Третейський суд вживають усіх необхідних заходів з метою забезпечення виконання рішення Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 106. Оскарження рішення Третейського суду.`
      },
      {
        content: `1. Рішення Третейського суду є остаточним і оскарженню не підлягає, крім випадків, передбачених Законом України "Про третейські суди".
    
    2. Рішення Третейського суду може бути оскаржене стороною у випадках, передбачених Законом України "Про третейські суди", до компетентного суду відповідно до встановлених законом підвідомчості та підсудності справ.
    
    3. Рішення Третейського суду може бути оскаржене та скасоване лише з таких підстав:
    1) справа, по якій прийнято рішення Третейського суду, не підвідомча Третейському суду відповідно до закону;
    2) рішення Третейського суду прийнято у спорі, не передбаченому третейською угодою, або цим рішенням вирішені питання, які виходять за межі Третейської угоди. Якщо рішенням Третейського суду вирішені питання, які виходять за межі Третейської угоди, то скасовано може бути лише ту частину рішення, що стосується питань, які виходять за межі Третейської угоди;
    3) Третейську угоду визнано недійсною компетентним судом;
    4) склад Третейського суду, яким прийнято рішення, не відповідав вимогам статей 16, 19 Закону України "Про третейські суди".
    
    4. Заяву про скасування рішення Третейського суду може бути подано до компетентного суду протягом трьох місяців з дня прийняття рішення Третейським судом.
    
    5. Скасування компетентним судом рішення Третейського суду не позбавляє сторони права повторно звернутися до Третейського суду, крім випадків, передбачених цією статтею.
    
    6. У разі якщо рішення Третейського суду скасовано повністю або частково внаслідок визнання компетентним судом недійсною Третейської угоди або через те, що рішення прийнято у спорі, який не передбачений Третейською угодою, чи цим рішенням вирішені питання, що виходять за межі Третейської угоди, відповідний спір не підлягає подальшому розгляду в третейських судах.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 17. Ухвали Третейського суду`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 107. Винесення Третейським судом ухвал.`
      },
      {
        content: `1. З питань, що виникають у процесі розгляду справи і не стосуються суті спору, Третейський суд постановляє ухвали.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 108. Зміст ухвали`
      },
      {
        content: `1. В ухвалі повинні бути зазначені:
    1) дата і місце винесення ухвали;
    2) найменування Третейського суду, склад суду;
    3) найменування і номер справи;
    4) найменування осіб, які приймають участь у справі;
    5) питання, з якого виноситься ухвала;
    6) мотиви, з яких Третейський суд дійшов до висновків, прийняв або відхилив доводи осіб, які приймають участь у справі, з посиланням на закони та інші нормативно-правові акти;
    7) висновок за результатами розгляду Третейським судом питання.
    Ухвала, підписується третейським суддею або складом Третейського суду, який виніс цю ухвалу.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 109. Направлення ухвали.`
      },
      {
        content: `1. Копії ухвали, направляються особам, які приймають участь у справі, та іншими особам, яких вона стосується, рекомендованим листом з повідомленням про вручення чи вручаються їм під розписку, або на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження.
    
    2. Копії ухвали направляються в п'ятиденний строк з дня винесення ухвали, якщо інший строк не встановлений цим Регламентом.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 110. Виконання ухвали.`
      },
      {
        content: `1. Ухвала, винесена Третейським судом, виконується негайно, якщо інше не встановлено цим Регламентом або Третейським судом.`
      },
      {
        center: true,
        bold: true,
        content: `Глава 18. Інше`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 111. Зберігання матеріалів справи, розглянутої Третейським судом.`
      },
      {
        content: `1. Справа, розглянута Третейським судом, зберігається у Третейському суді протягом 10 років з дня прийняття рішення Третейського суду.`
      },
      {
        center: true,
        italic: true,
        content: `Стаття 112. Публікація рішень Третейського суду.`
      },
      {
        content: `1. Опублікування винесених Третейським судом рішень дозволяється з дозволу голови Третейського суду, його заступника, у відсутність голови Третейського суду.
    
    2. При опублікуванні таких рішень необхідно забезпечити нерозголошення комерційної таємниці, слідкувати за тим, щоб не зазначались найменування контрагентів у правочині, найменування цінних паперів, цін, якщо інше не дозволено сторонами справи. голова Третейського суду, його заступник, у відсутність голови Третейського суду, може не допустити до опублікування й інші відомості.`
      }

    ]
  },

};

const regulationsContent = [
  {
    bold: true,
    right: true,
    content: `ЗАТВЕРДЖЕНО
рішенням Ради Асоціації «ПРАВОВА ЛІГА»
від 22 березня 2007 року, протокол № 1`,
  },
  {
    bold: true,
    right: true,
    content: `ЗМІНИ ЗАТВЕРДЖЕНО
рішенням Ради Асоціації «ПРАВОВА ЛІГА»
від 09 лютого 2021 року, протокол №1/2021`,
  },
  {
    bold: true,
    center: true,
    content: `РЕГЛАМЕНТ
Постійно діючого третейського суду
при Асоціації "ПРАВОВА ЛІГА"
(нова редакція)`,
  },

  {
    bold: true,
    content: `Глава 1. Загальні положення
Стаття 1. Статус Третейського суду.`,
  },
  {
    content: `1. Постійно діючий третейський суд при Асоціації "ПРАВОВА ЛІГА" (далі - Третейський суд) є недержавним, незалежним, постійно діючим третейським судом та здійснює свою діяльність у відповідності з чинним законодавством, що регулює діяльність третейських судів в Україні та цим Регламентом Третейського суду.
2. Третейський суд діє при Асоціації "ПРАВОВА ЛІГА", яка матеріально і організаційно забезпечує його діяльність.
3. Завданням Третейського суду є захист майнових і немайнових прав та охоронюваних законом інтересів фізичних чи юридичних осіб шляхом всебічного розгляду та вирішення спорів відповідно з чинним законодавством та Регламенту Третейського суду (далі - Регламент). Діяльність Третейського суду та третейських суддів не є підприємницькою.
4. На розгляд до Третейського суду може бути передано будь-який спір, що виник з цивільних чи господарських правовідносин, крім випадків, передбачених Законом України «Про третейські суди» та іншими законодавчими актами.`,
  },
  {
    bold: true,
    content: `Стаття 2. Компетенція Третейського суду.`,
  },
  {
    content: `1. Третейський суд порушує справи за позовними заявами підприємств, організацій, фізичних осіб-підприємців та фізичних осіб, які звертаються до Третейського суду за захистом своїх прав та охоронюваних законом інтересів.
2. Третейський суд з дотриманням вимог Закону України "Про третейські суди" самостійно вирішує питання про наявність або відсутність у нього компетенції для розгляду конкретного спору.
3. Третейський суд розглядає спори за наявністю третейського застереження у договорі, контракті або у вигляді окремої письмової угоди (далі - Третейська угода) між сторонами про передачу на його вирішення конкретного спору, визначених категорій або всіх спорів, що виникли або можуть виникнути між сторонами у зв'язку з будь-якими правовідносинами незалежно від того, мали вони чи ні договірний характер.
4. Третейська угода укладається у письмовій формі. Третейська угода вважається укладеною у письмовій формі, якщо вона міститься в документі, підписаному сторонами чи укладена шляхом обміну листами, повідомленнями по телетайпу, телеграфу або з використанням інших засобів електронного чи іншого зв'язку, що забезпечує фіксацію такої угоди або шляхом направлення відзиву на позов, в якому одна із сторін підтверджує наявність угоди, а інша сторона проти цього не заперечує. Посилання у договорі, контракті на документ, який містить умову про третейський розгляд спору, є Третейською угодою за умови, що договір укладений у письмовій формі і це посилання є таким, що робить Третейську угоду частиною договору.
5. Якщо сторони домовились звернутися до Третейського суду, Регламент для них є обов'язковим.
6. Сторона має право заявити про відсутність у Третейського суду компетенції стосовно переданого на вирішення спору до початку розгляду справи по суті.
7. Сторона має право заявити про перевищення Третейським судом меж його компетенції, якщо в процесі третейського розгляду справи виникне питання, розгляд якого не передбачено третейською угодою або яке не може бути предметом такого розгляду відповідно до закону та цього Регламенту.
8. У випадках, передбачених пунктами 6 і 7 цієї статті, Третейський суд повинен відкласти розгляд справи по суті до вирішення ним питання щодо наявності у нього компетенції.
9. Питання щодо компетенції Третейського суду по конкретній справі вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, складом Третейського суду чи третейським суддею, який розглядає спір одноособово. При цьому Третейська угода, що є частиною договору, тлумачиться як угода, яка не залежить від інших умов договору. Недійсність окремих положень договору, контракту, що містить третейське застереження, не тягне за собою недійсність такого третейського застереження.
10. З питань наявності чи відсутності компетенції Третейський суд постановляє мотивовану ухвалу.`,
  },
  {
    bold: true,
    content: `Стаття 3. Місцезнаходження Третейського суду.`,
  },
  {
    content: `5. Місцезнаходженням Третейського суду є місцезнаходження Асоціації "ПРАВОВА ЛІГА" (ідентифікаційний код 34826691): 46001, майдан Волі 4, м. Тернопіль, Україна.
6. Перед зверненням до Третейського суду Сторони зобов’язуються перевірити місцезнаходження Асоціації "ПРАВОВА ЛІГА" згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань.
7. Зміна місцезнаходження Третейського суду не впливає на чинність третейської угоди.
        `,
  },
  {
    bold: true,
    content: "Стаття 4. Місце третейського розгляду.",
  },
  {
    content: `1. Місцем третейського розгляду є місцезнаходження Третейського суду.
2. Сторони можуть домовитися про проведення третейського розгляду поза місцем знаходження Третейського суду. У такому разі всі додаткові витрати, пов'язані з проведенням розгляду, покладаються на сторони.`,
  },
  {
    bold: true,
    content: `Стаття 5. Законодавство, яке застосовується Третейським судом при вирішенні спорів.`,
  },
  {
    content: `1. Третейські суди вирішують спори на підставі Конституції та законів України, інших нормативно-правових актів та міжнародних договорів України.
2. Третейський суд у випадках, передбачених законом або міжнародним договором України, застосовує норми права інших держав.
3. У разі відсутності законодавства, що регулює певні спірні відносини, Третейський суд застосовує законодавство, яке регулює подібні відносини, а за відсутності такого Третейський суд застосовує аналогію права чи керується торговими та іншими звичаями, якщо останні за своїм характером та змістом властиві таким спірним відносинам.`,
  },
  {
    bold: true,
    content: `Стаття 6. Мова третейського розгляду.`,
  },
  {
    content: `1. Розгляд справ у Третейському суді провадиться українською мовою.
2. Процесуальні документи Третейського суду приймаються українською мовою.
3. Сторона, яка надає документи чи письмові докази мовою іншою, ніж мова третейського розгляду, повинна забезпечити їх переклад на мову третейського розгляду.
4. Якщо сторона не володіє мовою, якою провадиться розгляд, Третейський суд за проханням сторони і за її рахунок забезпечується послугами перекладача.`,
  },
  {
    bold: true,
    content: `Стаття 7. Гласність.`,
  },
  {
    content: `1. Третейський розгляд здійснюється відкрито.
2. У разі коли проти відкритого третейського розгляду висунуто хоча б однією стороною заперечення з мотивів додержання та збереження комерційної або банківської таємниці чи забезпечення конфіденційності інформації, справа розглядається у закритому засіданні.`,
  },
  {
    bold: true,
    content: `Стаття 8. Конфіденційність.`,
  },
  {
    content: `1. Відомості, пов’язані з третейським розглядом справи, є конфіденційними. Третейський суд, суддя Третейського суду, працівники апарату Третейського суду не вправі розголошувати відомості та інформацію, що стали йому (їм) відомі під час третейського розгляду, без згоди сторін або їх правонаступників.
2. Забороняється вимагати від третейського судді надання документів, відомостей та інформації, якими він володіє у зв'язку з третейським розглядом справи, крім випадків, передбачених законами України.
3. У будь-якому випадку, при публікаціях не допускається посилання на найменування сторін, їх банківські реквізити та аналогічні відомості.
4. Надання відомостей щодо спорів, переданих на розгляд до Третейського суду, допускається лише на письмовий запит компетентних органів у випадках та з підстав, передбачених законами України.
5. Не буде вважатись розголошенням відомостей в разі, якщо сторони в ході розгляду справи не подавали письмових заяв чи клопотань про збереження конфіденційності.`,
  },
  {
    bold: true,
    content: `Стаття 9. Принципи діяльності Третейського суду.`,
  },
  {
    content: `1. Третейський суд діє на принципах, визначених Законом України «Про третейські суди».`,
  },
  {
    bold: true,
    content: `Глава 2. Учасники третейського розгляду
Стаття 10. Склад осіб, які приймають участь у третейському розгляді.`,
  },
  {
    content: `1. Особами, що приймають участь у третейському розгляді є сторони, їх представники.
2. Сторонами третейського розгляду можуть бути юридичні та фізичні особи, які подали до Третейського суду позов на захист своїх прав та інтересів, або до яких пред'явлений позов.
3. Позивачами є юридичні особи, фізичні особи-підприємці та фізичні особи, які подали позов.
4. Відповідачами є юридичні особи, фізичні особи-підприємці та фізичні особи, до яких подана позовна вимога.
5. Треті особи вступають в третейський розгляд тільки зі згоди сторін третейського розгляду. Для залучення до третейського розгляду третьої особи, окрім згоди сторін, необхідна також згода особи, яка залучається. Згода на залучення третьої особи повинна бути викладена у письмовій формі.
6. Для об'єктивного і вірного третейського розгляду Третейський суд вправі за власною ініціативою залучати третіх осіб за їх згодою без згоди сторін.`,
  },
  {
    bold: true,
    content: `Стаття 11. Інші учасники третейського розгляду.`,
  },
  {
    content: `1. Іншими учасниками третейського розгляду, які сприяють здійсненню третейського розгляду можуть бути: експерти, свідки, перекладачі, секретар третейського розгляду.`,
  },
  {
    bold: true,
    content: `Стаття 12. Загальні засади третейського розгляду.`,
  },
  {
    content: `1. Третейський розгляд здійснюється на засадах змагальності та рівності сторін. До сторін повинно бути рівне ставлення і кожній стороні повинні бути надані рівні можливості для викладення своєї позиції.`,
  },
  {
    bold: true,
    content: `Стаття 13. Права та обов'язки осіб, які приймають участь у справі.`,
  },
  {
    content: `1. Особи, які приймають участь у справі, мають право знайомитись з матеріалами справи, робити з них виписки, знімати копії: заявляти відводи; надавати докази і ознайомлюватися з доказами, наданими іншими особами, що приймають участь у справі, до початку третейського розгляду; брати участь у дослідженні доказів; задавати питання іншим учасникам третейського розгляду, заявляти клопотання, робити заяви, давати пояснення Третейському суду, наводити свої доводи по всім питанням, що виникають при розгляді справи; знайомитися з клопотаннями, заявленими іншими особами, заперечувати проти клопотань, доводів інших осіб, що приймають участь у справі; отримувати копії ухвал та рішень, користуватися іншими процесуальними правами, наданими їм цим Регламентом.
2. Особи, які приймають участь у справі, повинні добросовісно користуватися всіма наданими їм правами.
3. Особи, які приймають участь у справі, несуть обов'язки, передбачені цим Регламентом або покладені на них Третейським судом у відповідності з цим Регламентом.`,
  },
  {
    bold: true,
    content: `Стаття 14. Процесуальна правоздатність і процесуальна дієздатність.`,
  },
  {
    content: `1. Здатність мати процесуальні права і нести процесуальні обов'язки (процесуальна правоздатність) визнається в рівній мірі за всіма юридичними і фізичними особами, які мають згідно з законом право на судовий захист в Третейському суді своїх прав і законних інтересів.
2. Здатність своїми діями здійснювати процесуальні права і виконувати процесуальні обов'язки (процесуальна дієздатність) належить в Третейському суді - юридичним і фізичним особам.
3. Права і законні інтереси недієздатних фізичних осіб захищають в третейському розгляді їх законні представники.`,
  },
  {
    bold: true,
    content: `Стаття 15. Участь у справі декількох позивачів або відповідачів.`,
  },
  {
    content: `1. Позов може бути поданий до Третейського суду сумісно декількома позивачами або до декількох відповідачів (процесуальна співучасть). Кожен з позивачів або відповідачів виступає в процесі самостійно. Співучасники можуть доручити ведення справи одному або декільком з співучасників.`,
  },
  {
    bold: true,
    content: `Стаття 16. Залучення до участі у справі іншого відповідача.
Заміна неналежного відповідача.`,
  },
  {
    content: `1. Третейський суд за наявністю достатніх підстав має право до прийняття рішення залучити за клопотанням позивача або за своєю ініціативою до участі у справі іншого відповідача, при наявності підписаної Третейської угоди.
2. У разі, якщо при підготовці справи до третейського розгляду або під час третейського розгляду буде встановлено, що позов пред'явлений не до тої особи, яка повинна відповідати за позовом, Третейський суд може за згодою позивача, не припиняючи провадження у справі, допустити заміну неналежного відповідача належним відповідачем, при наявності підписаної Третейської угоди.
3. Про залучення іншого відповідача чи заміну неналежного відповідача розгляд справи проводиться спочатку, про що Третейський суд виносить ухвалу.`,
  },
  {
    bold: true,
    content: `Стаття 17. Процесуальне правонаступництво.`,
  },
  {
    content: `1. У випадках вибуття однієї з сторін третейського розгляду (реорганізація юридичної особи, уступка вимоги, переведення боргу, смерть фізичної особи та інші випадки переміни осіб у зобов'язаннях) Третейський суд проводить заміну цієї сторони її правонаступником, при згоді правонаступника і зазначає про це в ухвалі або в рішенні. Правонаступництво можливе на будь-якій стадії третейського розгляду.
2. Для правонаступника усі дії, здійснені в третейському процесі до вступу правонаступника в справу, обов'язкові в тій мірі, в якій вони були обов'язкові для особи, яку правонаступник змінив.
3. Якщо Третейська угода укладена у вигляді третейського застереження, то вона вважається невід’ємною частиною угоди і щодо неї діють такі самі правила правонаступництва, що й до угоди в цілому.
4. Якщо Третейська угода укладена у вигляді окремої угоди, то у разі заміни кредитора у зобов’язанні останній повинен повідомити нового кредитора про її існування. Зміна Третейської угоди в такому випадку можлива лише за умови явно вираженої згоди іншої (інших) сторони (сторін) угоди.`,
  },
  {
    bold: true,
    content: `Стаття 18. Зміна підстави або предмета позову, зміна розміру позовних вимог, відмова від позову, визнання позову, мирова угода.`,
  },
  {
    content: `1. Позивач має право при розгляді справи в Третейському суді до прийняття рішення змінити підставу або предмет позову, збільшити або зменшити розмір позовних вимог.
2. Позивач має право при розгляді справи в Третейському суді до прийняття рішення, відмовитись від позову повністю або частково.
3. Відповідач має право при розгляді справи в Третейському суді визнати позов повністю або частково.
4. Сторони можуть закінчити справу мировою угодою в порядку, передбаченому главою 11 цього Регламенту.
5. Третейський суд не приймає відмову позивача від позову, зменшення ним розміру позовних вимог, визнання відповідачем позову, не затверджує мирову угоду сторін, якщо це суперечить закону або порушує права інших осіб.`,
  },
  {
    bold: true,
    content: `Стаття 19. Треті особи, які заявляють самостійні вимоги відносно предмета спору.`,
  },
  {
    content: `1. Треті особи, які заявляють самостійні вимоги відносно предмета спору, можуть за їх згодою та за згодою сторін вступити у справу третейського розгляду до прийняття Третейським судом рішення, при умові існування щодо них Третейської угоди. Вони користуються усіма правами і несуть усі обов'язки позивача.
2. У разі якщо третя особа, заявляє самостійні вимоги відносно предмета спору, вступила у справу після початку третейського розгляду, розгляд справи в Третейському суді проводиться спочатку.
3. Про вступ в справу третьої особи, яка заявляє самостійні вимоги відносно предмета спору, виноситься ухвала.`,
  },
  {
    bold: true,
    content: `Стаття 20. Треті особи, які не заявляють самостійних вимог відносно предмета спору.`,
  },
  {
    content: `1. Треті особи, які не заявляють самостійних вимог відносно предмета спору, можуть за згодою сторін вступити в справу третейського розгляду на стороні позивача або відповідача до прийняття Третейським судом рішення, якщо рішення в справі може вплинути на права або обов'язки по відношенню до однієї з сторін. Вони можуть бути залучені до участі у справі третейського розгляду за їх згодою за ініціативою Третейського суду.
2. Треті особи, які не заявляють самостійних вимог щодо предмета спору, мають процесуальні права і обов'язки сторони, за виключенням права на зміну підстави або предмета позову, збільшення або зменшення розміру позовних вимог, відмови від позову, визнання позову або укладення мирової угоди, пред'явлення зустрічного позову.
3. Про вступ у справу третьої особи, яка не заявляє самостійних вимог щодо предмета спору, або про залучення в справу цієї особи або про відмову про це Третейським судом виноситься ухвала.`,
  },
  {
    bold: true,
    content: `Глава 3. Представництво у Третейському суді
Стаття 21 Ведення справ у Третейському суді через представників.`,
  },
  {
    content: `1. Сторона, третя особа, а також особа, якій законом надано право звертатися до Третейського суду в інтересах іншої особи, може вести справи у Третейському суді особисто та (або) через представника.
2. Особиста участь у справі особи не позбавляє її права мати в цій справі представника.
3. Юридична особа незалежно від порядку її створення бере участь у справі через свого керівника, члена виконавчого органу, іншу особу, уповноважену діяти від її імені відповідно до закону, статуту, положення, трудового договору (контракту), або через представника.`,
  },
  {
    bold: true,
    content: `Стаття 22. Особи, які можуть бути представниками у Третейському суді.`,
  },
  {
    content: `1. Представником у Третейському суді може бути адвокат або представник.
2. Одна й та сама особа може бути одночасно представником декількох позивачів або декількох відповідачів або декількох третіх осіб на одній стороні, за умови відсутності конфлікту інтересів між ними.`,
  },
  {
    bold: true,
    content: `Стаття 23. Документи, що посвідчують повноваження представників`,
  },
  {
    content: `1. Повноваження представників сторін та інших учасників справи мають бути підтверджені такими документами:
1) довіреністю фізичної;
2) довіреністю юридичної особи.
2. Довіреність фізичної особи повинна бути посвідчена нотаріально або у визначених законом випадках іншою особою.
3. Довіреність від імені юридичної особи видається за підписом (електронним цифровим підписом) посадової особи, уповноваженої на це законом, установчими документами.
4. Повноваження адвоката як представника підтверджуються довіреністю або ордером, виданим відповідно до Закону України "Про адвокатуру та адвокатську діяльність".
5. Відповідність копії документа, що підтверджує повноваження представника, оригіналу може бути засвідчена підписом третейського судді.
6. Оригінали документів, зазначених у цій статті, копії з них, засвідчені третейським суддею, або копії з них, засвідчені у визначеному законом порядку, приєднуються до матеріалів справи.
7. У разі подання представником до Третейського суду заяви, скарги, клопотання він додає довіреність або інший документ, що посвідчує його повноваження, якщо в справі немає підтвердження такого повноваження на момент подання відповідної заяви, скарги, клопотання.
8. Довіреності або інші документи, що підтверджують повноваження представника і були посвідчені в інших державах, повинні бути легалізовані в установленому законодавством порядку, якщо інше не встановлено міжнародними договорами, згода на обов’язковість яких надана Верховною Радою України.`,
  },
  {
    bold: true,
    content: `Стаття 24. Повноваження представника.`,
  },
  {
    content: `1. Представник, який має повноваження на ведення справи в Третейському суді, здійснює від імені особи, яку він представляє, її процесуальні права та обов’язки.
2. Обмеження повноважень представника на вчинення певної процесуальної дії мають бути застережені у виданій йому довіреності або ордері.
3. Підстави і порядок припинення представництва за довіреністю визначаються Цивільним кодексом України, а представництва за ордером - законодавством про адвокатуру.
4. Про припинення представництва або обмеження повноважень представника за довіреністю або ордером має бути повідомлено Третейський суд шляхом подання письмової заяви.
5. У разі припинення повноважень представника на здійснення представництва особи у справі представник не може бути у цій самій справі представником іншої сторони, третьої особи на іншій стороні або третьої особи із самостійними вимогами щодо предмета спору.`,
  },
  {
    bold: true,
    content: `Глава 4. Процесуальні строки
Стаття 25. Встановлення та обчислення процесуальних строків.`,
  },
  {
    content: `1. Строки, в межах яких вчиняються процесуальні дії, встановлюються законом, цим Регламентом, а якщо такі строки законом, цим Регламентом не визначені, - встановлюються Третейським судом.
2. Третейський суд має встановлювати розумні строки для вчинення процесуальних дій. Строк є розумним, якщо він передбачає час, достатній, з урахуванням обставин справи, для вчинення процесуальної дії, та відповідає завданню третейського судочинства.
3. Строки, встановлені законом, цим Регламентом або Третейським судом, обчислюються роками, місяцями і днями, а також можуть визначатися вказівкою на подію, яка повинна неминуче настати.
4. Перебіг строку третейського розгляду починається з наступною дня після відповідної календарної дати або настання події, з якою пов'язано його початок.`,
  },
  {
    bold: true,
    content: `Стаття 26. Закінчення строків третейського розгляду.`,
  },
  {
    content: `1. Строк, обчислюваний роками, закінчується у відповідні місяць і число останнього року строку.
2. Строк, обчислюваний місяцями, закінчується у відповідне число останнього місяця строку. Якщо закінчення строку, обчислюваного місяцями, припадає на такий місяць, що відповідного числа не має, строк закінчується в останній день цього місяця.
3. Якщо закінчення строку припадає на вихідний, святковий чи інший неробочий день, останнім днем строку є перший після нього робочий день.
4. Перебіг строку, закінчення якого пов'язане з подією, яка повинна неминуче настати, закінчується наступного дня після настання події.
5. Останній день строку триває до 24 години. Строк не вважається пропущеним, якщо до його закінчення заява, інші документи чи матеріали або грошові кошти здано на пошту чи передані іншими відповідними засобами зв'язку.`,
  },
  {
    bold: true,
    content: `Стаття 27. Наслідки пропущення строків третейського розгляду.`,
  },
  {
    content: `1. Право на вчинення процесуальної дії втрачається із закінченням строку, встановленого законом, цим Регламентом або Третейським судом.
2. Документи, подані після закінчення строків третейського розгляду, залишаються без розгляду, якщо Третейський суд за клопотанням особи, що їх подала, не знайде підстав для поновлення або продовження строку.`,
  },
  {
    bold: true,
    content: `Стаття 28. Зупинення строків третейського розгляду.`,
  },
  {
    content: `1. Зупинення провадження у справі зупиняє перебіг строків третейського розгляду. Зупинення цих строків починається з моменту настання тієї події, внаслідок якої Третейський суд зупинив провадження.`,
  },
  {
    bold: true,
    content: `Стаття 29. Поновлення та продовження строків третейського розгляду.`,
  },
  {
    content: `1. Третейський суд поновлює або продовжує строк, встановлений відповідно законом, цим Регламентом за клопотанням сторони або іншої особи у разі його пропущення з поважних причин.
2. Строки вчиненим процесуальних дій, передбачені Законом України "Про третейські суди" можуть бути поновлені або продовжені лише за спільною згодою сторін та складу Третейського суду, яким вирішується спір.
3. Одночасно з клопотанням про поновлення чи продовження строку може бути вчинено ту дію або подано той документ чи доказ, стосовно якого заявлено клопотання.
4. З питань, зазначених у цій статті, Третейським судом виноситься ухвала.`,
  },
  {
    bold: true,
    content: `Стаття 30. Строк розгляду спору.`,
  },
  {
    content: `1. Розгляд справи Третейським судом починається з винесення відповідної ухвали та направлення її сторонам.
2. Третейський суд застосовує заходи до розгляду спору протягом розумного строку, але не більше 2 (двох) місяців з дня винесення ухвали про порушення провадження у справі, крім випадків, коли за згодою сторін або за рішенням Третейського суду строки розгляду справи продовжені. Про продовження строку третейського розгляду третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду виносить ухвалу.
3. Третейський суд на початку розгляду справи з’ясовує у сторін можливість закінчити справу мировою угодою та в подальшому сприяє вирішенню спору шляхом укладання мирової угоди на всіх стадіях третейського розгляду.`,
  },
  {
    bold: true,
    content: `Стаття 31. Інші строки.`,
  },
  {
    content: `1. Всі інші строки, пов'язані з третейським розглядом, визначені у конкретних статтях цього Регламенту.`,
  },
  {
    bold: true,
    content: `Глава 5. Докази
Стаття 32. Докази.`,
  },
  {
    content: `1. Доказами у третейському розгляді є будь-які фактичні дані, на підставі яких Третейський суд встановлює наявність або відсутність обставин, що обґрунтовують вимоги і заперечення сторін, та інші обставини, що мають значення для правильного вирішення спору.`,
  },
  {
    bold: true,
    content: `Стаття 33. Засоби доказування.`,
  },
  {
    content: `1. Засобами доказування є пояснення сторін, третіх осіб, їхніх представників, викладені в заявах по суті, показання свідків, письмові, речові докази, висновки експертів.`,
  },
  {
    bold: true,
    content: `Стаття 34. Обов'язки доказування і надання доказів та їх зберігання.`,
  },
  {
    content: `1. Кожна сторона третейського розгляду повинна довести ті обставини, на які вона посилається як на підставу своїх вимог і заперечень.
2. Докази подаються сторонами та іншими учасниками справи.
3. Третейський суд має право вимагати від сторін, надання доказів, необхідних для повного, всебічного і об'єктивного вирішення спору.
4. У разі посилання учасника справи на невчинення іншим учасником справи певних дій або відсутність певної події, Третейський суд може зобов’язати такого іншого учасника справи надати відповідні докази вчинення цих дій або наявності певної події. У разі ненадання таких доказів Третейський суд може визнати обставину невчинення відповідних дій або відсутності події встановленою.
5. Якщо Третейський суд визнає за необхідне одержати документи від підприємств, установ, організацій, які не є учасниками третейського розгляду, він своєю ухвалою уповноважує сторони або одну із сторін одержати відповідні документи та надати їх Третейському суду.
6. Сторони повинні надавати Третейському суду докази в оригіналах чи належним чином засвідчених копіях.
7. Всі зібрані письмові докази разом з процесуальними документами сторін та Третейського суду зберігаються у справі в прошитому та пронумерованому вигляді.
8. Третейський суд не може збирати докази, що стосуються предмета спору, з власної ініціативи, крім витребування доказів Третейським судом у випадку, коли він має сумніви у добросовісному здійсненні учасниками справи їхніх процесуальних прав або виконанні обов’язків щодо доказів.`,
  },
  {
    bold: true,
    content: `Стаття 35. Належність доказів.`,
  },
  {
    content: `1. Належними є докази, на підставі яких можна встановити обставини, які входять в предмет доказування. Третейський суд бере до розгляду тільки ті докази, які стосуються справи, що розглядається та предмета доказування.
2. Предметом доказування є обставини, які підтверджують заявлені вимоги чи заперечення або мають інше значення для розгляду справи і підлягають встановленню при прийнятті Третейським судом рішення.
3. Сторони мають право обґрунтовувати належність конкретного доказу для підтвердження їхніх вимог або заперечень.`,
  },
  {
    bold: true,
    content: `Стаття 36. Допустимість доказів.`,
  },
  {
    content: `1. Обставини справи, які за законом повинні бути підтверджені певними засобами доказування, не можуть підтверджуватися ніякими іншими засобами доказування.
2. Третейський суд не бере до уваги докази, які одержані з порушенням порядку, встановленого законом.`,
  },
  {
    bold: true,
    content: `Стаття 37. Підстави звільнення від доказування.`,
  },
  {
    content: `1. Обставини, визнані Третейським судом загальновідомими, не потребують доказування.
2. Обставини встановлені рішенням Третейського суду, що набрало законної сили, не доказуються при розгляді інших справ, у яких беруть участь ті самі особи або особа, щодо якої встановлено ці обставини.
3. Рішення судів в господарській, цивільній або адміністративній справі, що набрали законної сили, обов'язкові для Третейського суду про обставини, встановлені цими рішеннями по відношенню до осіб, які приймають участь у справі.
4. Обставини, встановлені стосовно певної особи рішенням суду в господарській, цивільній або адміністративній справі, що набрало законної сили, можуть бути у загальному порядку спростовані особою, яка не брала участі у справі, в якій такі обставини були встановлені.
5. Обвинувальний вирок суду в кримінальному провадженні або постанова суду, якою особу притягнуто до адміністративної відповідальності у справі про адміністративне правопорушення, які набрали законної сили, є обов’язковими для Третейського суду, що розглядає справу про правові наслідки дій чи бездіяльності особи, стосовно якої ухвалений вирок або постанова суду, лише в питанні, чи мали місце ці дії (бездіяльність) та чи вчинені вони цією особою.
6. Правова оцінка, надана судом певному факту при розгляді іншої справи, не є обов’язковою для Третейського суду.
7. Обставини, встановлені рішенням міжнародного комерційного арбітражу, виправдувальним вироком суду у кримінальному провадженні, ухвалою про закриття кримінального провадження і звільнення особи від кримінальної відповідальності, підлягають доказуванню в загальному порядку при розгляді справи Третейським судом.`,
  },
  {
    bold: true,
    content: `Стаття 38. Звільнення від доказування обставин, визнаних сторонами.`,
  },
  {
    content: `1. Третейський суд повинен сприяти досягненню сторонами згоди в оцінці обставин в цілому або в їх окремих частинах, проявляти в цих цілях необхідну ініціативу, використовувати свої процесуальні повноваження і авторитет Третейського суду.
2. Обставини, які визнаються учасниками справи, не підлягають доказуванню, якщо Третейський суд не має обґрунтованих підстав вважати їх недостовірними або визнаними у зв’язку з примусом. Обставини, які визнаються учасниками справи, можуть бути зазначені в заявах по суті справи, письмових поясненнях учасників справи, їх представників.
3. Відмова від визнання обставин приймається Третейським судом, якщо сторона, яка відмовляється, доведе, що вона визнала ці обставини внаслідок помилки, що має істотне значення, обману, насильства, погрози чи тяжкої обставини, або що обставини визнано у результаті зловмисної домовленості її представника з іншою стороною. Про прийняття відмови сторони від визнання обставин Третейський суд виносить ухвалу. У разі прийняття Третейським судом відмови сторони від визнання обставин вони доводяться в загальному порядку.`,
  },
  {
    bold: true,
    content: `Стаття 39 Оцінка доказів.`,
  },
  {
    content: `1. Третейський суд оцінює докази за своїм внутрішнім переконанням, що ґрунтується на всебічному, повному, об'єктивному і безпосередньому дослідженні наявних у справі доказів.
2. Кожний доказ підлягає оцінці Третейським судом поряд з іншими доказами.
3. Жодні докази не мають для Третейського суду заздалегідь встановленої сили. Третейський суд оцінює належність, допустимість, достовірність кожного доказу окремо, а також вірогідність і взаємний зв’язок доказів у їх сукупності.`,
  },
  {
    bold: true,
    content: `Стаття 40. Письмові докази.`,
  },
  {
    content: `1. Письмовими доказами є будь-які документи, акти, довідки, листування службового або особистого характеру або витяги з них, виконані у формі цифрового, графічного запису або іншим шляхом, що дозволяє встановити достовірність документа.
2. Документи, отримані через факсимільний, електронний або інший зв'язок, а також документ, підписані електронним цифровим підписом або іншим аналогом власноручного підпису, допускаються в якості письмових доказів у випадках і в порядку, встановлених законом, іншим нормативно - правовим актом або договором.
3. До письмових документів, що надаються Третейському суду, виконаних повністю або в частині іноземною мовою, повинні бути додані їх належним чином завірені переклади на українську мову.
4. Документ отриманий в іноземній державі, визнається в Третейському суді письмовим доказом, якщо він легалізований у встановленому порядку.
5. Іноземні офіційні документи визнаються у Третейському суді письмовими доказами без їх легалізації у випадках, передбачених міжнародними договорами України.
6. Оригінали документів сторін, що є у справі, за заявами осіб, що їх представляють, можуть бути повернуті їм після прийняття рішення, якщо ці документи не підлягають передачі іншій особі. Одночасно з заявами зазначені особи надають належним чином завірені копії документів або заявляють клопотання про засвідчення Третейським судом вірності копій, що залишаються у справі.
7. Якщо Третейський суд дійде висновку, що повернення оригіналів документів не нанесе шкоди правильному розгляду справи, ці документи можуть бути повернуті в процесі провадження у справі до винесення рішення.`,
  },
  {
    bold: true,
    content: `Стаття 41. Речові докази.`,
  },
  {
    content: `1. Речовими доказами є предмети матеріального світу, що містять інформацію про обставини, які мають значення для справи.
2. Речовими доказами є також магнітні, електронні та інші носії інформації, що містять аудіовізуальну інформацію про обставини, що мають значення для справи.`,
  },
  {
    bold: true,
    content: `Стаття 42. Зберігання речових доказів.`,
  },
  {
    content: `1. Речові докази зберігаються за місцем їх знаходження. Вони повинні бути детально описані, опечатані, а у разі необхідності зняті на фото - чи відеоплівку.
2. Речові докази можуть зберігатися за місцезнаходженням Третейського суду, якщо Третейський суд визнає це за необхідне.
3. Витрати на зберігання речових доказів розподіляються між сторонами у відповідності з правилами, встановленими цим Регламентом.
4. Третейський суд і зберігач вживають заходів по зберіганню речових доказів у незмінному стані.`,
  },
  {
    bold: true,
    content: `Стаття 43. Огляд і дослідження письмових, речових доказів за місцем їх знаходження.`,
  },
  {
    content: `1. Третейський суд може провести огляд і дослідження письмових, речових за їх місцезнаходженням, у разі неможливості їх доставки в Третейський суд. Про проведення огляду і дослідженню письмових, речових доказів за їх місцезнаходженням виноситься ухвала.
2. Про дату, час і місце огляду доказів за їх місцезнаходженням повідомляються учасники справи. Неявка цих осіб не є перешкодою для проведення огляду.
3. Про огляд доказів за їх місцезнаходженням складається протокол, що підписується всіма особами, які беруть участь в огляді. До протоколу додаються разом з описом усі складені або звірені під час огляду на місці плани, креслення, копії документів, а також зроблені під час огляду фотознімки, електронні копії доказів, відеозаписи тощо.
4. Якщо огляд проводиться за відсутності хоча б однієї із сторін, а також в інших випадках, коли Третейський суд визнає це за необхідне, здійснюється відеофіксація огляду технічними засобами.
5. Особи, які беруть участь в огляді доказів за їх місцезнаходженням, мають право звертати увагу Третейського суду на ту чи іншу обставину, яка, на їх погляд, має значення для повноцінного проведення огляду, встановлення обставин, які мають значення для розгляду справи, робити свої зауваження щодо протоколу огляду.`,
  },
  {
    bold: true,
    content: `Стаття 44. Розпорядження речовими доказами, що знаходяться у Третейському суді.`,
  },
  {
    content: `1. Речові докази, що знаходяться у Третейському суді, після їх огляду і дослідження Третейським судом повертаються особам, від яких вони були отримані, якщо вони не підлягають передачі іншим особам.
2. Речі, які згідно закону не можуть знаходитись у користуванні окремих осіб, передаються відповідним організаціям.
3. З питань розпорядження речовими доказами Третейський суд виносить ухвалу.`,
  },
  {
    bold: true,
    content: `Стаття 45. Пояснення осіб, які приймають участь у справі.`,
  },
  {
    content: `1. Особа, яка приймає участь у справі, надає Третейському суду свої пояснення про відомі їй обставини, які мають значення для справи, в письмовій або усній формі. На вимогою Третейського суду особа, яка приймає участь у справі, має викласти свої пояснення у письмовій формі.
2. Пояснення, викладені у письмовій формі, додаються до матеріалів справи.
3. Після оголошення пояснень, викладених у письмовій формі, особа, яка надала це пояснення, має право дати відносно нього необхідні пояснення, а також зобов'язана відповісти на питання інших осіб, які приймають участь у справі, Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 46. Призначення експертизи.`,
  },
  {
    content: `1. З метою правильного вирішення спору Третейський суд має право зобов'язати сторони чи одну із сторін замовити проведення експертизи для роз'яснення питань, що потребують спеціальних знань, про що Третейський суд виносить відповідну ухвалу.
2. У разі ухилення позивача від замовлення проведення експертизи, призначеної Третейським судом, і при неможливості розглянути справу через дослідження інших доказів у справі, позовна заява залишається без розгляду, про що виноситься ухвала.
3. У разі ухилення відповідача від замовлення проведення експертизи, призначеної Третейським судом, Третейський суд може зобов'язати замовити проведення експертизи позивача, або розглянути справу без проведення експертизи дослідивши інші докази у справі.
4. Перелік і зміст питань, з яких повинна бути проведена експертиза, визначається в ухвалі Третейського суду. Учасники справи мають право запропонувати Третейському суду питання, роз’яснення яких, на їхню думку, потребує висновку експерта. У разі відхилення або зміни питань, запропонованих учасниками справи, Третейський суд зобов’язаний мотивувати таке відхилення або зміну.
5. Питання, які ставляться експерту, і його висновок з них не можуть виходити за межі спеціальних знань експерта.
6. В ухвалі про зобов'язання замовлення проведення експертизи зазначаються підстави для призначення експертизи; прізвище, ім'я і по-батькові експерта або найменування експертної установи, в якій повинна бути проведена експертиза: питання, поставлені перед експертом: матеріали і документи, надані в розпорядження експерта; строк, на протязі якого повинна бути проведена експертиза і повинно бути надано висновок Третейському суду.
7. Висновок експерта оголошується у засіданні Третейського суду і досліджується поряд з іншими доказами у справі. За клопотанням особи, яка приймає участь у справі, або за ініціативою Третейського суду експерт може бути викликаний у засідання Третейського суду. Експерт після оголошення його висновку має право дати по ньому необхідні пояснення, а також відповісти на додаткові питання осіб, які приймають участь у справі, і Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 47. Показання свідків.`,
  },
  {
    content: `1. За клопотанням особи, яка приймає участь у справі, Третейський суд може викликати свідка для надання пояснень по справі. Особа, яка заявляє клопотання про виклик свідка, зобов'язана зазначити, які обставини, що мають значення для справи, може підтвердити свідок, і повідомити суду його прізвище, ім'я, по-батькові і місце проживання.
2. Третейський суд за власною ініціативою може викликати у якості свідка особу, яка приймала участь у складанні документа, що досліджується Третейським судом як письмовий доказ, або у створенні або зміні предмета, що досліджується Третейським судом як речовий доказ.
3. За пропозицією Третейського суду свідок може викласти показання, що надані усно, у письмовій формі. Показання свідка, що викладені у письмовій формі, які приєднуються до матеріалів справи.
4. Не є доказами відомості, які повідомлені свідком, якщо він не може зазначити джерело повідомлених ним відомостей.`,
  },
  {
    bold: true,
    content: `Глава 6. Витрати, пов'язані з вирішенням спору Третейським судом
Стаття 48. Склад витрат, пов'язаних з вирішенням спору Третейським судом.`,
  },
  {
    content: `1. До складу витрат, пов'язаних з вирішенням спору Третейським судом, належать:
1) гонорари третейських суддів;
2) третейський збір, розмір, строки та порядок сплати якого сторонами визначаються цим Регламентом;
3) витрати, понесені третейськими суддями у зв'язку з участю в третейському розгляді, в тому числі витрати, понесені третейськими суддями на оплату проїзду до місця вирішення спору;
4) витрати, пов'язані з оплатою послуг експертів, перекладачів, якщо такі були запрошені чи призначені для участі в третейському розгляді;
5) витрати, пов'язані з оглядом і дослідженням речових та письмових доказів у їх місцезнаходженні;
6) витрати, понесені свідками;
7) витрати, пов'язані з оплатою стороною, на користь якої було прийнято рішення Третейського суду, послуг представника, пов'язаних із наданням правової допомоги;
8) витрати, пов'язані з організаційним забезпеченням третейського розгляду;
9) витрати на листування Третейського суду;
10) витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок;
11) гонорар секретаря Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 49. Третейський збір.`,
  },
  {
    content: `1. При поданні позовної заяви позивач зобов'язаний сплатити третейський збір.
2. До сплати третейського збору третейський розгляд за позовом не здійснюється, крім випадку надання відстрочення сплати третейського збору.
3. Якщо третейський збір не сплачується на протязі місячного строку з моменту подання позовної заяви позовна заява повертається без розгляду, про що головою Третейського суду, його заступником, у відсутність голови Третейського суду, виноситься ухвала, яка не підлягає оскарженню.
4. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений, сплачений третейський збір повертається позивачу, крім випадків, якщо це було зумовлено відводом усіх суддів, укладанням сторонами мирової угоди, відмовою позивача від позову та іншими обставинами, передбаченими Законом України "Про третейські суди".
5. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений з підстав, зазначених у пункті 4 статті 84 цього Регламенту, то сторони відшкодовують Третейському суду всі понесені ним витрати, про що зазначається в ухвалі Третейського суду.
6. Голова Третейського суду, його заступник, у відсутність голови Третейського суду, може надати відстрочення, а також зменшити або збільшити розмір третейського збору, про що вказується у розпорядженні, яке не підлягає оскарженню.`,
  },
  {
    bold: true,
    content: `Стаття 50. Витрати, які здійснюються Третейським судом з наступним стягненням з сторін спору.`,
  },
  {
    content: `1. Розподіл витрат, пов'язаних з організаційним забезпеченням третейського розгляду, витрати на листування Третейського суду, витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок, витрати, пов’язані з оплатою гонорару секретаря Третейського суду, здійснюються Третейським судом з подальшим стягненням зі сторони спору, у порядку, визначеному ст.52 цього Регламенту.`,
  },
  {
    bold: true,
    content: `Стаття 51. Покриття витрат, які не включаються до складу третейського збору.`,
  },
  {
    content: `1. Третейський суд може покласти на сторони або на одну з них обов'язок внести аванс на покриття витрат, які не включаються до складу третейського збору.
2. Аванс на покриття витрат, які не включаються до складу третейського збору може бути, витребуваний Третейським судом від сторони, яка заявила про необхідність здійснення дії, що приводить або може привести до цих витрат.
3. У разі участі у третейському розгляді третейського судді, який має місцеперебування поза місцем проведення засідання Третейського суду, позивач повинен внести аванс на оплату витрат його участі у третейському розгляді (по проїзду, проживанню тощо).
4. Якщо на прохання однієї з сторін при розгляді справи здійснюється переклад пояснень сторін, їх заяв тощо, а також питань, вказівок, ухвал, рішень Третейського суду, то витрати по перекладу оплачуються зазначеною стороною.`,
  },
  {
    bold: true,
    content: `Стаття 52. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом.`,
  },
  {
    content: `1. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом здійснюється Третейським судом у такому порядку:
- при задоволенні позову витрати покладаються на відповідача; при відмові в позові - на позивача;
- при частковому задоволенні позову - на обидві сторони пропорційно розміру задоволених вимог.
2. Порядок розподілу витрат, пов'язаних з вирішенням спору Третейським судом, зазначається у рішенні або ухвалі Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 53. Розміри третейського збору.`,
  },
  {
    content: `1. Третейський збір справляється у відсотковому співвідношенні до ціни позову та у фіксованому розмірі у відповідному розмірі прожиткового мінімуму для працездатних осіб, встановленого законом на період, в якому відповідна позовна заява подається до Третейського суду.
2. Розмір третейського збору за подання до Третейського суду:
- позовної заяви майнового характеру - 1 відсоток ціни позову, але не менше 1 розміру прожиткового мінімуму для працездатних осіб.
- позовної заяви немайнового характеру – 1 розмір прожиткового мінімуму для працездатних осіб.
3. За позовні заяви, що містять одночасно вимоги майнового і не майнового характеру, сплачується одночасно третейський збір, встановлений для позовних заяв майнового характеру і для позовних заяв немайнового характеру.
4. При зменшенні ціни позову сплачений третейський збір не повертається.`,
  },
  {
    bold: true,
    content: `Стаття 54. Порядок сплати третейських зборів та інших витрат.`,
  },
  {
    content: `1. Суми третейських зборів та інших витрат, що підлягають сплаті Третейському суду, вважаються сплаченими у день їх зарахування на поточний рахунок Асоціації "ПРАВОВА ЛІГА".
2. При сплаті третейського збору у платіжному дорученні у графі "Призначення платежу" повинно бути зазначено: "Третейський збір за розгляд спору між "....." (найменування позивача та відповідача), а також сума позову. Платіжне доручення з відміткою банку про перерахування третейського збору залучаться до матеріалів справи.`,
  },
  {
    bold: true,
    content: `Глава 7. Гонорари третейських суддів та порядок їх виплати
Стаття 55. Гонорари третейських суддів, порядок їх виплати.`,
  },
  {
    content: `1. Третейському судді, який приймає участь третейському розгляді, після закінчення розгляду конкретної справи сплачується гонорар у розмірі 30 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.
2. Якщо справа розглядається складом Третейського суду у кількості трьох третейських суддів, цим третейським суддям після закінчення розгляду конкретної справи сплачуються гонорари у таких розмірах: головуючому складу Третейського суду 12 відсотків, двом іншим третейським суддям складу Третейського суду по 9 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.
3. Гонорари третейським суддям сплачуються Асоціацією "ПРАВОВА ЛІГА" на поточні рахунки третейських суддів у банківських установах. Реквізити банківських рахунків повідомляються кожним третейським суддею Третейському суду у письмовій формі. Для виплати гонорару Асоціація "ПРАВОВА ЛІГА" може вимагати від третейського судді надання додаткових документів (копію довідки про ідентифікаційний номер, копію паспорту, тощо).
4. За заявою третейського судді, викладеної письмово, розгляд справи може здійснюватись безоплатно.`,
  },
  {
    bold: true,
    content: `Глава 8. Формування третейського суду
Стаття 56. Склад третейського суду.`,
  },
  {
    content: `1. Третейським суддею, який розглядає справу одноособово, третейськими суддями у разі розгляду справи у складі трьох третейських суддів, можуть бути призначені особи із списку третейських суддів Третейського суду за їх згодою, які прямо чи опосередковано не заінтересовані в результаті вирішення спору, а також мають необхідний досвід, ділові та моральні якості, необхідні для вирішення спору.`,
  },
  {
    bold: true,
    content: `Стаття 57. Формування складу третейського суду.`,
  },
  {
    content: `1. Якщо сторони не домовились про те, що справа розглядається третейським судом у складі трьох третейських суддів, справа підлягає розгляду третейським суддею одноособово.
2. Третейський суддя, який розглядає справу одноособово, призначається за його згодою головою Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.
3. При розгляді справи у складі трьох третейських суддів головуючого складу третейського суду та третейських суддів призначає за їх згодою голова Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.
4. Після формування складу третейського суду, голова Третейського суду, його заступником, у відсутність голови Третейського суду, передає справу головуючому складу третейського суду або третейському судді, який розглядає справу одноособово.
5. Призначення третейського судді або складу третейських суддів, а також передача справи головуючому складу третейського суду або третейському судді, який розглядає справу одноособово, оформляється розпорядженням голови Третейського суду, його заступника, у відсутність голови Третейського суду, яке є остаточним і не підлягає оскарженню.`,
  },
  {
    bold: true,
    content: `Стаття 58. Повідомлення сторін про порушення справи.`,
  },
  {
    content: `1. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду постановляє ухвалу про порушення справи, про що повідомляються сторони, і пропонує відповідачу у строк не більше ніж 5 днів з дня отримання ухвали про порушення справи надати Третейському суду свій відзив на позов, що підкріплений відповідними доказами.
2. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду може продовжити строк для надання відповідачем відзиву на позов при надходженні відповідного клопотання. За результатами розгляду відповідного клопотання виноситься ухвала, яка не підлягає оскарженню.`,
  },
  {
    bold: true,
    content: `Стаття 59. Припинення повноважень третейського судді, складу третейського суду, заміна третейського судді.`,
  },
  {
    content: `1. Повноваження третейського судді припиняються:
1) за погодженням сторін;
2) у разі відводу чи самовідводу третейського судді відповідно до цього Регламенту;
3) у разі набрання законної сили обвинувальним вироком суду щодо нього;
4) у разі набрання законної сили судовим рішенням про визнання його обмежено дієздатним чи недієздатним;
5) у разі смерті, визнання його безвісно відсутнім або оголошення померлим рішенням суду, що набрало законної сили.
2. Повноваження складу третейського суду припиняються після ухвалення ним рішення у справі.`,
  },
  {
    bold: true,
    content: `Стаття 60. Поновлення повноважень третейського суду.`,
  },
  {
    content: `1. Повноваження Третейського суду поновлюються у разі виникнення потреби в:
1) прийнятті додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні, – за заявою сторони;
2) роз’ясненні резолютивної частини рішення – за заявою сторони;
3) виправленні у рішенні описок, арифметичних помилок або будь-яких інших неточностей – з власної ініціативи Третейського суду або за заявою сторони.
2. Після виконання зазначених у цій статті процесуальних дій повноваження Третейського суду припиняються.`,
  },
  {
    bold: true,
    content: `Стаття 61. Підстави відводу чи самовідводу третейського судді.`,
  },
  {
    content: `1. Третейський суддя не може брати участі у розгляді справи, а після його призначення підлягає відводу чи самовідводу:
- якщо він особисто чи опосередковано заінтересований у результаті розгляду справи;
- якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках;
- на його прохання або за спільним рішенням сторін;
- у разі встановлення стороною обставин, які дають їй підстави вважати упередженим або необ'єктивним ставлення третейського судді до справи, про яке сторона дізналася після його призначення;
- у разі тривалого, більш як один місяць від дня призначення, невиконання ним обов'язків третейського судді у конкретній справі;
- у разі виявлення невідповідності третейського судді вимогам, встановленим статтею 18 Закону України "Про третейські суди";
- якщо третейський суддя бере участь у вирішенні спору, який прямо чи опосередковано пов'язаний з виконанням ним службових повноважень, наданих державою.
2. Жодна особа не може бути третейським суддею у справі, в якій вона раніше брала участь як третейський суддя, але була відведена чи заявила самовідвід, як сторона, представник сторони або в будь-якій іншій якості.`,
  },
  {
    bold: true,
    content: `Стаття 62. Порядок відводу чи самовідводу третейського судді.`,
  },
  {
    content: `1. У разі звернення голови Третейського суду, його заступника, у відсутність голови Третейського суду, до особи зі списку третейських суддів Третейського суду за отриманням згоди на призначення її третейським суддею у конкретній справі, ця особа повинна повідомити про наявність обставин, які є підставами для відводу чи самовідводу відповідно до статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту.
2. Третейський суддя повинен без зволікання повідомити сторони про підстави його відводу чи самовідводу, зазначені у статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту, що виникли після початку третейського розгляду, та заявити самовідвід.
3. Письмова мотивована заява сторони про відвід третейського судді, з доказом надіслання її іншій стороні, має бути подана стороною до Третейського суду протягом п’яти календарних днів після того, як цій стороні стали відомі обставини, які є підставою для відводу третейського судді відповідно до Закону "Про третейські суди" та цього Регламенту.
4. У разі пропуску встановленого строку, питання про прийняття заяви про відвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, залежно від причин пропуску строку. У випадку відмови у прийнятті заяви про відвід третейського судді голова Третейського суду, його заступник, у відсутність голови Третейського суду, виносить ухвалу, яка є остаточною і не підлягає оскарженню.
5. Якщо відвід заявляється повторно з підстав, розглянутих раніше, така заява залишається без розгляду.
6. Третейський суд зобов’язаний надати іншій стороні можливість прокоментувати заяву про відвід третейського судді.
7. У разі коли інша сторона не погоджується з відводом третейського судді, вона має право протягом п’яти календарних днів з дня отримання копії заяви про відвід третейського судді, подати до Третейського суду свої мотивовані заперечення. У цьому випадку питання про відвід вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, а у разі розгляду справи у кількості трьох третейських суддів головою Третейського суду, його заступником, у відсутність голови Третейського суду, спільно з іншими третейськими суддями, призначеними у справі, рішення якого (яких) є обов'язковим для сторін. За результатами розгляду заяви про відвід третейського судді виноситься ухвала. Питання про відвід, яке заявлене голові Третейського суду, який здійснює розгляд справи як третейський суддя, вирішується заступником голови Третейського суду у порядку, визначеному цим Регламентом.
8. Питання про відвід вирішується без повідомлення учасників справи. За ініціативою голови Третейського суду, його заступника, у відсутність голови Третейського суду, питання про відвід може вирішуватися у засіданні третейського суду з повідомленням учасників справи. Неявка учасників справи у засідання третейського суду, в якому вирішується питання про відвід, не перешкоджає розгляду питання про відвід.
9. У разі задоволення заяви про відвід третейського судді, який розглядає справу одноособово, справа розглядається в Третейському суді іншим третейським суддею, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.
10. У разі задоволення заяви про відвід комусь із третейських суддів або всьому складу суду, у разі колегіального вирішення спору, справа розглядається в Третейському суді тим самим кількісним складом колегії третейських суддів без участі відведеного третейського судді або іншим складом третейських суддів, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.
11. Питання про самовідвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, на підставі мотивованої заяви третейського судді.
12. Під час розгляду заяви про відвід Третейський суд, включаючи третейського суддю, якому заявлено відвід, продовжує третейський розгляд. Третейський суд не може ухвалити рішення доти, поки голова Третейського суду, його заступник, у відсутність голови Третейського суду, не постановить ухвалу за підсумками розгляду згаданої заяви.
13. Самовідвід третейського судді або згода сторони на припинення його повноважень не повинна розцінюватися як визнання залежності та упередженості третейського судді.`,
  },
  {
    bold: true,
    content: `Стаття 63. Підстави та порядок відводу чи самовідводу експерта, перекладача.`,
  },
  {
    content: `1. Якщо експерт, перекладач особисто чи опосередковано заінтересовані у результаті розгляду справи, або якщо вони є родичами однієї із сторін або інших осіб, які беруть участь у справі, або перебувають з цими особами чи сторонами в особливих стосунках вони підлягають відводу чи самовідводу.
2. Питання про відвід експерта, перекладача вирішується складом третейським суддею, який розглядає справу одноособово, а у разі колегіального вирішення спору - складом третейських суддів.`,
  },
  {
    bold: true,
    content: `Глава 9. Пред'явлення позову
Стаття 64. Пред'явлення позову.`,
  },
  {
    content: `1. Провадження у справі порушується поданням позовної заяви.
2. Позовна заява подається у Третейський суд у письмовій формі і підписується керівником юридичної особи або іншою особою, яка має право діяти без довіреності від імені позивача, або фізичною особою-підприємцем, або фізичною особою, або уповноваженим довіреністю представником позивача.
3. Датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата отримання поштового відправлення представником Третейського суду, при відправленні позовної заяви через електронну приймальню – дата, зазначена в електронному повідомленні про отримання.
При подачі позовної заяви через електронну приймальню, її оригінал має бути поданий позивачем в першому засіданні третейського суду.
4. У разі якщо сторона подає позовну заяву у зв'язку з правовідношенням, яке вже є предметом третейського розгляду між тими ж сторонами у відповідності з цим Регламентом, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за заявою будь-якої з сторін може об'єднати вимоги, що містяться у різних позовних заявах в одне провадження.`,
  },
  {
    bold: true,
    content: `Стаття 65. Зміст позовної заяви.`,
  },
  {
    content: `1. Позовна заява подається у письмовій формі.
2. У позовній заяві, що подається до Третейського суду, повинні зазначатися:
- назва Третейського суду;
- дата подання позовної заяви;
- повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові - для фізичних осіб) сторін та інших учасників справи, їх місцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб); поштовий індекс; ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), а також реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України (якщо такі відомості відомі позивачу), вказівку на статус фізичної особи - підприємця (для фізичних осіб - підприємців); відомі номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти;
- повне найменування і місцезнаходження представника позивача, якщо він є юридичною особою або прізвище, ім'я, по батькові, дата народження, місце реєстрації представника, який є фізичною особою або фізичною особою-підприємцем, у випадках, коли позов подається представником; номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти представника;
- ціна позову, якщо позов підлягає грошовій оцінці;
- зміст позовних вимог: спосіб (способи) захисту прав або інтересів, передбачений законом чи договором, або інший спосіб (способи) захисту прав та інтересів, який не суперечить закону і який позивач просить Третейський суд визначити у рішенні; якщо позов подано до кількох відповідачів - зміст позовних вимог щодо кожного з них;
- виклад обставин, якими позивач обґрунтовує свої вимоги; зазначення доказів, що підтверджують вказані обставини; правові підстави позову;
- посилання на наявність Третейської угоди між сторонами та докази її укладення;
- перелік письмових матеріалів, які додаються до позовної заяви;
- підпис позивача або його представника з посиланням на документ, що засвідчує повноваження представника.
3. У позовній заяві можуть бути вказані й інші відомості, необхідні для правильного вирішення спору.`,
  },
  {
    bold: true,
    content: `Стаття 66. Документи, що додаються до позовної заяви.`,
  },
  {
    content: `1. До позовної заяви додаються документи, які підтверджують:
1) наявність Третейської угоди;
2) відправлення іншим учасникам справи копії позовної заяви і доданих до неї документів;
3) обґрунтованість позовних вимог;
4) сплату третейського збору у встановленому цим Регламентом порядку і розмірі.
2. До позовної заяви, підписаної представником позивача, додається довіреність чи інший документ, що підтверджує повноваження представника позивача.`,
  },
  {
    bold: true,
    content: `Стаття 67. Ціна позову.`,
  },
  {
    content: `1. Ціна позву визначається:
1) у позовах про стягнення грошових коштів - сумою, яка стягується;
2) у позовах про визнання права власності на майно або його витребування – дійсною вартістю майна;
3) у позовах про розірвання договору найму (оренди) - сукупністю платежів за користування майном протягом строку, що залишається до кінця дії договору;
4) у позовах, які складаються з кількох самостійних вимог, - загальною сумою всіх вимог.
2. Якщо позивач не визначив або неправильно визначив ціну позову, або визначена позивачем ціна позову вочевидь не відповідає дійсній вартості спірного майна або на момент пред’явлення позову встановити точну його ціну неможливо, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за власною ініціативою визначає ціну позову на підставі наявних відомостей та виносить відповідну ухвалу з зазначенім строків для сплати третейського збору.`,
  },
  {
    bold: true,
    content: `Стаття 68. Прийняття позовної заяви і порушення провадження у справі.`,
  },
  {
    content: `1. Питання про прийняття позовної заяви до провадження Третейського суду вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, у триденний строк з дня отримання позовної заяви Третейським судом.
2. При прийнятті позову Третейський суд вирішує питання про наявність і дійсність угоди про передачу спору на розгляд Третейського суду.
3. У разі, якщо Третейський суд дійде висновку про відсутність або недійсність зазначеної угоди, він відмовляє у розгляді справи. Про відмову у розгляді справи виноситься мотивована ухвала, яка надсилається сторонам. При цьому заявнику разом з ухвалою повертаються позовні матеріали.
4. Третейський суд приймає до провадження позовну заяву, подану з додержанням вимог цього Регламенту до його форми і змісту.
5. Про прийняття позовної заяви Третейський суд постановляє ухвалу, якою порушується провадження у справі.
6. В ухвалі зазначається на підготовку справи до третейського розгляду, дії, які належить виконати особам, які приймають участь у справі, і строки їх виконання.
7. Ухвала про порушення провадження у справі направляються особам, які приймають участь у справі, не пізніше трьох днів після дня її винесення.`,
  },
  {
    bold: true,
    content: `Стаття 69. Усунення недоліків позовної заяви.`,
  },
  {
    content: `1. Встановивши, що позовна заява подана без додержання вимог цього Регламенту, голова Третейського суду, його заступник, у відсутність голови Третейського суду, пропонує позивачу усунути виявлені недоліки.
2. Якщо ці недоліки будуть усунуті, то датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата штемпеля поштового відомства адресата.`,
  },
  {
    bold: true,
    content: `Стаття 70. Відзив на позовну заяву.`,
  },
  {
    content: `1. Відповідач повинен надати Третейському суду письмовий відзив на позовну заяву. Відзив на позовну заяву направляється Третейському суду по пошті, шляхом вручення або через електронну приймальню не менше ніж за три дні до першого засідання Третейського суду.
2. У випадку подачі відзиву на позовну заяву через електронну приймальню, його оригінал подається відповідачем в першому засіданні.
3. У відзиві відповідач викладає заперечення проти позову.
4. Відзив підписується відповідачем або його представником.
5. Відзив повинен містити:
1) найменування (ім’я) позивача і номер справи;
2) повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові) (для фізичних осіб) відповідача, його місцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб), поштовий індекс, ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України, номери засобів зв’язку, офіційну електронну адресу та адресу електронної пошти, за наявності;
3) у разі повного або часткового визнання позовних вимог - вимоги, які визнаються відповідачем;
4) обставини, які визнаються відповідачем, а також правову оцінку обставин, наданих позивачем, з якою відповідач погоджується;
5) заперечення (за наявності) щодо наведених позивачем обставин та правових підстав позову, з якими відповідач не погоджується, із посиланням на відповідні докази та норми права;
6) перелік документів та інших доказів, що додаються до відзиву, та зазначення документів і доказів, які не можуть бути подані разом із відзивом, із зазначенням причин їх неподання.
6. Копія відзиву та доданих до нього документів іншим учасникам справи повинна бути надіслана (надана) одночасно з надісланням (наданням) відзиву до Третейського суду.
7. До відзиву додаються:
1) докази, які підтверджують обставини, на яких ґрунтуються заперечення відповідача, якщо такі докази не надані позивачем;
2) документи, що підтверджують надіслання (надання) відзиву і доданих до нього доказів іншим учасникам справи.
8. До відзиву, підписаного представником відповідача, додається довіреність чи інший документ, що підтверджує повноваження представника відповідача.
9. У разі ненадання відповідачем відзиву у встановлений Третейським судом строк без поважних причин, Третейський суд вирішує справу за наявними матеріалами.`,
  },
  {
    bold: true,
    content: `Стаття 71. Зустрічний позов.`,
  },
  {
    content: `1. Відповідач вправі подати зустрічний позов для розгляду Третейським судом, якщо такий позов є підвідомчим Третейському суду та може бути предметом третейського розгляду відповідно до Третейської угоди.
2. Зустрічний позов може бути подано на будь-якій стадії третейського розгляду до прийняття рішення по справі.
3. Третейський суд приймає зустрічний позов до спільного розгляду з первісним позовом, якщо обидва позови взаємно пов'язані та їх спільний розгляд є доцільним, зокрема, коли вони випливають з одних і тих самих правовідносин або коли задоволення зустрічного позову може виключити повністю або частково задоволення первісного позову.
4. Зустрічна позовна заява має відповідати вимогам статті 35 Закону України "Про третейські суди" та статтям 65, 66 цього Регламенту.
5. Зустрічна позовна заява, подана з порушенням вимог цієї статті, ухвалою Третейського суду повертається заявнику. Копія зустрічної позовної заяви долучається до матеріалів справи.
6. Сторона зобов'язана надати відзив на пред'явлений до неї зустрічний позов у порядку та строки, визначені Третейським судом.`,
  },
  {
    bold: true,
    content: `Стаття 72. Забезпечення позову.`,
  },
  {
    content: `1. Якщо сторони не домовилися про інше, Третейський суд може за заявою будь-якої сторони розпорядитися про вжиття стороною таких забезпечувальних заходів щодо предмета спору, які він вважає необхідними, з урахуванням положень цивільного та господарського процесуального законодавства. Третейський суд може витребувати від будь-якої сторони надати належне забезпечення позову у зв'язку з такими заходами.`,
  },
  {
    bold: true,
    content: `Глава 10. Підготовка до третейського розгляду
Стаття 73. Підготовка розгляду справи.`,
  },
  {
    content: `1. Склад Третейського суду перевіряє стан підготовки справи до розгляду і, якщо визнає за необхідне, вживає додаткові заходи по підготовці справи.
2. Якщо склад Третейського суду приймає додаткові заходи по підготовці справи, він повинен встановити строки, на протязі яких ці додаткові заходи повинні бути виконані.
3. Головуючий складу Третейського суду, або третейський суддя, який розглядає справу одноособово, може давати відповідальному секретарю Третейського суду окремі доручення у зв'язку з підготовкою та проведенням розгляду справи. Він також доручає йому виклик сторін на засідання.
4. З метою правильного і своєчасного вирішення спору третейський суддя вчиняє в необхідних випадках такі дії по підготовці справи до розгляду:
- вирішує питання про залучення до участі у справі іншого відповідача та про виключення чи заміну неналежного відповідача;
- викликає представників сторін для уточнення обставин справи і з’ясовує, які матеріали може бути подано додатково;
- зобов’язує сторони, інших осіб, що приймають участь у справі, виконати певні дії (звірити розрахунки, надати документи, відомості, висновки, необхідні для вирішення спору, тощо);
- вирішує питання про призначення експертизи;
- вирішує питання про визнання явки представників сторін у засідання Третейського суду обов’язковою;
- вирішує питання про виклик посадових та інших осіб для дачі пояснень по суті справи;
- вирішує питання про вжиття заходів до забезпечення позову;
- вчиняє інші дії, спрямовані на забезпечення правильного і своєчасного розгляду справи.`,
  },
  {
    bold: true,
    content: `Стаття 74. Подання та надсилання документів щодо третейського розгляду.`,
  },
  {
    content: `1. Усі документи, що подаються однією із сторін до Третейського суду, повинні бути надіслані або передані іншій стороні з дотриманням правил, передбачених цією статтею. Сторонам також повинні бути надіслані або передані інші документи і докази, на яких може ґрунтуватися рішення третейського суду.
2. Своєчасне надсилання або передача сторонам усіх документів у справі забезпечуються третейським суддею.
3. Третейський суд повідомляє сторони та інших осіб, які беруть участь у третейському розгляді, про день, час та місце проведення засідання Третейського суду.
4. Листування здійснюється за офіційним місцезнаходженням юридичної особи або фізичної особи – підприємця згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань або за місцем проживання фізичної особи.
5. Сторони зобов’язані невідкладно повідомити Третейський суд про зміну своєї адреси у спосіб, що передбачає фіксацію надходження такого повідомлення.
У разі відсутності заяви про зміну місця проживання або місцезнаходження документи надсилаються сторонам справи на останню відому Третейському суду адресу і вважається доставленою, навіть якщо сторона процесу за цією адресою більше не проживає або не знаходиться.
Позовна заява, відзив на позов, інші заяви, клопотання та заперечення з процесуальних питань, судове повідомлення, рішення, ухвала Третейського суду, надсилаються сторонам рекомендованим листом з повідомленням про вручення або вручаються їх представникам особисто під розписку.
6. Документи можуть надсилатися будь-яким поштовим оператором за умови дотримання вимог цієї статті.
7. Днем вручення документів, пов’язаних з розглядом справи, є:
- день вручення документів під розписку;
- день отримання повідомлення про доставлення судової повістки на електронну адресу особи;
- день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, повідомленою цією особою Третейському суду;
- день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, що зареєстровані у встановленому законом порядку, якщо ця особа не повідомила Третейському суду іншої адреси.
Якщо документи надіслано на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження, відправлення вважається врученим у робочий день, в який його було надіслано, а якщо його було надіслано пізніше 17 години, відправлення вважається врученим у робочий день, наступний за днем її відправлення, незалежно від надходження до суду повідомлення про її доставлення.`,
  },
  {
    bold: true,
    content: `Глава 11. Сприяння сторонам у досягненні ними мирової угоди
Стаття 75. Примирення сторін.`,
  },
  {
    content: `1. Третейський суд застосовує заходи для примирення сторін, сприяє їм у вирішенні спору шляхом укладення мирової угоди на всіх стадіях процесу.
2. Третейський суд на початку розгляду справи повинен з'ясувати у сторін можливість закінчити справу мировою угодою.`,
  },
  {
    bold: true,
    content: `Стаття 76. Укладення мирової угоди.`,
  },
  {
    content: `1. Сторони мають право закінчити справу укладенням мирової угоди, як до початку третейського розгляду, так і на будь-якій його стадії, до прийняття рішення.
2. Мирова угода не повинна порушувати права і законні інтереси інших осіб і суперечити закону.
3. Мирова угода затверджується Третейським судом.`,
  },
  {
    bold: true,
    content: `Стаття 77. Вирішення питання щодо затвердження мирової угоди.`,
  },
  {
    content: `1. За клопотанням сторін Третейський суд приймає рішення про затвердження мирової угоди. Мирова угоди може стосуватися лише прав і обов'язків сторін щодо предмета спору.
2. Зміст мирової угоди викладається безпосередньо в рішенні Третейського суду. До рішень третейського суду, прийнятих відповідно до цієї статті, застосовуються правила розділу VI Закону України "Про третейські суди".
3. Третейський суд не затверджує мирову угоду, якщо вона суперечить закону або порушує права і законні інтереси інших осіб.`,
  },
  {
    bold: true,
    content: `Глава 12. Зупинення провадження у справі
Стаття 78. Обов'язок Третейського суду зупинити провадження у справі.`,
  },
  {
    content: `1. Третейський суд зобов'язаний зупинити провадження у справі у випадках:
1) неможливості розгляду цієї справи до вирішення пов'язаної з нею іншої справи, що розглядається судом або відповідного питання компетентними органами;
2) злиття, приєднання, поділу, перетворення юридичної особи, яка була стороною у справі або у зв'язку зі смертю або оголошення фізичної особи померлою, яка була стороною у справі, якщо спірні правовідносини допускають правонаступництво;
3) призначення Третейським судом експертизи;
4) надсилання Третейським судом матеріалів до слідчих органів.
2. Третейський суд зупиняє провадження у справі також в інших випадках, передбачених цим Регламентом.
3. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`,
  },
  {
    bold: true,
    content: `Стаття 79. Право Третейського суду зупинити провадження у справі.`,
  },
  {
    content: `1. Третейський суд може за заявою особи, яка бере участь у справі, а також з власної ініціативи зупинити провадження у справі у випадках:
1) перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
2) захворювання сторони, підтвердженого медичною довідкою, що виключає можливість явки до суду протягом тривалого часу;
3) перебування сторони у тривалому службовому відрядженні;
4) в інших випадках, коли Третейський суд вважатиме за необхідне зупинити провадження у справі, у зв'язку з неможливістю її розгляду.
2. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`,
  },
  {
    bold: true,
    content: `Стаття 80. Строки, на які зупиняється провадження у справі.`,
  },
  {
    content: `1. Провадження у справі зупиняється у випадках, встановлених:
1) пунктом 1 частини першої статті 78 цього Регламенту - до набрання законної сили судового рішення, від якого залежить вирішення справи або рішення відповідного компетентного органу;
2) пунктом 2 частини першої статті 78 цього Регламенту - до залучення до участі у справі правонаступника чи представника;
3) пунктом 3 частини першої статті 78 цього Регламенту - на час проведення експертизи;
4) пунктом 4 частини першої статті 78 цього Регламенту - на час проведення відповідної перевірки;
5) пунктом 1 частини першої статті 79 цього Регламенту - до припинення перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
6) пунктом 2 частини першої статті 79 цього Регламенту - на час хвороби сторони;
7) пунктами 3-4 частини першої статті 79 цього Регламенту - до усунення обставин, що зумовили зупинення провадження у справі.`,
  },
  {
    bold: true,
    content: `Стаття 81. Відновлення провадження у справі.`,
  },
  {
    content: `1. Провадження у справі відновлюється ухвалою Третейського суду за заявою особи, яка бере участь у справі, або з ініціативи суду після усунення обставин, що викликали його зупинення.
2. З дня відновлення провадження у справі перебіг процесуальних строків продовжується.
3. Після відновлення провадження Третейський суд викликає сторони та інших осіб, які беруть участь у справі, і продовжує третейський розгляд.`,
  },
  {
    bold: true,
    content: `Глава 13. Залишення позовної заяви без розгляду
Стаття 82. Підстави для залишення позовної заяви без розгляду.`,
  },
  {
    content: `1. Третейський суд залишає позовну заяву без розгляду, якщо після її прийняття до розгляду встановить, що:
1) позов подано особою, яка не має процесуальної дієздатності;
2) позовну заяву не підписано або підписано особою, яка не має права підписувати її, або особою, посадове становище якої не вказано;
3) позивач без поважних причин не подав витребувані Третейським судом докази, необхідні для вирішення спору, або позивач (його представник) не з’явився у засідання Третейського суду або не повідомив про причини неявки, крім випадку, якщо від нього надійшла заява про розгляд справи за його відсутності і його нез’явлення не перешкоджає вирішенню спору;
4) спір між тими самими сторонами, про той самий предмет і з тих самих підстав розглядається в іншому суді;
5) позивач ухиляється від замовлення проведення експертизи, призначеної Третейським судом, і якщо неможливо розглянути справу за допомогою дослідження інших доказів;
6) позовна заява не відповідає вимогам цього Регламенту, або не було сплачено третейський збір, і позивач не усунув цих недоліків у встановлений Третейським судом строк;
7) позивач подав заяву про залишення позову без розгляду.`,
  },
  {
    bold: true,
    content: `Стаття 83. Порядок і наслідки залишення позовної заяви без розгляду.`,
  },
  {
    content: `1. У разі залишення позовної заяви без розгляду виноситься ухвала, в якій зазначаються підстави для залишення позовної заяви без розгляду. Копії ухвали направляються особам, які приймають участь у справі.
2. Залишення позовної заяви без розгляду не позбавляє позивача права знову звернутись у Третейський суд з позовною заявою у загальному порядку після усунення обставин, що послугували підставою для залишення заяви без розгляду.`,
  },
  {
    bold: true,
    content: `Глава 14. Припинення третейського розгляду
Стаття 84. Підстави припинення третейського розгляду.`,
  },
  {
    content: `1. Підставами для припинення третейського розгляду є такі випадки:
1) спір не підлягає вирішенню в третейських судах України;
2) відсутній предмет спору;
3) є рішення компетентного суду між тими ж сторонами, з того ж предмета і з тих самих підстав;
4) позивач відмовився від позову і відмову прийнято Третейським судом;
5) сторони уклали угоду про припинення третейського розгляду і вона затверджена Третейським судом;
6) підприємство, установу чи організацію, які є стороною третейського розгляду, ліквідовано;
7) Третейський суд є некомпетентним щодо переданого на його розгляд спору;
8) у разі смерті фізичної особи (фізичної особи-підприємця), яка була стороною у справі, якщо спірні правовідносини не допускають правонаступництва.`,
  },
  {
    bold: true,
    content: `Стаття 85. Порядок і наслідки припинення третейського розгляду.`,
  },
  {
    content: `1. Про припинення третейського розгляду Третейський суд постановляє ухвалу. В ухвалі Третейський суд зазначає підстави для припинення третейського розгляду, а також вирішує питання про розподіл між сторонами витрат. Копії ухвали направляються особам, які приймають участь у справі.
2. У разі припинення третейського розгляду повторне звернення до Третейського суду з приводу спору між тими самими сторонами, про той самий предмет і з тих самих підстав не допускається. Наявність ухвали про припинення третейського розгляду у зв'язку з прийняттям відмови позивача від позову не позбавляє відповідача в цій справі права на звернення до Третейського суду за вирішенням цього спору.`,
  },
  {
    bold: true,
    content: `Глава 15. Третейський розгляд
Стаття 86. Засідання Третейського суду.`,
  },
  {
    content: `1. Третейський розгляд здійснюється у засіданні Третейського суду за участю сторін або їх представників. Сторона має право заявити Третейському суду клопотання щодо розгляду спору без її участі.
2. Третейський суд вправі визнати явку сторін у засідання обов'язковою.
3. Третейський суддя, а при колегіальному вирішенні спору – головуючий у засіданні Третейського суду:
1) відкриває засідання Третейського суду і оголошує, яка справа підлягає розгляду;
2) перевіряє явку в засідання осіб, які приймають участь у справі, їх представників та інших учасників третейського розгляду, встановлює їх особи і перевіряє їх повноваження; встановлює, чи повідомлені належним чином особи, які не з'явились у засідання, і які є відомості про причини їх неявки;
3) з'ясовує питання щодо можливості розгляду справи;
4) оголошує склад Третейського суду, повідомляє, хто веде протокол засідання третейського розгляду, хто приймає участь в якості експерта, перекладача, і роз'яснює особам, які приймають участь у справі, їх право заявляти відводи;
5) роз'яснює особам, які приймають участь у справі, та іншим учасникам третейського процесу їх процесуальні права і обов'язки;
6) видаляє з місця третейського розгляду свідків до початку їх опитування;
7) визначає з врахуванням думки осіб, які приймають участь у справі, послідовність проведення процесуальних дій;
8) з'ясовує, чи підтримує позивач позов, чи визнає позов відповідач, чи не бажають сторони закінчить справу мировою угодою;
9) керує засіданням Третейського суду, забезпечує умови для всебічного і повного дослідження доказів і обставин справи, забезпечує розгляд заяв і клопотань осіб, які приймають участь у справі.`,
  },
  {
    bold: true,
    content: `Стаття 87. Протокол засідання Третейського суду.`,
  },
  {
    content: `1. Протокол засідання Третейського суду ведеться лише в разі наявності угоди між сторонами про ведення протоколу.
2. Для ведення протоколу за згодою третейських суддів (третейського судді) сторонами може бути призначений секретар третейського розгляду. За його відсутності третейські судді можуть обрати секретаря із складу (крім головуючого) третейського суду, яким вирішується спір.
3. У протоколі засідання третейського суду зазначаються:
1) дата та місце розгляду справи,
2) прізвища третейських суддів і секретаря;
3) суть спору;
4) найменування сторін, їхніх представників, свідків, експертів, інших осіб, які беруть участь у розгляді справи;
5) заяви та клопотання сторін;
6) вказівки та вимоги до сторін по справі, висунуті Третейським судом;
7) розклад засідань третейського суду;
8) зміст пояснень сторін та інших осіб, які беруть участь у справі;
9) подані під час третейського розгляду докази, відомості про їх огляд;
10) узгодження сторонами питання про необхідність зберігання та умови зберігання рішень Третейського суду;
11) витрати, понесені сторонами.
4. Протокол підписується секретарем та всіма третейськими суддями, які розглядали справу.`,
  },
  {
    bold: true,
    content: `Стаття 88. Підстави та порядок відводу секретаря третейського розгляду, який веде протокол.`,
  },
  {
    content: `1. Якщо секретар особисто чи опосередковано заінтересований у результаті розгляду справи, або якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках він підлягає відводу чи самовідводу.
2. Питання про відвід секретаря вирішується складом Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 89. Наслідки неподання сторонами документів, інших письмових матеріалів або неявки сторін.`,
  },
  {
    content: `1. Ненадання витребуваних Третейським судом доказів, інших письмових документів чи матеріалів, неявка у засідання Третейського суду сторін або їхніх представників, які належним чином були повідомлені про розгляд справи і проведення засідання Третейського суду, не є перешкодою для третейського розгляду і прийняті рішення, якщо причина ненадання документів або неявки сторін у засідання визнана Третейським судом неповажною.`,
  },
  {
    bold: true,
    content: `Стаття 90. Наслідки неявки в засідання експертів, свідків, перекладачів.`,
  },
  {
    content: `1. При неявці в засідання Третейського суду експертів, свідків, перекладачів, належним чином повідомлених про час і місце засідань Третейський суд виносить ухвалу про відкладення третейського розгляду, якщо сторони не заявили клопотання про розгляд справи за відсутністю зазначених осіб.`,
  },
  {
    bold: true,
    content: `Стаття 91. Відкладення засідання Третейського суду.`,
  },
  {
    content: `1. Третейський суд відкладає третейський розгляд у випадках, передбачених цим Регламентом, а також у разі неявки в засідання Третейського суду особи, яка приймає участь у справі, якщо у відношенні цієї особи у суду відсутні відомості про повідомлення її про час і місце третейського розгляду.
2. Третейський суд може відкласти третейський розгляд за клопотанням сторін.
3. У разі якщо особа, яка приймає участь у справі і повідомлена належним чином про час і місце третейського розгляду, заявила клопотання про відкладення третейського розгляду з обґрунтуванням причини неявки у засідання, Третейський суд може відкласти третейський розгляд, якщо визнає причини неявки поважними.
4. Третейський суд може відкласти третейський розгляд за клопотанням особи, яка приймає участь у справі, у зв'язку з неявкою у засідання його представника з поважної причини.
5. Третейський суд може відкласти третейський розгляд, якщо визнає, що він не може відбутися в даному засіданні, у тому числі внаслідок неявки будь-кого з осіб, які приймають участь у справі, інших учасників третейського процесу, а також при задоволенні клопотання сторони про відкладення третейського розгляду у зв'язку з необхідністю надання нею додаткових доказів, при здійсненні інших процесуальних дій.
6. При відкладенні третейського розгляду Третейський суд вправі допитати свідків, які з'явилися, якщо в засіданні присутні сторони або їх представники. Показання цих свідків оголошуються у новому засіданні Третейського суду.
7. Третейський розгляд може бути відкладено на строк, необхідний для усунення обставин, що послугували підставою для відкладення, в межах строку розгляду справи, встановленого цим Регламентом.
8. Про відкладення третейського розгляду Третейський суд виносить ухвалу.
9. Про час і місце нового засідання Третейський суд повідомляє осіб, які приймають участь у справі, та інших учасників третейського розгляду. Особи, які з'явились в засідання, повідомляються про час і місце нового засідання безпосередньо в засіданні Третейського суду під розписку.
10. Третейський розгляд в новому засіданні Третейського суду поновлюється з того моменту, з якого воно було відкладено. Повторний розгляд доказів, досліджених до відкладення третейського розгляду, не провадиться.`,
  },
  {
    bold: true,
    content: `Стаття 92. Розгляд Третейським судом заяв і клопотань осіб, які приймають участь у справі.`,
  },
  {
    content: `1. Заяви і клопотання осіб, які приймають участь у справі, розглядаються Третейським судом після того, як буде заслухана думка решти присутніх у засіданні Третейського суду осіб, які беруть участь у справі.
2. За результатами розгляду заяв і клопотань Третейський суд виносить ухвали.`,
  },
  {
    bold: true,
    content: `Стаття 93. Дослідження доказів.`,
  },
  {
    content: `1. При розгляді справи Третейський суд повинен безпосередньо дослідити докази у справі: ознайомитися з письмовими доказами, оглянути речові докази, заслухати пояснення осіб, які приймають участь у справі, показання свідків, висновки експертизи, а також оголосити такі пояснення, показання, висновки, надані у письмовій формі.
2. Особа, яка приймає участь у справі, вправі дати Третейському суду пояснення про представлені нею докази, і докази, витребувані Третейським судом за її клопотанням, а також задати питання викликаним у засідання Третейського суду експертам і свідкам. При цьому першим задає питання особа, за клопотанням якої були викликані експерти і свідки.`,
  },
  {
    bold: true,
    content: `Стаття 94. Перерва у засіданні Третейського суду.`,
  },
  {
    content: `1. Третейський суд за клопотанням особи, яка приймає участь у справі, або за власною ініціативою може оголосити перерву у засіданні Третейського суду.
2. Перерва у засіданні третейського суду може бути об'явлена на строк, що не перевищує п'яти днів.
3. Щодо перерви і час продовження засідання, зазначається в ухвалі Третейського суду.
4. Після закінчення перерви засідання Третейського суду продовжується. Повторний розгляд досліджених до перерви доказів не провадиться, у тому числі при заміні представників осіб, які приймають участь у справі.
5. Особи, які приймають участь у справі і присутні в засіданні Третейського суду до оголошення перерви, вважаються належним чином оповіщеними про час і місце засідання Третейського суду, і їх нез'явлення у засідання після закінчення перерви не є перешкодою для його продовження.`,
  },
  {
    bold: true,
    content: `Стаття 95. Проведення усного слухання.`,
  },
  {
    content: `1. Для викладення сторонами своїх позицій на підставі доказів, що надаються та для проведення усних промов сторін здійснюється усне слухання справи.
2. Слухання проводиться у закритому засіданні. З дозволу складу Третейського суду, або третейського судді, який розглядає справу одноособово, і зі згоди сторін під час слухання можуть бути присутні особи, які не приймають участі у третейському розгляді.`,
  },
  {
    bold: true,
    content: `Стаття 96. Розгляд на підставі письмових матеріалів.`,
  },
  {
    content: `1. Сторони можуть домовитись про розгляд спору на підставі тільки письмових матеріалів наданих сторонами, без проведення усного слухання.
2. Якщо надані матеріали виявляться недостатніми для розгляду спору, склад Третейського суду, або суддя, який розглядає справу одноособово, може призначити усне слухання.`,
  },
  {
    bold: true,
    content: `Стаття 97. Промови сторін.`,
  },
  {
    content: `1. Після з'ясування всіх обставин справи та перевірки їх доказами головуючий у засіданні Третейського суду з'ясовує у осіб, які приймають участь у справі, чи не бажають вони доповнити матеріали справи. За відсутністю таких заяв головуючий у засіданні оголошує дослідження доказів закінченими, і Третейський суд надає право особам, які приймають участь у справі висловити свою думку про те, як повинна бути вирішена справа, дати оцінку доказам, зробити висновки про встановлення чи не встановлення фактів, що мають значення для правильного розгляду справи, висловитись відносно правової кваліфікації спірних правовідносин з посиланням на закон, який, за їх міркуванням, регулює ці правовідносини, але лише з посиланням на обставини і докази, які були досліджені у засіданні Третейського суду.
2. Черговість виступу сторін встановлюється суддею Третейського суду, а у разі розгляду справи колегіально - складом колегії суддів.`,
  },
  {
    bold: true,
    content: `Стаття 98. Відновлення дослідження доказів.`,
  },
  {
    content: `1. У разі якщо Третейський суд під час або після промов сторін визнає за необхідне з'ясувати додаткові обставини або дослідити нові докази, Третейський суд поновлює дослідження доказів, про що виноситься ухвала.
2. Після закінчення додаткового дослідження доказів, промови сторін проводяться у загальному порядку, встановленому цим Регламентом.`,
  },
  {
    bold: true,
    content: `Стаття 99. Закінчення розгляду справи по суті.`,
  },
  {
    content: `1. Після того як Третейський суд переконається, що сторони мали достатньо можливостей для викладення своєї позиції та для подання доказів, Третейський суд оголошує про завершення третейського розгляду у частині дослідження обставин справи і переходить до прийняття рішення.`,
  },
  {
    bold: true,
    content: `Глава 16. Рішення Третейського суду
Стаття 100. Прийняття рішення Третейським судом.`,
  },
  {
    content: `1. Рішення Третейського суду приймається після дослідження усіх обставин справи третейським суддею, що одноособово розглядав справу, або більшістю голосів третейських суддів, які входять до складу Третейського суду. Рішення приймається у закритій нараді Третейського суду.
2. Рішення оголошується у засіданні Третейського суду.
3. Третейський суддя, який не згодний із прийнятим рішенням (частково або в цілому), може викласти у письмовій формі свою окрему думку.
4. Третейський суд вправі оголосити лише резолютивну частину рішення. У цьому випадку, якщо сторони не погодили строк направлення їм рішення, мотивоване рішення має бути направлене сторонам у строк, який не перевищує п'яти днів з дня оголошення резолютивної частини рішення.
5. Кожній стороні направляється по одному примірнику рішення.
6. У разі відмови сторони одержати рішення Третейського суду або її неявки без поважних причин у засідання Третейського суду, де воно оголошується, рішення вважається таким, що оголошене сторонам, про що на рішенні робиться відповідна відмітка, а копія такого рішення надсилається такій стороні.
7. Датою прийняття рішення є дата оголошення рішення у засіданні Третейського суду або оголошення його резолютивної частини.`,
  },
  {
    bold: true,
    content: `Стаття 101. Вимоги до рішення Третейського суду.`,
  },
  {
    content: `1. Рішення Третейського суду викладається у письмовій формі і підписується третейським суддею, який одноособово розглядав справу, або повним складом третейського суду, що розглядав справу, в тому числі і третейським суддею, який має окрему думку. Окрема думка третейського судді викладається письмово та додається до рішення Третейського суду.
2. Рішення Третейського суду скріплюється підписом Президента Асоціації "ПРАВОВА ЛІГА" та круглою печаткою Асоціації "ПРАВОВА ЛІГА".
3. У рішенні Третейського суду повинні бути зазначені:
1) назва Третейського суду;
2) дата прийняття рішення;
3) склад третейського суду і порядок його формування:
4) місце третейського розгляду;
5) сторони, їх представники та інші учасники третейського розгляду, що брали участь у розгляді справи третейським судом;
6) висновок про компетенцію Третейського суду, обсяг його повноважень за Третейською угодою;
7) стислий виклад позовної заяви, відзиву на позовну заяву, заяв, пояснень, клопотань сторін та їхніх представників, інших учасників третейського розгляду;
8) встановлені обставини справи, підстави виникнення спору, докази, на підставі яких прийнято рішення, зміст мирової угоди, якщо вона укладена сторонами, мотиви, з яких Третейський суд відхилив доводи, докази та заявлені під час третейського розгляду клопотання сторін;
9) висновок про задоволення позову або про відмову в позові повністю або частково по кожній із заявлених вимог;
10) норми законодавства, якими керувався Третейський суд при прийнятті рішення.
4. Висновки третейського суду, що містяться в рішенні по справі, не можуть залежати від настання або ненастання будь-яких обставин.
5. У разі задоволення позовних вимог у резолютивній частині рішення зазначаються:
1) сторона, на користь якої вирішено спір;
2) сторона, з якої за рішенням Третейського суду має бути здійснено стягнення грошових сум та/або яка зобов'язана виконати певні дії або утриматися від виконання певних дій;
3) розмір грошової суми, яка підлягає стягненню, та/або дії, які підлягають виконанню або від виконання яких сторона має утриматися за рішенням Третейського суду;
4) строк сплати коштів та/або строк і спосіб виконання таких дій;
5) порядок розподілу між сторонами витрат, пов'язаних з вирішенням спору третейським судом;
6) інші обставини, які Третейський суд вважає за необхідне зазначити.`,
  },
  {
    bold: true,
    content: `Стаття 102. Додаткове рішення.`,
  },
  {
    content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, може протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про прийняття додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні.
2. Заяву про прийняття додаткового рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.
3. За результатами розгляду заяви приймається додаткове рішення, яке є складовою частиною рішення Третейського суду, або виноситься мотивована ухвала про відмову у задоволенні заяви про прийняття додаткового рішення.`,
  },
  {
    bold: true,
    content: `Стаття 103. Роз'яснення рішення.`,
  },
  {
    content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, має право протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про роз'яснення резолютивної частини рішення.
2. Заяву про роз'яснення резолютивної частини рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.
3. За результатами розгляду заяви виноситься ухвала про роз'яснення рішення, яка є складовою частиною рішення, або мотивована ухвала про відмову у роз'ясненні рішення.
4. Здійснюючи роз'яснення резолютивної частини рішення, Третейський суд не має права змінювати зміст рішення.`,
  },
  {
    bold: true,
    content: `Стаття 104. Виправлення рішення.`,
  },
  {
    content: `1. Третейський суд у тому ж складі, з власної ініціативи або за заявою сторони третейського розгляду, може виправити у рішенні описки, арифметичні помилки або будь-які інші неточності, про що виноситься ухвала, яка є складовою частиною рішення.`,
  },
  {
    bold: true,
    content: `Стаття 105. Обов'язковість рішення Третейського суду.`,
  },
  {
    content: `1. Сторони, які передали спір на вирішення Третейського суду, зобов'язані добровільно виконати рішення Третейського суду, без будь-яких зволікань чи застережень.
2. Сторони та Третейський суд вживають усіх необхідних заходів з метою забезпечення виконання рішення Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 106. Оскарження рішення Третейського суду.`,
  },
  {
    content: `1. Рішення Третейського суду є остаточним і оскарженню не підлягає, крім випадків, передбачених Законом України "Про третейські суди".
2. Рішення Третейського суду може бути оскаржене стороною у випадках, передбачених Законом України "Про третейські суди", до компетентного суду відповідно до встановлених законом підвідомчості та підсудності справ.
3. Рішення Третейського суду може бути оскаржене та скасоване лише з таких підстав:
1) справа, по якій прийнято рішення Третейського суду, не підвідомча Третейському суду відповідно до закону;
2) рішення Третейського суду прийнято у спорі, не передбаченому третейською угодою, або цим рішенням вирішені питання, які виходять за межі Третейської угоди. Якщо рішенням Третейського суду вирішені питання, які виходять за межі Третейської угоди, то скасовано може бути лише ту частину рішення, що стосується питань, які виходять за межі Третейської угоди;
3) Третейську угоду визнано недійсною компетентним судом;
4) склад Третейського суду, яким прийнято рішення, не відповідав вимогам статей 16, 19 Закону України "Про третейські суди".
4. Заяву про скасування рішення Третейського суду може бути подано до компетентного суду протягом трьох місяців з дня прийняття рішення Третейським судом.
5. Скасування компетентним судом рішення Третейського суду не позбавляє сторони права повторно звернутися до Третейського суду, крім випадків, передбачених цією статтею.
6. У разі якщо рішення Третейського суду скасовано повністю або частково внаслідок визнання компетентним судом недійсною Третейської угоди або через те, що рішення прийнято у спорі, який не передбачений Третейською угодою, чи цим рішенням вирішені питання, що виходять за межі Третейської угоди, відповідний спір не підлягає подальшому розгляду в третейських судах.`,
  },
  {
    bold: true,
    content: `Глава 17. Ухвали Третейського суду
Стаття 107. Винесення Третейським судом ухвал.`,
  },
  {
    content: `1. З питань, що виникають у процесі розгляду справи і не стосуються суті спору, Третейський суд постановляє ухвали.`,
  },
  {
    bold: true,
    content: `Стаття 108. Зміст ухвали`,
  },
  {
    content: `1. В ухвалі повинні бути зазначені:
1) дата і місце винесення ухвали;
2) найменування Третейського суду, склад суду;
3) найменування і номер справи;
4) найменування осіб, які приймають участь у справі;
5) питання, з якого виноситься ухвала;
6) мотиви, з яких Третейський суд дійшов до висновків, прийняв або відхилив доводи осіб, які приймають участь у справі, з посиланням на закони та інші нормативно-правові акти;
7) висновок за результатами розгляду Третейським судом питання.
Ухвала, підписується третейським суддею або складом Третейського суду, який виніс цю ухвалу.`,
  },
  {
    bold: true,
    content: `Стаття 109. Направлення ухвали.`,
  },
  {
    content: `1. Копії ухвали, направляються особам, які приймають участь у справі, та іншими особам, яких вона стосується, рекомендованим листом з повідомленням про вручення або вручаються їм під розписку.
2. Копії ухвали направляються в п'ятиденний строк з дня винесення ухвали, якщо інший строк не встановлений цим Регламентом.`,
  },
  {
    bold: true,
    content: `Стаття 110. Виконання ухвали.`,
  },
  {
    content: `1. Ухвала, винесена Третейським судом, виконується негайно, якщо інше не встановлено цим Регламентом або Третейським судом.`,
  },
  {
    bold: true,
    content: `Глава 18. Інше
Стаття 111. Зберігання матеріалів справи, розглянутої Третейським судом.`,
  },
  {
    content: `1. Справа, розглянута Третейським судом, зберігається у Третейському суді протягом 10 років з дня прийняття рішення Третейського суду.`,
  },
  {
    bold: true,
    content: `Стаття 112. Публікація рішень Третейського суду.`,
  },
  {
    content: `1. Опублікування винесених Третейським судом рішень дозволяється з дозволу голови Третейського суду, його заступника, у відсутність голови Третейського суду.
2. При опублікуванні таких рішень необхідно забезпечити нерозголошення комерційної таємниці, слідкувати за тим, щоб не зазначались найменування контрагентів у правочині, найменування цінних паперів, цін, якщо інше не дозволено сторонами справи. голова Третейського суду, його заступник, у відсутність голови Третейського суду, може не допустити до опублікування й інші відомості.`,
  },
];

const regulationsContent2024 = [
  {
    right: true,
    content: `ЗАТВЕРДЖЕНО
Рішення Ради Асоціації «ПРАВОВА ЛІГА»
від 22 березня 2007 року, протокол № 1`,
  },
  {
    right: true,
    content: `із змінами
ЗАТВЕРДЖЕНО
Рішення Ради Асоціації «ПРАВОВА ЛІГА»
від 09 лютого 2021 року, протокол №1/2021`,
  },
  {
    right: true,
    content: `ЗАТВЕРДЖЕНО
Рішення Ради Асоціації «ПРАВОВА ЛІГА»
від 30 вересня 2024 року, протокол №1/2024
`,
  },
  {
    center: true,
    content: `РЕГЛАМЕНТ
Постійно діючого третейського суду
при Асоціації "ПРАВОВА ЛІГА"
(нова редакція)
`,
  },
  {
    center: true,
    bold: true,
    content: `Глава 1. Загальні положення`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 1. Статус Третейського суду`,
  },
  {
    content: `1. Постійно діючий третейський суд при Асоціації "ПРАВОВА ЛІГА" (далі - Третейський суд) є недержавним, незалежним, постійно діючим третейським судом та здійснює свою діяльність у відповідності з чинним законодавством, що регулює діяльність третейських судів в Україні та цим Регламентом Третейського суду.

    2. Третейський суд діє при Асоціації "ПРАВОВА ЛІГА", яка матеріально і організаційно забезпечує його діяльність.

    3. Завданням Третейського суду є захист майнових і немайнових прав та охоронюваних законом інтересів фізичних чи юридичних осіб шляхом всебічного розгляду та вирішення спорів відповідно з чинним законодавством та Регламенту Третейського суду (далі - Регламент). Діяльність Третейського суду та третейських суддів не є підприємницькою.
    
    4. На розгляд до Третейського суду може бути передано будь-який спір, що виник з цивільних чи господарських правовідносин, крім випадків, передбачених Законом України «Про третейські суди» та іншими законодавчими актами.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 2. Компетенція Третейського суду.`,
  },
  {
    content: `1. Третейський суд порушує справи за позовними заявами підприємств, організацій, фізичних осіб-підприємців та фізичних осіб, які звертаються до Третейського суду за захистом своїх прав та охоронюваних законом інтересів.

2. Третейський суд з дотриманням вимог Закону України "Про третейські суди" самостійно вирішує питання про наявність або відсутність у нього компетенції для розгляду конкретного спору.

3. Третейський суд розглядає спори за наявністю третейського застереження у договорі, контракті або у вигляді окремої письмової угоди (далі - Третейська угода) між сторонами про передачу на його вирішення конкретного спору, визначених категорій або всіх спорів, що виникли або можуть виникнути між сторонами у зв'язку з будь-якими правовідносинами незалежно від того, мали вони чи ні договірний характер.

4. Третейська угода укладається у письмовій формі. Третейська угода вважається укладеною у письмовій формі, якщо вона міститься в документі, підписаному сторонами чи укладена шляхом обміну листами, повідомленнями по телетайпу, телеграфу або з використанням інших засобів електронного чи іншого зв'язку, що забезпечує фіксацію такої угоди або шляхом направлення відзиву на позов, в якому одна із сторін підтверджує наявність угоди, а інша сторона проти цього не заперечує. Посилання у договорі, контракті на документ, який містить умову про третейський
розгляд спору, є Третейською угодою за умови, що договір укладений у письмовій формі і це посилання є таким, що робить Третейську угоду частиною договору.

5. Якщо сторони домовились звернутися до Третейського суду, Регламент для них є обов'язковим.

6. Сторона має право заявити про відсутність у Третейського суду компетенції стосовно переданого на вирішення спору до початку розгляду справи по суті.

7. Сторона має право заявити про перевищення Третейським судом меж його компетенції, якщо в процесі третейського розгляду справи виникне питання, розгляд якого не передбачено третейською угодою або яке не може бути предметом такого розгляду відповідно до закону та цього Регламенту.

8. У випадках, передбачених пунктами 6 і 7 цієї статті, Третейський суд повинен відкласти розгляд справи по суті до вирішення ним питання щодо наявності у нього компетенції.

9. Питання щодо компетенції Третейського суду по конкретній справі вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, складом Третейського суду чи третейським суддею, який розглядає спір одноособово. При цьому Третейська угода, що є частиною договору, тлумачиться як угода, яка не залежить від інших умов договору. Недійсність окремих положень договору, контракту, що містить третейське застереження, не тягне за собою недійсність такого третейського застереження.

10. З питань наявності чи відсутності компетенції Третейський суд постановляє мотивовану ухвалу.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 3. Місцезнаходження Третейського суду.`,
  },
  {
    content: `5. Місцезнаходженням Третейського суду є місцезнаходження Асоціації "ПРАВОВА ЛІГА" (ідентифікаційний код 34826691): 46001, майдан Волі, 4, м. Тернопіль, Україна.

6. Перед зверненням до Третейського суду Сторони зобов’язуються перевірити місцезнаходження Асоціації "ПРАВОВА ЛІГА" згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань.

7. Зміна місцезнаходження Третейського суду не впливає на чинність третейської угоди.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 4. Місце третейського розгляду.`,
  },
  {
    content: `1. Місцем третейського розгляду є місцезнаходження Третейського суду.

2. Сторони можуть домовитися про проведення третейського розгляду поза місцем знаходження Третейського суду. У такому разі всі додаткові витрати, пов'язані з проведенням розгляду, покладаються на сторони.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 5. Законодавство, яке застосовується Третейським судом при вирішенні спорів.`,
  },
  {
    content: `1. Третейські суди вирішують спори на підставі Конституції та законів України, інших нормативно-правових актів та міжнародних договорів України.

2. Третейський суд у випадках, передбачених законом або міжнародним договором України, застосовує норми права інших держав.

3. У разі відсутності законодавства, що регулює певні спірні відносини, Третейський суд застосовує законодавство, яке регулює подібні відносини, а за відсутності такого Третейський суд застосовує аналогію права чи керується торговими та іншими звичаями, якщо останні за своїм характером та змістом властиві таким спірним відносинам.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 6. Мова третейського розгляду.`,
  },
  {
    content: `1. Розгляд справ у Третейському суді провадиться українською мовою.

2. Процесуальні документи Третейського суду приймаються українською мовою.

3. Сторона, яка надає документи чи письмові докази мовою іншою, ніж мова третейського розгляду, повинна забезпечити їх переклад на мову третейського розгляду.

4. Якщо сторона не володіє мовою, якою провадиться розгляд, Третейський суд за проханням сторони і за її рахунок забезпечується послугами перекладача.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 7. Гласність.`,
  },
  {
    content: `1. Третейський розгляд здійснюється відкрито.

2. У разі коли проти відкритого третейського розгляду висунуто хоча б однією стороною заперечення з мотивів додержання та збереження комерційної або банківської таємниці чи забезпечення конфіденційності інформації, справа розглядається у закритому засіданні.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 8. Конфіденційність.`,
  },
  {
    content: `1. Відомості, пов’язані з третейським розглядом справи, є конфіденційними. Третейський суд, суддя Третейського суду, працівники апарату Третейського суду не вправі розголошувати відомості та інформацію, що стали йому (їм) відомі під час третейського розгляду, без згоди сторін або їх правонаступників.

2. Забороняється вимагати від третейського судді надання документів, відомостей та інформації, якими він володіє у зв'язку з третейським розглядом справи, крім випадків, передбачених законами України.

3. У будь-якому випадку, при публікаціях не допускається посилання на найменування сторін, їх банківські реквізити та аналогічні відомості.

4. Надання відомостей щодо спорів, переданих на розгляд до Третейського суду, допускається лише на письмовий запит компетентних органів у випадках та з підстав, передбачених законами України.

5. Не буде вважатись розголошенням відомостей в разі, якщо сторони в ході розгляду справи не подавали письмових заяв чи клопотань про збереження конфіденційності.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 9. Принципи діяльності Третейського суду.`,
  },
  {
    content: `1. Третейський суд діє на принципах, визначених Законом України «Про третейські суди».`,
  },
  {
    center: true,
    bold: true,
    content: `Глава 2. Учасники третейського розгляду`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 10. Склад осіб, які приймають участь у третейському розгляді.`,
  },
  {
    content: `1. Особами, що приймають участь у третейському розгляді є сторони, їх представники.

2. Сторонами третейського розгляду можуть бути юридичні та фізичні особи, які подали до Третейського суду позов на захист своїх прав та інтересів, або до яких пред'явлений позов.

3. Позивачами є юридичні особи, фізичні особи-підприємці та фізичні особи, які подали позов.

4. Відповідачами є юридичні особи, фізичні особи-підприємці та фізичні особи, до яких подана позовна вимога.

5. Треті особи вступають в третейський розгляд тільки зі згоди сторін третейського розгляду. Для залучення до третейського розгляду третьої особи, окрім згоди сторін, необхідна також згода особи, яка залучається. Згода на залучення третьої особи повинна бути викладена у письмовій формі.

6. Для об'єктивного і вірного третейського розгляду Третейський суд вправі за власною ініціативою залучати третіх осіб за їх згодою без згоди сторін.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 11. Інші учасники третейського розгляду.`,
  },
  {
    content: `1. Іншими учасниками третейського розгляду, які сприяють здійсненню третейського розгляду можуть бути: експерти, свідки, перекладачі, секретар третейського розгляду.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 12. Загальні засади третейського розгляду.`,
  },
  {
    content: `1. Третейський розгляд здійснюється на засадах змагальності та рівності сторін. До сторін повинно бути рівне ставлення і кожній стороні повинні бути надані рівні можливості для викладення своєї позиції.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 13. Права та обов'язки осіб, які приймають участь у справі.`,
  },
  {
    content: `1. Особи, які приймають участь у справі, мають право знайомитись з матеріалами справи, робити з них виписки, знімати копії: заявляти відводи; надавати докази і ознайомлюватися з доказами, наданими іншими особами, що приймають участь у справі, до початку третейського розгляду; брати участь у дослідженні доказів; задавати питання іншим учасникам третейського розгляду, заявляти клопотання, робити заяви, давати пояснення Третейському суду, наводити свої доводи по всім питанням, що виникають при розгляді справи; знайомитися з клопотаннями, заявленими іншими особами, заперечувати проти клопотань, доводів інших осіб, що приймають участь у справі; отримувати копії ухвал та рішень, користуватися іншими процесуальними правами, наданими їм цим Регламентом.

2. Особи, які приймають участь у справі, повинні добросовісно користуватися всіма наданими їм правами.

3. Особи, які приймають участь у справі, несуть обов'язки, передбачені цим Регламентом або покладені на них Третейським судом у відповідності з цим Регламентом.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 14. Процесуальна правоздатність і процесуальна дієздатність.`,
  },
  {
    content: `1. Здатність мати процесуальні права і нести процесуальні обов'язки (процесуальна правоздатність) визнається в рівній мірі за всіма юридичними і фізичними особами, які мають згідно з законом право на судовий захист в Третейському суді своїх прав і законних інтересів.

2. Здатність своїми діями здійснювати процесуальні права і виконувати процесуальні обов'язки (процесуальна дієздатність) належить в Третейському суді - юридичним і фізичним особам.

3. Права і законні інтереси недієздатних фізичних осіб захищають в третейському розгляді їх законні представники.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 15. Участь у справі декількох позивачів або відповідачів.`,
  },
  {
    content: `1. Позов може бути поданий до Третейського суду сумісно декількома позивачами або до декількох відповідачів (процесуальна співучасть). Кожен з позивачів або відповідачів виступає в процесі самостійно. Співучасники можуть доручити ведення справи одному або декільком з співучасників.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 16. Залучення до участі у справі іншого відповідача.
Заміна неналежного відповідача.`,
  },
  {
    content: `1. Третейський суд за наявністю достатніх підстав має право до прийняття рішення залучити за клопотанням позивача або за своєю ініціативою до участі у справі іншого відповідача, при наявності підписаної Третейської угоди.

2. У разі, якщо при підготовці справи до третейського розгляду або під час третейського розгляду буде встановлено, що позов пред'явлений не до тої особи, яка повинна відповідати за позовом, Третейський суд може за згодою позивача, не припиняючи провадження у справі, допустити заміну неналежного відповідача належним відповідачем, при наявності підписаної Третейської угоди.

3. Про залучення іншого відповідача чи заміну неналежного відповідача розгляд справи проводиться спочатку, про що Третейський суд виносить ухвалу.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 17. Процесуальне правонаступництво.`,
  },
  {
    content: `1. У випадках вибуття однієї з сторін третейського розгляду (реорганізація юридичної особи, уступка вимоги, переведення боргу, смерть фізичної особи та інші випадки переміни осіб у зобов'язаннях) Третейський суд проводить заміну цієї сторони її правонаступником, при згоді правонаступника і зазначає про це в ухвалі або в рішенні. Правонаступництво можливе на будь-якій стадії третейського розгляду.

2. Для правонаступника усі дії, здійснені в третейському процесі до вступу правонаступника в справу, обов'язкові в тій мірі, в якій вони були обов'язкові для особи, яку правонаступник змінив.

3. Якщо Третейська угода укладена у вигляді третейського застереження, то вона вважається невід’ємною частиною угоди і щодо неї діють такі самі правила правонаступництва, що й до угоди в цілому.

4. Якщо Третейська угода укладена у вигляді окремої угоди, то у разі заміни кредитора у зобов’язанні останній повинен повідомити нового кредитора про її існування. Зміна Третейської угоди в такому випадку можлива лише за умови явно вираженої згоди іншої (інших) сторони (сторін) угоди.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 18. Зміна підстави або предмета позову, зміна розміру позовних вимог, відмова від позову, визнання позову, мирова угода.`,
  },
  {
    content: `1. Позивач має право при розгляді справи в Третейському суді до прийняття рішення змінити підставу або предмет позову, збільшити або зменшити розмір позовних вимог.

2. Позивач має право при розгляді справи в Третейському суді до прийняття рішення, відмовитись від позову повністю або частково.

3. Відповідач має право при розгляді справи в Третейському суді визнати позов повністю або частково.

4. Сторони можуть закінчити справу мировою угодою в порядку, передбаченому главою 11 цього Регламенту.

5. Третейський суд не приймає відмову позивача від позову, зменшення ним розміру позовних вимог, визнання відповідачем позову, не затверджує мирову угоду сторін, якщо це суперечить закону або порушує права інших осіб.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 19. Треті особи, які заявляють самостійні вимоги відносно предмета спору.`,
  },
  {
    content: `1. Треті особи, які заявляють самостійні вимоги відносно предмета спору, можуть за їх згодою та за згодою сторін вступити у справу третейського розгляду до прийняття Третейським судом рішення, при умові існування щодо них Третейської угоди. Вони користуються усіма правами і несуть усі обов'язки позивача.

2. У разі якщо третя особа, заявляє самостійні вимоги відносно предмета спору, вступила у справу після початку третейського розгляду, розгляд справи в Третейському суді проводиться спочатку.

3. Про вступ в справу третьої особи, яка заявляє самостійні вимоги відносно предмета спору, виноситься ухвала.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 20. Треті особи, які не заявляють самостійних вимог відносно предмета спору.`,
  },
  {
    content: `1. Треті особи, які не заявляють самостійних вимог відносно предмета спору, можуть за згодою сторін вступити в справу третейського розгляду на стороні позивача або відповідача до прийняття Третейським судом рішення, якщо рішення в справі може вплинути на права або обов'язки по відношенню до однієї з сторін. Вони можуть бути залучені до участі у справі третейського розгляду за їх згодою за ініціативою Третейського суду.

2. Треті особи, які не заявляють самостійних вимог щодо предмета спору, мають процесуальні права і обов'язки сторони, за виключенням права на зміну підстави або предмета позову, збільшення або зменшення розміру позовних вимог, відмови від позову, визнання позову або укладення мирової угоди, пред'явлення зустрічного позову.

3. Про вступ у справу третьої особи, яка не заявляє самостійних вимог щодо предмета спору, або про залучення в справу цієї особи або про відмову про це Третейським судом виноситься ухвала.`,
  },
  {
    center: true,
    bold: true,
    content: `Глава 3. Представництво у Третейському суді`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 21 Ведення справ у Третейському суді через представників.`,
  },
  {
    content: `1. Сторона, третя особа, а також особа, якій законом надано право звертатися до Третейського суду в інтересах іншої особи, може вести справи у Третейському суді особисто та (або) через представника.

2. Особиста участь у справі особи не позбавляє її права мати в цій справі представника.

3. Юридична особа незалежно від порядку її створення бере участь у справі через свого керівника, члена виконавчого органу, іншу особу, уповноважену діяти від її імені відповідно до закону, статуту, положення, трудового договору (контракту), або через представника.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 22. Особи, які можуть бути представниками у Третейському суді.`,
  },
  {
    content: `1. Представником у Третейському суді може бути адвокат або представник.

2. Одна й та сама особа може бути одночасно представником декількох позивачів або декількох відповідачів або декількох третіх осіб на одній стороні, за умови відсутності конфлікту інтересів між ними.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 23. Документи, що посвідчують повноваження представників`,
  },
  {
    content: `1. Повноваження представників сторін та інших учасників справи мають бути підтверджені такими документами:

1) довіреністю фізичної;
2) довіреністю юридичної особи.

2. Довіреність фізичної особи повинна бути посвідчена нотаріально або у визначених законом випадках іншою особою.

3. Довіреність від імені юридичної особи видається за підписом (електронним цифровим підписом) посадової особи, уповноваженої на це законом, установчими документами.

4. Повноваження адвоката як представника підтверджуються довіреністю або ордером, виданим відповідно до Закону України "Про адвокатуру та адвокатську діяльність".

5. Відповідність копії документа, що підтверджує повноваження представника, оригіналу може бути засвідчена підписом третейського судді.

6. Оригінали документів, зазначених у цій статті, копії з них, засвідчені третейським суддею, або копії з них, засвідчені у визначеному законом порядку, приєднуються до матеріалів справи.

7. У разі подання представником до Третейського суду заяви, скарги, клопотання він додає довіреність або інший документ, що посвідчує його повноваження, якщо в справі немає підтвердження такого повноваження на момент подання відповідної заяви, скарги, клопотання.

8. Довіреності або інші документи, що підтверджують повноваження представника і були посвідчені в інших державах, повинні бути легалізовані в установленому законодавством порядку, якщо інше не встановлено міжнародними договорами, згода на обов’язковість яких надана Верховною Радою України.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 24. Повноваження представника.`,
  },
  {
    content: `1. Представник, який має повноваження на ведення справи в Третейському суді, здійснює від імені особи, яку він представляє, її процесуальні права та обов’язки.

2. Обмеження повноважень представника на вчинення певної процесуальної дії мають бути застережені у виданій йому довіреності або ордері.

3. Підстави і порядок припинення представництва за довіреністю визначаються Цивільним кодексом України, а представництва за ордером - законодавством про адвокатуру.

4. Про припинення представництва або обмеження повноважень представника за довіреністю або ордером має бути повідомлено Третейський суд шляхом подання письмової заяви.

5. У разі припинення повноважень представника на здійснення представництва особи у справі представник не може бути у цій самій справі представником іншої сторони, третьої особи на іншій стороні або третьої особи із самостійними вимогами щодо предмета спору.`,
  },
  {
    center: true,
    bold: true,
    content: `Глава 4. Процесуальні строки`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 25. Встановлення та обчислення процесуальних строків.`,
  },
  {
    content: `1. Строки, в межах яких вчиняються процесуальні дії, встановлюються законом, цим Регламентом, а якщо такі строки законом, цим Регламентом не визначені, - встановлюються Третейським судом.

2. Третейський суд має встановлювати розумні строки для вчинення процесуальних дій. Строк є розумним, якщо він передбачає час, достатній, з урахуванням обставин справи, для вчинення процесуальної дії, та відповідає завданню третейського судочинства.

3. Строки, встановлені законом, цим Регламентом або Третейським судом, обчислюються роками, місяцями і днями, а також можуть визначатися вказівкою на подію, яка повинна неминуче настати.

4. Перебіг строку третейського розгляду починається з наступною дня після відповідної календарної дати або настання події, з якою пов'язано його початок.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 26. Закінчення строків третейського розгляду.`,
  },
  {
    content: `1. Строк, обчислюваний роками, закінчується у відповідні місяць і число останнього року строку.

2. Строк, обчислюваний місяцями, закінчується у відповідне число останнього місяця строку. Якщо закінчення строку, обчислюваного місяцями, припадає на такий місяць, що відповідного числа не має, строк закінчується в останній день цього місяця.

3. Якщо закінчення строку припадає на вихідний, святковий чи інший неробочий день, останнім днем строку є перший після нього робочий день.

4. Перебіг строку, закінчення якого пов'язане з подією, яка повинна неминуче настати, закінчується наступного дня після настання події.

5. Останній день строку триває до 24 години. Строк не вважається пропущеним, якщо до його закінчення заява, інші документи чи матеріали або грошові кошти здано на пошту чи передані іншими відповідними засобами зв'язку.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 27. Наслідки пропущення строків третейського розгляду.`,
  },
  {
    content: `1. Право на вчинення процесуальної дії втрачається із закінченням строку, встановленого законом, цим Регламентом або Третейським судом.

2. Документи, подані після закінчення строків третейського розгляду, залишаються без розгляду, якщо Третейський суд за клопотанням особи, що їх подала, не знайде підстав для поновлення або продовження строку.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 28. Зупинення строків третейського розгляду.`,
  },
  {
    content: `1. Зупинення провадження у справі зупиняє перебіг строків третейського розгляду. Зупинення цих строків починається з моменту настання тієї події, внаслідок якої Третейський суд зупинив провадження.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 29. Поновлення та продовження строків третейського розгляду.`,
  },
  {
    content: `1. Третейський суд поновлює або продовжує строк, встановлений відповідно законом, цим Регламентом за клопотанням сторони або іншої особи у разі його пропущення з поважних причин.

2. Строки вчиненим процесуальних дій, передбачені Законом України "Про третейські суди" можуть бути поновлені або продовжені лише за спільною згодою сторін та складу Третейського суду, яким вирішується спір.

3. Одночасно з клопотанням про поновлення чи продовження строку може бути вчинено ту дію або подано той документ чи доказ, стосовно якого заявлено клопотання.

4. З питань, зазначених у цій статті, Третейським судом виноситься ухвала.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 30. Строк розгляду спору.`,
  },
  {
    content: `1. Розгляд справи Третейським судом починається з винесення відповідної ухвали
та направлення її сторонам.

2. Третейський суд застосовує заходи до розгляду спору протягом розумного строку, але не більше 2 (двох) місяців з дня винесення ухвали про порушення провадження у справі, крім випадків, коли за згодою сторін або за рішенням Третейського суду строки розгляду справи продовжені. Про продовження строку третейського розгляду третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду виносить ухвалу.

3. Третейський суд на початку розгляду справи з’ясовує у сторін можливість закінчити справу мировою угодою та в подальшому сприяє вирішенню спору шляхом укладання мирової угоди на всіх стадіях третейського розгляду.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 31. Інші строки.`,
  },
  {
    content: `1. Всі інші строки, пов'язані з третейським розглядом, визначені у конкретних статтях цього Регламенту.`,
  },
  {
    center: true,
    bold: true,
    content: `Глава 5. Докази`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 32. Докази.`,
  },
  {
    content: `1. Доказами у третейському розгляді є будь-які фактичні дані, на підставі яких Третейський суд встановлює наявність або відсутність обставин, що обґрунтовують вимоги і заперечення сторін, та інші обставини, що мають значення для правильного вирішення спору.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 33. Засоби доказування.`,
  },
  {
    content: `1. Засобами доказування є пояснення сторін, третіх осіб, їхніх представників, викладені в заявах по суті, показання свідків, письмові, речові докази, висновки експертів.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 34. Обов'язки доказування і надання доказів та їх зберігання.`,
  },
  {
    content: `1. Кожна сторона третейського розгляду повинна довести ті обставини, на які вона посилається як на підставу своїх вимог і заперечень.

2. Докази подаються сторонами та іншими учасниками справи.

3. Третейський суд має право вимагати від сторін, надання доказів, необхідних для повного, всебічного і об'єктивного вирішення спору.

4. У разі посилання учасника справи на невчинення іншим учасником справи певних дій або відсутність певної події, Третейський суд може зобов’язати такого іншого учасника справи надати відповідні докази вчинення цих дій або наявності певної події. У разі ненадання таких доказів Третейський суд може визнати обставину невчинення відповідних дій або відсутності події встановленою.

5. Якщо Третейський суд визнає за необхідне одержати документи від підприємств, установ, організацій, які не є учасниками третейського розгляду, він своєю ухвалою уповноважує сторони або одну із сторін одержати відповідні документи та надати їх Третейському суду.

6. Сторони повинні надавати Третейському суду докази в оригіналах чи належним чином засвідчених копіях.

7. Всі зібрані письмові докази разом з процесуальними документами сторін та Третейського суду зберігаються у справі в прошитому та пронумерованому вигляді.

8. Третейський суд не може збирати докази, що стосуються предмета спору, з власної ініціативи, крім витребування доказів Третейським судом у випадку, коли він має сумніви у добросовісному здійсненні учасниками справи їхніх процесуальних прав або виконанні обов’язків щодо доказів.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 35. Належність доказів.`,
  },
  {
    content: `1. Належними є докази, на підставі яких можна встановити обставини, які входять в предмет доказування. Третейський суд бере до розгляду тільки ті докази, які стосуються справи, що розглядається та предмета доказування.

2. Предметом доказування є обставини, які підтверджують заявлені вимоги чи заперечення або мають інше значення для розгляду справи і підлягають встановленню при прийнятті Третейським судом рішення.

3. Сторони мають право обґрунтовувати належність конкретного доказу для підтвердження їхніх вимог або заперечень.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 36. Допустимість доказів.`,
  },
  {
    content: `1. Обставини справи, які за законом повинні бути підтверджені певними засобами доказування, не можуть підтверджуватися ніякими іншими засобами доказування.

2. Третейський суд не бере до уваги докази, які одержані з порушенням порядку, встановленого законом.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 37. Підстави звільнення від доказування.`,
  },
  {
    content: `1. Обставини, визнані Третейським судом загальновідомими, не потребують доказування.

2. Обставини встановлені рішенням Третейського суду, що набрало законної сили, не доказуються при розгляді інших справ, у яких беруть участь ті самі особи або особа, щодо якої встановлено ці обставини.

3. Рішення судів в господарській, цивільній або адміністративній справі, що набрали законної сили, обов'язкові для Третейського суду про обставини, встановлені цими рішеннями по відношенню до осіб, які приймають участь у справі.

4. Обставини, встановлені стосовно певної особи рішенням суду в господарській, цивільній або адміністративній справі, що набрало законної сили, можуть бути у загальному порядку спростовані особою, яка не брала участі у справі, в якій такі обставини були встановлені.

5. Обвинувальний вирок суду в кримінальному провадженні або постанова суду, якою особу притягнуто до адміністративної відповідальності у справі про адміністративне правопорушення, які набрали законної сили, є обов’язковими для Третейського суду, що розглядає справу про правові наслідки дій чи бездіяльності особи, стосовно якої ухвалений вирок або постанова суду, лише в питанні, чи мали місце ці дії (бездіяльність) та чи вчинені вони цією особою.

6. Правова оцінка, надана судом певному факту при розгляді іншої справи, не є обов’язковою для Третейського суду.

7. Обставини, встановлені рішенням міжнародного комерційного арбітражу, виправдувальним вироком суду у кримінальному провадженні, ухвалою про закриття кримінального провадження і звільнення особи від кримінальної відповідальності, підлягають доказуванню в загальному порядку при розгляді справи Третейським судом.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 38. Звільнення від доказування обставин, визнаних сторонами.`,
  },
  {
    content: `1. Третейський суд повинен сприяти досягненню сторонами згоди в оцінці обставин в цілому або в їх окремих частинах, проявляти в цих цілях необхідну ініціативу, використовувати свої процесуальні повноваження і авторитет Третейського суду.

2. Обставини, які визнаються учасниками справи, не підлягають доказуванню, якщо Третейський суд не має обґрунтованих підстав вважати їх недостовірними або визнаними у зв’язку з примусом. Обставини, які визнаються учасниками справи, можуть бути зазначені в заявах по суті справи, письмових поясненнях учасників справи, їх представників.

3. Відмова від визнання обставин приймається Третейським судом, якщо сторона, яка відмовляється, доведе, що вона визнала ці обставини внаслідок помилки, що має істотне значення, обману, насильства, погрози чи тяжкої обставини, або що обставини визнано у результаті зловмисної домовленості її представника з іншою стороною. Про прийняття відмови сторони від визнання обставин Третейський суд виносить ухвалу. У разі прийняття Третейським судом відмови сторони від визнання обставин вони доводяться в загальному порядку.
`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 39 Оцінка доказів.`,
  },
  {
    content: `1. Третейський суд оцінює докази за своїм внутрішнім переконанням, що ґрунтується на всебічному, повному, об'єктивному і безпосередньому дослідженні наявних у справі доказів.


2. Кожний доказ підлягає оцінці Третейським судом поряд з іншими доказами.

3. Жодні докази не мають для Третейського суду заздалегідь встановленої сили. Третейський суд оцінює належність, допустимість, достовірність кожного доказу окремо, а також вірогідність і взаємний зв’язок доказів у їх сукупності.


Стаття 40. Письмові докази.

1. Письмовими доказами є будь-які документи, акти, довідки, листування службового або особистого характеру або витяги з них, виконані у формі цифрового, графічного запису або іншим шляхом, що дозволяє встановити достовірність документа.

2. Документи, отримані через факсимільний, електронний або інший зв'язок, а також документ, підписані електронним цифровим підписом або іншим аналогом власноручного підпису, допускаються в якості письмових доказів у випадках і в порядку, встановлених законом, іншим нормативно - правовим актом або договором.

3. До письмових документів, що надаються Третейському суду, виконаних повністю або в частині іноземною мовою, повинні бути додані їх належним чином завірені переклади на українську мову.

4. Документ отриманий в іноземній державі, визнається в Третейському суді письмовим доказом, якщо він легалізований у встановленому порядку.

5. Іноземні офіційні документи визнаються у Третейському суді письмовими доказами без їх легалізації у випадках, передбачених міжнародними договорами України.

6. Оригінали документів сторін, що є у справі, за заявами осіб, що їх представляють, можуть бути повернуті їм після прийняття рішення, якщо ці документи не підлягають передачі іншій особі. Одночасно з заявами зазначені особи надають належним чином завірені копії документів або заявляють клопотання про засвідчення Третейським судом вірності копій, що залишаються у справі.

7. Якщо Третейський суд дійде висновку, що повернення оригіналів документів не нанесе шкоди правильному розгляду справи, ці документи можуть бути повернуті в процесі провадження у справі до винесення рішення.`,
  },
  {
    center: true,
    italic: true,
    content: `Стаття 41. Речові докази.`
  },
  {
    content: `1. Речовими доказами є предмети матеріального світу, що містять інформацію про обставини, які мають значення для справи.

2. Речовими доказами є також магнітні, електронні та інші носії інформації, що містять аудіовізуальну інформацію про обставини, що мають значення для справи.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 42. Зберігання речових доказів.`
  },
  {
    content: `1. Речові докази зберігаються за місцем їх знаходження. Вони повинні бути детально описані, опечатані, а у разі необхідності зняті на фото - чи відеоплівку.

2. Речові докази можуть зберігатися за місцезнаходженням Третейського суду, якщо Третейський суд визнає це за необхідне.

3. Витрати на зберігання речових доказів розподіляються між сторонами у відповідності з правилами, встановленими цим Регламентом.

4. Третейський суд і зберігач вживають заходів по зберіганню речових доказів у незмінному стані.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 43. Огляд і дослідження письмових, речових доказів за місцем їх знаходження.`
  },
  {
    content: `1. Третейський суд може провести огляд і дослідження письмових, речових за їх місцезнаходженням, у разі неможливості їх доставки в Третейський суд. Про проведення огляду і дослідженню письмових, речових доказів за їх місцезнаходженням виноситься ухвала.

2. Про дату, час і місце огляду доказів за їх місцезнаходженням повідомляються учасники справи. Неявка цих осіб не є перешкодою для проведення огляду.

3. Про огляд доказів за їх місцезнаходженням складається протокол, що підписується всіма особами, які беруть участь в огляді. До протоколу додаються разом з описом усі складені або звірені під час огляду на місці плани, креслення, копії документів, а також зроблені під час огляду фотознімки, електронні копії доказів, відеозаписи тощо.

4. Якщо огляд проводиться за відсутності хоча б однієї із сторін, а також в інших випадках, коли Третейський суд визнає це за необхідне, здійснюється відеофіксація огляду технічними засобами.

5. Особи, які беруть участь в огляді доказів за їх місцезнаходженням, мають право звертати увагу Третейського суду на ту чи іншу обставину, яка, на їх погляд, має значення для повноцінного проведення огляду, встановлення обставин, які мають значення для розгляду справи, робити свої зауваження щодо протоколу огляду.

Стаття 44. Розпорядження речовими доказами, що знаходяться у Третейському суді.

1. Речові докази, що знаходяться у Третейському суді, після їх огляду і дослідження Третейським судом повертаються особам, від яких вони були отримані, якщо вони не підлягають передачі іншим особам.

2. Речі, які згідно закону не можуть знаходитись у користуванні окремих осіб, передаються відповідним організаціям.

3. З питань розпорядження речовими доказами Третейський суд виносить ухвалу.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 45. Пояснення осіб, які приймають участь у справі.`
  },
  {
    content: `1. Особа, яка приймає участь у справі, надає Третейському суду свої пояснення про відомі їй обставини, які мають значення для справи, в письмовій або усній формі. На вимогою Третейського суду особа, яка приймає участь у справі, має викласти свої пояснення у письмовій формі.

2. Пояснення, викладені у письмовій формі, додаються до матеріалів справи.

3. Після оголошення пояснень, викладених у письмовій формі, особа, яка надала це пояснення, має право дати відносно нього необхідні пояснення, а також зобов'язана відповісти на питання інших осіб, які приймають участь у справі, Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 46. Призначення експертизи.`
  },
  {
    content: `1. З метою правильного вирішення спору Третейський суд має право зобов'язати сторони чи одну із сторін замовити проведення експертизи для роз'яснення питань, що потребують спеціальних знань, про що Третейський суд виносить відповідну ухвалу.

2. У разі ухилення позивача від замовлення проведення експертизи, призначеної Третейським судом, і при неможливості розглянути справу через дослідження інших доказів у справі, позовна заява залишається без розгляду, про що виноситься ухвала.

3. У разі ухилення відповідача від замовлення проведення експертизи, призначеної Третейським судом, Третейський суд може зобов'язати замовити проведення експертизи позивача, або розглянути справу без проведення експертизи дослідивши інші докази у справі.

4. Перелік і зміст питань, з яких повинна бути проведена експертиза, визначається в ухвалі Третейського суду. Учасники справи мають право запропонувати Третейському суду питання, роз’яснення яких, на їхню думку, потребує висновку експерта. У разі відхилення або зміни питань, запропонованих учасниками справи, Третейський суд зобов’язаний мотивувати таке відхилення або зміну.

5. Питання, які ставляться експерту, і його висновок з них не можуть виходити за межі спеціальних знань експерта.

6. В ухвалі про зобов'язання замовлення проведення експертизи зазначаються підстави для призначення експертизи; прізвище, ім'я і по-батькові експерта або найменування експертної установи, в якій повинна бути проведена експертиза: питання, поставлені перед експертом: матеріали і документи, надані в розпорядження експерта; строк, на протязі якого повинна бути проведена експертиза і повинно бути надано висновок Третейському суду.

7. Висновок експерта оголошується у засіданні Третейського суду і досліджується поряд з іншими доказами у справі. За клопотанням особи, яка приймає участь у справі, або за ініціативою Третейського суду експерт може бути викликаний у засідання Третейського суду. Експерт після оголошення його висновку має право дати по ньому необхідні пояснення, а також відповісти на додаткові питання осіб, які приймають участь у справі, і Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 47. Показання свідків.`
  },
  {
    content: `1. За клопотанням особи, яка приймає участь у справі, Третейський суд може викликати свідка для надання пояснень по справі. Особа, яка заявляє клопотання про виклик свідка, зобов'язана зазначити, які обставини, що мають значення для справи, може підтвердити свідок, і повідомити суду його прізвище, ім'я, по-батькові і місце проживання.

2. Третейський суд за власною ініціативою може викликати у якості свідка особу, яка приймала участь у складанні документа, що досліджується Третейським судом як письмовий доказ, або у створенні або зміні предмета, що досліджується Третейським судом як речовий доказ.

3. За пропозицією Третейського суду свідок може викласти показання, що надані усно, у письмовій формі. Показання свідка, що викладені у письмовій формі, які приєднуються до матеріалів справи.

4. Не є доказами відомості, які повідомлені свідком, якщо він не може зазначити джерело повідомлених ним відомостей.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 6. Витрати, пов'язані з вирішенням спору Третейським судом`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 48. Склад витрат, пов'язаних з вирішенням спору Третейським судом.`
  },
  {
    content: `1. До складу витрат, пов'язаних з вирішенням спору Третейським судом, належать:

1) гонорари третейських суддів;
2) третейський збір, розмір, строки та порядок сплати якого сторонами визначаються цим Регламентом;
3) витрати, понесені третейськими суддями у зв'язку з участю в третейському розгляді, в тому числі витрати, понесені третейськими суддями на оплату проїзду до місця вирішення спору;
4) витрати, пов'язані з оплатою послуг експертів, перекладачів, якщо такі були запрошені чи призначені для участі в третейському розгляді;
5) витрати, пов'язані з оглядом і дослідженням речових та письмових доказів у їх місцезнаходженні;
6) витрати, понесені свідками;
7) витрати, пов'язані з оплатою стороною, на користь якої було прийнято рішення Третейського суду, послуг представника, пов'язаних із наданням правової допомоги;
8) витрати, пов'язані з організаційним забезпеченням третейського розгляду;
9) витрати на листування Третейського суду;
10) витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок;
11) гонорар секретаря Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 49. Третейський збір.`
  },
  {
    content: `1. При поданні позовної заяви позивач зобов'язаний сплатити третейський збір.

2. До сплати третейського збору третейський розгляд за позовом не здійснюється, крім випадку надання відстрочення сплати третейського збору.

3. Якщо третейський збір не сплачується на протязі місячного строку з моменту подання позовної заяви позовна заява повертається без розгляду, про що головою Третейського суду, його заступником, у відсутність голови Третейського суду, виноситься ухвала, яка не підлягає оскарженню.

4. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений, сплачений третейський збір повертається позивачу, крім випадків, якщо це було зумовлено відводом усіх суддів, укладанням сторонами мирової угоди, відмовою позивача від позову та іншими обставинами, передбаченими Законом України
"Про третейські суди".

5. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений з підстав, зазначених у пункті 4 статті 84 цього Регламенту, то сторони відшкодовують Третейському суду всі понесені ним витрати, про що зазначається в ухвалі Третейського суду.

6. Голова Третейського суду, його заступник, у відсутність голови Третейського суду, може надати відстрочення, а також зменшити або збільшити розмір третейського збору, про що вказується у розпорядженні, яке не підлягає оскарженню.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 50. Витрати, які здійснюються Третейським судом з наступним стягненням з сторін спору.`
  },
  {
    content: `1. Розподіл витрат, пов'язаних з організаційним забезпеченням третейського розгляду, витрати на листування Третейського суду, витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок, витрати, пов’язані з оплатою гонорару секретаря Третейського суду, здійснюються Третейським судом з подальшим стягненням зі сторони спору, у порядку, визначеному ст.52 цього Регламенту.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 51. Покриття витрат, які не включаються до складу третейського збору.`
  },
  {
    content: `1. Третейський суд може покласти на сторони або на одну з них обов'язок внести аванс на покриття витрат, які не включаються до складу третейського збору.

2. Аванс на покриття витрат, які не включаються до складу третейського збору може бути, витребуваний Третейським судом від сторони, яка заявила про необхідність здійснення дії, що приводить або може привести до цих витрат.

3. У разі участі у третейському розгляді третейського судді, який має місцеперебування поза місцем проведення засідання Третейського суду, позивач повинен внести аванс на оплату витрат його участі у третейському розгляді (по проїзду, проживанню тощо).

4. Якщо на прохання однієї з сторін при розгляді справи здійснюється переклад пояснень сторін, їх заяв тощо, а також питань, вказівок, ухвал, рішень Третейського суду, то витрати по перекладу оплачуються зазначеною стороною.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 52. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом.`
  },
  {
    content: `1. Розподіл витрат, пов'язаних з вирішенням спору Третейським судом здійснюється Третейським судом у такому порядку:

-при задоволенні позову витрати покладаються на відповідача; при відмові в позові - на позивача;
-при частковому задоволенні позову - на обидві сторони пропорційно розміру задоволених вимог.

2. Порядок розподілу витрат, пов'язаних з вирішенням спору Третейським судом, зазначається у рішенні або ухвалі Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 53. Розміри третейського збору.`
  },
  {
    content: `1. Третейський збір справляється у відсотковому співвідношенні до ціни позову та у фіксованому розмірі у відповідному розмірі прожиткового мінімуму для працездатних осіб, встановленого законом на період, в якому відповідна позовна заява подається до Третейського суду.

2. Розмір третейського збору за подання до Третейського суду:
-позовної заяви майнового характеру - 1 відсоток ціни позову, але не менше 1 розміру прожиткового мінімуму для працездатних осіб.
-позовної заяви немайнового характеру – 1 розмір прожиткового мінімуму для працездатних осіб.

3. За позовні заяви, що містять одночасно вимоги майнового і не майнового характеру, сплачується одночасно третейський збір, встановлений для позовних заяв майнового характеру і для позовних заяв немайнового характеру.

4. При зменшенні ціни позову сплачений третейський збір не повертається.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 54. Порядок сплати третейських зборів та інших витрат.`
  },
  {
    content: `1. Суми третейських зборів та інших витрат, що підлягають сплаті Третейському суду, вважаються сплаченими у день їх зарахування на поточний рахунок Асоціації "ПРАВОВА ЛІГА".

2. При сплаті третейського збору у платіжному дорученні у графі "Призначення платежу" повинно бути зазначено: "Третейський збір за розгляд спору між "....." (найменування позивача та відповідача), а також сума позову. Платіжне доручення з відміткою банку про перерахування третейського збору долучаються до матеріалів справи.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 7. Гонорари третейських суддів та порядок їх виплати`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 55. Гонорари третейських суддів, порядок їх виплати.`
  },
  {
    content: `1. Третейському судді, який приймає участь третейському розгляді, після закінчення розгляду конкретної справи сплачується гонорар у розмірі 30 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.

2. Якщо справа розглядається складом Третейського суду у кількості трьох третейських суддів, цим третейським суддям після закінчення розгляду конкретної справи сплачуються гонорари у таких розмірах: головуючому складу Третейського суду 12 відсотків, двом іншим третейським суддям складу Третейського суду по 9 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.

3. Гонорари третейським суддям сплачуються Асоціацією "ПРАВОВА ЛІГА" на поточні рахунки третейських суддів у банківських установах. Реквізити банківських рахунків повідомляються кожним третейським суддею Третейському суду у письмовій формі. Для виплати гонорару Асоціація "ПРАВОВА ЛІГА" може вимагати від третейського судді надання додаткових документів (копію довідки про ідентифікаційний номер, копію паспорту, тощо).

4. За заявою третейського судді, викладеної письмово, розгляд справи може здійснюватися безоплатно.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 8. Формування третейського суду`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 56. Склад третейського суду.`
  },
  {
    content: `1. Третейським суддею, який розглядає справу одноособово, третейськими суддями у разі розгляду справи у складі трьох третейських суддів, можуть бути призначені особи із списку третейських суддів Третейського суду за їх згодою, які прямо чи опосередковано не заінтересовані в результаті вирішення спору, а також мають необхідний досвід, ділові та моральні якості, необхідні для вирішення спору.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 57. Формування складу третейського суду.`
  },
  {
    content: `1. Якщо сторони не домовились про те, що справа розглядається третейським судом у складі трьох третейських суддів, справа підлягає розгляду третейським суддею одноособово.

2. Третейський суддя, який розглядає справу одноособово, призначається за його згодою головою Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.

3. При розгляді справи у складі трьох третейських суддів головуючого складу третейського суду та третейських суддів призначає за їх згодою голова Третейського суду, його заступником, у відсутність голови Третейського суду, із списку третейських суддів Третейського суду.

4. Після формування складу третейського суду, голова Третейського суду, його заступником, у відсутність голови Третейського суду, передає справу головуючому складу третейського суду або третейському судді, який розглядає справу одноособово.

5. Призначення третейського судді або складу третейських суддів, а також передача справи головуючому складу третейського суду або третейському судді, який
розглядає справу одноособово, оформляється розпорядженням голови Третейського суду, його заступника, у відсутність голови Третейського суду, яке є остаточним і не підлягає оскарженню.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 58. Повідомлення сторін про порушення справи.`
  },
  {
    content: `1. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду постановляє ухвалу про порушення справи, про що повідомляються сторони, і пропонує відповідачу у строк не більше ніж 5 днів з дня отримання ухвали про порушення справи надати Третейському суду свій відзив на позов, що підкріплений відповідними доказами.

2. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду може продовжити строк для надання відповідачем відзиву на позов при надходженні відповідного клопотання. За результатами розгляду відповідного клопотання виноситься ухвала, яка не підлягає оскарженню.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 59. Припинення повноважень третейського судді, складу третейського суду, заміна третейського судді.`
  },
  {
    content: `1. Повноваження третейського судді припиняються:
1) за погодженням сторін;
2) у разі відводу чи самовідводу третейського судді відповідно до цього Регламенту;
3) у разі набрання законної сили обвинувальним вироком суду щодо нього;
4) у разі набрання законної сили судовим рішенням про визнання його обмежено дієздатним чи недієздатним;
5) у разі смерті, визнання його безвісно відсутнім або оголошення померлим рішенням суду, що набрало законної сили.

2. Повноваження складу третейського суду припиняються після ухвалення ним рішення у справі.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 60. Поновлення повноважень третейського суду.`
  },
  {
    content: `1. Повноваження Третейського суду поновлюються у разі виникнення потреби в:
1) прийнятті додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні, – за заявою сторони;
2) роз’ясненні резолютивної частини рішення – за заявою сторони;
3) виправленні у рішенні описок, арифметичних помилок або будь-яких інших неточностей – з власної ініціативи Третейського суду або за заявою сторони.

2. Після виконання зазначених у цій статті процесуальних дій повноваження Третейського суду припиняються.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 61. Підстави відводу чи самовідводу третейського судді.`
  },
  {
    content: `1. Третейський суддя не може брати участі у розгляді справи, а після його призначення підлягає відводу чи самовідводу:
-якщо він особисто чи опосередковано заінтересований у результаті розгляду справи;
-якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках;
на його прохання або за спільним рішенням сторін;
-у разі встановлення стороною обставин, які дають їй підстави вважати упередженим або необ'єктивним ставлення третейського судді до справи, про яке сторона дізналася після його призначення;
-у разі тривалого, більш як один місяць від дня призначення, невиконання ним обов'язків третейського судді у конкретній справі;
-у разі виявлення невідповідності третейського судді вимогам, встановленим статтею 18 Закону України "Про третейські суди";
-якщо третейський суддя бере участь у вирішенні спору, який прямо чи опосередковано пов'язаний з виконанням ним службових повноважень, наданихдержавою.
2. Жодна особа не може бути третейським суддею у справі, в якій вона раніше брала участь як третейський суддя, але була відведена чи заявила самовідвід, як сторона, представник сторони або в будь-якій іншій якості.

3. Незгода сторони з процесуальними рішеннями третейського судді, рішення або окрема думка третейського судді в інших справах, висловлена публічно думка третейського судді щодо того чи іншого юридичного питання не може бути підставою для відводу.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 62. Порядок відводу чи самовідводу третейського судді.`
  },
  {
    content: `1. У разі звернення голови Третейського суду, його заступника, у відсутність голови Третейського суду, до особи зі списку третейських суддів Третейського суду за отриманням згоди на призначення її третейським суддею у конкретній справі, ця особа повинна повідомити про наявність обставин, які є підставами для відводу чи самовідводу відповідно до статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту.

2. Третейський суддя повинен без зволікання повідомити сторони про підстави його відводу чи самовідводу, зазначені у статті 19 Закону України "Про третейські суди" та статті 61 цього Регламенту, що виникли після початку третейського розгляду, та заявити самовідвід.

3. Письмова мотивована заява сторони про відвід третейського судді, з доказом надіслання її іншій стороні, має бути подана стороною до Третейського суду протягом п’яти календарних днів після того, як цій стороні стали відомі обставини, які є підставою для відводу третейського судді відповідно до Закону "Про третейські суди" та цього Регламенту.

4. У разі пропуску встановленого строку, питання про прийняття заяви про відвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, залежно від причин пропуску строку. У випадку відмови у прийнятті заяви про відвід третейського судді голова Третейського суду, його заступник, у відсутність голови Третейського суду, виносить ухвалу.

5. Якщо відвід заявляється повторно з підстав, розглянутих раніше, третейський суд, який розглядає справу, залишає таку заяву без розгляду, про що виносить ухвалу.

6. Третейський суд зобов’язаний надати іншій стороні можливість прокоментувати заяву про відвід третейського судді.

7. У разі коли інша сторона не погоджується з відводом третейського судді, вона має право протягом п’яти календарних днів з дня отримання копії заяви про відвід третейського судді, подати до Третейського суду свої мотивовані заперечення. У цьому випадку питання про відвід вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, а у разі розгляду справи у кількості трьох третейських суддів головою Третейського суду, його заступником, у відсутність голови Третейського суду, спільно з іншими третейськими суддями, призначеними у справі, рішення якого (яких) є обов'язковим для сторін. За результатами розгляду заяви про відвід третейського судді виноситься ухвала. Питання про відвід, яке заявлене голові Третейського суду, який здійснює розгляд справи як третейський суддя, вирішується заступником голови Третейського суду у порядку, визначеному цим Регламентом.

8. Питання про відвід вирішується без повідомлення учасників справи. За ініціативою голови Третейського суду, його заступника, у відсутність голови Третейського суду, питання про відвід може вирішуватися у засіданні третейського суду з повідомленням учасників справи. Неявка учасників справи у засідання третейського суду, в якому вирішується питання про відвід, не перешкоджає розгляду питання про відвід.

9. У разі задоволення заяви про відвід третейського судді, який розглядає справу одноособово, справа розглядається в Третейському суді іншим третейським суддею, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.

10. У разі задоволення заяви про відвід комусь із третейських суддів або всьому
складу суду, у разі колегіального вирішення спору, справа розглядається в Третейському суді тим самим кількісним складом колегії третейських суддів без участі відведеного третейського судді або іншим складом третейських суддів, який визначається у порядку, встановленому цим Регламентом. Розгляд справи проводиться спочатку.

11. Питання про самовідвід третейського судді вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, на підставі мотивованої заяви третейського судді.

12. Під час розгляду заяви про відвід Третейський суд, включаючи третейського суддю, якому заявлено відвід, продовжує третейський розгляд. Третейський суд не може ухвалити рішення доти, поки голова Третейського суду, його заступник, у відсутність голови Третейського суду, не постановить ухвалу за підсумками розгляду згаданої заяви.

13. Самовідвід третейського судді або згода сторони на припинення його повноважень не повинна розцінюватися як визнання залежності та упередженості третейського судді.

14. Особі, яка розглядає заяву про відвід, не може бути заявлений відвід.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 63. Підстави та порядок відводу чи самовідводу експерта, перекладача.`
  },
  {
    content: `1. Якщо експерт, перекладач особисто чи опосередковано заінтересовані у результаті розгляду справи, або якщо вони є родичами однієї із сторін або інших осіб, які беруть участь у справі, або перебувають з цими особами чи сторонами в особливих стосунках вони підлягають відводу чи самовідводу.

2. Питання про відвід експерта, перекладача вирішується складом третейським суддею, який розглядає справу одноособово, а у разі колегіального вирішення спору - складом третейських суддів.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 9. Пред'явлення позову`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 64. Пред'явлення позову.`
  },
  {
    content: `1. Провадження у справі порушується поданням позовної заяви.

2. Позовна заява подається у Третейський суд у письмовій формі і підписується керівником юридичної особи або іншою особою, яка має право діяти без довіреності від імені позивача, або фізичною особою-підприємцем, або фізичною особою, або уповноваженим довіреністю представником позивача.

3. Датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата отримання поштового відправлення представником Третейського суду, при відправленні позовної заяви через електронну приймальню – дата, зазначена в електронному повідомленні про отримання. При подачі позовної заяви через електронну приймальню, її оригінал має бути поданий позивачем в першому засіданні третейського суду.

4. У разі якщо сторона подає позовну заяву у зв'язку з правовідносинами, які вже є предметом третейського розгляду між тими ж сторонами у відповідності з цим Регламентом, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за заявою будь-якої з сторін може об'єднати вимоги, що містяться у різних позовних заявах в одне провадження. Про об’єднання справ в одне провадження, про відмову в об’єднанні справ в одне провадження виноситься ухвала.

5. Справи, що перебувають у провадженні Третейського суду, в разі об’єднання їх в одне провадження передаються на розгляд третейському судді, який раніше за інших суддів відкрив провадження у справі.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 65. Зміст позовної заяви.`
  },
  {
    content: `1. Позовна заява подається у письмовій формі.

2. У позовній заяві, що подається до Третейського суду, повинні зазначатися:

-назва Третейського суду;
-дата подання позовної заяви;
-повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові - для фізичних осіб) сторін та інших учасників справи, їхмісцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб); поштовий індекс; ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), а також реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України (якщо такі відомості відомі позивачу), вказівку на статус фізичної особи - підприємця (для фізичних осіб - підприємців); відомі номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти;
-повне найменування і місцезнаходження представника позивача, якщо він є юридичною особою або прізвище, ім'я, по батькові, дата народження, місце реєстрації представника, який є фізичною особою або фізичною особою-підприємцем, у випадках, коли позов подається представником; номери засобів зв’язку, офіційної електронної адреси та адреси електронної пошти представника;
-ціна позову, якщо позов підлягає грошовій оцінці;
-зміст позовних вимог: спосіб (способи) захисту прав або інтересів, передбачений законом чи договором, або інший спосіб (способи) захисту прав та інтересів, який не суперечить закону і який позивач просить Третейський суд визначити у рішенні; якщо позов подано до кількох відповідачів - зміст позовних вимог щодо кожного з них;
-виклад обставин, якими позивач обґрунтовує свої вимоги; зазначення доказів, що підтверджують вказані обставини; правові підстави позову;
-посилання на наявність Третейської угоди між сторонами та докази її укладення;
перелік письмових матеріалів, які додаються до позовної заяви;
-підпис позивача або його представника з посиланням на документ, що засвідчує повноваження представника.

3. У позовній заяві можуть бути вказані й інші відомості, необхідні для правильного вирішення спору.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 66. Документи, що додаються до позовної заяви.`
  },
  {
    content: `1. До позовної заяви додаються документи, які підтверджують:
1) наявність Третейської угоди;
2) відправлення іншим учасникам справи копії позовної заяви і доданих до неї документів;
3) обґрунтованість позовних вимог;
4)сплату третейського збору у встановленому цим Регламентом порядку і розмірі.

2. До позовної заяви, підписаної представником позивача, додається довіреність чи інший документ, що підтверджує повноваження представника позивача.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 67. Ціна позову.`
  },
  {
    content: `1. Ціна позву визначається:
1) у позовах про стягнення грошових коштів - сумою, яка стягується;
2) у позовах про визнання права власності на майно або його витребування – дійсною вартістю майна;
3) у позовах про розірвання договору найму (оренди) - сукупністю платежів за користування майном протягом строку, що залишається до кінця дії договору;
4)у позовах, які складаються з кількох самостійних вимог, - загальною сумою всіх вимог.

2. Якщо позивач не визначив або неправильно визначив ціну позову, або визначена позивачем ціна позову вочевидь не відповідає дійсній вартості спірного майна або на момент пред’явлення позову встановити точну його ціну неможливо, голова Третейського суду, його заступник, у відсутність голови Третейського суду, за власною ініціативою визначає ціну позову на підставі наявних відомостей та виносить
відповідну ухвалу з зазначенім строків для сплати третейського збору.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 68. Прийняття позовної заяви і порушення провадження у справі.`
  },
  {
    content: `1. Питання про прийняття позовної заяви до провадження Третейського суду вирішується головою Третейського суду, його заступником, у відсутність голови Третейського суду, у триденний строк з дня отримання позовної заяви Третейським судом.

2. При прийнятті позову Третейський суд вирішує питання про наявність і дійсність угоди про передачу спору на розгляд Третейського суду.

3. У разі, якщо Третейський суд дійде висновку про відсутність або недійсність зазначеної угоди, він відмовляє у розгляді справи. Про відмову у розгляді справи виноситься мотивована ухвала, яка надсилається сторонам. При цьому заявнику разом з ухвалою повертаються позовні матеріали.

4. Третейський суд приймає до провадження позовну заяву, подану з додержанням вимог цього Регламенту до його форми і змісту.

5. Про прийняття позовної заяви Третейський суд постановляє ухвалу, якою порушується провадження у справі.

6. В ухвалі зазначається на підготовку справи до третейського розгляду, дії, які належить виконати особам, які приймають участь у справі, і строки їх виконання.

7. Ухвала про порушення провадження у справі направляються особам, які приймають участь у справі, не пізніше трьох днів після дня її винесення.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 69. Усунення недоліків позовної заяви.`
  },
  {
    content: `1. Встановивши, що позовна заява подана без додержання вимог цього Регламенту, голова Третейського суду, його заступник, у відсутність голови Третейського суду, пропонує позивачу усунути виявлені недоліки.

2. Якщо ці недоліки будуть усунуті, то датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата штемпеля поштового відомства адресата.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 70. Відзив на позовну заяву.`
  },
  {
    content: `1. Відповідач повинен надати Третейському суду письмовий відзив на позовну заяву. Відзив на позовну заяву направляється Третейському суду по пошті, шляхом вручення або через електронну приймальню не менше ніж за три дні до першого засідання Третейського суду.

2. У випадку подачі відзиву на позовну заяву через електронну приймальню, його оригінал подається відповідачем в першому засіданні.

3. У відзиві відповідач викладає заперечення проти позову.

4. Відзив підписується відповідачем або його представником.

5. Відзив повинен містити:
1) найменування (ім’я) позивача і номер справи;
2) повне найменування (для юридичних осіб) або ім’я (прізвище, ім’я та по батькові) (для фізичних осіб) відповідача, його місцезнаходження (для юридичних осіб) або місце проживання чи перебування (для фізичних осіб), поштовий індекс, ідентифікаційний код юридичної особи в Єдиному державному реєстрі підприємств і організацій України (для юридичних осіб, зареєстрованих за законодавством України), реєстраційний номер облікової картки платника податків (для фізичних осіб) за його наявності або номер і серію паспорта для фізичних осіб - громадян України, номери засобів зв’язку, офіційну електронну адресу та адресу електронної пошти, за наявності;
3) у разі повного або часткового визнання позовних вимог - вимоги, які визнаються відповідачем;
4) обставини, які визнаються відповідачем, а також правову оцінку обставин, наданих позивачем, з якою відповідач погоджується;
5) заперечення (за наявності) щодо наведених позивачем обставин та правових підстав позову, з якими відповідач не погоджується, із посиланням на відповідні докази та норми права;
6) перелік документів та інших доказів, що додаються до відзиву, та зазначення документів і доказів, які не можуть бути подані разом із відзивом, із зазначенням причин їх неподання.

6. Копія відзиву та доданих до нього документів іншим учасникам справи повинна бути надіслана (надана) одночасно з надісланням (наданням) відзиву до Третейського суду.

7. До відзиву додаються:
1) докази, які підтверджують обставини, на яких ґрунтуються заперечення відповідача, якщо такі докази не надані позивачем;
2) документи, що підтверджують надіслання (надання) відзиву і доданих до нього доказів іншим учасникам справи.

8. До відзиву, підписаного представником відповідача, додається довіреність чи інший документ, що підтверджує повноваження представника відповідача.

9. У разі ненадання відповідачем відзиву у встановлений Третейським судом строк без поважних причин, Третейський суд вирішує справу за наявними матеріалами.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 71. Зустрічний позов.`
  },
  {
    content: `1. Відповідач вправі подати зустрічний позов для розгляду Третейським судом, якщо такий позов є підвідомчим Третейському суду та може бути предметом третейського розгляду відповідно до Третейської угоди.

2. Зустрічний позов може бути подано на будь-якій стадії третейського розгляду до прийняття рішення по справі.

3. Третейський суд приймає зустрічний позов до спільного розгляду з первісним позовом, якщо обидва позови взаємно пов'язані та їх спільний розгляд є доцільним, зокрема, коли вони випливають з одних і тих самих правовідносин або коли задоволення зустрічного позову може виключити повністю або частково задоволення первісного позову.

4. Зустрічна позовна заява має відповідати вимогам статті 35 Закону України "Про третейські суди" та статтям 65, 66 цього Регламенту.

5. Зустрічна позовна заява, подана з порушенням вимог цієї статті, ухвалою Третейського суду повертається заявнику. Копія зустрічної позовної заяви долучається до матеріалів справи.

6. Сторона зобов'язана надати відзив на пред'явлений до неї зустрічний позов у порядку та строки, визначені Третейським судом.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 72. Забезпечення позову.`
  },
  {
    content: `1. Якщо сторони не домовилися про інше, Третейський суд може за заявою будь-якої сторони розпорядитися про вжиття стороною таких забезпечувальних заходів щодо предмета спору, які він вважає необхідними, з урахуванням положень цивільного та господарського процесуального законодавства. Третейський суд може витребувати від будь-якої сторони надати належне забезпечення позову у зв'язку з такими заходами.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 10. Підготовка до третейського розгляду`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 73. Підготовка розгляду справи.`
  },
  {
    content: `1. Склад Третейського суду перевіряє стан підготовки справи до розгляду і, якщо визнає за необхідне, вживає додаткові заходи по підготовці справи.

2. Якщо склад Третейського суду приймає додаткові заходи по підготовці справи, він повинен встановити строки, на протязі яких ці додаткові заходи повинні бути виконані.

3. Головуючий складу Третейського суду, або третейський суддя, який розглядає справу одноособово, може давати відповідальному секретарю Третейського суду окремі доручення у зв'язку з підготовкою та проведенням розгляду справи. Він також доручає йому виклик сторін на засідання.

4. З метою правильного і своєчасного вирішення спору третейський суддя вчиняє в необхідних випадках такі дії по підготовці справи до розгляду:
-вирішує питання про залучення до участі у справі іншого відповідача та провиключення чи заміну неналежного відповідача;
-викликає представників сторін для уточнення обставин справи і з’ясовує, які матеріали може бути подано додатково;
-зобов’язує сторони, інших осіб, що приймають участь у справі, виконати певні дії (звірити розрахунки, надати документи, відомості, висновки, необхідні для вирішення спору, тощо);
-вирішує питання про призначення експертизи;
-вирішує питання про визнання явки представників сторін у засідання Третейського суду обов’язковою;
-вирішує питання про виклик посадових та інших осіб для дачі пояснень по суті справи;
-вирішує питання про вжиття заходів до забезпечення позову;
-вчиняє інші дії, спрямовані на забезпечення правильного і своєчасного розгляду справи.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 74. Подання та надсилання документів щодо третейського розгляду.`
  },
  {
    content: `1. Усі документи, що подаються однією із сторін до Третейського суду, повинні бути надіслані або передані іншій стороні з дотриманням правил, передбачених цією статтею. Сторонам також повинні бути надіслані або передані інші документи і докази, на яких може ґрунтуватися рішення третейського суду.

2. Своєчасне надсилання або передача сторонам усіх документів у справі забезпечуються третейським суддею.

3. Третейський суд повідомляє сторони та інших осіб, які беруть участь у третейському розгляді, про день, час та місце проведення засідання Третейського суду.

4. Листування здійснюється за офіційним місцезнаходженням юридичної особи або фізичної особи-підприємця згідно даних Єдиного державного реєстру юридичних осіб, фізичних осіб-підприємців та громадських формувань або за місцем проживання фізичної особи чи на електронну адресу, яка вказана у документах, надісланих Третейському суду або на документах, в яких міститься третейське застереження.

5. Якщо остання відома адреса місця проживання (перебування), місцезнаходження чи місця роботи учасників справи знаходиться на тимчасово окупованій території, Третейський суд викликає або повідомляє учасників справи, які не мають електронної адреси (електронного кабінету) через оголошення на веб-сайті Третейського суду (https://www.sud.te.ua/). На даній сторінці розміщуються оголошення про дату, час і місце судового засідання/розгляду справи, а також про ухвалення відповідного судового рішення. Указана інформація для учасників справи:
1) остання відома адреса місця проживання (перебування), місцезнаходження чи місця роботи яких знаходиться на тимчасово окупованій території;
2) остання відома адреса місця проживання (перебування), місцезнаходження чи місце роботи яких знаходиться в районі проведення антитерористичної операції;
3) зареєстроване місце проживання (перебування), місцезнаходження чи місце роботи яких невідоме.
Третейський суд викликає або повідомляє таких учасників справи про дату, час і місце першого судового засідання у справі через оголошення на веб-сайті Третейського суду, яке повинно бути розміщене не пізніше ніж за двадцять днів до дати відповідного судового засідання
Третейський суд викликає або повідомляє таких учасників справи про дату, час і місце інших судових засідань чи про вчинення відповідної процесуальної дії через оголошення на веб-сайті Третейського суду, яке повинно бути розміщене не пізніше ніж за десять днів до дати відповідного судового засідання або вчинення відповідної процесуальної дії.
З опублікуванням такого оголошення відповідач вважається повідомленим про дату, час і місце розгляду справи.

6. Учасники справи, остання відома адреса місця проживання (перебування) чи місцезнаходження яких знаходиться на тимчасово окупованій території і які не мають електронної адреси (електронного кабінету), повідомляються про ухвалення
відповідного судового рішення шляхом розміщення інформації на веб-сайті Третейського суду або шляхом розміщення тексту відповідного судового рішення на веб-сайті Третейського суду, з урахуванням вимог, визначених цим Регламентом.
З моменту розміщення такої інформації вважається, що особа отримала судове рішення.

7. Передбачений частинами 5 та 6 цієї статті порядок виклику у Третейський суд та повідомлення про судове рішення може застосовуватися стосовно інших учасників судового процесу, адреса місця проживання (перебування), місцезнаходження чи місця роботи яких знаходиться на тимчасово окупованій території, якщо від цього залежить реалізація ними своїх процесуальних прав і обов’язків.

8. Сторони зобов’язані невідкладно повідомити Третейський суд про зміну своєї адреси (у тому числі електронної адреси) у спосіб, що передбачає фіксацію надходження такого повідомлення.
У разі відсутності заяви про зміну місця проживання або місцезнаходження документи надсилаються сторонам справи на останню відому Третейському суду адресу і вважається доставленою, навіть якщо сторона процесу за цією адресою більше не проживає або не знаходиться.
Позовна заява, відзив на позов, інші заяви, клопотання та заперечення з процесуальних питань, судове повідомлення, рішення, ухвала Третейського суду, надсилаються сторонам рекомендованим листом з повідомленням про вручення чи вручаються їх представникам особисто під розписку або на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження, з дотриманням правил, визначених цим Регламентом.

9. Документи можуть надсилатися будь-яким поштовим оператором, за умови дотримання вимог цієї статті.

10. Днем вручення документів, пов’язаних з розглядом справи, є:
- день вручення документів під розписку;
- день отримання повідомлення про доставлення судової повістки на електронну адресу особи;
- день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, повідомленою цією особою Третейському суду;
- день проставлення у поштовому повідомленні відмітки про відмову отримати відправлення чи відмітки про повернення листа у зв’язку із закінченням строків зберігання чи відмітки про відсутність особи за адресою місцезнаходження, місця проживання чи перебування особи, що зареєстровані у встановленому законом порядку, якщо ця особа не повідомила Третейському суду іншої адреси.
Якщо документи надіслано на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження, відправлення вважається врученим у робочий день, в який його було надіслано, а якщо його було надіслано пізніше 17 години, відправлення вважається врученим у робочий день, наступний за днем її відправлення, незалежно від надходження до суду повідомлення про її доставлення.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 11. Сприяння сторонам у досягненні ними мирової угоди`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 75. Примирення сторін.`
  },
  {
    content: `1. Третейський суд застосовує заходи для примирення сторін, сприяє їм у вирішенні спору шляхом укладення мирової угоди на всіх стадіях процесу.

2. Третейський суд на початку розгляду справи повинен з'ясувати у сторін можливість закінчити справу мировою угодою.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 76. Укладення мирової угоди.`
  },
  {
    content: `1. Сторони мають право закінчити справу укладенням мирової угоди, як до початку третейського розгляду, так і на будь-якій його стадії, до прийняття рішення.

2. Мирова угода не повинна порушувати права і законні інтереси інших осіб і суперечити закону.

3. Мирова угода затверджується Третейським судом.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 77. Вирішення питання щодо затвердження мирової угоди.`
  },
  {
    content: `1. За клопотанням сторін Третейський суд приймає рішення про затвердження мирової угоди. Мирова угоди може стосуватися лише прав і обов'язків сторін щодо предмета спору.

2. Зміст мирової угоди викладається безпосередньо в рішенні Третейського суду. До рішень третейського суду, прийнятих відповідно до цієї статті, застосовуються правила розділу VI Закону України "Про третейські суди".

3. Третейський суд не затверджує мирову угоду, якщо вона суперечить закону або порушує права і законні інтереси інших осіб.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 12. Зупинення провадження у справі`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 78. Обов'язок Третейського суду зупинити провадження у справі.`
  },
  {
    content: `1. Третейський суд зобов'язаний зупинити провадження у справі у випадках:
1)неможливості розгляду цієї справи до вирішення пов'язаної з нею іншої справи, що розглядається судом або відповідного питання компетентними органами;
2)злиття, приєднання, поділу, перетворення юридичної особи, яка була стороною у справі або у зв'язку зі смертю або оголошення фізичної особи померлою, яка була стороною у справі, якщо спірні правовідносини допускають правонаступництво;
3)призначення Третейським судом експертизи;
4)надсилання Третейським судом матеріалів до слідчих органів.

2. Третейський суд зупиняє провадження у справі також в інших випадках, передбачених цим Регламентом.

3. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 79. Право Третейського суду зупинити провадження у справі.`
  },
  {
    content: `1. Третейський суд може за заявою особи, яка бере участь у справі, а також з власної ініціативи зупинити провадження у справі у випадках:
1) перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
2) захворювання сторони, підтвердженого медичною довідкою, що виключає можливість явки до суду протягом тривалого часу;
3) перебування сторони у тривалому службовому відрядженні;
4) в інших випадках, коли Третейський суд вважатиме за необхідне зупинити провадження у справі, у зв'язку з неможливістю її розгляду.

2. З питань, зазначених у цій статті, Третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 80. Строки, на які зупиняється провадження у справі.`
  },
  {
    content: `1. Провадження у справі зупиняється у випадках, встановлених:
-пунктом 1 частини першої статті 78 цього Регламенту - до набрання законної сили судового рішення, від якого залежить вирішення справи або рішення відповідного компетентного органу;
-пунктом 2 частини першої статті 78 цього Регламенту - до залучення до участі у справі правонаступника чи представника;
-пунктом 3 частини першої статті 78 цього Регламенту - на час проведення експертизи;
-пунктом 4 частини першої статті 78 цього Регламенту - на час проведення відповідної перевірки;
-пунктом 1 частини першої статті 79 цього Регламенту - до припинення перебування сторони на строковій військовій службі або альтернативній(невійськовій) службі не за місцем проживання;
-пунктом 2 частини першої статті 79 цього Регламенту - на час хвороби сторони;
-пунктами 3-4 частини першої статті 79 цього Регламенту - до усунення обставин, що зумовили зупинення провадження у справі.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 81. Відновлення провадження у справі.`
  },
  {
    content: `1. Провадження у справі відновлюється ухвалою Третейського суду за заявою особи, яка бере участь у справі, або з ініціативи суду після усунення обставин, що викликали його зупинення.

2. З дня відновлення провадження у справі перебіг процесуальних строків продовжується.

3. Після відновлення провадження Третейський суд викликає сторони та інших осіб, які беруть участь у справі, і продовжує третейський розгляд.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 13. Залишення позовної заяви без розгляду`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 82. Підстави для залишення позовної заяви без розгляду.`
  },
  {
    content: `1. Третейський суд залишає позовну заяву без розгляду, якщо після її прийняття до розгляду встановить, що:
1) позов подано особою, яка не має процесуальної дієздатності;
2) позовну заяву не підписано або підписано особою, яка не має права підписувати її, або особою, посадове становище якої не вказано;
3) позивач без поважних причин не подав витребувані Третейським судом докази, необхідні для вирішення спору, або позивач (його представник) не з’явився у засідання Третейського суду або не повідомив про причини неявки, крім випадку, якщо від нього надійшла заява про розгляд справи за його відсутності і його нез’явлення не перешкоджає вирішенню спору;
4) спір між тими самими сторонами, про той самий предмет і з тих самих підстав розглядається в іншому суді;
5) позивач ухиляється від замовлення проведення експертизи, призначеної Третейським судом, і якщо неможливо розглянути справу за допомогою дослідження інших доказів;
6) позовна заява не відповідає вимогам цього Регламенту, або не було сплачено третейський збір, і позивач не усунув цих недоліків у встановлений Третейським судом строк;
7) позивач подав заяву про залишення позову без розгляду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 83. Порядок і наслідки залишення позовної заяви без розгляду.`
  },
  {
    content: `1. У разі залишення позовної заяви без розгляду виноситься ухвала, в якій зазначаються підстави для залишення позовної заяви без розгляду. Копії ухвали направляються особам, які приймають участь у справі.

2. Залишення позовної заяви без розгляду не позбавляє позивача права знову звернутись у Третейський суд з позовною заявою у загальному порядку після усунення обставин, що послугували підставою для залишення заяви без розгляду.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 14. Припинення третейського розгляду`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 84. Підстави припинення третейського розгляду.`
  },
  {
    content: `1. Підставами для припинення третейського розгляду є такі випадки:
1) спір не підлягає вирішенню в третейських судах України;
2) відсутній предмет спору;
3) є рішення компетентного суду між тими ж сторонами, з того ж предмета і з тих самих підстав;
4) позивач відмовився від позову і відмову прийнято Третейським судом;
5) сторони уклали угоду про припинення третейського розгляду і вона затверджена Третейським судом;
6) підприємство, установу чи організацію, які є стороною третейського розгляду, ліквідовано;
Третейський суд є некомпетентним щодо переданого на його розгляд спору;
7) у разі смерті фізичної особи (фізичної особи-підприємця), яка була стороною у справі, якщо спірні правовідносини не допускають правонаступництва.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 85. Порядок і наслідки припинення третейського розгляду.`
  },
  {
    content: `1. Про припинення третейського розгляду Третейський суд постановляє ухвалу. В ухвалі Третейський суд зазначає підстави для припинення третейського розгляду, а також вирішує питання про розподіл між сторонами витрат. Копії ухвали направляються особам, які приймають участь у справі.

2. У разі припинення третейського розгляду повторне звернення до Третейського суду з приводу спору між тими самими сторонами, про той самий предмет і з тих самих підстав не допускається. Наявність ухвали про припинення третейського розгляду у зв'язку з прийняттям відмови позивача від позову не позбавляє відповідача в цій справі права на звернення до Третейського суду за вирішенням цього спору.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 15. Третейський розгляд`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 86. Засідання Третейського суду.`
  },
  {
    content: `1. Третейський розгляд здійснюється у засіданні Третейського суду за участю сторін або їх представників. Сторона має право заявити Третейському суду клопотання щодо розгляду спору без її участі.

2. Третейський суд вправі визнати явку сторін у засідання обов'язковою.

3. Третейський суддя, а при колегіальному вирішенні спору – головуючий у засіданні Третейського суду:
1) відкриває засідання Третейського суду і оголошує, яка справа підлягає розгляду;
2) перевіряє явку в засідання осіб, які приймають участь у справі, їх представників та інших учасників третейського розгляду, встановлює їх особи і перевіряє їх повноваження;
3) встановлює, чи повідомлені належним чином особи, які не з'явились у засідання, і які є відомості про причини їх неявки;
4) з'ясовує питання щодо можливості розгляду справи;
5) оголошує склад Третейського суду, повідомляє, хто веде протокол засідання третейського розгляду, хто приймає участь в якості експерта, перекладача, і роз'яснює особам, які приймають участь у справі, їх право заявляти відводи;
6) роз'яснює особам, які приймають участь у справі, та іншим учасникам третейського процесу їх процесуальні права і обов'язки;
7) видаляє з місця третейського розгляду свідків до початку їх опитування;
8) визначає з врахуванням думки осіб, які приймають участь у справі, послідовність проведення процесуальних дій;
9) з'ясовує, чи підтримує позивач позов, чи визнає позов відповідач, чи не бажають сторони закінчить справу мировою угодою;
10) керує засіданням Третейського суду, забезпечує умови для всебічного і повного дослідження доказів і обставин справи, забезпечує розгляд заяв і клопотань осіб, які приймають участь у справі.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 87. Протокол засідання Третейського суду.`
  },
  {
    content: `1. Протокол засідання Третейського суду ведеться лише в разі наявності угоди між сторонами про ведення протоколу.

2. Для ведення протоколу за згодою третейських суддів (третейського судді) сторонами може бути призначений секретар третейського розгляду. За його відсутності третейські судді можуть обрати секретаря із складу (крім головуючого) третейського суду, яким вирішується спір.

3. У протоколі засідання третейського суду зазначаються:
1) дата та місце розгляду справи,
2) прізвища третейських суддів і секретаря;
3) суть спору;
4) найменування сторін, їхніх представників, свідків, експертів, інших осіб, якіберуть участь у розгляді справи;
5) заяви та клопотання сторін;
6) вказівки та вимоги до сторін по справі, висунуті Третейським судом;
7) розклад засідань третейського суду;
8) зміст пояснень сторін та інших осіб, які беруть участь у справі;
9) подані під час третейського розгляду докази, відомості про їх огляд;
10) узгодження сторонами питання про необхідність зберігання та умови зберігання рішень Третейського суду;
11) витрати, понесені сторонами.

4. Протокол підписується секретарем та всіма третейськими суддями, які розглядали справу.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 88. Підстави та порядок відводу секретаря третейського розгляду, який веде протокол.`
  },
  {
    content: `1. Якщо секретар особисто чи опосередковано заінтересований у результаті розгляду справи, або якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках він підлягає відводу чи самовідводу.

2. Питання про відвід секретаря вирішується складом Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 89. Наслідки неподання сторонами документів, інших письмових матеріалів або неявки сторін.`
  },
  {
    content: `1. Ненадання витребуваних Третейським судом доказів, інших письмових документів чи матеріалів, неявка у засідання Третейського суду сторін або їхніх представників, які належним чином були повідомлені про розгляд справи і проведення засідання Третейського суду, не є перешкодою для третейського розгляду і прийняті рішення, якщо причина ненадання документів або неявки сторін у засідання визнана Третейським судом неповажною.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 90. Наслідки неявки в засідання експертів, свідків, перекладачів.`
  },
  {
    content: `1. При неявці в засідання Третейського суду експертів, свідків, перекладачів, належним чином повідомлених про час і місце засідань Третейський суд виносить ухвалу про відкладення третейського розгляду, якщо сторони не заявили клопотання про розгляд справи за відсутністю зазначених осіб.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 91. Відкладення засідання Третейського суду.`
  },
  {
    content: `1. Третейський суд відкладає третейський розгляд у випадках, передбачених цим Регламентом, а також у разі неявки в засідання Третейського суду особи, яка приймає участь у справі, якщо у відношенні цієї особи у суду відсутні відомості про повідомлення її про час і місце третейського розгляду.

2. Третейський суд може відкласти третейський розгляд за клопотанням сторін.

3. У разі якщо особа, яка приймає участь у справі і повідомлена належним чином про час і місце третейського розгляду, заявила клопотання про відкладення третейського розгляду з обґрунтуванням причини неявки у засідання, Третейський суд може відкласти третейський розгляд, якщо визнає причини неявки поважними.

4. Третейський суд може відкласти третейський розгляд за клопотанням особи, яка приймає участь у справі, у зв'язку з неявкою у засідання його представника з поважної причини.

5. Третейський суд може відкласти третейський розгляд, якщо визнає, що він не може відбутися в даному засіданні, у тому числі внаслідок неявки будь-кого з осіб, які приймають участь у справі, інших учасників третейського процесу, а також при задоволенні клопотання сторони про відкладення третейського розгляду у зв'язку з необхідністю надання нею додаткових доказів, при здійсненні інших процесуальних дій.

6. При відкладенні третейського розгляду Третейський суд вправі допитати свідків, які з'явилися, якщо в засіданні присутні сторони або їх представники.
Показання цих свідків оголошуються у новому засіданні Третейського суду.

7. Третейський розгляд може бути відкладено на строк, необхідний для усунення обставин, що послугували підставою для відкладення, в межах строку розгляду справи, встановленого цим Регламентом.

8. Про відкладення третейського розгляду Третейський суд виносить ухвалу.

9. Про час і місце нового засідання Третейський суд повідомляє осіб, які приймають участь у справі, та інших учасників третейського розгляду. Особи, які з'явились в засідання, повідомляються про час і місце нового засідання безпосередньо в засіданні Третейського суду під розписку.

10. Третейський розгляд в новому засіданні Третейського суду поновлюється з того моменту, з якого воно було відкладено. Повторний розгляд доказів, досліджених до відкладення третейського розгляду, не провадиться.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 92. Розгляд Третейським судом заяв і клопотань осіб, які приймають участь у справі.`
  },
  {
    content: `1. Заяви і клопотання осіб, які приймають участь у справі, розглядаються Третейським судом після того, як буде заслухана думка решти присутніх у засіданні Третейського суду осіб, які беруть участь у справі.

2. За результатами розгляду заяв і клопотань Третейський суд виносить ухвали.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 93. Дослідження доказів.`
  },
  {
    content: `1. При розгляді справи Третейський суд повинен безпосередньо дослідити докази у справі: ознайомитися з письмовими доказами, оглянути речові докази, заслухати пояснення осіб, які приймають участь у справі, показання свідків, висновки експертизи, а також оголосити такі пояснення, показання, висновки, надані у письмовій формі.

2. Особа, яка приймає участь у справі, вправі дати Третейському суду пояснення про представлені нею докази, і докази, витребувані Третейським судом за її клопотанням, а також задати питання викликаним у засідання Третейського суду експертам і свідкам. При цьому першим задає питання особа, за клопотанням якої були викликані експерти і свідки.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 94. Перерва у засіданні Третейського суду.`
  },
  {
    content: `1. Третейський суд за клопотанням особи, яка приймає участь у справі, або за власною ініціативою може оголосити перерву у засіданні Третейського суду.

2. Перерва у засіданні третейського суду може бути об'явлена на строк, що не перевищує п'яти днів.

3. Щодо перерви і час продовження засідання, зазначається в ухвалі Третейського суду.

4. Після закінчення перерви засідання Третейського суду продовжується. Повторний розгляд досліджених до перерви доказів не провадиться, у тому числі при заміні представників осіб, які приймають участь у справі.

5. Особи, які приймають участь у справі і присутні в засіданні Третейського суду до оголошення перерви, вважаються належним чином оповіщеними про час і місце засідання Третейського суду, і їх нез'явлення у засідання після закінчення перерви не є перешкодою для його продовження.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 95. Проведення усного слухання.`
  },
  {
    content: `1. Для викладення сторонами своїх позицій на підставі доказів, що надаються та для проведення усних промов сторін здійснюється усне слухання справи.

2. Слухання проводиться у закритому засіданні. З дозволу складу Третейського суду, або третейського судді, який розглядає справу одноособово, і зі згоди сторін під час слухання можуть бути присутні особи, які не приймають участі у третейському розгляді.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 96. Розгляд на підставі письмових матеріалів.`
  },
  {
    content: `1. Сторони можуть домовитись про розгляд спору на підставі тільки письмових матеріалів наданих сторонами, без проведення усного слухання.


2. Якщо надані матеріали виявляться недостатніми для розгляду спору, склад Третейського суду, або суддя, який розглядає справу одноособово, може призначити усне слухання.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 97. Промови сторін.`
  },
  {
    content: `1. Після з'ясування всіх обставин справи та перевірки їх доказами головуючий у засіданні Третейського суду з'ясовує у осіб, які приймають участь у справі, чи не бажають вони доповнити матеріали справи. За відсутністю таких заяв головуючий у засіданні оголошує дослідження доказів закінченими, і Третейський суд надає право особам, які приймають участь у справі висловити свою думку про те, як повинна бути вирішена справа, дати оцінку доказам, зробити висновки про встановлення чи не встановлення фактів, що мають значення для правильного розгляду справи, висловитись відносно правової кваліфікації спірних правовідносин з посиланням на закон, який, за їх міркуванням, регулює ці правовідносини, але лише з посиланням на обставини і докази, які були досліджені у засіданні Третейського суду.

2. Черговість виступу сторін встановлюється суддею Третейського суду, а у разі розгляду справи колегіально - складом колегії суддів.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 98. Відновлення дослідження доказів.`
  },
  {
    content: `1. У разі якщо Третейський суд під час або після промов сторін визнає за необхідне з'ясувати додаткові обставини або дослідити нові докази, Третейський суд поновлює дослідження доказів, про що виноситься ухвала.

2. Після закінчення додаткового дослідження доказів, промови сторін проводяться у загальному порядку, встановленому цим Регламентом.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 99. Закінчення розгляду справи по суті.`
  },
  { content: `1. Після того як Третейський суд переконається, що сторони мали достатньо можливостей для викладення своєї позиції та для подання доказів, Третейський суд оголошує про завершення третейського розгляду у частині дослідження обставин справи і переходить до прийняття рішення.` },
  {
    center: true,
    bold: true,
    content: `Глава 16. Рішення Третейського суду`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 100. Прийняття рішення Третейським судом.`
  },
  {
    content: `1. Рішення Третейського суду приймається після дослідження усіх обставин справи третейським суддею, що одноособово розглядав справу, або більшістю голосів третейських суддів, які входять до складу Третейського суду. Рішення приймається у закритій нараді Третейського суду.

2. Рішення оголошується у засіданні Третейського суду.

3. Третейський суддя, який не згодний із прийнятим рішенням (частково або в цілому), може викласти у письмовій формі свою окрему думку.

4. Третейський суд вправі оголосити лише резолютивну частину рішення. У цьому випадку, якщо сторони не погодили строк направлення їм рішення, мотивоване рішення має бути направлене сторонам у строк, який не перевищує п'яти днів з дня оголошення резолютивної частини рішення.

5. Кожній стороні направляється по одному примірнику рішення.

6. У разі відмови сторони одержати рішення Третейського суду або її неявки без поважних причин у засідання Третейського суду, де воно оголошується, рішення вважається таким, що оголошене сторонам, про що на рішенні робиться відповідна відмітка, а копія такого рішення надсилається такій стороні.

7. Датою прийняття рішення є дата оголошення рішення у засіданні Третейського суду або оголошення його резолютивної частини.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 101. Вимоги до рішення Третейського суду.`
  },
  {
    content: `1. Рішення Третейського суду викладається у письмовій формі і підписується третейським суддею, який одноособово розглядав справу, або повним складом
третейського суду, що розглядав справу, в тому числі і третейським суддею, який має окрему думку. Окрема думка третейського судді викладається письмово та додається до рішення Третейського суду.


2. Рішення Третейського суду скріплюється підписом Президента Асоціації "ПРАВОВА ЛІГА" та круглою печаткою Асоціації "ПРАВОВА ЛІГА".

3. У рішенні Третейського суду повинні бути зазначені:
-назва Третейського суду;
-дата прийняття рішення;
-склад третейського суду і порядок його формування:
-місце третейського розгляду;
-сторони, їх представники та інші учасники третейського розгляду, що брали участь у розгляді -справи третейським судом;
-висновок про компетенцію Третейського суду, обсяг його повноважень за Третейською угодою;
-стислий виклад позовної заяви, відзиву на позовну заяву, заяв, пояснень, клопотань сторін та їхніх представників, інших учасників третейського розгляду;
-встановлені обставини справи, підстави виникнення спору, докази, на підставі яких прийнято рішення, зміст мирової угоди, якщо вона укладена сторонами, мотиви, з яких Третейський суд відхилив доводи, докази та заявлені під час третейського розгляду клопотання сторін;
-висновок про задоволення позову або про відмову в позові повністю або частково по кожній із заявлених вимог;
-норми законодавства, якими керувався Третейський суд при прийнятті рішення.

4. Висновки третейського суду, що містяться в рішенні по справі, не можуть залежати від настання або ненастання будь-яких обставин.

5. У разі задоволення позовних вимог у резолютивній частині рішення зазначаються:
1) сторона, на користь якої вирішено спір;
2) сторона, з якої за рішенням Третейського суду має бути здійснено стягнення грошових сум та/або яка зобов'язана виконати певні дії або утриматися від виконання певних дій;
3) розмір грошової суми, яка підлягає стягненню, та/або дії, які підлягають виконанню або від виконання яких сторона має утриматися за рішенням Третейського суду;
4) строк сплати коштів та/або строк і спосіб виконання таких дій;
5) порядок розподілу між сторонами витрат, пов'язаних з вирішенням спору третейським судом;
інші обставини, які Третейський суд вважає за необхідне зазначити.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 102. Додаткове рішення.`
  },
  {
    content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, може протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про прийняття додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні.

2. Заяву про прийняття додаткового рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.

3. За результатами розгляду заяви приймається додаткове рішення, яке є складовою частиною рішення Третейського суду, або виноситься мотивована ухвала про відмову у задоволенні заяви про прийняття додаткового рішення.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 103. Роз'яснення рішення.`
  },
  {
    content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, має право протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про роз'яснення резолютивної частини рішення.

2. Заяву про роз'яснення резолютивної частини рішення має бути розглянуто тим складом Третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.

3. За результатами розгляду заяви виноситься ухвала про роз'яснення рішення, яка є складовою частиною рішення, або мотивована ухвала про відмову у роз'ясненні рішення.

4. Здійснюючи роз'яснення резолютивної частини рішення, Третейський суд не має права змінювати зміст рішення.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 104. Виправлення рішення.`
  },
  {
    content: `1. Третейський суд у тому ж складі, з власної ініціативи або за заявою сторони третейського розгляду, може виправити у рішенні описки, арифметичні помилки або будь-які інші неточності, про що виноситься ухвала, яка є складовою частиною рішення.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 105. Обов'язковість рішення Третейського суду.`
  },
  {
    content: `1. Сторони, які передали спір на вирішення Третейського суду, зобов'язані добровільно виконати рішення Третейського суду, без будь-яких зволікань чи застережень.

2. Сторони та Третейський суд вживають усіх необхідних заходів з метою забезпечення виконання рішення Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 106. Оскарження рішення Третейського суду.`
  },
  {
    content: `1. Рішення Третейського суду є остаточним і оскарженню не підлягає, крім випадків, передбачених Законом України "Про третейські суди".

2. Рішення Третейського суду може бути оскаржене стороною у випадках, передбачених Законом України "Про третейські суди", до компетентного суду відповідно до встановлених законом підвідомчості та підсудності справ.

3. Рішення Третейського суду може бути оскаржене та скасоване лише з таких підстав:
1) справа, по якій прийнято рішення Третейського суду, не підвідомча Третейському суду відповідно до закону;
2) рішення Третейського суду прийнято у спорі, не передбаченому третейською угодою, або цим рішенням вирішені питання, які виходять за межі Третейської угоди. Якщо рішенням Третейського суду вирішені питання, які виходять за межі Третейської угоди, то скасовано може бути лише ту частину рішення, що стосується питань, які виходять за межі Третейської угоди;
3) Третейську угоду визнано недійсною компетентним судом;
4) склад Третейського суду, яким прийнято рішення, не відповідав вимогам статей 16, 19 Закону України "Про третейські суди".

4. Заяву про скасування рішення Третейського суду може бути подано до компетентного суду протягом трьох місяців з дня прийняття рішення Третейським судом.

5. Скасування компетентним судом рішення Третейського суду не позбавляє сторони права повторно звернутися до Третейського суду, крім випадків, передбачених цією статтею.

6. У разі якщо рішення Третейського суду скасовано повністю або частково внаслідок визнання компетентним судом недійсною Третейської угоди або через те, що рішення прийнято у спорі, який не передбачений Третейською угодою, чи цим рішенням вирішені питання, що виходять за межі Третейської угоди, відповідний спір не підлягає подальшому розгляду в третейських судах.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 17. Ухвали Третейського суду`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 107. Винесення Третейським судом ухвал.`
  },
  {
    content: `1. З питань, що виникають у процесі розгляду справи і не стосуються суті спору, Третейський суд постановляє ухвали.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 108. Зміст ухвали`
  },
  {
    content: `1. В ухвалі повинні бути зазначені:
1) дата і місце винесення ухвали;
2) найменування Третейського суду, склад суду;
3) найменування і номер справи;
4) найменування осіб, які приймають участь у справі;
5) питання, з якого виноситься ухвала;
6) мотиви, з яких Третейський суд дійшов до висновків, прийняв або відхилив доводи осіб, які приймають участь у справі, з посиланням на закони та інші нормативно-правові акти;
7) висновок за результатами розгляду Третейським судом питання.
Ухвала, підписується третейським суддею або складом Третейського суду, який виніс цю ухвалу.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 109. Направлення ухвали.`
  },
  {
    content: `1. Копії ухвали, направляються особам, які приймають участь у справі, та іншими особам, яких вона стосується, рекомендованим листом з повідомленням про вручення чи вручаються їм під розписку, або на електронну адресу, яка вказана у документах надісланих Третейському суду або на документах, в яких міститься третейське застереження.

2. Копії ухвали направляються в п'ятиденний строк з дня винесення ухвали, якщо інший строк не встановлений цим Регламентом.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 110. Виконання ухвали.`
  },
  {
    content: `1. Ухвала, винесена Третейським судом, виконується негайно, якщо інше не встановлено цим Регламентом або Третейським судом.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 18. Інше`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 111. Зберігання матеріалів справи, розглянутої Третейським судом.`
  },
  {
    content: `1. Справа, розглянута Третейським судом, зберігається у Третейському суді протягом 10 років з дня прийняття рішення Третейського суду.`
  },
  {
    center: true,
    italic: true,
    content: `Стаття 112. Публікація рішень Третейського суду.`
  },
  {
    content: `1. Опублікування винесених Третейським судом рішень дозволяється з дозволу голови Третейського суду, його заступника, у відсутність голови Третейського суду.

2. При опублікуванні таких рішень необхідно забезпечити нерозголошення комерційної таємниці, слідкувати за тим, щоб не зазначались найменування контрагентів у правочині, найменування цінних паперів, цін, якщо інше не дозволено сторонами справи. голова Третейського суду, його заступник, у відсутність голови Третейського суду, може не допустити до опублікування й інші відомості.`
  }

];

const regulationsContent2007 = [
  {
    right: true,
    content: `	
«ЗАТВЕРДЖЕНО»
Зборами Ради Асоціації “Правова Ліга”
Протокол №1 від 22.03.2007р.

Президент Асоціації “Правова Ліга”
Кудiнов В.В.
 
_________________`
  },
  {
    center: true,
    bold: true,
    content: `Регламент
Постійно діючого третейського суду
при Асоціації «Правова Ліга»

 
Розділ І. Загальні положення
Глава 1. Загальні положення
 `
  },
  {
    italic: true,
    content: `Стаття 1. Статус третейського суду`
  },
  {
    content: `1. Постійно діючий третейський суд при Асоціації “Правова Ліга” (далі - Третейський суд) є недержавним незалежним, постійно діючим третейським судом та здійснює свою діяльність у відповідності з чинним законодавством, що регулює діяльність третейських судів в Україні.

2. Третейський суд діє при Асоціації “Правова Ліга”, яка матеріально і організаційно забезпечує його діяльність.

3. Функцією Третейського суду є представлення можливості врегулювання спорів і захисту порушених або оспорюваних цивільних прав шляхом третейського розгляду у відповідності з цим Регламентом Третейського суду при Асоціації “Правова Ліга” (далі - Регламент). Діяльність Третейського суду та третейських суддів не є підприємницькою.

4. На розгляд Третейського суду можуть бути передані спори, що підвідомчі третейським судам у відповідності з Законом України «Про третейські суди».`
  },
  {
    italic: true,
    content: `Стаття 2. Компетенція Третейського суду`
  },
  {
    content: `1. Третейський суд порушує справи за позовними заявами підприємств, організацій та фізичних осіб, які звертаються до суду за захистом своїх прав та охоронюваних законом інтересів.

2. Третейський суд з дотриманням вимог Закону України “Про третейські суди” самостійно вирішує питання про наявність або відсутність у нього компетенції для розгляду конкретного спору.

3. Третейський суд розглядає спори за наявністю третейського застереження у договорі, контракті або у вигляді окремої письмової угоди (далі - Третейська угода) між сторонами про передачу на його вирішення конкретного спору, визначених категорій або всіх спорів, що виникли або можуть виникнути між сторонами у зв'язку з будь-яким правовідношенням незалежно від того, мало воно чи ні договірний характер.

4. Третейська угода укладається у письмовій формі. Третейська угода вважається укладеною у письмовій формі, якщо вона міститься в документі, підписаному сторонами чи укладена шляхом обміну листами, повідомленнями по телетайпу, телеграфу або з використанням інших засобів електронного чи іншого зв'язку, що забезпечує фіксацію такої угоди або шляхом направлення відзиву на позов, в якому одна із сторін підтверджує наявність угоди, а інша сторона проти цього не заперечує. Посилання у договорі, контракті на документ, який містить умову про третейський розгляд спору, є третейською угодою за умови, що договір укладений у письмовій формі і це посилання є таким, що робить третейську угоду частиною договору.

5. Якщо сторони домовились звернутися до Третейського суду, Регламент для них є обов'язковим.

6. Питання щодо компетенції Третейського суду по конкретній справі вирішується головою Третейського суду, складом Третейського суду чи третейським суддею, який розглядає спір одноособово. При цьому Третейська угода, що є частиною договору, тлумачиться як угода, яка не залежить від інших умов договору. Недійсність окремих положень договору, контракту, що містить третейське застереження, не тягне за собою недійсність такого третейського застереження.`
  },
  {
    italic: true,
    content: `Стаття 3. Законодавство, яке застосовується Третейським судом при вирішенні спорів`
  },
  {
    content: `1. Третейські суди вирішують спори на підставі Конституції та законів України, інших нормативно-правових актів та міжнародних договорів України.

2. Третейський суд у випадках, передбачених законом або міжнародним договором України, застосовує норми права інших держав.

3. У разі відсутності законодавства, що регулює певні спірні відносини, третейські суди застосовують законодавство, яке регулює подібні відносини, а за відсутності такого третейські суди застосовують аналогію права чи керуються торговими та іншими звичаями, якщо останні за своїм характером та змістом властиві таким спірним відносинам.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 2. Склад третейського суду`
  },
  {
    italic: true,
    content: `Стаття 4. Третейські судді`
  },
  {
    content: `1. Третейські судді обираються зборами Ради Асоціації “Правова Ліга” строком на 5 (п'ять) років з числа фізичних осіб, які мають знання, досвід ділові та моральні якості, необхідні для вирішення спору.

2. Список обраних третейських суддів, у тому числі голови Третейського суду, подається до головного управління юстиції у Тернопільській області. У разі змін складу третейських суддів, такі зміни подаються до головного управління юстиції у Тернопільській області протягом 15 днів з моменту їх виникнення.

3. Третейськими суддями Третейського суду не можуть бути: особи, які не досягли повноліття, та особи, які перебувають під опікою чи піклуванням; особи, які не мають кваліфікації визначеної Регламентом; особи, які мають судимість; особи, визнані в судовому порядку недієздатними.

4. У разі одноособового вирішення спору третейський суддя постійно діючого третейського суду повинен мати вищу юридичну освіту. У разі колегіального вирішення спору вимоги щодо наявності вищої юридичної освіти поширюються лише на головуючого складу третейського суду.

5. При вирішенні спору третейським судом для вирішення конкретного спору угодою сторін можуть бути встановлені додаткові вимоги до третейських суддів.`
  },
  {
    italic: true,
    content: `Стаття 5. Голова Третейського суду`
  },
  {
    content: `1. Голова Третейського суду обирається Радою Асоціації “Правова Ліга” строком на 5 (п'ять) років.

2. Головою Третейського суду може бути обрана фізична особа, яка має досвід роботи за юридичною спеціальністю не менше 10 (десяти) років і має вищу юридичну освіту.

4. Голова Третейського суду представляє Третейський суд у його відношеннях з іншими організаціями, підприємствами і установами, державними органами, загальними судами, господарськими судами та іншими особами. При організації діяльності Третейського суду, голова виконує функції, передбачені цим Регламентом та Положенням про Третейський суд.

5. Голова Третейського суду має право бути третейським суддею у вирішенні спорів Третейським судом.

Стаття 6. Вимоги до третейських суддів при формуванні третейського суду

Третейським суддею, який розглядає справу одноособово, третейськими суддями у разі розгляду справи у складі трьох третейських суддів, можуть бути призначені особи із списку третейських суддів Третейського суду за їх згодою, які прямо чи опосередковано не заінтересовані в результаті вирішення спору, а також мають необхідний досвід, ділові та моральні якості, необхідні для вирішення спору.`
  },
  {
    italic: true,
    content: `Стаття 7. Формування складу третейського суду`
  },
  {
    content: `1. Якщо сторони не домовились про те, що справа розглядається третейським судом у складі трьох третейських суддів, справа підлягає розгляду третейським суддею одноособово.

2. За клопотанням однієї із сторін третейського розгляду голова Третейського суду може призначити розгляд справи третейським судом у складі трьох третейських суддів.

3. Третейський суддя, який розглядає справу одноособово, призначається за його згодою головою Третейського суду із списку третейських суддів Третейського суду.

4. При розгляді справи у складі трьох третейських суддів головуючого складу третейського суду та третейських суддів призначає за їх згодою голова Третейського суду із списку третейських суддів Третейського суду.

5. Після формування складу третейського суду, голова Третейського суду передає справу головуючому складу третейського суду або третейському судді, який розглядає справу одноособово.`
  },
  {
    italic: true,
    content: `Стаття 8. Повідомлення сторін про порушення справи`
  },
  {
    content: `1. Третейський суддя, який розглядає справу одноособово або головуючий складу третейського суду постановляє ухвалу про порушення справи, про що повідомляються сторони, і пропонує відповідачу у строк не більше ніж 5 днів з дня отримання ухвали про порушення справи надати Третейському суду свій відзив на позов, що підкріплений відповідними доказами.

2. Голова Третейського суду може продовжити строк для надання відповідачем відзиву на позов при заявлені відповідного клопотання.`
  },
  {
    italic: true,
    content: `Стаття 9. Припинення повноважень третейського судді, складу третейського суду, заміна третейського судді`
  },
  {
    content: `1. Якщо третейський суддя, склад третейського суду, виявляються юридично або фактично не здатними виконувати свої функції або з інших підстав не здійснюють ці функції без виправданої затримки, повноваження кожного з них можуть бути припинені за погодженням сторін.

2. Повноваження третейського судді, складу третейського суду припиняються при їх заяві про самовідвід, або їх відводу у відповідності до Закону України "Про третейські суди" та цього Регламенту.

3. Повноваження третейського судді також припиняються:

- у разі набрання законної сили обвинувальним вироком суду щодо нього;
- у разі набрання законної сили судовим рішенням про визнання його обмежено дієздатним чи недієздатним;
- у разі його смерті, визнання його безвісно відсутнім або оголошення померлим рішенням суду, що набрало законної сили.

4. Голова третейського суду може за власною ініціативою вирішити питання про припинення повноважень третейського судді при наявності підстав, зазначених у цьому Регламенті.

5. Повноваження складу третейського суду, яким вирішувався спір, припиняються після прийняття рішення по конкретній справі.

6. У разі, якщо повноваження третейського судді припинені відповідно до цього Регламенту, інший третейський суддя призначається згідно з правилами цього Регламенту, які застосовувалися при призначенні третейського судді, який замінюється.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 3. Самовідводи та відводи`
  },
  {
    italic: true,
    content: `Стаття 10. Підстави відводу чи самовідводу третейського судді`
  },
  {
    content: `1. Третейський суддя не може брати участі у розгляді справи, а після його призначення підлягає відводу чи самовідводу:
- якщо він особисто чи опосередковано заінтересований у результаті розгляду справи;
- якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках;
на його прохання або за спільним рішенням сторін;
- у разі встановлення стороною обставин, які дають їй підстави вважати упередженим або необ'єктивним ставлення третейського судді до справи,про яке сторона дізналася після його призначення;
- у разі тривалого, більш як один місяць від дня призначення, невиконання ним обов'язків третейського судді у конкретній справі;
- у разі виявлення невідповідності третейського судді вимогам, встановленим статтею 18 Закону України "Про третейські суди";
якщо третейський суддя бере участь у вирішенні спору, який прямо чи опосередковано пов'язаний з виконанням ним службових повноважень, наданих державою.

2. Жодна особа не може бути третейським суддею у справі, в якій вона раніше брала участь як третейський суддя, але була відведена чи заявила самовідвід, як сторона, представник сторони або в будь-якій іншій якості.`
  },
  {
    italic: true,
    content: `Стаття 11. Порядок відводу чи самовідводу третейського судді`
  },
  {
    content: `1. У разі звернення голови Третейського суду до особи зі списку третейських суддів Третейського суду за отриманням згоди на призначення її третейським суддею у конкретні справі ця особа повинна повідомити про наявність обставин, які є підставами для відводу чи самовідводу відповідно до статті 19 Закону України "Про третейські суди" та статті 10 цього Регламенту.

2. Третейський суддя повинен без зволікання повідомити сторони про підстави його відводу чи самовідводу, зазначені у статті 19 Закону України "Про третейські суди" та статті 10 цього Регламенту, що виникли після початку третейського розгляду, та заявити самовідвід.

3. Письмова мотивована заява про відвід третейського судді має бути подана стороною протягом трьох днів після того, як цій стороні стали відомі обставини, які є підставою для відводу третейського судді відповідно до Закону "Про третейські суди" та цього Регламенту.

4. У разі пропуску встановленого строку питання про прийняття заяви про відвід третейського судді вирішується головою Третейського суду залежно від причин пропуску строку.

5. Заява сторони про відвід подається голові Третейського суду.

6. За відсутності заперечень іншої сторони щодо заявленого відводу третейський суддя є відведеним з дня подання стороною заяви про його відвід.

7. Якщо інша сторона не погоджується з відводом третейського судді, вона має право протягом трьох днів подати голові Третейського суду свої мотивовані заперечення. У цьому випадку питання про відвід вирішується головою Третейського суду, а у разі розгляду справи у кількості трьох третейських суддів головою Третейського суду спільно з іншими третейськими суддями, призначеними у справі, протягом п'яти днів з моменту отримання заяви сторони, рішення якого (яких) є обов'язковим для сторін.`
  },
  {
    italic: true,
    content: `Стаття 12. Підстави та порядок відводу чи самовідводу експерта, перекладача`
  },
  {
    content: `1. Якщо експерт, перекладач особисто чи опосередковано заінтересовані у результаті розгляду справи, або якщо вони є родичами однієї із сторін або інших осіб, які беруть участь у справі, або перебувають з цими особами чи сторонами в особливих стосунках вони підлягають відводу чи самовідводу.

2. Питання про відвід експерта, перекладача вирішується складом третейського суду.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 4. Апарат третейського суду та місце проведення третейського розгляду`
  },
  {
    italic: true,
    content: `Стаття 13. Секретаріат`
  },
  {
    content: `1. Секретаріат здійснює функції, необхідні для забезпечення діяльності Третейського суду у відповідності с цим Регламентом. Секретаріат очолює відповідальний секретар.

2. Працівники секретаріату Третейського суду приймаються на роботу в Асоціацію "Правова Ліга" або запрошуються за угодою.`
  },
  {
    italic: true,
    content: `Стаття 14. Відповідальний секретар`
  },
  {
    content: `1. Відповідальний секретар Третейського суду може мати заступника.

2. Відповідальний секретар, а в його відсутність його заступник, організує та
веде діловодство, пов'язане з діяльністю Третейського суду, а також виконує інші передбачені цим Регламентом функції.

3. Відповідальний секретар Третейського суду і його заступник призначаються президентом Асоціації "Правова Ліга". Розподіл обов'язків між ними, а також між іншими працівниками секретаріату, здійснює відповідальний секретар.`
  },
  {
    italic: true,
    content: `Стаття 15. Місце проведення третейського розгляду`
  },
  {
    content: `1. Місце проведення третейського розгляду визначається головою Третейського суду з урахуванням усіх обставин справи, зокрема, характеру справи, зручності місця третейського розгляду для сторін та складу третейського суду.

2. Сторони можуть на свій розсуд обрати місце третейського розгляду у третейському суді для вирішення конкретного спору.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 5. Принципи, мова третейського розгляду, конфіденційність`
  },
  {
    italic: true,
    content: `Стаття 16. Принципи організації і діяльності Третейського суду`
  },
  {
    content: `1. Третейський суд утворюється та діє на принципах:
1) законності;
2) незалежності третейських суддів та підкорення їх тільки законові;
3) рівності всіх учасників третейського розгляду перед законом і третейським судом;
4) змагальності сторін, свободи в наданні ними третейському суду своїх доказів і у доведенні перед судом їх переконливості;
5) обов'язковості для сторін рішень третейського суду;
6) добровільності утворення третейського суду;
7) добровільної згоди третейських суддів на їхнє призначенням у конкретній справі;
8) арбітрування;
9) самоврядування третейських, суддів;
10) всебічності, повноти та об'єктивності вирішення спорів;
11) сприяння сторонам у досягненні ними мирової угоди на будь-якій стадії третейського розгляду.`
  },
  {
    italic: true,
    content: `Стаття 17. Мова третейського розгляду`
  },
  {
    content: `1. Розгляд справ у третейському суді провадиться українською мовою.

2. Якщо одна з сторін подає складу третейського суду клопотання про розгляд справи російською мовою, і при відсутності заперечення проти цього іншої сторони, склад третейського суду погоджується про розгляд справи російською мовою. При цьому процесуальні документи третейського суду приймаються українською мовою.

3. Сторона, яка надає документи чи письмові докази мовою іншою, ніж мова третейського розгляду, повинна забезпечити їх переклад на мову третейського розгляду.

4. Якщо сторона не володіє мовою, якою провадиться розгляд, Третейський суд за проханням сторони і за її рахунок забезпечується послугами перекладача.

Стаття 18. Конфіденційність

1. Третейський суд, суддя третейського суду, працівники апарату Третейського суду не вправі розголошувати відомості та інформацію, що стали йому (їм) відомі під час третейського розгляду, без згоди сторін або їх правонаступників.

2. Забороняється вимагати від третейського судді надання документів, відомостей та інформації, якими він володіє у зв'язку з третейським розглядом справи, крім випадків, передбачених законами України.

3. У будь-якому випадку, при публікаціях не допускається посилання на найменування сторін, їх банківські реквізити та аналогічні відомості.`
  }, {
    center: true,
    bold: true,
    content: `Глава 6. Учасники третейського розгляду`
  },
  {
    italic: true,
    content: `Стаття 19. Склад осіб, які приймають участь у третейському розгляді`
  },
  {
    content: `1. Особами, що приймають участь у третейському розгляді є сторони та їх представники, а також, у випадках передбачених цим Регламентом, треті особи та їх представники. Треті особи не користуються правом оскарження рішення третейського суду.

2. Сторонами третейського розгляду можуть бути юридичні та фізичні особи, які подали до Третейського суду позов на захист своїх прав та інтересів, або до яких
пред'явлений позов.

3. Позивачами є юридичні та фізичні особи, які подали позов.

4. Відповідачами є юридичні та фізичні особи, до яких подана позовна вимога.

5. Позов може бути поданий сумісно декількома позивачами та/або одночасно до декількох відповідачів.

6. Треті особи вступають в третейський розгляд тільки зі згоди сторін третейського розгляду. Для залучення до третейського розгляду третьої особи, крім згоди сторін, необхідна також згода особи, яка залучається. Подання клопотання про залучення третьої особи допускається тільки до закінчення строку надання відзиву на позовну заяву. Згода на залучення третьої особи повинна бути викладена у письмовій формі.

7. Для об'єктивного і вірного третейського розгляду третейський суд вправі за власною ініціативою залучати третіх осіб за їх згодою без згоди сторін.

8. Треті особи не користуються правом оскарження рішення Третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 20. Інші учасники третейського розгляду`
  },
  {
    content: `1. Особи, які приймають участь у справі, мають право знайомитись с матеріалами справи, робити з них виписки, знімати копії: заявляти відводи; надавати докази і ознайомлюватися з доказами, наданими іншими особами, що приймають участь у справі, до початку третейського розгляду; брати участь у дослідженні доказів; задавати питання іншим учасникам третейського розгляду, заявляти клопотання, робити заяви, давати пояснення третейському суду, наводити свої доводи по всім питанням, що виникають при розгляді справи; знайомитися з клопотаннями, заявленими іншими особами, заперечувати проти клопотань, доводів інших осіб, що приймають участь у справі; отримувати копії ухвал та рішень, користуватися іншими процесуальними правами, наданими їм цим Регламентом.

2. Особи, які приймають участь у справі, повинні добросовісно користуватися всіма наданими їм правами.

3. Особи, які приймають участь у справі, несуть обов'язки, передбачені цим Регламентом або покладені на них третейським судом у відповідності з цим Регламентом.`
  },
  {
    italic: true,
    content: `Стаття 22. Процесуальна правоздатність і процесуальна дієздатність`
  },
  {
    content: `1. Здатність мати процесуальні права і нести процесуальні обов'язки (процесуальна правоздатність) визнається в рівній мірі за всіма юридичними і фізичними особами, які мають згідно з законом право на судовий захист в третейському суді своїх прав і законних інтересів.

2. Здатність своїми діями здійснювати процесуальні права і виконувати процесуальні обов'язки (процесуальна дієздатність) належить в третейському суді - юридичним і фізичним особам.

3. Права і законні інтереси недієздатних фізичних осіб захищають в третейському розгляді їх законні представники.`
  },
  {
    italic: true,
    content: `Стаття 23. Участь у справі декількох позивачів або відповідачів`
  },
  {
    content: `1. Позов може бути поданий до третейського суду сумісно декількома позивачами або до декількох відповідачів (процесуальна співучасть). Кожен з позивачів або відповідачів виступає в процесі самостійно. Співучасники можуть доручити ведення справи одному або декільком з співучасників.`
  },
  {
    italic: true,
    content: `Стаття 24. Заміна неналежного відповідача`
  },
  {
    content: `1. У разі, якщо при підготовці справи до судового розгляду або під час судового розгляду в суді буде встановлено, що позов пред'явлений не до тої особи, яка повинна відповідати за позовом, третейський суд може за клопотанням або зі згоди позивача допустити заміну неналежного відповідача належним при його згоді на розгляд спору в Третейському суді, при наявності підписаної Третейської угоди.

2. Після заміни неналежного відповідача розгляд справи проводиться спочатку,
про що третейський суд виносить ухвалу.`
  },
  {
    italic: true,
    content: `Стаття 25. Процесуальне правонаступництво`
  },
  {
    content: `1. У випадках вибуття однієї з сторін третейського розгляду (реорганізація юридичної особи, уступка вимоги, переведення боргу, смерть фізичної особи та інші випадки переміни осіб у зобов'язаннях) третейський суд проводить заміну цієї сторони її правонаступником при згоді правонаступника і зазначає про це в ухвалі або в рішенні. Правонаступництво можливе на будь-якій стадії третейського розгляду.

2. Для правонаступника усі дії, здійснені в третейському процесі до вступу правонаступника в справу, обов'язкові в тій мірі, в якій вони були обов'язкові для особи, яку правонаступник змінив.`
  },
  {
    italic: true,
    content: `Стаття 26. Зміна підстави або предмета позову, зміна розміру позовних вимог, відмова від позову, визнання позову, мирова угода`
  },
  {
    content: `1. Позивач має право при розгляді справи в Третейському суді до прийняття рішення змінити підставу або предмет позову, збільшити або зменшити розмір позовних вимог.

2. Позивач має право при розгляді справи в Третейському суді до прийняття рішення, відмовитись від позову повністю або частково.

3. Відповідач має право при розгляді справи в Третейському суді визнати позов повністю або частково.

4. Сторони можуть закінчити справу мировою угодою в порядку, передбаченому главою 14 цього Регламенту.

5. Третейський суд не приймає відмову позивача від позову, зменшення ним розміру позовних вимог, визнання відповідачем позову, не затверджує мирову угоду сторін, якщо це суперечить закону або порушує права інших осіб.`
  },
  {
    italic: true,
    content: `Стаття 27. Треті особи, які заявляють самостійні вимоги відносно предмета спору`
  },
  {
    content: `1. Треті особи, які заявляють самостійні вимоги відносно предмета спору, можуть за їх згодою та за згодою сторін вступити у справу третейського розгляду до прийняття третейським судом рішення, при умові існування щодо них Третейської угоди. Вони користуються усіма правами і несуть усі обов'язки позивача.

2. У разі якщо третя особа, заявляє самостійні вимоги відносно предмета спору, вступила у справу після початку судового розгляду, розгляд справи в третейському суді проводиться спочатку.

3. Про вступ в справу третьої особи, яка заявляє самостійні вимоги відносно предмета спору, виноситься ухвала.`
  },
  {
    italic: true,
    conte: `Стаття 28. Треті особи, які не заявляють самостійних вимог відносно предмета спору`
  },
  {
    content: `1. Треті особи, які не заявляють самостійних вимог відносно предмета спору, можуть за згодою сторін вступити в справу третейського розгляду на стороні позивача або відповідача до прийняття третейським судом рішення, якщо рішення в справі може вплинути на права або обов'язки по відношенню до однієї з сторін. Вони можуть бути залучені до участі у справі третейського розгляду за їх згодою за ініціативою третейського суду.

2. Треті особи, які не заявляють самостійних вимог щодо предмета спору, мають процесуальні права і обов'язки сторони, за виключенням права на зміну підстави або предмета позову, збільшення або зменшення розміру позовних вимог, відмови від позову, визнання позову або укладення мирової угоди, пред'явлення зустрічного позову.

3. Про вступ у справу третьої особи, яка не заявляє самостійних вимог щодо предмета спору, або про залучення в справу цієї особи або про відмову про це третейським судом виноситься ухвала.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 7. Представництво у третейському суді`
  },
  {
    italic: true,
    content: `Стаття 29 Ведення справ у Третейському суді через представників`
  },
  {
    content: `1. Фізичні особи мають право вести свої справи у Третейському суді особисто або через представників. Особиста участь у справі фізичної особи не обмежує його права мати у цій справі представника.

2. Справи юридичних осіб ведуть у Третейському суді їх органи, що діють в
межах повноважень, наданих їм законом, іншими нормативно-правовими актами і установчими документами, або представники.

3. Повноваження органів, які ведуть справи юридичних осіб, підтверджуються документами, що посвідчують службові повноваження їх представників, а при необхідності установчими документами.`
  },
  {
    italic: true,
    content: `Стаття 30. Особи, які можуть бути представниками у Третейському суді`
  },
  {
    content: `1. Повноваження представників сторін мають бути посвідчені такими документами:
1) довіреністю фізичної особи;
2) довіреністю юридичної особи.

2. Довіреність фізичної особи повинна бути посвідчена нотаріально або посадовою особою організації, в якій довіритель працює, навчається, перебуває на службі, стаціонарному лікуванні чи за рішенням суду, або за місцем його проживання.

3. Довіреність від імені юридичної особи видається за підписом посадової особи, уповноваженої на це законом, статутом або положенням, з прикладенням печатки юридичної особи.

4. Повноваження адвоката як представника можуть також посвідчуватись ордером, який виданий відповідним адвокатським об'єднанням, або договором.

5. Оригінали документів, зазначених у цій статті, або копії з них, посвідчені третейським суддею, приєднуються до справи.`
  },
  {
    italic: true,
    content: `Стаття 32. Повноваження представника`
  },
  {
    content: `1. Представник має право вчиняти від імені особи, яку він представляє, усі процесуальні дії, що їх має право вчиняти ця особа. Однак право представника на підписання позовної заяви, про пред'явлення її у Третейський суд, пред'явлення зустрічного позову, повну або часткову відмову від позовних вимог, зменшення їх розміру, визнання позову, зміну предмета або підстав позову, укладення мирової угоди, передачу повноважень іншій особі (передоручення) повинно бути спеціально обумовлено у довіреності, виданій особою, що представляється.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 8. Докази`
  },
  {
    italic: true,
    content: `Стаття 33. Докази`
  },
  {
    content: `1. Доказами у третейському розгляді є будь-які фактичні дані, на підставі яких третейський суд встановлює наявність або відсутність обставин, що обґрунтовують вимоги і заперечення сторін, та інші обставини, що мають значення для правильного вирішення спору.`
  },
  {
    italic: true,
    content: `Стаття 34. Засоби доказування`
  },
  {
    content: `1. Засобами доказування є пояснення сторін, третіх осіб, їхніх представників, показання свідків, письмові і речові докази, висновки експертів.`
  },
  {
    italic: true,
    content: `Стаття 35. Обов'язки доказування і надання доказів та їх зберігання`
  },
  {
    content: `1. Кожна сторона третейського розгляду повинна довести ті обставини, на які вона посилається як на підставу своїх вимог і заперечень.

2. Третейським суд має право вимагати від сторін, надання доказів, необхідних для повного, всебічного і об'єктивного вирішення спору.

3. Якщо третейський суд визнає за необхідне одержати документи від підприємств, установ, організацій, які не є учасниками третейського розгляду, він своєю ухвалою уповноважує сторони або одну із сторін одержати відповідні документи та надати їх третейському суду.

4. Сторони повинні надавати третейському суду докази в оригіналах чи належним чином засвідчених копіях.

5. Всі зібрані письмові докази разом з процесуальними документами сторін та третейського суду зберігаються у справі в прошитому та пронумерованому вигляді.`
  },
  {
    italic: true,
    content: `Стаття 36. Належність доказів`
  },
  {
    content: `1. Третейський суд бере до розгляду тільки ті докази, які стосуються справи, що
розглядається та предмета доказування.

2. Сторони мають право обґрунтовувати належність конкретного доказу для підтвердження їхніх вимог або заперечень.`
  },
  {
    italic: true,
    content: `Стаття 37. Допустимість доказів`
  },
  {
    content: `1. Обставини справи, які за законом повинні бути підтверджені певними засобами доказування, не можуть підтверджуватися ніякими іншими засобами доказування.

2. Третейський суд не бере до уваги докази, які одержані з порушенням порядку, встановленого законом`
  },
  {
    italic: true,
    content: `Стаття 38. Підстави звільнення від доказування`
  },
  {
    content: `1. Обставини справи, визнані третейським судом загальновідомими, не потребують доказування.

2. Обставини встановлені рішенням третейського суду, що набрало законної сили, не доказуються при розгляді інших справ, у яких беруть участь ті самі особи або особа, щодо якої встановлено ці обставини.

3. Рішення судів у цивільній або господарській справі, що набрали законної сили, обов'язкові для третейського суду про обставини, встановлені цими рішеннями по відношенню до осіб, які приймають участь у справі.

4. Вирок у кримінальній справі, що набрав законної сили, або постанова суду у справі про адміністративне правопорушення обов'язкові для третейського суду, що розглядає справу про цивільно-правові наслідки дій особи, стосовно якої ухвалено вирок або постанову суду, з питань, чи мали місце ці дії та чи вчинені вони цією особою.`
  },
  {
    italic: true,
    content: `Стаття 39. Звільнення від доказування обставин, визнаних сторонами`
  },
  {
    content: `1. Третейський суд повинен сприяти досягненню сторонами згоди в оцінці обставин в цілому або в їх окремих частинах, проявляти в цих цілях необхідну ініціативу, використовувати свої процесуальні повноваження і авторитет третейського суду.

2. Визнані сторонами в результаті досягнутої між ними згоди обставини приймаються третейським судом як факти, не потребуючі подальшого доказування.

3. Визнання стороною обставин, на яких інша сторона обґрунтовує свої вимоги або заперечення, звільняє іншу сторону від необхідності доказування таких обставин. Визнання, викладені у письмовій формі, залучаються до матеріалів справи.`
  },
  {
    italic: true,
    content: `Стаття 40 Оцінка доказів`
  },
  {
    content: `1. Третейський суд оцінює докази за своїм внутрішнім переконанням, що ґрунтується на всебічному, повному, об'єктивному і безпосередньому дослідженні доказів, що є у справі.

2. Кожний доказ підлягає оцінці третейським судом поряд з іншими доказами.

3. Ніякі докази не мають для третейського суду заздалегідь встановленої сили.`
  },
  {
    italic: true,
    content: `Стаття 41. Письмові докази`
  },
  {
    content: `1. Письмовими доказами є будь-які документи, акти, довідки, листування службового або особистого характеру або витяги з них, виконані у формі цифрового, графічного запису або іншим шляхом, що дозволяє встановити достовірність документа.

2. Документи, отримані через факсимільний, електронний або інший зв'язок, а також документ, підписані електронним цифровим підписом або іншим аналогом власноручного підпису, допускаються в якості письмових доказів у випадках і в порядку, встановлених законом, іншим нормативно - правовим актом або договором.

3. До письмових документів, що надаються третейському суду, виконаних повністю або в частині іноземною мовою, повинні бути додані їх належним чином завірені переклади на українську мову.

4. Документ отриманий в іноземній державі, визнається в третейському суді письмовим доказом, якщо він легалізований у встановленому порядку.

5. Іноземні офіційні документи визнаються у третейському суді письмовими доказами без їх легалізації у випадках, передбачених міжнародними договорами України.

6. Оригінали документів сторін, що є у справі, за заявами осіб, що їх представляють, можуть бути повернуті їм після прийняття рішення, якщо ці документи не підлягають передачі іншій особі. Одночасно з заявами зазначені особи надають належним чином завірені копії документів або заявляють клопотання про засвідчення третейським судом вірності копій, що залишаються у справі.

7. Якщо третейський суд дійде висновку, що повернення оригіналів документів не нанесе шкоди правильному розгляду справи, ці документи можуть бути повернуті в процесі провадження у справі до винесення рішення.`
  },
  {
    italic: true,
    content: `Стаття 42. Речові докази`
  },
  {
    content: `1. Речовими доказами є предмети матеріального світу, що містять інформацію про обставини, які мають значення для справи.

2. Речовими доказами є також магнітні, електронні та інші носії інформації, що містять аудіовізуальну інформацію про обставини, що мають значення для справи.`
  },
  {
    italic: true,
    content: `Стаття 43. Зберігання речових доказів`
  },
  {
    content: `1. Речові докази зберігаються за місцем їх знаходження. Вони повинні бути детально описані, опечатані, а у разі необхідності засняті на фото - чи відеоплівку.

2. Речові докази можуть зберігатися за місцезнаходженням Третейського суду, якщо третейський суд визнає це за необхідне.

3. Витрати на зберігання речових доказів розподіляються між сторонами у відповідності з правилами, встановленими цим Регламентом.

4. Третейський суд і зберігач вживають заходів по зберіганню речових доказів у незмінному стані.`
  },
  {
    italic: true,
    content: `Стаття 44. Огляд і дослідження письмових і речових доказів за місцем їх знаходження`
  },
  {
    content: `1. Третейський суд може провести огляд і дослідження письмових і речових доказів за місцем їх знаходження у разі неможливості їх доставки в Третейський суд. Про проведення огляду і дослідження на місці виноситься ухвала.

2. Огляд і дослідження письмових і речових доказів проводяться третейським судом з повідомленням осіб, які приймають участь у справі, про місце і час огляду і дослідження. Нез'явлення належним чином повідомлених осіб не перешкоджає проведенню огляду і дослідження.`
  },
  {
    italic: true,
    content: `Стаття 45. Розпорядження речовими доказами, що знаходяться у Третейському суді`
  },
  {
    content: `1. Речові докази, що знаходяться у Третейському суді, після їх огляду і дослідження третейським судом повертаються особам, від яких вони були отримані, якщо вони не підлягають передачі іншим особам.

2. Речі, які згідно закону не можуть знаходитись у користуванні окремих осіб, передаються відповідним організаціям.

3. З питань розпорядження речовими доказами третейський суд виносить ухвалу.`
  },
  {
    italic: true,
    content: `Стаття 46. Пояснення осіб, які приймають участь у справі`
  },
  {
    content: `1. Особа яка приймає участь у справі, представляє третейському суду свої пояснення про відомі їй обставини, які мають значення для справи, в письмовій або усній формі. За пропозицією третейського суду особа, яка приймає участь у справі, може викласти свої пояснення у письмовій формі.

2. Пояснення, викладені у письмовій формі, додаються до матеріалів справи.

3. Після оголошення пояснень, викладених у письмовій формі, особа, яка надала це пояснення, має право дати відносно нього необхідні пояснення, а також зобов'язана відповісти на питання інших осіб, які приймають участь у справі, третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 47. Призначення експертизи`
  },
  {
    content: `1. З метою правильною вирішення спору третейський суд має право зобов'язати сторони чи одну із сторін замовити проведення експертизи для роз'яснення питань, що потребують спеціальних знань, про що третейський суд виносить відповідну ухвалу.

2. У разі ухилення позивача від замовлення проведення експертизи, призначеної третейським судом, і при неможливості розглянути справу через дослідження інших доказів у справі, розгляд справи зупиняється, про що виноситься ухвала.

3. У разі ухилення відповідача від замовлення проведення експертизи,
призначеної третейським судом, третейський суд може зобов'язати замовити проведення експертизи позивача, або розглянути справу без проведення експертизи дослідивши інші докази у справі.


4. Перелік і зміст питань, з яких повинна бути проведена експертиза, визначається в ухвалі третейського суду. Особи, які приймають участь у справі, мають право надати третейському суду питання, які повинні бути роз'яснені при проведенні експертизи.

5. В ухвалі про зобов'язання замовлення проведення експертизи зазначаються підстави для призначення експертизи; прізвище, ім'я і по-батькові експерта або найменування експертної установи, в якій повинна бути проведена експертиза: питання, поставлені перед експертом: матеріали і документи, надані в розпорядження експерта; строк, на протязі якого повинна бути проведена експертиза і повинно бути надано висновок третейському суду.

6. Висновок експерта оголошується у судовому засіданні і досліджується поряд з іншими доказами у справі. За клопотанням особи, яка приймає участь у справі, або за ініціативою третейського суду експерт може бути викликаний у засідання третейського суду. Експерт після оголошення його висновку має право дати по ньому необхідні пояснення, а також відповісти на додаткові питання осіб, які приймають участь у справі, і третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 48. Показання свідків`
  },
  {
    content: `1. За клопотанням особи, яка приймає участь у справі, третейський суд може викликати свідка для участі у третейському розгляді. Особа, яка заявляє клопотання про виклик свідка, зобов'язана зазначити, які обставини, що мають значення для справи, може підтвердити свідок, і повідомити суду його прізвище, ім'я, по-батькові і місце проживання.

2. Третейський суд за власною ініціативою може викликати у якості свідка особу, яка приймала участь у складенні документа, що досліджується третейським судом як письмовий доказ, або у створенні або зміні предмета, що досліджується третейським судом як речовий доказ.

3. За пропозицією третейського суду свідок може викласти показання, що надані усно, у письмовій формі. Показання свідка, що викладені у письмовій формі, залучаються до матеріалів справи.

4. Не є доказами відомості, які повідомлені свідком, якщо він не може зазначити джерело повідомлених ним відомостей.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 9. Строки третейського розгляду`
  },
  {
    italic: true,
    content: `Стаття 49. Встановлення та обчислення строків третейського розгляду`
  },
  {
    content: `1. Третейський розгляд здійснюються у строки, встановлені цим Регламентом або третейською угодою сторін.

2. Строки, встановлені законом, цим Регламентом або третейським судом, обчислюються роками, місяцями і днями, а також можуть визначатися вказівкою на подію, яка повинна неминуче настати.

3. Перебіг строку третейського розгляду починається з наступною дня після відповідної календарної дати або настання події, з якою пов'язано його початок.

Стаття 50. Закінчення строків третейського розгляду

1. Строк, обчислюваний роками, закінчується у відповідні місяць і число останнього року строку.

2. Строк, обчислюваний місяцями, закінчується у відповідне число останнього місяця строку. Якщо закінчення строку, обчислюваного місяцями, припадає на такий місяць, що відповідного числа не має, строк закінчується в останній день цього місяця.

3. Якщо закінчення строку припадає на вихідний, святковий чи інший неробочий день, останнім днем строку є перший після нього робочий день.

4. Перебіг строку, закінчення якого пов'язане з подією, яка повинна неминуче настати, закінчується наступного дня після настання події.

5. Останній день строку триває до 24 години. Строк не вважається пропущеним, якщо до його закінчення заява, інші документи чи матеріали або грошові кошти здано на пошту чи передані іншими відповідними засобами зв'язку.`
  },
  {
    italic: true,
    content: `Стаття 51. Наслідки пропущення строків третейського розгляду`
  },
  {
    content: `1. Право на вчинення процесуальної дії втрачається із закінченням строку, встановленого законом, цим Регламентом або третейським судом.

2. Документи, подані після закінчення строків третейського розгляду, залишаються без розгляду, якщо третейський суд за клопотанням особи, що їх подала, не знайде підстав для поновлення або продовження строку.`
  },
  {
    italic: true,
    content: `Стаття 52. Зупинення строків третейського розгляду`
  },
  {
    content: `1. Зупинення провадження у справі зупиняє перебіг строків третейського розгляду. Зупинення цих строків починається з моменту настання тієї події, внаслідок якої третейський суд зупинив провадження.`
  },
  {
    italic: true,
    content: `Стаття 53. Поновлення та продовження строків третейського розгляду`
  },
  {
    content: `1. Третейський суд поновлює або продовжує строк, встановлений відповідно законом, цим Регламентом за клопотанням сторони або іншої особи у разі його пропущення з поважних причин.

2. Строки вчиненим процесуальних дій, передбачені Законом України "Про третейські суди" можуть бути поновлені або продовжені лише за спільною згодою сторін та складу третейського суду, яким вирішується спір.

3. Одночасно з клопотанням про поновлення чи продовження строку може бути вчинено ту дію або подано той документ чи доказ, стосовно якого заявлено клопотання.

4. З питань, зазначених у цій статті, третейським судом виноситься ухвала.`
  },
  {
    italic: true,
    content: `Стаття 54. Строк розгляду спору`
  },
  {
    content: `1. Третейський суд застосовує заходи до розгляду спору по можливості у найкоротший строк. Строк третейського розгляду не може перевищувати 2 місяці з дня призначення складу третейського суду, призначення третейського судді, який розглядає справу одноособово, якщо інший строк третейського розгляду не встановлено третейською угодою сторін.

2. У виключних випадках голова Третейського суду вправі продовжити строк провадження у справі, якщо інше не встановлено третейською угодою сторін.`
  },
  {
    italic: true,
    content: `Стаття 55. Інші строки`
  },
  {
    content: `1. Всі інші строки, пов'язані з третейським розглядом, визначені у конкретних статтях цього Регламенту.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 10. Витрати, пов'язані з вирішенням спору третейським судом`
  },
  {
    italic: true,
    content: `Стаття 56. Склад витрат, пов'язаних з вирішенням спору третейським судом`
  },
  {
    content: `1. До складу витрат, пов'язаних з вирішенням спору третейським судом, належать:
1) гонорари третейських суддів;
2) третейський збір, розмір, строки та порядок сплати якого сторонами визначаються цим Регламентом;
3) витрати, понесені третейськими суддями у зв'язку з участю в третейському розгляді, в тому числі витрати, понесені третейськими суддями на оплату проїзду до місця вирішення спору;
4) витрати, пов'язані з оплатою послуг експертів, перекладачів, якщо такі були запрошені чи призначені для участі в третейському розгляді;
5) витрати, пов'язані з оглядом і дослідженням речових та письмових доказів у їх місцезнаходженні;
6) витрати, понесені свідками;
7) витрати, пов'язані з оплатою стороною, на користь якої було прийнято рішення третейського суду, послуг представника, пов'язаних із наданням правової допомоги;
8) витрати, пов'язані з організаційним забезпеченням третейського розгляду;
9) витрати на листування третейського суду;
10) витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок;
11) гонорар секретаря третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 57. Третейський та реєстраційний збір`
  },
  {
    content: `1. При поданні позовної заяви позивач зобов'язаний сплатити третейський збір.

2. До сплати третейського збору третейський розгляд за позовом зупиняється, крім випадку надання відстрочення сплати третейського збору.

3. Якщо третейський збір не сплачується на протязі місячного строку з моменту подання позовної заяви позовна заява залишається без розгляду.

4. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений, сплачений третейський збір повертається позивачу, крім випадків, якщо це було зумовлено відводом усіх суддів, укладанням сторонами мирової угоди, відмовою позивача від позову та іншими обставинами, передбаченими Законом України "Про третейські суди".

5. У разі, коли третейський розгляд справи Третейським судом не відбувся чи був припинений з підстав, зазначених у пункті 4 статті 57 цього Регламенту, то сторони відшкодовують Третейському суду всі понесені ним витрати, про що зазначається в ухвалі Третейського суду.

6. Голова Третейського суду може надати відстрочення, а також зменшити розмір третейського збору.

7. При поданні позивачем позовної заяви сплачується реєстраційний збір, за рахунок якого Третейський суд покриває свої витрати, пов’язані з організаційним забезпеченням третейського розгляду, листуванням Третейського суду, телефонним, телеграфним, телексним, факсимільним, електронним та іншим зв’язком. Документ, що підтверджує сплату реєстраційного збору додається до позовної заяви.
Реєстраційний збір складає 300 (триста) гривень. Розмір реєстраційного збору не залежить від ціни позову.
Реєстраційний збір є безповоротним.
Реєстраційний збір вважається сплаченим у день, коли сума збору надійшла на поточний рахунок Асоціації "Правова Ліга".`
  },
  {
    italic: true,
    content: `Стаття 58. Витрати, які здійснюються Третейським судом з наступним стягненням з сторін спору`
  },
  {
    content: `1. Розподіл витрат, пов'язаних з організаційним забезпеченням третейського розгляду, витрати на листування Третейського суду, витрати на телефонний, телеграфний, телексний, факсимільний, електронний та інший зв'язок – реєстраційний збір, здійснюються Третейським судом у порядку, визначеному ст.60 цього Регламенту.`
  },
  {
    italic: true,
    content: `Стаття 59. Покриття витрат, які не включаються до складу третейського збору`
  },
  {
    content: `1. Третейський суд може покласти на сторони або на одну з них обов'язок внести аванс на покриття витрат, які не включаються до складу третейського збору.

2. Аванс на покриття витрат, які не включаються до складу третейського збору може бути, витребуваний Третейським судом від сторони, яка заявила про необхідність здійснення дії, що приводить або може привести до цих витрат.

3. У разі участі у третейському розгляді третейського судді, який має місцеперебування поза місцем проведення засідання Третейського суду, позивач повинен внести аванс на оплату витрат його участі у третейському розгляді (по проїзду, проживанню тощо).

4. Якщо на прохання однієї з сторін при розгляді справи здійснюється переклад пояснень сторін, їх заяв тощо, а також питань, вказівок, ухвал, рішень Третейського суду, то витрати по перекладу оплачуються зазначеною стороною.`
  },
  {
    italic: true,
    content: `Стаття 60. Розподіл витрат, пов'язаних з вирішенням спору третейським судом`
  },
  {
    content: `1. Розподіл витрат, пов'язаних з вирішенням спору третейським судом здійснюється Третейським судом у такому порядку:
- при задоволенні позову витрати покладаються на відповідача; при відмові в позові - на позивача;
- при частковому задоволенні позову - на обидві сторони пропорційно розміру задоволених вимог.

2. Порядок розподілу витрат, пов'язаних з вирішенням спору Третейським судом, зазначається у рішенні або ухвалі третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 61. Розміри третейського збору`
  },
  {
    content: `1. Третейський збір обчислюється у таких розмірах:
При сумі позову (у гривнях) Розмір третейського збору (у гривнях):

- 1 (один) відсоток ціни позову, але не менше 15 (п’ятнадцяти) неоподатковуваних мінімумів доходів громадян.

2. У справах немайнового характеру з позовних заяв по спорам, що виникають при укладенні, зміні або розірванні договорів (крім розірвання договорів найму (оренди)), і по спорам про визнання правочинів недійсними та інших позовних заяв немайнового характеру – 1000 (одна тисяча) гривень.

3. За позовні заяви, що містять одночасно вимоги майнового і не майнового характеру, сплачується одночасно третейський збір, встановлений для позовних заяв майнового характеру і для позовних заяв немайнового характеру.

4. При зменшенні ціни позову сплачений третейський збір не повертається.

5. З врахування складності справи, що суттєво підвищує витрати часу и матеріальні витрати, пов'язані з третейським розглядом, голова Третейського суду може постановити ухвалу про збільшення розміру третейського збору.`
  },
  {
    italic: true,
    content: `Стаття 62. Порядок сплати третейських зборів та інших витрат`
  },
  {
    content: `1. Суми третейських зборів та інших витрат, що підлягають сплаті Третейському суду, вважаються сплаченими у день їх зарахування на поточний рахунок Асоціації "Правова Ліга".

2. При сплаті третейського збору у платіжному дорученні у графі "Призначення платежу" повинно бути зазначено: "Третейський збір за розгляд спору між "....." (найменування позивача та відповідача), а також сума позову. Платіжне доручення з відміткою банку про перерахування третейського збору залучаться до матеріалів справи.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 11. Гонорари третейських суддів та порядок їх виплати`
  },
  {
    italic: true,
    content: `Стаття 63. Гонорари третейських суддів, порядок їх виплати`
  },
  {
    content: `1. Третейському судді, який приймає участь третейському розгляді, після закінчення розгляду конкретної справи сплачується гонорар у розмірі 30 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.

2. Якщо справа розглядається складом третейського суду у кількості трьох третейських суддів, цим третейським суддям після закінчення розгляду конкретної справи сплачуються гонорари у таких розмірах: головуючому складу третейського суду 12 відсотків, двом іншим третейським суддям складу третейського суду по 9 відсотків від розміру третейського збору, який не підлягає поверненню позивачу, сплаченого по конкретній справі.

3. Гонорари третейським суддям сплачуються Асоціацією "Правова Ліга" на поточні рахунки третейських суддів у банківських установах. Реквізити банківських рахунків повідомляються кожним третейським суддею Третейському суду у письмовій формі. Для виплати гонорару Асоціація "Правова Ліга " може вимагати від третейського судді надання додаткових документів (копію довідки про ідентифікаційний номер, копію паспорту, тощо).

4. За заявою третейського судді, викладеної письмово, розгляд справи може здійснюватись безоплатно.`
  },
  {
    center: true,
    bold: true,
    content: `Розділ 2. Провадження у третейському суді
Глава 12. Пред'явлення позову`
  },
  {
    italic: true,
    content: `Стаття 64. Пред'явлення позову`
  },
  {
    content: `1. Провадження у справі порушується поданням позовної заяви.

2. Позовна заява подається у Третейський суд у письмовій формі і підписується керівником юридичної особи або іншою особою, яка має право діяти без довіреності від імені позивача, або фізичною особою-підприємцем, або фізичною особою, або уповноваженим довіреністю представником позивача.

3. Датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата штемпеля поштового відомства адресата.

4. У разі якщо сторона подає позовну заяву у зв'язку з правовідношенням, яке
вже є предметом третейського розгляду між тими ж сторонами у відповідності з цим Регламентом, голова Третейського суду на прохання будь-якої з сторін може об'єднати вимоги, що містяться у різних позовних заявах в одне провадження.`
  },
  {
    italic: true,
    content: `Стаття 65. Зміст позовної заяви`
  },
  {
    content: `1. У позовній заяві, що подається до Третейського суду, повинні зазначатися:
- назва Третейського суду;
- дата подання позовної заяви;
- найменування і юридичні адреси сторін, які є юридичними особами, та/або прізвище, ім'я, по батькові, дата народження, місце проживання і місце роботи сторін, які є фізичними особами;
- найменування і юридична адреса представника позивача, якщо він є юридичною особою або прізвище, ім'я, по батькові, дата народження, місце проживання і місце роботи представника, який є фізичною особою, у випадках, коли позов подається представником;
- зміст вимоги, ціна позову, якщо вимога підлягає оцінці;
- обставини, якими обґрунтовані позовні вимоги, докази, що їх підтверджують, розрахунок вимог;
- посилання на наявність третейської угоди між сторонами та докази її укладення; перелік письмових матеріалів, які додаються до позовної заяви;
- підпис позивача або його представника з посиланням на документ, що засвідчує повноваження представника.

2. У позовні заяві можуть бути зазначені номери телефонів, факсів, адреси електронної пошти, інші відомості, що мають значення для розгляду та вирішення справи.`
  },
  {
    italic: true,
    content: `Стаття 66. Документи, що додаються до позовної заяви`
  },
  {
    content: `До позовної заяви додаються документи, що підтверджують:
1) наявність третейської угоди;
2) обґрунтованість позовних вимог;
3) повноваження представника;
4) направлення копії позовної заяви іншій стороні (опис вкладення про відправлення цінного листа, виписка з реєстру поштових відправлень тощо).`
  },
  {
    italic: true,
    content: `Стаття 67. Ціна позову`
  },
  {
    content: `1. Ціна позву визначається:
1) у позовах про стягнення грошових коштів - сумою, яка стягується;
2) у позовах про визнання права власності на майно або його витребування – дійсною вартістю майна;
3) у позовах про розірвання договору найму (оренди) - сукупністю платежів за користування майном протягом строку, що залишається до кінця дії договору;
4) у позовах, які складаються з кількох самостійних вимог, - загальною сумою всіх вимог.

2. Якщо визначена позивачем ціна позову вочевидь не відповідає дійсній вартості спірного майна або на момент пред’явлення позову встановити точну його ціну неможливо, голова Третейського суду за власною ініціативою визначає ціну позову, з наступним стягненням недоплаченого третейського збору відповідно до ціни позову, встановленої при вирішенні справи.`
  },
  {
    italic: true,
    content: `Стаття 68. Прийняття позовної заяви і порушення провадження у справі`
  },
  {
    content: `1. Питання про прийняття позовної заяви до провадження Третейського суду вирішується головою Третейського суду у триденний строк з дня отримання позовної заяви Третейським судом.

2. При прийнятті позову Третейський суд вирішує питання про наявність і дійсність угоди про передачу спору на розгляд Третейського суду.

3. У разі, якщо Третейський суд дійде висновку про відсутність або недійсність зазначеної угоди, він відмовляє у розгляді справи. Про відмову у розгляді справи виноситься мотивована ухвала, яка надсилається сторонам. При цьому заявнику разом з ухвалою повертаються позовні матеріали.

4. Третейський суд приймає до провадження позовну заяву, подану з додержанням вимог цього Регламенту до його форми і змісту.

5. Про прийняття позовної заяви Третейський суд постановляє ухвалу, якою порушується провадження у справі.

6. В ухвалі зазначається на підготовку справи до третейського розгляду, дії, які належить виконати особам, які приймають участь у справі, і строки їх виконання.

7. Ухвали про прийняття позовної заяви до провадження Третейського суду та порушення провадження у справі направляються особам, які приймають участь у справі, не пізніше трьох днів після дня її винесення.`
  },
  {
    italic: true,
    content: `Стаття 69. Усунення недоліків позовної заяви`
  },
  {
    content: `1. Встановивши, що позовна заява подана без додержання вимог цього Регламенту, голова Третейського суду пропонує позивачу усунути виявлені недоліки.

2. Якщо ці недоліки будуть усунуті, то датою подання позовної заяви вважається день її вручення Третейському суду, а при відправці позовної заяви по пошті - дата штемпеля поштового відомства адресата.`
  },
  {
    italic: true,
    content: `Стаття 70. Відзив на позовну заяву`
  },
  {
    content: `1. Відповідач повинен надати Третейському суду письмовий відзив на позовну заяву. Відзив на позовну заяву направляється позивачу та Третейському суду по пошті або шляхом вручення не менше ніж за три дні до першого засідання третейського суду.

2. Неподання відповідачем відзиву у встановлені цією статтею строки не звільняє його в подальшому від виконання вимог Третейського суду про надання відзиву на позов.

3. Наслідки невиконання вимог Третейського суду можуть бути встановлені складом третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 71. Зустрічний позов`
  },
  {
    content: `1. Відповідач вправі подати зустрічний позов для розгляду Третейським судом, якщо такий позов є підвідомчим Третейському суду та може бути предметом третейського розгляду відповідно до третейської угоди.

2. Зустрічний позов може бути подано на будь-якій стадії третейського розгляду до прийняття рішення по справі.

3. Третейський суд приймає зустрічний позов до спільного розгляду з первісним позовом, якщо обидва позови взаємно пов'язані та їх спільний розгляд є доцільним, зокрема, коли вони випливають з одних і тих самих правовідносин або коли вимоги за ними можуть зараховуватися.

4. Зустрічний позов має відповідати вимогам статті 35 Закону України "Про третейські суди" та статтям 65, 66 цього Регламенту.

5. Сторона зобов'язана надати відзив на пред'явлений до неї зустрічний позов у порядку та строки, передбачені цим Регламентом.`
  },
  {
    italic: true,
    content: `Стаття 72. Забезпечення позову`
  },
  {
    content: `Якщо сторони не домовилися про інше, третейський суд може за заявою будь-якої сторони розпорядитися про вжиття стороною таких забезпечувальних заходів щодо предмета спору, які він вважає необхідними, з урахуванням положень цивільного та господарського процесуального законодавства. Третейський суд може витребувати від будь-якої сторони надати належне забезпечення позову у зв'язку з такими заходами.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 13. Підготовка до третейського розгляду`
  },
  {
    italic: true,
    content: `Стаття 73. Підготовка розгляду справи`
  },
  {
    content: `1. Склад третейського суду перевіряє стан підготовки справи до розгляду і, якщо визнає за необхідне, вживає додаткові заходи по підготовці справи.

2. Якщо склад третейського суду приймає додаткові заходи по підготовці справи, він повинен встановити строки, на протязі яких ці додаткові заходи повинні бути виконані.

3. Головуючий складу третейського суду, або третейський суддя, який розглядає справу одноособово, може давати відповідальному секретарю Третейського суду окремі доручення у зв'язку з підготовкою та проведенням розгляду справи. Він також доручає йому виклик сторін на засідання.`
  },
  {
    italic: true,
    content: `Стаття 74. Повідомлення сторін про засідання третейського суду`
  },
  {
    content: `1. Третейський суд направляє сторонам повідомлення про день, час та місце проведення засідання третейського суду не пізніше ніж за 10 днів до такого засідання.

2. Повідомлення направляється за останнім відомим місцем проживання фізичної особи чи за юридичною адресою або місцезнаходженням юридичної особи рекомендованим листом з повідомленням про вручення чи в інший спосіб, що передбачає отримання доказів доставки повідомлення адресатові, у тому числі шляхом вручення, та вважаються такими, що отримані в день такої доставки, навіть якщо на цей час одержувач за цією адресою не знаходиться чи не проживає, а про зміну своєї адреси ним не було повідомлено іншу сторону належним чином.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 14. Сприяння сторонам у досягненні ними мирової угоди`
  },
  {
    italic: true,
    content: `Стаття 75. Примирення сторін`
  },
  {
    content: `1. Третейський суд застосовує заходи для примирення сторін, сприяє їм у вирішенні спору шляхом укладення мирової угоди на всіх стадіях процесу.

2. Третейський суд на початку розгляду справи повинен з'ясувати у сторін можливість закінчити справу мировою угодою.`
  },
  {
    italic: true,
    content: `Стаття 76. Укладення мирової угоди`
  },
  {
    content: `1. Сторони мають право закінчити справу укладенням мирової угоди, як до початку третейського розгляду, так і на будь-якій його стадії, до прийняття рішення.

2. Мирова угода не повинна порушувати права і законні інтереси інших осіб і суперечити закону.

3. Мирова угода затверджується третейським судом.`
  },
  {
    italic: true,
    content: `Стаття 77. Вирішення питання щодо затвердження мирової угоди.`
  },
  {
    content: `1. За клопотанням сторін третейський суд приймає рішення про затвердження мирової угоди. Мирова угоди може стосуватися лише прав і обов'язків сторін щодо предмета спору.

2. Зміст мирової угоди викладається безпосередньо в рішенні третейського суду. До рішень третейського суду, прийнятих відповідно до цієї статті, застосовуються правила розділу VI Закону України "Про третейські суди".

3. Третейський суд не затверджує мирову угоду, якщо вона суперечить закону або порушує права і законні інтереси інших осіб.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 15. Зупинення провадження у справі`
  },
  {
    italic: true,
    content: `Стаття 78. Обов'язок третейського суду зупинити провадження у справі`
  },
  {
    contnet: `1. Третейський суд зобов'язаний зупинити провадження у справі у випадках:
1) неможливості розгляду цієї справи до вирішення пов'язаної з нею іншої справи, що розглядається судом або відповідного питання компетентними органами;
2) до вирішення третейським судом питання щодо наявності у нього відповідної компетенції для розгляду справи;
3) злиття, приєднання, поділу, перетворення юридичної особи, яка була стороною у справі або у зв'язку зі смертю або оголошення фізичної особи померлою, яка була стороною у справі, якщо спірні правовідносини допускають правонаступництво;
4) неможливості розгляду цієї справи у зв'язку з перебування однієї із сторін у складі Збройних Сил України або інших утворених, відповідно до закону, військових формувань, що переведені на воєнний стан.

2. Третейський суд зупиняє провадження у справі також в інших випадках, передбачених цим Регламентом.

3. З питань, зазначених у цій статті, третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`
  },
  {
    italic: true,
    content: `Стаття 79. Право третейського суду зупинити провадження у справі.`
  },
  {
    content: `1. Третейський суд може за заявою особи, яка бере участь у справі, а також з власної ініціативи зупинити провадження у справі у випадках:
1) перебування сторони на строковій військовій службі або альтернативній (невійськовій) службі не за місцем проживання;
2) захворювання сторони, підтвердженого медичною довідкою, що виключає можливість явки до суду протягом тривалого часу;
3) перебування сторони у тривалому службовому відрядженні;
4) призначення третейським судом експертизи;
5) в інших випадках, коли суд вважатиме за необхідне зупинити провадження у справі, у зв'язку з неможливістю її розгляду.

2. З питань, зазначених у цій статті, третейський суд виносить ухвалу, в якій зазначає строк, на який зупиняється провадження у справі.`
  },
  {
    italic: true,
    content: `Стаття 80. Строки, на які зупиняється провадження у справі`
  },
  {
    content: `1. Провадження у справі зупиняється у випадках, встановлених:
1) пунктом 1 частини першої статті 78 цього Регламенту - до набрання законної сили судового рішення, від якого залежить вирішення справи або рішення відповідного компетентного органу;
2) пунктом 2 частини першої статті 78 цього Регламенту - до винесення відповідної ухвали про наявність компетенції;
3) пунктом 3 частини першої статті 78 цього Регламенту - до залучення до участі у справі правонаступника чи законного представника;
4) пунктом 4 частини першої статті 78 та пунктами 1-4 частини першої статті 79 цього Регламенту - до припинення перебування сторони у складі Збройних Сил України або інших утворених, відповідно до закону, військових формувань, що переведені на воєнний стан, на строковій військовій службі, альтернативній (невійськовій) службі, у службовому відрядженні;
 - на час хвороби сторони;
 - на час проведення експертизи;
5) пунктом 5 частини першої статті 79 цього Регламенту - до усунення обставин, що зумовили зупинення провадження у справі.`
  },
  {
    italic: true,
    content: `Стаття 81. Відновлення провадження у справі`
  },
  {
    content: `1. Провадження у справі відновлюється ухвалою третейського суду за заявою особи, яка бере участь у справі, або з ініціативи суду після усунення обставин, що викликали його зупинення.

2. З дня відновлення провадження у справі перебіг процесуальних строків продовжується.

3. Після відновлення провадження третейський суд викликає сторони та інших осіб, які беруть участь у справі, і продовжує третейський розгляд.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 16. Залишення позовної заяви без розгляду`
  },
  {
    italic: true,
    content: `Стаття 82. Підстави для залишення позовної заяви без розгляду`
  },
  {
    content: `1. Третейський суд залишає позовну заяву без розгляду, якщо після її прийняття до розгляду встановить, що:
1) позовну заяву підписано, подано не уповноваженою особою;
2) належним чином повідомлений позивач, без поважних причин не подав витребувані третейським судом матеріали, необхідні для вирішення спору, або не з'явився у засідання третейського суду і його нез'явлення перешкоджає вирішенню спору, якщо від нього не надійшла заява про розгляд справи за його відсутності;
3) спір між тими самими сторонами, про той самий предмет і з тих самих підстав розглядається в іншому суді;
4) позовна заява не відповідає вимогам цього Регламенту, або не було сплачено третейський збір, і позивач не усунув цих недоліків у встановлений третейським судом строк або позивач подав заяву про залишення позову без розгляду.`
  },
  {
    italic: true,
    content: `Стаття 83. Порядок і наслідки залишення позовної заяви без розгляду`
  },
  {
    content: `1. У разі залишення позовної заяви без розгляду виноситься ухвала, в якій зазначаються підстави для залишення позовної заяви без розгляду. Копії ухвали направляються особам, які приймають участь у справі.

2. Залишення позовної заяви без розгляду не позбавляє позивача права знову звернутись у Третейський суд з позовною заявою у загальному порядку після усунення обставин, що послугували підставою для залишення заяви без розгляду.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 17. Припинення третейського розгляду`
  },
  {
    italic: true,
    content: `Стаття 84. Підстави припинення третейського розгляду`
  },
  {
    content: `1. Підставами для припинення третейського розгляду є такі випадки:
1) спір не підлягає вирішенню в третейських судах України;
2) є рішення компетентного суду між тими ж сторонами, з того ж предмета і з тих самих підстав;
3) позивач відмовився від позову;
4) сторони уклали угоду про припинення третейського розгляду;
5) підприємство, установу чи організацію, які є стороною третейського розгляду, ліквідовано;
6) Третейський суд є некомпетентним щодо переданого на його розгляд спору;
7) у разі смерті фізичної особи, яка була стороною у справі, якщо спірні правовідносини не допускають правонаступництва.`
  },
  {
    italic: true,
    content: `Стаття 85. Порядок і наслідки припинення третейського розгляду`
  },
  {
    content: `1. Про припинення третейського розгляду Третейський суд постановляє ухвалу. В ухвалі Третейський суд зазначає підстави для припинення третейського розгляду, а також вирішує питання про розподіл між сторонами витрат. Копії ухвали направляються особам, які приймають участь у справі.

2. У разі припинення третейського розгляду повторне звернення до Третейського суду з приводу спору між тими самими сторонами, про той самий предмет і з тих самих підстав не допускається. Наявність ухвали про припинення третейського розгляду у зв'язку з прийняттям відмови позивача від позову не позбавляє відповідача в цій справі права на звернення до Третейського суду за вирішенням цього спору.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 18. Третейський розгляд`
  },
  {
    italic: true,
    content: `Стаття 86. Засідання третейського суду`
  },
  {
    content: `1. Третейський розгляд здійснюється у засіданні третейського суду за участю сторін або їх представників, якщо сторони не домовилися про інше щодо їхньої участі в засіданні.

2. Третейський суд вправі визнати явку сторін у засідання обов'язковою.

3. Суддя, а при колегіальному розгляді справи головуючий у засіданні третейського суду:
1) відкриває засідання третейського суду і оголошує, яка справа підлягає розгляду;
2) перевіряє явку в судове засідання осіб, які приймають участь у справі, їх представників та інших учасників третейського розгляду, встановлює їх особи і перевіряє повноваження; встановлює, чи повідомлені належним чином особи, які не з'явились у засідання , і які є відомості про причини їх неявки;
3) з'ясовує питання щодо можливості слухання справи;
4) оголошує склад третейського суду, повідомляє, хто веде протокол засідання третейського розгляду, хто приймає участь в якості експерта, перекладача, і роз'яснює особам, які приймають участь у справі, їх право заявляти відводи;
5) роз'яснює особам, які приймають участь у справі, та іншим учасникам третейського процесу їх процесуальні права і обов'язки;
6) видаляє з місця третейського розгляду свідків до початку їх опитування;
7) визначає з врахуванням думки осіб, які приймають участь у справі, послідовність проведення процесуальних дій;
8) з'ясовує, чи підтримує позивач позов, чи визнає позов відповідач, чи не бажають сторони закінчить справу мировою угодою;
9) керує засіданням третейського суду, забезпечує умови для всебічного і повного дослідження доказів і обставин справи, забезпечує розгляд заяв і клопотань осіб, які приймають участь у справі.`
  },
  {
    italic: true,
    content: `Стаття 87. Протокол засідання третейського суду`
  },
  {
    content: `1. Протокол засідання третейського суду ведеться лише в разі наявності угоди
між сторонами про ведення протоколу.

2. Для ведення протоколу за згодою третейських суддів (третейського судді) сторонами може бути призначений секретар третейського розгляду. За його відсутності третейські судді можуть обрати секретаря із складу (крім головуючого) третейського суду, яким вирішується спір.

3. У протоколі засідання третейського суду зазначаються:
1) дата та місце розгляду справи,
2) прізвища третейських суддів і секретаря;
3) суть спору;
4) найменування сторін, їхніх представників, свідків, експертів, інших осіб, які беруть участь у розгляді справи;
5) заяви та клопотання сторін;
6) вказівки та вимоги до сторін по справі, висунуті третейським судом;
7) розклад засідань третейського суду;
8) зміст пояснень сторін та інших осіб, які беруть участь у справі;
9) подані під час третейського розгляду докази, відомості про їх огляд;
10) узгодження сторонами питання про необхідність зберігання та умови зберігання рішень третейського суду;
11) витрати, понесені сторонами.

4. Протокол підписується секретарем та всіма третейськими суддями, які розглядали справу.`
  },
  {
    italic: true,
    content: `Стаття 88. Підстави та порядок відводу секретаря третейського розгляду, який веде протокол`
  },
  {
    content: `1. Якщо секретар особисто чи опосередковано заінтересований у результаті розгляду справи, або якщо він є родичем однієї із сторін або інших осіб, які беруть участь у справі, або перебуває з цими особами чи сторонами в особливих стосунках він підлягає відводу чи самовідводу.

2. Питання про відвід секретаря вирішується складом третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 89. Наслідки неподання сторонами документів, інших письмових матеріалів або неявки сторін`
  },
  {
    content: `1. Ненадання витребуваних третейським судом доказів, інших письмових документів чи матеріалів, неявка у засідання третейського суду сторін або їхніх представників, які належним чином були повідомлені про розгляд справи і проведення засідання третейського суду, не є перешкодою для третейського розгляду і прийняті рішення, якщо причина ненадання документів або неявки сторін у засідання визнана третейським судом неповажною.`
  },
  {
    italic: true,
    content: `Стаття 90. Наслідки неявки в засідання експертів, свідків, перекладачів`
  },
  {
    content: `1. При неявці в судове засідання експертів, свідків, перекладачів, належним чином повідомлених про час і місце судових засідань Третейський суд виносить ухвалу про відкладення судового розгляду, якщо сторони не заявили клопотання про розгляд справи за відсутністю зазначених осіб.`
  },
  {
    italic: true,
    content: `Стаття 91. Відкладення засідання третейського суду`
  },
  {
    content: `1. Третейський суд відкладає третейський розгляд у випадках, передбачених цим Регламентом, а також у разі неявки в засідання третейського суду особи, яка приймає участь у справі, якщо у відношенні цієї особи у суду відсутні відомості про повідомлення її про час і місце третейського розгляду.

2. Третейський суд може відкласти третейський розгляд за клопотанням сторін.

3. У разі якщо особа, яка приймає участь у справі і повідомлена належним чином про час і місце третейського розгляду, заявила клопотання про відкладення третейського розгляду з обґрунтуванням причини неявки у засідання, третейський суд може відкласти третейський розгляд, якщо визнає причини неявки поважними.

4. Третейський суд може відкласти третейський розгляд за клопотанням особи, яка приймає участь у справі, у зв'язку з неявкою у засідання його представника з поважної причини.

5. Третейський суд може відкласти третейський розгляд, якщо визнає, що він не може відбутися в даному засіданні, у тому числі внаслідок неявки будь-кого з осіб, які
приймають участь у справі, інших учасників третейського процесу, а також при задоволенні клопотання сторони про відкладення третейського розгляду у зв'язку з необхідністю надання нею додаткових доказів, при здійсненні інших процесуальних дій.

6. При відкладенні третейського розгляду Третейський суд вправі опросити свідків, які з'явилися, якщо в засіданні присутні сторони або їх представники. Показання цих свідків оголошуються у новому засіданні третейського суду.

7. Третейський розгляд може бути відкладено на строк, необхідний для усунення обставин, що послугували підставою для відкладення, але не більше ніж на один місяць.

8. Про відкладення третейського розгляду Третейський суд виносить ухвалу.

9. Про час і місце нового засідання Третейський суд повідомляє осіб, які приймають участь у справі, та інших учасників третейського розгляду. Особи, які з'явились в засідання, повідомляються про час і місце нового засідання безпосередньо в засіданні третейського суду під розписку.

10. Третейський розгляд в новому засіданні третейського суду поновлюється з того моменту, з якого воно було відкладено. Повторний розгляд доказів, досліджених до відкладення третейського розгляду, не провадиться.`
  },
  {
    italic: true,
    content: `Стаття 92. Розгляд третейським судом заяв і клопотань осіб, які приймають участь у справі.`
  },
  {
    content: `1. Заяви і клопотання осіб, які приймають участь у справі, розглядаються третейським судом після того, як буде заслухана думка решти присутніх у засіданні третейського суду осіб, які беруть участь у справі.

2. За результатами розгляду заяв і клопотань третейський суд виносить ухвали.`
  },
  {
    italic: true,
    content: `Стаття 93. Дослідження доказів`
  },
  {
    content: `1. При розгляді справи Третейський суд повинен безпосередньо дослідити докази у справі: ознайомитися з письмовими доказами, оглянути речові докази, заслухати пояснення осіб, які приймають участь у справі, показання свідків, висновки експертизи, а також оголосити такі пояснення, показання, висновки, надані у письмовій формі.

2. Особа, яка приймає участь у справі, вправі дати третейському суду пояснення про представлені нею докази, і докази, витребувані третейським судом за її клопотанням, а також задати питання викликаним у судове засідання експертам і свідкам. При цьому першим задає питання особа, за клопотанням якої були викликані експерти і свідки.`
  },
  {
    italic: true,
    content: `Стаття 94. Перерва у засіданні третейського суду`
  },
  {
    content: `1. Третейський суд за клопотанням особи, яка приймає участь у справі, або за власною ініціативою може об'явити перерву у засіданні третейського суду.

2. Перерва у засіданні третейського суду може бути об'явлена на строк, що не перевищує п'яти днів.

3. Щодо перерви і час продовження засідання, зазначається в ухвалі третейського суду.

4. Після закінчення перерви засідання третейського суду продовжується. Повторний розгляд досліджених до перерви доказів не провадиться, у тому числі при заміні представників осіб, які приймають участь у справі.

5. Особи, які приймають участь у справі і присутні в засіданні третейського суду до оголошення перерви, вважаються належним чином оповіщеними про час і місце засідання третейського суду, і їх нез'явлення у засідання після закінчення перерви не є перешкодою для його продовження.`
  },
  {
    italic: true,
    content: `Стаття 95. Проведення усного слухання`
  },
  {
    content: `1. Для викладення сторонами своїх позицій на підставі доказів, що надаються та для проведення усних промов сторін здійснюється усне слухання справи.

2. Слухання проводиться у закритому засіданні. З дозволу складу третейського суду, або третейського судді, який розглядає справу одноособово, і зі згоди сторін під час слухання можуть бути присутні особи, які не приймають участі у третейському розгляді.`
  },
  {
    italic: true,
    content: `Стаття 96. Розгляд на підставі письмових матеріалів`
  },
  {
    content: `1. Сторони можуть домовитись про розгляд спору на підставі тільки письмових матеріалів наданих сторонами, без проведення усного слухання.

2. Якщо надані матеріали виявляться недостатніми для розгляду спору, склад третейського суду, або суддя, який розглядає справу одноособово, може призначити усне слухання.`
  },
  {
    italic: true,
    content: `Стаття 97. Промови сторін.`
  },
  {
    content: `1. Після з'ясування всіх обставин справи та перевірки їх доказами головуючий у засіданні третейського суду з'ясовує у осіб, які приймають участь у справі, чи не бажають вони доповнити матеріали справи. За відсутністю таких заяв головуючий у засіданні оголошує дослідження доказів закінченими, і третейський суд надає право особам, які приймають участь у справі висловити свою думку про те, як повинна бути вирішена справа, дати оцінку доказам, зробити висновки про встановлення чи невстановлення фактів, що мають значення для правильного розгляду справи, висловитись відносно правової кваліфікації спірних правовідносин з посиланням на закон, який, за їх міркуванням, регулює ці правовідносини, але лише з посиланням на обставини і докази, які були досліджені у засіданні третейського суду.

2. Черговість виступу сторін встановлюється суддею третейського суду, а у разі розгляду справи колегіально - складом колегії суддів.`
  },
  {
    italic: true,
    content: `Стаття 98. Відновлення дослідження доказів`
  },
  {
    content: `1. У разі якщо третейський суд під час або після промов сторін визнає за необхідне з'ясувати додаткові обставини або дослідити нові докази, третейський суд поновлює дослідження доказів, про що виноситься ухвала.

2. Після закінчення додаткового дослідження доказів, промови сторін проводяться у загальному порядку, встановленому цим Регламентом.`
  },
  {
    italic: true,
    content: `Стаття 99. Закінчення розгляду справи по суті`
  },
  {
    content: `1. Після дослідження доказів у справі і промов сторін головуючий у засіданні третейського суду оголошує розгляд справи по суті закінченим, і третейський суд виходить до іншої кімнати для прийняття рішення, про що оголошується присутнім у залі третейського засідання.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 19. Рішення третейського суду`
  },
  {
    italic: true,
    content: `Стаття 100. Прийняття рішення Третейським судом`
  },
  {
    content: `1. Рішення Третейського суду приймається після дослідження усіх обставин справи третейським суддею, що одноособово розглядав справу, або більшістю голосів третейських суддів, які входять до складу третейського суду. Рішення оголошується у засіданні третейського суду.

2. Третейський суд вправі оголосити лише резолютивну частину рішення. У цьому випадку, якщо сторони не погодили строк направлення їм рішення, мотивоване рішення має бути направлене сторонам у строк, який не перевищує п'яти днів з дня оголошення резолютивної частини рішення.

3. Кожній стороні направляється по одному примірнику рішення.

4. У разі відмови сторони одержати рішення Третейського суду або її неявки без поважних причин у засідання Третейського суду, де воно оголошується, рішення вважається таким, що оголошене сторонам, про що на рішенні робиться відповідна відмітка, а копія такого рішення надсилається такій стороні.`
  },
  {
    italic: true,
    content: `Стаття 101. Вимоги до рішення Третейського суду`
  },
  {
    content: `1. Рішення Третейського суду викладається у письмовій формі і підписується третейським суддею, який одноособово розглядав справу, або повним складом третейського суду, що розглядав справу, в тому числі і третейським суддею, який має окрему думку. Окрема думка третейського судді викладається письмово та додається до рішення Третейського суду.

2. Рішення Третейського суду скріплюється підписом Президента Асоціації "Правова Ліга" та круглою печаткою Асоціації "Правова Ліга".

3. У рішенні Третейського суду повинні бути зазначені:
- назва Третейського суду;
- дата прийняття рішення;
- склад третейського суду і порядок його формування:
- місце третейського розгляду;
- сторони, їх представники та інші учасники третейського розгляду, що брали участь у розгляді справи третейським судом;
- висновок про компетенцію Третейського суду, обсяг його повноважень за третейською угодою;
- стислий виклад позовної заяви, відзиву на позовну заяву, заяв, пояснень, клопотань сторін та їхніх представників, інших учасників третейського розгляду;
- встановлені обставини справи, підстави виникнення спору, докази, на підставі яких прийнято рішення, зміст мирової угоди, якщо вона укладена сторонами, мотиви, з яких третейський суд відхилив доводи, докази та заявлені під час третейського розгляду клопотання сторін;
- висновок про задоволення позову або про відмову в позові повністю або частково по кожній із заявлених вимог;
- норми законодавства, якими керувався третейський суд при прийнятті рішення.

4. Висновки третейського суду, що містяться в рішенні по справі, не можуть залежати від настання або ненастання будь-яких обставин.

5. У разі задоволення позовних вимог у резолютивній частині рішення зазначаються:
1) сторона, на користь якої вирішено спір;
2) сторона, з якої за рішенням третейського суду має бути здійснено стягнення грошових сум та/або яка зобов'язана виконати певні дії або утриматися від виконання певних дій;
3) розмір грошової суми, яка підлягає стягненню, та/або дії, які підлягають виконанню або від виконання яких сторона має утриматися за рішенням третейського суду;
4) строк сплати коштів та/або строк і спосіб виконання таких дій;
5) порядок розподілу між сторонами витрат, пов'язаних з вирішенням спору третейським судом;
6) інші обставини, які третейський суд вважає за необхідне зазначити.`
  },
  {
    italic: true,
    content: `Стаття 102. Додаткове рішення`
  },
  {
    content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, може протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про прийняття додаткового рішення щодо вимог, які були заявлені під час третейського розгляду, але не знайшли відображення у рішенні.

2. Заяву про прийняття додаткового рішення має бути розглянуто тим складом третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.

3. За результатами розгляду заяви приймається додаткове рішення, яке є складовою частиною рішення третейського суду, або виноситься мотивована ухвала про відмову у задоволенні заяви про прийняття додаткового рішення.`
  },
  {
    italic: true,
    content: `Стаття 103. Роз'яснення рішення`
  },
  {
    content: `1. Якщо сторони не домовилися про інше, будь-яка із сторін, повідомивши про це іншу сторону, має право протягом семи днів після одержання рішення звернутися до Третейського суду із заявою про роз'яснення резолютивної частини рішення.

2. Заяву про роз'яснення резолютивної частини рішення має бути розглянуто тим складом третейського суду, який вирішував спір, протягом семи днів після її одержання Третейським судом.

3. За результатами розгляду заяви виноситься ухвала про роз'яснення рішення, яка є складовою частиною рішення, або мотивована ухвала про відмову у роз'ясненні рішення.

4. Здійснюючи роз'яснення резолютивної частини рішення, третейський суд не має права змінювати зміст рішення.`
  },
  {
    italic: true,
    content: `Стаття 104. Виправлення рішення`
  },
  {
    content: `1. Третейський суд у тому ж складі, з власної ініціативи або за заявою сторони третейського розгляду, може виправити у рішенні описки, арифметичні помилки або будь-які інші неточності, про що виноситься ухвала, яка є складовою частиною рішення.`
  },
  {
    italic: true,
    content: `Стаття 105. Обов'язковість рішення Третейського суду`
  },
  {
    content: `1. Сторони, які передали спір на вирішення Третейського суду, зобов'язані добровільно виконати рішення Третейського суду, без будь-яких зволікань чи застережень.

2. Сторони та Третейський суд вживають усіх необхідних заходів з метою забезпечення виконання рішення Третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 106. Оскарження рішення Третейського суду`
  },
  {
    content: `1. Рішення Третейського суду є остаточним і оскарженню не підлягає, крім випадків, передбачених Законом України "Про третейські суди".

2. Рішення Третейського суду може бути оскаржене стороною у випадках, передбачених Законом України "Про третейські суди", до компетентного суду відповідно до встановлених законом підвідомчості та підсудності справ.

3. Рішення Третейського суду може бути оскаржене та скасоване лише з таких підстав:
1) справа, по якій прийнято рішення Третейського суду, не підвідомча Третейському суду відповідно до закону;
2) рішення Третейського суду прийнято у спорі, не передбаченому третейською угодою, або цим рішенням вирішені питання, які виходять за межі Третейської угоди. Якщо рішенням Третейського суду вирішені питання, які виходять за межі Третейської угоди, то скасовано може бути лише ту частину рішення, що стосується питань, які виходять за межі Третейської угоди;
3) Третейську угоду визнано недійсною компетентним судом;
4) склад третейського суду, яким прийнято рішення, не відповідав вимогам статей 16, 19 Закону України "Про третейські суди".

4. Заяву про скасування рішення Третейського суду може бути подано до компетентного суду протягом трьох місяців з дня прийняття рішення Третейським судом.

5. Скасування компетентним судом рішення Третейського суду не позбавляє сторони права повторно звернутися до Третейського суду, крім випадків, передбачених цією статтею.

6. У разі якщо рішення Третейського суду скасовано повністю або частково внаслідок визнання компетентним судом недійсною третейської угоди або через те, що рішення прийнято у спорі, який не передбачений Третейською угодою, чи цим рішенням вирішені питання, що виходять за межі Третейської угоди, відповідний спір не підлягає подальшому розгляду в третейських судах.`
  },
  {
    center: true,
    bold: true,
    content: `Глава 20. Ухвали третейського суду`
  },
  {
    italic: true,
    content: `Стаття 107. Винесення Третейським судом ухвал`
  },
  {
    content: `1. З питань, що виникають у процесі розгляду справи і не стосуються суті спору, Третейський суд постановляє ухвали.`
  },
  {
    italic: true,
    content: `Стаття 108. Зміст ухвали`
  },
  {
    content: `1. В ухвалі повинні бути зазначені:
1) дата і місце винесення ухвали;
2) найменування третейського суду, склад суду;
3) найменування і номер справи;
4) найменування осіб, які приймають участь у справі;
5) питання, з якого виноситься ухвала;
6)мотиви, з яких третейський суд дійшов до висновків, прийняв або відхилив доводи осіб, які приймають участь у справі, з посиланням на закони та інші нормативно-правові акти;
7) висновок за результатами розгляду третейським судом питання.

Ухвала, підписується третейським суддею або складом третейського суду,який виніс цю ухвалу.`
  },
  {
    italic: true,
    content: `Стаття 109. Направлення ухвали`
  },
  {
    content: `1. Копії ухвали, направляються особам, які приймають участь у справі, та іншими особам, яких вона стосується, рекомендованим листом з повідомленням про вручення або вручаються їм під розписку.

2. Копії ухвали направляються в п'ятиденний строк з дня винесення ухвали, якщо інший строк не встановлений цим Регламентом.`
  },
  {
    italic: true,
    content: `Стаття 109-1. Виконання ухвали`
  },
  {
    content: `1. Ухвала, винесена третейським судом, виконується негайно, якщо інше не встановлено цим Регламентом або третейським судом.`
  },
  {
    center: true,
    bold: true,
    content: `Розділ 3. Заключні положення
Глава 21. Інше`
  },
  {
    italic: true,
    content: `Стаття 110. Зберігання матеріалів справи, розглянутої Третейським судом`
  },
  {
    content: `1. Справа, розглянута Третейським судом, зберігається у Третейському суді протягом 10 років з дня прийняття рішення Третейського суду.`
  },
  {
    italic: true,
    content: `Стаття 111. Публікація рішень Третейського суду`
  },
  {
    content: `1. Опублікування винесених Третейським судом рішень дозволяється з дозволу голови Третейського суду.

2. При опублікуванні таких рішень необхідно забезпечити нерозголошення комерційної таємниці, слідкувати за тим, щоб не зазначались найменування контрагентів у правочині, найменування цінних паперів, цін, якщо інше не дозволено сторонами справи. Голова Третейського суду може не допустити до опублікування й інші відомості.`
  }
]

export default { regulationsContent, regulationsContent2024, regulationsContent2007, pageData };
