export const advertisementLinks = [
  {
    id: 1,

    description: "Оголошення про розгляд справи, або про прийняте рішення по справі, по якій місцезнаходження чи місце проживання (перебування) учасників справи знаходиться на тимчасово окупованій території, в районі проведення антитерористичної операції або зареєстроване місце проживання (перебування), місцезнаходження чи місце роботи яких невідоме",
    image: "https://via.placeholder.com/300",
  },
  // {
  //   id: 2,
  //   title: "Card 2",
  //   description: "This is the second card.",
  //   image: "https://via.placeholder.com/300",
  // },
  // {
  //   id: 3,
  //   title: "Card 3",
  //   description: "This is the third card.",
  //   image: "https://via.placeholder.com/300",
  // },
];

export const advertisementDetails = {
  1: {
    details: [
      {
        text: `Дата опублікування оголошення - 12.03.24`,
        bold: true,
      },
      {
        text: `Постійно діючий третейський суд при Асоціації «ПРАВОВА ЛІГА» повідомляє про розгляд справи №1/2024, яка порушена за позовом Товариства з обмеженою відповідальністю «ПОЛЕТЕХНІКА», ідентифікаційний код __________ (м. Запоріжжя, вул. Космічна, 121В) до Товариства з обмеженою відповідальністю «______», ідентифікаційний код ___________ (_____________________________) про стягнення 114 800,00 грн., розгляд якої відбудеться 12.04.2024 о 11 год. 30 хв. Місце розгляду справи: 46021, м. Тернопіль, майдан Волі, 4.

Додатково пропонується сторонам подати такі докази:
Відповідачу:
- відзив на позов із доказами, що підтверджують надіслання (надання) відзиву і доданих до нього доказів іншим учасникам справи - протягом 5 днів з дня опублікування даного оголошення.

Попередити сторони, що у випадку ненадання витребуваних третейським судом документів, інших письмових документів чи матеріалів, неявки у засідання третейського суду сторін або їхніх представників, справу буде розглянуто в порядку ст. 89 Регламенту постійно діючого третейського суду при Асоціації «ПРАВОВА ЛІГА», за наявними в ній матеріалами, без участі сторін.
Явка повноважних представників на розсуд сторін.
У разі неявки в судове засідання представників сторін, такі сторони зобов'язані повідомити третейський суд про причини неявки.
Повідомити сторони, що вони мають можливість подавати кореспонденцію, в тому числі, на електронну адресу постійно діючого третейського суду при Асоціації «ПРАВОВА ЛІГА» (sud.te.ua@gmail.com).

Третейський суддя Костишин В.М.

 `,
        bold: false
      },
      {
        text: `Дата опублікування оголошення - 12.05.24`,
        bold: true
      },
      {
        text: `Постійно діючий третейський суд при Асоціації «ПРАВОВА ЛІГА» повідомляє про те, що 12.05.2024 за результатами розгляду справи №1/2024, яка порушена за позовом Товариства з обмеженою відповідальністю «ПОЛЕТЕХНІКА», ідентифікаційний код __________ (м. Запоріжжя, вул. Космічна, 121В) до Товариства з обмеженою відповідальністю «______», ідентифікаційний код ___________ (_____________________________) про стягнення 114 800,00 грн., прийняте рішення, яким: ______________________.

Третейський суддя Костишин В.М.`
      }
    ],
  },
  // 2: {
  //   details: [
  //     { text: "Detailed content for Card 2. Introduction paragraph.", bold: false },
  //     { text: "Another paragraph describing Card 2 in detail.", bold: true },
  //   ],
  // },
  // 3: {
  //   details: [
  //     { text: "Detailed content for Card 3. This card is very special.", bold: true },
  //     { text: "Additional information about Card 3 can be found here.", bold: false },
  //     { text: "Final remarks on Card 3's details.", bold: true },
  //   ],
  // },
};
