import React from "react";
import SertificatePhoto1 from "../assets/img/sertificate1.jpg";
import SertificatePhoto2 from "../assets/img/sertificate2.jpg";
import { Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { animations } from "../constants";
import { Helmet } from "react-helmet";
const Sertificate = () => {
  return (
    <motion.div
      variants={animations.sectionAnimation}
      initial="offscreen"
      animate="onscreen"
    >
      <Helmet>
        <title>Свідоцтво</title>
      </Helmet>
      <Container sx={{ marginTop: "100px" }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "1.5rem", // розмір для мобільних пристроїв
              sm: "2rem", // розмір для планшетів
              md: "2.5rem", // розмір для великих екранів
            },
            textAlign:'center',
            textTransform:'uppercase',
            marginBottom:'50px',
            fontWeight:700,
          }}
        >
          Свідоцтво
        </Typography>
        <img style={{ width: "100%" }} src={SertificatePhoto1} />
        <img style={{ width: "100%" }} src={SertificatePhoto2} />
      </Container>
    </motion.div>
  );
};

export default Sertificate;
