import { Routes, Route, Link } from "react-router-dom";
import React from "react";
import { CssBaseline } from "@mui/material";
import "./App.css";
import Home from "./pages/Home";

import subHeaderMenu from "./constants/links"; // Імпортуємо навігацію
import pageData from "./constants/regulations"; // Імпортуємо контент для сторінок
import regulationsContent from "./constants/regulations";
import regulationsContent2024 from './constants/regulations';
import regulationsContent2007 from './constants/regulations';
import NavBar from "./components/navbar/NavBar";
import Footer from "./components/footer/Footer";
import Principal from "./pages/Principal";
import Documents from "./pages/Documents";
import Regulations from "./pages/Regulations";
import BackToTop from "./components/backToTop/BackToTop";
import Sertificate from "./pages/Sertificate";
import Agreement from "./pages/Agreement";
import Collecting from "./pages/Collecting";
import Judges from "./pages/Judges";
import Contacts from "./pages/Contacts";
import ModalWindow from "./components/modalWindow/ModalWindow";
import ModalWindowResult from "./components/modalWindowResult/ModalWindowResult";
// Імпортуємо PageTemplate
import { ThemeProvider } from "@mui/material/styles";
import myTheme from "./customThemeMUI";
import Advertisement from "./pages/Advertisement";
import AdvertisementDetails from "./pages/AdvertisementDetails";

function App() {

  return (
    <ThemeProvider theme={myTheme}>
      <div className="App">
        <CssBaseline />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="standing" element={<Principal />} />
          <Route path="sertificate" element={<Sertificate />} />
          <Route path="agreement" element={<Agreement />} />
          <Route path="documents" element={<Documents />}>



            {/* Динамічні маршрути для сторінок з ID 2 і 3 */}
            {/* Динамічні маршрути для сторінок з ID 2 і 3 */}
          </Route>
          <Route
            path="/order2023"
            element={
              <Regulations
                helmet={'Регламент(09.02.2021)'}
                title={'2023'}
                descr={regulationsContent}
              />
            }
          />
          <Route
            path="/order2024"
            element={
              <Regulations
              helmet={'Регламент(Поточна редакція)'}
                title={'2024'}
                descr={regulationsContent2024}
              />
            }
          />
          <Route 
          path="/order2007"
          element={
            <Regulations 
              helmet={'Регламент(22.03.2007'}
              title={'2007'}
              descr={regulationsContent2007}
            />
          }
          />

          <Route path="personnel" element={<Judges />} />
          <Route path="collecting" element={<Collecting />} />
          <Route path="advertisement" element={<Advertisement />} />
          <Route path="/card/:id" element={<AdvertisementDetails />} />
          <Route path="contacts" element={<Contacts />} />
        </Routes>
        <BackToTop />
        <ModalWindow />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
