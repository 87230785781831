import React from "react";
import { Container, Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { advertisementLinks } from '../constants';

const Advertisement = () => {
  return (
    <Container>
      <Typography
        sx={{
          textAlign:"center",
          textTransform: "uppercase",
          fontSize: { xs: "35px", sm: "40px" },
          fontWeight: 700,
          margin: "50px 0 25px 0",
        }}
      >
        Оголошення про розгляд справ
      </Typography>
      <Grid container spacing={3} >
        {advertisementLinks.map((card) => (
          <Grid
            item
            key={card.id}
            xs={12} // Картки займають 100% ширини незалежно від розміру екрана
          >
            <Card>
              <CardContent>
                <Typography variant="h5">{card.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {card.description}
                </Typography>
                <Button
                  variant="contained"
                  component={Link}
                  to={`/card/${card.id}`} // Додаємо посилання на сторінку картки
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#cab397", // Встановлюємо кастомний колір фону
                    color: "#fff", // Колір тексту
                    "&:hover": {
                      backgroundColor: "#b09a86", // Колір при наведенні
                    },
                  }}
                >
                  Детально
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Advertisement;
